import { useNavigation } from '@react-navigation/core';

import { PracticeItem } from '@oui/app-core/src/components/PracticeItem';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import { useHopeKitContext } from './HopeKitContext';

export const HopeKitPracticeItem = ({
  complete,
  type,
}: {
  complete: boolean;
  type:
    | 'PendingHopeKitAddAction'
    | 'HopeKitAddAction'
    | 'PendingHopeKitReviewAction'
    | 'HopeKitReviewAction';
}) => {
  const { $t } = useI18n();
  const hopeKitTheme = useHopeKitContext();
  const hopeKitName = hopeKitTheme.name;
  const { navigate } = useNavigation();

  if (type === 'PendingHopeKitAddAction' || type === 'HopeKitAddAction') {
    return (
      <PracticeItem
        complete={complete}
        icon="kit"
        text={$t(
          {
            id: 'ActionTodoItem_hopeKitAdd',
            defaultMessage: 'Add to your {hopeKitName}',
          },
          { hopeKitName },
        )}
        color={hopeKitTheme.mainPracticeTileColor}
        onPress={() => {
          navigate('HopeKit', {});
          navigate('AddHopeKit', {});
        }}
      />
    );
  }

  return (
    <PracticeItem
      complete={complete}
      icon="kit"
      text={$t(
        {
          id: 'ActionTodoItem_hopeKitReview',
          defaultMessage: 'Review your {hopeKitName}',
        },
        { hopeKitName },
      )}
      color={hopeKitTheme.mainPracticeTileColor}
      onPress={() => navigate('HopeKit', {})}
    />
  );
};
