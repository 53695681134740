{
  "ID": "v2-session-01",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "MYPLAN"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Welcome! I'm Aviva. I'm a non-human chatbot that will guide you through this app.",
          "If you've been given this app, it likely means things haven't been great for you lately.",
          "This app is here to hopefully help."
        ]
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "How can it help?",
          "1": "Continue"
        }
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "This app uses a therapy called Cognitive Behavioral Therapy (CBT) to reduce your suicide risk.",
          "In 12 sessions, it will teach you skills and suggest changes in your life so you won't try to harm yourself.",
          "The app uses me, Aviva (a chatbot), animated videos, and the experiences of other patients to do this.",
          "It also has a number of different exercises, diaries and reminders to help you keep track of your progress."
        ]
      }
    },
    {
      "ID": "intro-pause-2",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "What's the proof this works?",
          "1": "Tell me more"
        }
      }
    },
    {
      "ID": "intro-3",
      "type": "ChatText",
      "props": {
        "text": [
          "This app is based on published scientific research.",
          "The research has shown that this treatment can reduce suicide attempts by over 50%.",
          "If you can use this app for 3-5 minutes each day, this app can really help you."
        ]
      }
    },
    {
      "ID": "intro-pause-3",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "So how does it work?",
          "1": "Next"
        }
      }
    },
    {
      "ID": "intro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "Each session uses the same format.",
          "First, I'll introduce a new skill and explain why it's helpful. And there will be some videos to add to that learning.",
          "Often, there will be quiz questions during the videos to stress key points.",
          "Second, we'll give you a chance to use the skill in the session.",
          "Third, after each session, you'll get regular reminders to practice the skill."
        ]
      }
    },
    {
      "ID": "intro-pause-4",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "So what's next?",
          "1": "Continue"
        }
      }
    },
    {
      "ID": "intro-5",
      "type": "ChatText",
      "props": {
        "text": [
          "Let's dive in. This is session 1 of 12. In this session, we'll do three things:",
          "We'll talk about the suicide risk curve.",
          "Then we'll work together to plot your risk curve.",
          "Last, we'll build a plan to help prevent you from attempting suicide."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_VOICEOVER"
        },
        "buttonText": "Play Risk Curve video"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_VOICEOVER"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "transition-to-vignette",
      "type": "ChatText",
      "props": {
        "text": [
          "Before we plot your risk curve, let's watch a video of someone sharing their suicide crisis story, and how the risk curve helped them.",
          "Throughout this app, these people will share their experiences with suicide, and what they're doing to reduce their risk.",
          "There are a number of stories for you to choose from.",
          "You're welcome to watch as many as you like."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "transition-to-risk-curve-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "Hopefully, you now have a good sense about the risk curve, and how it can help.",
          "In the next exercise, I'm going to ask you to think about your recent suicide crisis.",
          "You will organize what happened along the risk curve.",
          "It's ok if you don't remember everything perfectly. You can edit things any time after today. Give it a try."
        ]
      }
    },
    {
      "ID": "prompt-start-risk-curve",
      "type": "InputArtifact",
      "props": {
        "artifactName": "SoloRiskCurve",
        "buttonText": "Build my Risk Curve"
      },
      "param": {
        "retryButton": "Finish Risk Curve",
        "retryText": [
          "Looks like you didn't finish your Risk Curve.",
          "It is important to complete your Risk Curve to continue.",
          "Let's go back and finish up."
        ],
        "completeNode": {
          "ID": "risk-curve-preview",
          "type": "ChatActivityPreview",
          "props": {
            "artifactName": "RiskCurve",
            "linkText": "View Risk Curve"
          }
        }
      }
    },
    {
      "ID": "intro-myplan-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great job plotting your risk curve.",
          "Hopefully it's helped give you some context about how your risk changes in different stages.",
          "You're now ready to do the last part of this session - building MyPlan."
        ]
      }
    },
    {
      "ID": "intro-myplan-pause-1",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "What is MyPlan?"
        }
      }
    },
    {
      "ID": "intro-myplan-2",
      "type": "ChatText",
      "props": {
        "text": [
          "It is a plan you can use to stop the risk curve getting too high.",
          "This plan includes your warning signs, ways to calm down that have worked for you before, and key contacts."
        ]
      }
    },
    {
      "ID": "intro-myplan-pause-2",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "Why is MyPlan important?"
        }
      }
    },
    {
      "ID": "intro-myplan-3",
      "type": "ChatText",
      "props": {
        "text": [
          "During a crisis, it is often hard to think about anything other than the crisis itself.",
          "MyPlan has all the steps you can take to keep you from reaching the crisis point and thus keeps you safe."
        ]
      }
    },
    {
      "ID": "intro-myplan-pause-3",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "How does it work?"
        }
      }
    },
    {
      "ID": "intro-myplan-4",
      "type": "ChatText",
      "props": {
        "text": [
          "When your warning signs tell you your risk is rising, you can use the coping steps in the plan.",
          "And if you feel the risk still rising, the plan has a number of ways to help you.",
          "Here's a short video with more about MyPlan."
        ]
      }
    },
    {
      "ID": "prompt-myplan-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MYPLAN_VOICEOVER"
        },
        "buttonText": "Play MyPlan video"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "myplan-learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "myplan-learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "myplan-learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MYPLAN_VOICEOVER"
        },
        "linkText": "Watch MyPlan Video"
      }
    },
    {
      "ID": "intro-myplan-5",
      "type": "ChatText",
      "props": {
        "text": ["Before you build MyPlan, let's watch someone share how MyPlan helps them."]
      }
    },
    {
      "ID": "prompt-myplan-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MYPLAN_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "myplan-learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "myplan-learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "myplan-learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MYPLAN_LEARN_VIGNETTE"
        },
        "linkText": "MyPlan example"
      }
    },
    {
      "ID": "intro-myplan-6",
      "type": "ChatText",
      "props": {
        "text": [
          "Now let's build your MyPlan.",
          "This will be the last exercise for today so you're nearly done."
        ]
      }
    },
    {
      "ID": "prompt-start-myplan",
      "type": "InputArtifact",
      "props": {
        "artifactName": "SoloMyPlan",
        "buttonText": "Build MyPlan"
      },
      "param": {
        "retryButton": "Finish MyPlan",
        "retryText": [
          "Looks like you didn't finish your MyPlan.",
          "It is important to complete your MyPlan to continue.",
          "Let's go back and finish up."
        ],
        "completeNode": {
          "ID": "myplan-preview",
          "type": "ChatActivityPreview",
          "props": {
            "artifactName": "MyPlan",
            "linkText": "View MyPlan"
          }
        }
      }
    },
    {
      "ID": "outro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Well done!",
          "You've learned about the risk curve, plotted your own curve, and built MyPlan.",
          "You've nearly finished session 1."
        ]
      }
    },
    {
      "ID": "outro-pause-1",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "What else is left?"
        }
      }
    },
    {
      "ID": "outro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "At the end of every session, there are two final tasks: write lessons learned and plan your practice.",
          "First, I'll get you to write in the lessons learned."
        ]
      }
    },
    {
      "ID": "outro-lessons-learned-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "outro-lessons-learned-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "What are the lessons learned?",
              "1": "Got it. Where do I go to do this?"
            }
          }
        },
        "map": {
          "0": {
            "ID": "outro-lessons-learned-mux-0",
            "type": "ChatText",
            "props": {
              "text": [
                "Lessons learned are the one or two most important things you took away from today's session.",
                "You'll do this at the end of every session.",
                "By the end, you'll have a summary of all the important things you have learned.",
                "Anytime you want to edit or review it, go to the \"Profile\" section of the app."
              ]
            }
          },
          "1": {
            "ID": "outro-lessons-learned-mux-1",
            "type": "ChatText",
            "props": {
              "text": [
                "You'll write the lessons learned right here in this chat.",
                "Anytime you want to edit or review them, go to the \"Profile\" section of the app."
              ]
            }
          }
        }
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "outro-3",
      "type": "ChatText",
      "props": {
        "text": [
          "The last thing to do today is talk about practicing what you've learned.",
          "For this session, the task is to use the MyPlan Review so you know what to do if your suicide risk is rising."
        ]
      }
    },
    {
      "ID": "outro-pause-3",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "Got it. What's next?"
        }
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This app has 12 sessions, divided into three parts.",
          "Part 1 has two sessions - build tools that you can use in really tough times.",
          "Part 2 has seven sessions - learn ways to change your thoughts and actions to reduce your risk.",
          "Part 3 has three sessions - review what's been covered and prepare for the future."
        ]
      }
    },
    {
      "ID": "outro-pause-4",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "Anything else?"
        }
      }
    },
    {
      "ID": "outro-5",
      "type": "ChatText",
      "props": {
        "text": [
          "Nope, that's it. You've done really well to get through this.",
          "It's totally normal if not everything made sense straight away.",
          "You can go back and reread or re-watch anything in this session.",
          "Thank you for your time. I look forward to seeing you again."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
