{
  "ID": "v2-session-09",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "COPING_CARDS"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": ["Very happy to see you again.", "Let's start with a check of your practice."]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["THOUGHT_DIARY_SWITCH"],
                "previousSession": "SWITCH_IT"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "SWITCH_IT"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to practice Switch It since your last session? ",
                          "Not to worry. Switch It is a very important skill so I'll make sure to put it in your practice to do list."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I saw you used Switch It last week. Great job.",
                    "How confident did you feel using it?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "Very unconfident",
                        "2": "Unconfident",
                        "3": "OK",
                        "4": "Confident",
                        "5": "Very confident"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "Please keep using it when you have negative thoughts.",
                    "I'll make sure using it stays on your practice reminder list."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": ["Let's move to today's session.", "Today, we're going to make some Coping Cards."]
      }
    },
    {
      "ID": "intro-what-is-copingcards-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-what-is-copingcards-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "What are Coping Cards?",
              "1": "Continue"
            }
          }
        },
        "map": {}
      }
    },
    {
      "ID": "intro-explain-copingcards",
      "type": "ChatText",
      "props": {
        "text": [
          "Coping Cards are just flashcards.",
          "On one side, you'll write common unhelpful thoughts you might have, or a tough situation you might be in.",
          "On the other side, you'll write more helpful thoughts, or how you might handle the situation."
        ]
      }
    },
    {
      "ID": "intro-pause-4",
      "type": "InputButton",
      "props": {
        "label": "How do I make & use them?"
      }
    },
    {
      "ID": "intro-5",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "COPING_CARDS_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "COPING_CARDS_VOICEOVER"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "quiz-question-1-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "Coping Cards help remind you of skills you've learned already and help you deal with negative thoughts. ",
          "You can call upon them when needed to lower your risk."
        ]
      }
    },
    {
      "ID": "copingcards-vignette",
      "type": "ChatText",
      "props": {
        "text": ["Let's now watch someone talking about their experience with the Coping Cards."]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "COPING_CARDS_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "COPING_CARDS_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "copingcards-vignette-apply",
      "type": "ChatText",
      "props": {
        "text": ["Let's now watch someone make, and use, the Coping Cards."]
      }
    },
    {
      "ID": "prompt-start-apply-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "COPING_CARDS_APPLY_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "apply-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "apply-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "apply-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "COPING_CARDS_APPLY_VIGNETTE"
        },
        "linkText": "Watch Apply Video"
      }
    },
    {
      "ID": "transition-to-copingcards-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "You've now seen how to make the Coping Cards, and how they can be helpful.",
          "Let's start making your Coping Cards."
        ]
      }
    },
    {
      "ID": "prompt-start-copingcards",
      "type": "InputArtifact",
      "props": {
        "artifactName": "CopingCards",
        "buttonText": "Make Coping Cards"
      },
      "param": {
        "retryText": [
          "Looks like no cards were added",
          "I know it can be hard to think of something at first. Think about one or two things you have learned over the past 9 sessions that have helped you.",
          "Try adding coping cards again."
        ],
        "retryButton": "Add coping cards",
        "skipButton": "I'll do it later",
        "skipNode": {
          "ID": "copingcards-skip",
          "type": "ChatText",
          "props": {
            "text": ["Got it. Let's wrap up for today."]
          }
        },
        "completeNode": {
          "ID": "copingcards-complete",
          "type": "Seq",
          "children": [
            {
              "ID": "copingcards-preview",
              "type": "ChatActivityPreview",
              "props": {
                "artifactName": "CopingCards",
                "linkText": "View Coping Cards"
              }
            },
            {
              "ID": "copingcards-complete-text",
              "type": "ChatText",
              "props": {
                "text": [
                  "Good job. You've now made some Coping Cards.",
                  "Hopefully you'll find these cards handy in reviewing what you've learned with Aviva."
                ]
              }
            },
            {
              "ID": "copingcards-complete-pause",
              "type": "InputButton",
              "props": {
                "label": "Got it"
              }
            }
          ]
        }
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of every session, there are two final tasks.",
          "First, please write the lessons you learned from this session.",
          "Lessons learned are the one or two most important things you took away from today's session."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what you've learned.",
          "To help, set how many times this week you think you'll try to use your Coping Cards?"
        ]
      }
    },
    {
      "ID": "practice-frequency-store",
      "type": "Store",
      "param": {
        "key": "practiceFrequency",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputSlider",
          "props": {
            "minValue": 1,
            "maxValue": 7,
            "step": 1,
            "value": 3,
            "labels": {}
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "This week, I'll make sure that Coping Cards are on your to do list. ",
          "I'll also be sending you reminders to do the items on your to do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "Coping cards can help you remember all the things we've covered together.",
          "I hope you find it helpful. Take care."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
