import { useMutation } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import omit from 'lodash/omit';
import { useCallback, useEffect } from 'react';
import { AsyncReturnType } from 'type-fest';

import { Button } from '@oui/app-core/src/components/Button';
import { HeaderButtons } from '@oui/app-core/src/components/HeaderButtons';
import { Icon } from '@oui/app-core/src/components/Icon';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { SliderInput } from '@oui/app-core/src/components/SliderInput';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  ActivityFragment,
  getRatingsLabels,
  PracticeFragment,
  useActivityPractice,
} from '@oui/app-core/src/hooks/practices';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card, Shadow, useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { RatingType } from '@oui/lib/src/types/graphql.generated';

import { type ActivityDiaryEntriesQueryName } from './ActivityDiary';
import { ActivityEventInstanceTiming } from './ActivityPractice';
import { useActivityDiaryContext } from '../components';
import { StackScreenProps } from '../types/navigation';

export const UpdateActivityPracticeMutation = graphql(
  `
    mutation UpdateActivityPractice($input: UpdateActivityPracticeInput!) {
      saveActivityDiaryEntry: updateActivityPractice(input: $input) {
        activityPractice {
          ...PracticeFragment
          activity {
            ...ActivityFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, ActivityFragment],
);

export function EditActivityPractice() {
  const { replace, navigate, setOptions } =
    useNavigation<StackScreenProps<'EditActivityPractice'>['navigation']>();
  const { theme } = useTheme();
  const { activityPractice } = useActivityPractice();
  const { params } = useRoute<StackScreenProps<'EditActivityPractice'>['route']>();
  const activityPlan = activityPractice?.activity;
  const [updateActivityPractice] = useMutation(UpdateActivityPracticeMutation);
  const { $t } = useI18n();
  const activityDiaryTheme = useActivityDiaryContext();

  const { data, bind } = useForm({
    ratingBefore:
      activityPractice?.practiceValues.ratings.find((r) => r.type === RatingType.RATING_BEFORE)
        ?.value ?? 3,
    ratingAfter:
      activityPractice?.practiceValues.ratings.find((r) => r.type === RatingType.RATING_AFTER)
        ?.value ?? 3,
  });

  const save = useCallback(() => {
    function afterSave(r: AsyncReturnType<typeof updateActivityPractice>) {
      const newPracticeID = r.data?.saveActivityDiaryEntry.activityPractice.practiceID;
      if (newPracticeID) {
        if (params.fromActivityDiary || params.fromActivityPractice) {
          navigate('ActivityPractice', {
            practiceID: newPracticeID,
          });
        } else {
          replace('ActivityPractice', {
            practiceID: newPracticeID,
          });
        }
      }
    }

    if (!activityPractice) return;

    return updateActivityPractice({
      variables: {
        input: {
          practiceID: activityPractice.practiceID,
          practiceValues: {
            patientID: activityPractice.practiceValues.role.ID,
            date: formatGQLDate(parseGQLDateTime(activityPractice.activity.startTime)),
            ratings: [
              {
                type: RatingType.RATING_BEFORE,
                value: data.ratingBefore,
              },
              {
                type: RatingType.RATING_AFTER,
                value: data.ratingAfter,
              },
            ],
          },
          activity: omit(activityPractice.activity, ['__typename']),
        },
      },
      refetchQueries: ['ActivityDiaryEntries' satisfies ActivityDiaryEntriesQueryName],
    }).then(afterSave);
  }, [
    navigate,
    replace,
    params.fromActivityDiary,
    params.fromActivityPractice,
    updateActivityPractice,
    data,
    activityPractice,
  ]);

  useEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderButtons>
          <Button
            text={$t({ id: 'EditActivityPractice_saveButton', defaultMessage: 'Done' })}
            onPress={save}
            testID="EditActivityPractice_saveButton"
          />
        </HeaderButtons>
      ),
    });
  }, [setOptions, save, $t]);

  const ratingLabels = getRatingsLabels($t);

  if (!activityPlan) return null;
  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      contentContainerStyle={{ paddingHorizontal: 20, paddingVertical: 25 }}
    >
      <View style={[card, { padding: 28, paddingVertical: 20 }, Shadow.high]}>
        <View row spacing={8} style={{ marginBottom: 30 }}>
          <Text text={activityPlan.title} size={21} weight="semibold" style={{ flex: 1 }} />
          <Icon
            aria-label={$t(
              {
                id: 'EditActivityPractice_editButton',
                defaultMessage: `Edit {item}`,
              },
              {
                item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
              },
            )}
            onPress={() => navigate('EditActivityEvent', { practiceID: params.practiceID })}
            name="edit"
            color={theme.color.gray400}
            testID="EditActivityPractice_editButton"
          />
        </View>
        <ActivityEventInstanceTiming
          startTime={activityPlan.startTime}
          endTime={activityPlan.endTime}
        />
        {activityPlan?.attendee ? (
          <View row spacing={16} style={{ marginTop: 20 }}>
            <Icon name="people" color={activityDiaryTheme.mainColor} size={24} />
            <Text text={activityPlan.attendee} weight="semibold" size={17} />
          </View>
        ) : null}
      </View>
      <View style={{ marginTop: 40 }} spacing={12}>
        <Text
          text={$t({
            id: 'EditActivityPractice_ratingBeforeLabel',
            defaultMessage: 'What was your mood before doing this?',
          })}
          textAlign="center"
          size={17}
          weight="semibold"
          style={{ alignSelf: 'center' }}
        />
        <View style={{ paddingHorizontal: 20 }}>
          <SliderInput
            minValue={1}
            minLabel={ratingLabels[1]}
            maxValue={5}
            maxLabel={ratingLabels[5]}
            labels={getRatingsLabels($t)}
            showMinMaxValues
            {...bind('ratingBefore', {
              'aria-label': $t({
                id: 'EditActivityPractice_ratingBeforeLabel',
                defaultMessage: 'What was your mood before doing this?',
              }),
            })}
          />
        </View>
      </View>
      <View style={{ marginTop: 40 }} spacing={12}>
        <Text
          text={$t({
            id: 'EditActivityPractice_ratingAfterLabel',
            defaultMessage: 'What was your mood after doing this?',
          })}
          textAlign="center"
          size={17}
          weight="semibold"
          style={{ alignSelf: 'center' }}
        />
        <View style={{ paddingHorizontal: 20 }}>
          <SliderInput
            minValue={1}
            minLabel={ratingLabels[1]}
            maxValue={5}
            maxLabel={ratingLabels[5]}
            labels={getRatingsLabels($t)}
            showMinMaxValues
            {...bind('ratingAfter', {
              'aria-label': $t({
                id: 'EditActivityPractice_ratingAfterLabel',
                defaultMessage: 'What was your mood after doing this?',
              }),
            })}
          />
        </View>
      </View>
    </ScrollView>
  );
}
