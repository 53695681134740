import { produce } from 'immer';
import { useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import type { CrisisTimeline, RiskFactors } from '@oui/lib/src/types/avro';
import { PeakMethod } from '@oui/lib/src/types/avro/crisisTimeline';

type CheckboxFormAnswer = { checked: boolean; text?: string; order: number };
type CheckboxFormQuestion = { category?: string; label: string; question?: string };
type CheckboxForm<T = CheckboxFormAnswer | undefined> = { [key: string]: T };

export const LETHAL_METHODS: Record<PeakMethod, string> = {
  gun: 'Gun or rifle',
  poison: 'Poisons',
  drugs: 'Drugs',
  prescriptions: 'Prescribed meds',
  suffocation: 'Suffocation',
  hanging: 'Hanging',
  cutting: 'Cutting / piercing',
  other: 'Other',
};

export const FALLING_SECTIONS: Record<string, Record<string, string>> = {
  afterEvents: {
    interruption: 'Interruption',
    medical_treatment: 'Medical treatment',
    therapy: 'Therapy',
    other: 'Other event',
  },
};

export const RISING_SECTIONS: Record<string, Record<string, string>> = {
  beforeEvents: {
    relationship_problems: 'Relationship problems',
    financial_problems: 'Financial problems',
    legal_problems: 'Legal problems',
    work_problems: 'Problems at work',
    health_issues: 'Health issues',
    other_event: 'Other event',
  },
  beforeEventsAdolescent: {
    relationship_problems: 'Relationship stress / conflict (eg. parent, partner, friend/peer)',
    bullying: 'Being bullied / bullying others',
    substance_abuse: 'Abusing substances (ie. drugs / alcohol)',
    school_work_problems: 'Problem with school / work',
    research_suicide: 'Researching ways to die',
    took_risk: 'Took a risk for no reason',
    other_event: 'Other event',
  },
  feelings: {
    depression: 'Depression',
    guilt: 'Guilt',
    anger: 'Anger',
    anxiety: 'Anxiety',
    numbness: 'Numbness',
    shame: 'Shame',
    anguish: 'Mental anguish',
    other_feeling: 'Other feeling',
  },
  thoughts: {
    idiot: `"I'm an idiot."`,
    here_we_go_again: `"Here we go again."`,
    never_ending: `"This will never end."`,
    my_fault: `"It's my fault."`,
    nobody_cares: `"Nobody cares about me."`,
    whats_the_point: `"What's the point?"`,
    cant_take_it: `"I can't take this anymore."`,
    deserved: `"I deserve to be punished."`,
    failure: `"I'm a failure"`,
    other_thought: `Other thought`,
  },
  behaviors: {
    alcohol_use: 'Alcohol use',
    drug_use: 'Drug use',
    self_harm: 'Self-injury',
    pacing: 'Pacing',
    being_quiet: 'Being quiet around others',
    avoiding_others: 'Avoiding others',
    yelling: 'Yelling/screaming',
    crying: 'Crying',
    shaking: 'Shaking/trembling',
    fighting: 'Fighting',
    suicide_preparation: 'Prepping for suicide attempt',
    suicide_practice: 'Practicing suicide attempt',
    other_behavior: 'Other behavior',
  },
  physiological: {
    headaches: 'Headaches or other pain',
    agitation: 'Agitation/feeling on edge',
    racing_heart: 'Racing heart',
    muscle_tension: 'Muscle tension',
    nausea: 'Nausea',
    difficulty_breathing: 'Breathing difficulties',
    insomnia: 'Insomnia',
    other_physiological: 'Other response',
  },
};

export function getCheckboxListInputProps<
  T extends typeof RISING_SECTIONS | typeof FALLING_SECTIONS,
>({
  checkedKeysSet,
  collection,
  section,
  suicideModeSection,
  timeline,
  setTimeline,
}: {
  checkedKeysSet: Set<string>;
  collection: T;
  section: keyof T;
  suicideModeSection: NonNullable<CrisisTimeline['timeline'][number]['suicideModeSection']>;
  timeline: CrisisTimeline['timeline'];
  setTimeline: (newTimeline: CrisisTimeline['timeline']) => void;
}) {
  function addTimelineCheckbox(lookup: Record<string, string>, values: string[]) {
    const keys = Object.keys(lookup);
    const newTimeline = produce(timeline, (draft) => {
      keys.forEach((key) => {
        const isChecked = values.includes(key);
        const matchingTimelineEventIndex = draft.findIndex((e) => e.text.startsWith(lookup[key]));
        if (isChecked && matchingTimelineEventIndex === -1) {
          draft.push({
            ID: uuid(),
            text: lookup[key],
            isWarningSign: false,
            suicideModeSection,
          });
        } else if (!isChecked && matchingTimelineEventIndex !== -1) {
          draft.splice(matchingTimelineEventIndex, 1);
        }
      });
    });
    setTimeline(newTimeline);
  }
  function setTimelineDetails(lookup: Record<string, string>, followups: Record<string, string>) {
    const keys = Object.keys(lookup);
    const newTimeline = produce(timeline, (draft) => {
      keys.forEach((key) => {
        const matchingTimelineEvent = draft.find((e) => e.text.startsWith(lookup[key]));
        if (followups[key] && matchingTimelineEvent) {
          matchingTimelineEvent.text = `${lookup[key]}: ${followups[key]}`;
        } else if (matchingTimelineEvent) {
          matchingTimelineEvent.text = `${lookup[key]}`;
        }
      });
    });
    setTimeline(newTimeline);
  }

  const value = Object.keys(collection[section]).filter((key) => checkedKeysSet.has(key));
  return {
    value,
    followupsValue: value.reduce<Record<string, string>>((carry, key) => {
      const timelineEvent = timeline.find((e) => e.text.startsWith(collection[section][key]));
      if (timelineEvent) {
        const [, ...parts] = timelineEvent.text.split(': ');
        carry[key] = parts.join(': ') ?? '';
      }
      return carry;
    }, {}),
    onChangeValue: (newValue: string[]) => {
      addTimelineCheckbox(collection[section], newValue);
    },
    onChangeFollowupsValue: (followups: Record<string, string>) => {
      setTimelineDetails(collection[section], followups);
    },
    items: collection[section],
  };
}

const RISK_FACTORS: CheckboxForm<CheckboxFormQuestion> = {
  previous_suicide_attempts: {
    label: 'Previous suicide attempts',
    question: 'How many attempts?',
  },
  previous_psychiatric_diagnoses: {
    label: 'Previous psychiatric diagnoses',
    question: 'What was the diagnosis?',
  },
  history_abuse_trauma: {
    label: 'History of abuse or trauma',
    question: 'What was the trauma?',
  },
  family_history_suicide: {
    label: 'Family history of suicide',
    question: 'Who died by suicide?',
  },
  Other: { label: 'Other', question: 'What is the risk factor?' },
};

export function useRiskFactorsState(initialState: RiskFactors) {
  const [value, setValue] = useState(initialState);
  const inputProps = useMemo(() => {
    const keys = Object.keys(RISK_FACTORS);
    const is: Record<string, string> = {};
    const fs: Record<string, string> = {};
    const fVs: Record<string, string> = {};
    const inputValue = [];

    for (let key of keys) {
      is[key] = RISK_FACTORS[key].label;
      const question = RISK_FACTORS[key].question;
      const matchingFactor = value.find((v) => v.text.startsWith(is[key]));
      if (question) {
        fs[key] = question;
      }
      if (matchingFactor) {
        const [, followupValue] = matchingFactor.text.split(': ');
        fVs[key] = followupValue;
      }
      if (matchingFactor) inputValue.push(key);
    }
    return {
      value: inputValue,
      items: is,
      followups: fs,
      followupsValue: fVs,
      onChangeValue: (newValue: string[]) => {
        const newFactors = produce(value, (draft) => {
          keys.forEach((key) => {
            const isChecked = newValue.includes(key);
            const matchingFactorIndex = value.findIndex((v) => v.text.startsWith(is[key]));
            if (isChecked && matchingFactorIndex === -1) {
              draft.push({
                ID: uuid(),
                text: RISK_FACTORS[key].label,
              });
            } else if (!isChecked && matchingFactorIndex !== -1) {
              draft.splice(matchingFactorIndex, 1);
            }
          });
        });
        setValue(newFactors);
      },
      onChangeFollowupsValue: (followups: Record<string, string>) => {
        const lookup = RISK_FACTORS;
        const newFactors = produce(value, (draft) => {
          keys.forEach((key) => {
            const matchingFactor = draft.find((e) => e.text.startsWith(lookup[key].label));
            if (followups[key] && matchingFactor) {
              matchingFactor.text = `${lookup[key].label}: ${followups[key]}`;
            } else if (matchingFactor) {
              matchingFactor.text = `${lookup[key].label}`;
            }
          });
        });
        setValue(newFactors);
      },
    };
  }, [value]);

  return { value, inputProps };
}

export function formatCrisisPeakText({
  type,
  checked,
  followupsValue,
  formatList,
}: {
  type: 'attempt' | 'ideation' | undefined;
  checked: PeakMethod[];
  followupsValue: Record<string, string>;
  formatList: (list: string[]) => string;
}) {
  const items = LETHAL_METHODS;
  const means = checked.map((key) => {
    const followup = followupsValue[key];
    return followup ? `${items[key]} (${followup})` : items[key];
  });
  return type
    ? `${
        type === 'attempt' ? 'I made an attempt' : 'I had strong thoughts and/or a plan'
      } via ${formatList(means)}`
    : `Via ${formatList(means)}`;
}
