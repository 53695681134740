[
  {
    "ID": "1$001",
    "t": "2019-07-03T18:56:03.57736-04:00",
    "kind": 1,
    "from": 1,
    "props": {
      "text": ["Welcome back, Ms. Test!", "We've covered a lot of ground together so far."]
    }
  }
]
