{
  "ID": "e2e-test",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "TEST"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": ["Hi there tester"]
      }
    },
    {
      "ID": "button-1",
      "type": "InputButton",
      "props": {
        "label": "Continue"
      }
    },
    {
      "ID": "mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "mux-body",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "A",
              "1": "B",
              "2": "ERROR"
            }
          }
        },
        "map": {
          "1": {
            "ID": "mux-branch-1",
            "type": "ChatText",
            "props": {
              "text": ["B was chosen"]
            }
          }
        }
      }
    },
    {
      "ID": "artifact",
      "type": "InputArtifact",
      "props": {
        "artifactName": "TestWidget",
        "params": {},
        "buttonText": "Use Test Widget"
      },
      "param": {
        "retryText": ["Retry path."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "skipped",
          "type": "ChatText",
          "props": {
            "text": ["Artifact skipped."]
          }
        }
      }
    },
    {
      "ID": "outro",
      "type": "ChatText",
      "props": {
        "text": ["Goodbye."]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
