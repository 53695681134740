import type {
  compile as compileType,
  parseWithoutProcessing as parseWithoutProcessingType,
  registerHelper as registerHelperType,
} from 'handlebars';

import { formatHoursAndMinutes } from './formatHoursAndMinutes';

const Handlebars: {
  registerHelper: typeof registerHelperType;
  compile: typeof compileType;
  parseWithoutProcessing: typeof parseWithoutProcessingType;
} = require('handlebars/dist/handlebars').default;

/// <reference path="handlebars" />

// This is a simple pluralization helper meant for english only but could be extended for other languages
Handlebars.registerHelper('plural', function (num, oneString, otherString) {
  const sourceString = num === 1 ? oneString : otherString;
  return sourceString.replace(/#/g, num);
});

Handlebars.registerHelper('json', function (context) {
  // context is undefined if the referenced variable passed to this function is not present
  if (!context) return 'null';
  // @ts-expect-error
  return JSON.stringify(context.fn ? context.fn(this) : context);
});

// This helper can be used when we expect a JSON value (e.g. boolean, number) to be output
// but we don't want to stringify the result past the default stringify behavior
// Useful in stateMachine.evaluateTemplate where we check for "{{json" blocks to execute JSON.parse
Handlebars.registerHelper('jsonparse', function (context) {
  // context is undefined if the referenced variable passed to this function is not present
  if (typeof context === 'undefined' || context === null) return 'null';
  // @ts-expect-error
  return context.fn ? context.fn(this) : context;
});

Handlebars.registerHelper('formatTime', function (arg1, options) {
  return formatHoursAndMinutes(options.data.root.__LOCALE, arg1);
});

Handlebars.registerHelper('eq', function (arg1, arg2) {
  return arg1 === arg2;
});

Handlebars.registerHelper('and', function (arg1, arg2, options) {
  if (arg1 && arg2) {
    // @ts-expect-error
    return options.fn(this);
  }
  // @ts-expect-error
  return options.inverse(this);
});

Handlebars.registerHelper('or', function (...args) {
  const toCheck = [...args];
  const options = toCheck.pop();
  if (toCheck.some((v) => !!v)) {
    // @ts-expect-error
    return options.fn(this);
  }
  // @ts-expect-error
  return options.inverse(this);
});

Handlebars.registerHelper('gt', function (a, b) {
  return a > b;
});

Handlebars.registerHelper('sum', function (...args) {
  const toSum = [...args];
  toSum.pop(); // remove options argument
  return toSum.reduce<number>((carry, curr) => {
    if (typeof curr !== 'number') return carry;
    return carry + curr;
  }, 0);
});

export function evaluateTemplate(template: string, context: object) {
  const t = Handlebars.compile(template, { noEscape: true });
  return t(context);
}

export function getTemplateVariables(template: string) {
  const variables = [];

  const ast = Handlebars.parseWithoutProcessing(template);
  for (let el of ast.body) {
    if (el.type === 'MustacheStatement' || el.type === 'BlockStatement') {
      const stmt = el as hbs.AST.MustacheStatement | hbs.AST.BlockStatement;
      if (stmt.params.length > 0) {
        for (let param of stmt.params) {
          if (param.type === 'PathExpression') {
            const p = param as hbs.AST.PathExpression;
            variables.push(p.original);
          }
        }
      } else if (stmt.path.type === 'PathExpression') {
        const path = stmt.path as hbs.AST.PathExpression;
        variables.push(path.original);
      }
    }
  }

  return variables;
}
