{
  "ID": "v2-session-02",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "ENVIRONMENT_SAFETY"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": ["THIS IS PLACEHOLDER CONTENT", "I'm really happy to see you here."]
      }
    },
    {
      "ID": "prompt-start-environment-safety",
      "type": "InputArtifact",
      "props": {
        "artifactName": "SoloMyPlan",
        "params": { "step": "edit", "initialEditingSection": "ENVIRONMENT_SAFETY" },
        "buttonText": "Environment Safety"
      },
      "param": {
        "retryButton": "Try again",
        "retryText": ["Looks like you didn't finish."],
        "completeNode": {
          "ID": "environment-safety-preview",
          "type": "ChatActivityPreview",
          "props": {
            "artifactName": "MyPlan",
            "linkText": "Environment Safety"
          }
        }
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "Well done on finishing this session.",
          "The Hope Kit has helped a lot of people and it can help you too.",
          "Take care and see you next session!"
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
