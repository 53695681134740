import { useNavigation } from '@react-navigation/core';
import noop from 'lodash/noop';

import { PracticeItem } from '@oui/app-core/src/components/PracticeItem';
import { useI18n } from '@oui/app-core/src/lib/i18n';

export const ActivityDiaryPracticeItem = (
  props: {
    complete: boolean;
  } & (
    | {
        type:
          | 'PendingActivityAddAction'
          | 'ActivityAddAction'
          | 'PendingActivityRateAction'
          | 'ActivityRateAction';
        practice: { practiceID: string; activity: { title: string } };
      }
    | {
        type: 'PendingActivityAddAction' | 'ActivityAddAction';
        practice: null;
      }
  ),
) => {
  const { $t } = useI18n();
  const { navigate } = useNavigation();

  if (props.type === 'PendingActivityRateAction' || props.type === 'ActivityRateAction') {
    return (
      <PracticeItem
        complete={props.complete}
        icon="calendar"
        text={$t(
          {
            id: 'ActionTodoItem_activityRate',
            defaultMessage: 'Rate {title}',
          },
          { title: props.practice.activity.title },
        )}
        color="#1b9fa0"
        onPress={
          props.type === 'PendingActivityRateAction'
            ? () => {
                navigate('ActivityDiary', {});
                navigate('ActivityPractice', {
                  practiceID: props.practice.practiceID,
                });
                navigate('EditActivityPractice', {
                  practiceID: props.practice.practiceID,
                });
              }
            : noop
        }
      />
    );
  }

  return (
    <PracticeItem
      complete={props.complete}
      icon="calendar"
      text={$t({
        id: 'ActionTodoItem_activityAdd',
        defaultMessage: 'Add an activity',
      })}
      color="#1b9fa0"
      onPress={() => {
        navigate('ActivityDiary', {});
        navigate('NewActivityEvent');
      }}
    />
  );
};
