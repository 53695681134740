import type { RouteProp } from '@react-navigation/core';
import type { StackNavigationProp } from '@react-navigation/stack';

import {
  BooleanParam,
  CoreRootStackParamList,
  DeeplinkConfigShape,
} from '@oui/app-core/src/types/navigation';
import { GQLDateOnly } from '@oui/lib/src/types/scalars';

export type SleepDiaryStackParamList = {
  SleepDiary: {};
  SetupSleepDiary: {
    fromSleepDiary?: BooleanParam; // used by SleepDiary screen to help "Done" button navigation
  };
  EditSleepDiaryEntry: {
    step: 'morning' | 'night';
    date: GQLDateOnly;
  };
  SleepDiaryEntry: { date: GQLDateOnly };
};

export const SLEEP_DIARY_DEEPLINK_CONFIG = {
  EditSleepDiaryEntry: 'sleep-diary/:date/edit',
  SetupSleepDiary: 'sleep-diary/setup',
  SleepDiary: 'sleep-diary',
  SleepDiaryEntry: 'sleep-diary/:date',
} as const satisfies DeeplinkConfigShape<keyof SleepDiaryStackParamList>;

type FullStackParamList = SleepDiaryStackParamList & CoreRootStackParamList;

export type StackScreenProps<
  T extends keyof SleepDiaryStackParamList,
  Other extends object = {},
> = {
  navigation: StackNavigationProp<FullStackParamList, T>;
  route: RouteProp<FullStackParamList, T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends FullStackParamList {}
  }
}
