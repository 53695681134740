import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  MyStoryMyPlanCompositionDataHash,
  useMyStoryMyPlanCompositionSections,
  useMyStoryMyPlanCompositionSectionSubscriptionData,
} from '@oui/app-core/src/hooks/useComposition';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card, useTheme } from '@oui/app-core/src/styles';

import { EnvironmentSafety, PatientMyPlan } from '../components/PatientMyPlan';
import { PatientMyStoryMyPlanContainer } from '../components/PatientMyStoryMyPlanContainer';

function ReviewInner({ data }: { data: MyStoryMyPlanCompositionDataHash | null | undefined }) {
  const { theme } = useTheme();

  return (
    <>
      <View
        style={[
          card,
          { margin: 20, padding: 20, borderColor: theme.color.primary100, borderWidth: 2 },
        ]}
      >
        <View
          style={{
            paddingHorizontal: 20,
            paddingBottom: 10,
            marginBottom: 10,
            marginTop: -10,
            marginHorizontal: -20,
            borderBottomWidth: 1,
            borderColor: theme.color.gray600,
          }}
        >
          <Text text="Safety steps" color={theme.color.gray300} weight="semibold" />
        </View>
        {data ? <PatientMyPlan data={data} isEditing={false} onEdit={() => {}} /> : null}
      </View>
      <View
        style={[
          card,
          { margin: 20, padding: 20, borderColor: theme.color.primary100, borderWidth: 2 },
        ]}
      >
        {data ? <EnvironmentSafety data={data} isEditing={false} onEdit={() => {}} /> : null}
      </View>
    </>
  );
}

// Used for rendering live updated data via subscription
export function PatientMyPlanReview() {
  const { $t } = useI18n();
  const { data } = useMyStoryMyPlanCompositionSectionSubscriptionData();

  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientMyPlanReview_heading', defaultMessage: 'MyPlan' })}
      testID="PatientMyPlanReview"
    >
      <ReviewInner data={data} />
    </PatientMyStoryMyPlanContainer>
  );
}

// Used for rendering latest data from API without live updating subscription
export function PatientMyPlanReviewStatic() {
  const { $t } = useI18n();
  const { loading, data } = useMyStoryMyPlanCompositionSections();

  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientMyPlanReview_heading', defaultMessage: 'MyPlan' })}
      testID="PatientMyPlanReview"
    >
      {loading ? <ActivityIndicator /> : <ReviewInner data={data} />}
    </PatientMyStoryMyPlanContainer>
  );
}
