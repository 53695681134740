{
  "ID": "v2-session-08",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "SWITCH_IT"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": ["Welcome back!", "Let's get started."]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["THOUGHT_DIARY_TEST"],
                "previousSession": "TEST_IT"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "TEST_IT"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to practice 'Test It' since your last session? ",
                          "Not to worry. 'Test It' is a very important skill so I'll make sure to put it in your practice to do list."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I see you used 'Test It' on some of the negative thoughts you spotted last time. Great job.",
                    "How confident did you feel with the practice?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "Very unconfident",
                        "2": "Unconfident",
                        "3": "OK",
                        "4": "Confident",
                        "5": "Very confident"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "Keep up the good work!",
                    "I'll make sure there are reminders to practice 'Test It' in the coming weeks."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Let's move to today's session.",
          "Today, we're going to do work on a skill called 'Switch It'.",
          "It is a skill that builds on the last two sessions, 'Spot It' and 'Test It'."
        ]
      }
    },
    {
      "ID": "intro-what-is-switchit-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-what-is-switchit-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "What will I be switching?",
              "1": "Continue"
            }
          }
        },
        "map": {}
      }
    },
    {
      "ID": "intro-explain-switchit",
      "type": "ChatText",
      "props": {
        "text": [
          "You have learned to spot a negative thought, and test that thought.",
          "If you find the thought is not really true, or that it is not helpful, then why have that thought?",
          "The goal in this session is to learn ways to switch the thought."
        ]
      }
    },
    {
      "ID": "intro-pause-4",
      "type": "InputButton",
      "props": {
        "label": "How do I learn how to 'Switch It'?"
      }
    },
    {
      "ID": "intro-5",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SWITCH_IT_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SWITCH_IT_VOICEOVER"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "learn-voiceover-followup",
      "type": "ChatText",
      "props": {
        "text": ["Let's now watch someone talking about their experience with 'Switch It'."]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SWITCH_IT_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SWITCH_IT_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "switchit-vignette-apply",
      "type": "ChatText",
      "props": {
        "text": ["Let's now watch someone using the Thought Diary to practice 'Switch It'."]
      }
    },
    {
      "ID": "prompt-start-apply-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SWITCH_IT_APPLY_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "apply-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "apply-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "apply-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SWITCH_IT_APPLY_VIGNETTE"
        },
        "linkText": "Watch Apply Video"
      }
    },
    {
      "ID": "transition-to-switchit-widget",
      "type": "ChatText",
      "props": {
        "text": ["Let's now use your Thought Diary to switch untrue, or unhelpful, thoughts."]
      }
    },
    {
      "ID": "prompt-start-switchit",
      "type": "InputArtifact",
      "props": {
        "artifactName": "ThoughtDiary",
        "buttonText": "Use Thought Diary"
      },
      "param": {
        "retryText": [
          "Looks like no unhelpful thoughts were switched.",
          "I know it can be hard to think of something at first. It doesn't have to be perfect.",
          "Try again"
        ],
        "retryButton": "Switch thought",
        "skipButton": "I'll do it later",
        "skipNode": {
          "ID": "switchit-skip",
          "type": "ChatText",
          "props": {
            "text": ["Got it. Let's wrap up for today."]
          }
        },
        "completeNode": {
          "ID": "switchit-complete",
          "type": "Seq",
          "children": [
            {
              "ID": "switchit-preview",
              "type": "ChatActivityPreview",
              "props": {
                "artifactName": "ThoughtDiary",
                "linkText": "View Thought Diary"
              }
            },
            {
              "ID": "switchit-complete-text",
              "type": "ChatText",
              "props": {
                "text": [
                  "Good job. You've now learned some ways to switch negative thoughts.",
                  "This is another important skill to use in preventing you from getting into a spiral of negative thoughts and feelings."
                ]
              }
            },
            {
              "ID": "switchit-complete-pause",
              "type": "InputButton",
              "props": {
                "label": "Got it"
              }
            }
          ]
        }
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of every session, there are two final tasks.",
          "First, let's add to your 'Lessons Learned'."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what you've learned.",
          "Please use what you have learned today whenever it would be useful. As I‘ve said many times before, it is the best way to benefit from this app.",
          "To help, set how many times this week you think you'll try to practice switching thoughts?"
        ]
      }
    },
    {
      "ID": "practice-frequency-store",
      "type": "Store",
      "param": {
        "key": "practiceFrequency",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputSlider",
          "props": {
            "minValue": 1,
            "maxValue": 7,
            "step": 1,
            "value": 3,
            "labels": {}
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "This week, I'll make sure that switching thoughts is on your to do list for this week.",
          "I'll also be sending you reminders to do the other items on your to do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "You've just finished all three parts - 'Spot It', 'Test It' and 'Switch It'.",
          "It can feel a little strange to think about how you think.",
          "But I hope you see it can help.",
          "See you soon."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
