import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';

import { SleepDiary } from './screens';

registerChatArtifactPreviewComponent('SleepDiary', () => SleepDiary);

export * from './screens';
export * from './components';
export * from './hooks';
export * from './types/navigation';
export * from './register';
