import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import addMinutes from 'date-fns/addMinutes';
import startOfHour from 'date-fns/startOfHour';
import omit from 'lodash/omit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TextInput as RNTextInput } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Button } from '@oui/app-core/src/components/Button';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { DateTimeInput } from '@oui/app-core/src/components/DateTimeInput';
import { Icon } from '@oui/app-core/src/components/Icon';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { TextInput } from '@oui/app-core/src/components/TextInput';
import { View } from '@oui/app-core/src/components/View';
import {
  ActivityFragment,
  PracticeFragment,
  useActivityPractice,
} from '@oui/app-core/src/hooks/practices';
import { useCurrentPatientID } from '@oui/app-core/src/hooks/useCurrentUser';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card, Shadow, useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { parseHoursAndMinutes } from '@oui/lib/src/parseHoursAndMinutes';
import { AddActivityPracticeInput } from '@oui/lib/src/types/graphql.generated';
import { GQLDateTime, GQLTime } from '@oui/lib/src/types/scalars';

import { type ActivityDiaryEntriesQueryName } from './ActivityDiary';
import { UpdateActivityPracticeMutation } from './EditActivityPractice';
import { useActivityDiaryContext } from '../components';
import { StackScreenProps } from '../types/navigation';

export const AddActivityPracticeMutation = graphql(
  `
    mutation AddActivityPractice($input: AddActivityPracticeInput!) {
      saveActivityDiaryEntry: addActivityPractice(input: $input) {
        activityPractice {
          ...PracticeFragment
          activity {
            ...ActivityFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, ActivityFragment],
);

export function EditActivityEvent() {
  const navigation =
    useNavigation<StackScreenProps<'EditActivityEvent' | 'NewActivityEvent'>['navigation']>();
  const insets = useSafeAreaInsets();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [now] = useState(() => new Date());
  const defaultStartTime = startOfHour(addMinutes(now, 60));
  const defaultStartTimeStr = defaultStartTime.toISOString();
  const defaultEndTimeStr = addMinutes(defaultStartTime, 30).toISOString();
  const locationInputRef = useRef<RNTextInput>(null);
  const notesInputRef = useRef<RNTextInput>(null);
  const { practiceID, activityPractice } = useActivityPractice();
  const patientID = useCurrentPatientID();
  const [updateActivityPractice] = useMutation(UpdateActivityPracticeMutation);
  const [addActivityPractice] = useMutation(AddActivityPracticeMutation);
  const { $t, formatDate } = useI18n();
  const activityDiaryTheme = useActivityDiaryContext();

  const { theme } = useTheme();
  const { dirty, validate, data, bind, errors } = useForm<AddActivityPracticeInput['activity']>({
    endTime: defaultEndTimeStr as GQLDateTime,
    startTime: defaultStartTimeStr as GQLDateTime,
    attendee: '',
    location: '',
    notes: '',
    title: '',
    ...(activityPractice ? omit(activityPractice.activity, ['__typename']) : undefined),
  });

  const setOptions = navigation.setOptions;
  const goBack = navigation.goBack;
  const navigate = navigation.navigate;
  const replace = navigation.replace;

  const save = useCallback(
    async function () {
      if (validate()) {
        if (practiceID && activityPractice) {
          return updateActivityPractice({
            variables: {
              input: {
                practiceID,
                practiceValues: {
                  patientID: activityPractice.practiceValues.role.ID,
                  date: formatGQLDate(parseGQLDateTime(data.startTime)),
                  ratings: activityPractice.practiceValues.ratings,
                },
                activity: data,
              },
            },
          }).then(() => {
            navigate('ActivityPractice', { practiceID });
          });
        } else {
          return addActivityPractice({
            variables: {
              input: {
                practiceValues: {
                  date: formatGQLDate(parseGQLDateTime(data.startTime)),
                  patientID: patientID!,
                  ratings: [],
                },
                activity: data,
              },
            },
            refetchQueries: ['ActivityDiaryEntries' satisfies ActivityDiaryEntriesQueryName],
          }).then((r) => {
            const entry = r.data?.saveActivityDiaryEntry.activityPractice;
            if (entry) {
              const isInPast = parseGQLDateTime(entry.activity.endTime) < new Date();
              replace('ActivityPractice', { practiceID: entry.practiceID });
              if (isInPast) {
                navigate('EditActivityPractice', {
                  practiceID: entry.practiceID,
                  fromActivityPractice: true,
                });
              }
            }
          });
        }
      }
      return;
    },
    [
      validate,
      addActivityPractice,
      updateActivityPractice,
      data,
      replace,
      navigate,
      patientID,
      practiceID,
      activityPractice,
    ],
  );

  useEffect(() => {
    setOptions({
      headerLeft: ({ tintColor }) => (
        <Icon
          aria-label={$t({
            id: 'EditActivityEvent_cancelButton',
            defaultMessage: 'Cancel',
          })}
          style={{ marginLeft: 10 }}
          onPress={() => {
            if (dirty) {
              setShowConfirmationModal(true);
            } else {
              goBack();
            }
          }}
          name="close"
          color={tintColor}
        />
      ),
      headerRight: () => (
        <Button
          onPress={save}
          text={$t({ id: 'EditActivityEvent_saveButton', defaultMessage: 'Save' })}
          style={{ marginRight: 8, paddingVertical: 6, paddingHorizontal: 14 }}
          testID="EditActivityEvent_saveButton"
        />
      ),
      title: practiceID
        ? $t(
            {
              id: 'EditActivityEvent_editHeader',
              defaultMessage: `Edit {item}`,
            },
            {
              item: activityDiaryTheme.item.singular,
            },
          )
        : $t(
            {
              id: 'EditActivityEvent_addHeader',
              defaultMessage: `Add {item}`,
            },
            {
              item: activityDiaryTheme.item.singular,
            },
          ),
    });
  }, [dirty, data, setOptions, goBack, save, practiceID, activityDiaryTheme, $t]);

  const { value: startDateTime, onChangeValue: changeStartDateTime } = bind(['startTime'], {
    'aria-label': 'Start on',
  });
  const startDate = formatGQLDate(parseGQLDateTime(startDateTime));
  const startHHMM = formatDate(parseGQLDateTime(startDateTime), {
    hour: 'numeric',
    minute: '2-digit',
    hourCycle: 'h23',
  }) as GQLTime;
  const { value: endDateTime, onChangeValue: changeEndDateTime } = bind(['endTime'], {
    'aria-label': $t({
      id: 'EditActivityEvent_endTimeAccessibilityLabel',
      defaultMessage: 'End time',
    }),
    validator: () => {
      if (parseGQLDateTime(data.endTime) < parseGQLDateTime(data.startTime)) {
        return $t({
          id: 'EditActivityEvent_endTimeValidationError',
          defaultMessage: 'End time cannot be before start time',
        });
      }
      return undefined;
    },
  });

  const endHHMM = formatDate(parseGQLDateTime(endDateTime), {
    hour: 'numeric',
    minute: '2-digit',
    hourCycle: 'h23',
  }) as GQLTime;

  return (
    <>
      <ScrollView
        style={{ flex: 1, backgroundColor: theme.color.gray800 }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingTop: 25,
          paddingBottom: insets.bottom + 25,
        }}
      >
        <View style={[card, { paddingVertical: 20, padding: 14, marginBottom: 20 }, Shadow.high]}>
          <TextInput
            placeholder={$t(
              {
                id: 'EditActivityEvent_titlePlaceholder',
                defaultMessage: `Name of {item}`,
              },
              {
                item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
              },
            )}
            {...bind(['title'], {
              label: activityDiaryTheme.item.singular,
              validator: { type: 'present' },
            })}
          />
          <View style={{ marginTop: 40 }}>
            <DateTimeInput
              mode="date"
              value={startDate}
              onChangeValue={(newDate) => {
                changeStartDateTime(
                  parseHoursAndMinutes(
                    startHHMM,
                    parseGQLDateTime(newDate),
                  ).toISOString() as GQLDateTime,
                );
                changeEndDateTime(
                  parseHoursAndMinutes(
                    endHHMM,
                    parseGQLDateTime(newDate),
                  ).toISOString() as GQLDateTime,
                );
              }}
              label={$t({ id: 'EditActivityEvent_startDate', defaultMessage: 'Date' })}
              testID="EditActivityEvent_startDate"
            />
            <View row spacing={10} style={{ marginTop: 10 }}>
              <DateTimeInput
                mode="time"
                style={{ flex: 1 }}
                value={startHHMM}
                onChangeValue={(newStartHHMM) => {
                  changeStartDateTime(
                    parseHoursAndMinutes(
                      newStartHHMM,
                      parseGQLDateTime(startDate),
                    ).toISOString() as GQLDateTime,
                  );
                }}
                aria-label={$t({
                  id: 'EditActivityEvent_startTimeAccessibilityLabel',
                  defaultMessage: 'Start time',
                })}
                testID="EditActivityEvent_startTime"
              />
              <Text
                text={$t({
                  id: 'EditActivityEvent_durationPrepositionLabel',
                  defaultMessage: 'to',
                })}
                weight="semibold"
              />
              <DateTimeInput
                aria-label={$t({
                  id: 'EditActivityEvent_endTimeAccessibilityLabel',
                  defaultMessage: 'End time',
                })}
                mode="time"
                style={{ flex: 1 }}
                value={endHHMM}
                onChangeValue={(newEndHHMM) => {
                  changeEndDateTime(
                    parseHoursAndMinutes(
                      newEndHHMM,
                      parseGQLDateTime(startDate),
                    ).toISOString() as GQLDateTime,
                  );
                }}
                testID="EditActivityEvent_endTime"
              />
            </View>
            {errors?.endTime ? <Text text={errors.endTime} color={theme.color.danger} /> : null}
          </View>
        </View>
        <View spacing={20}>
          <Text
            text={$t({ id: 'EditActivityEvent_optional', defaultMessage: 'Optional' })}
            weight="semibold"
            color={theme.color.gray300}
          />
          <View row spacing={16}>
            <Icon name="people" color={theme.color.gray500} size={24} />
            <TextInput
              {...bind(['attendee'], {
                'aria-label': $t({
                  id: 'EditActivityEvent_attendeeAccessibilityLabel',
                  defaultMessage: 'Attendees',
                }),
              })}
              style={{ flex: 1 }}
              placeholder={$t(
                {
                  id: 'EditActivityEvent_attendeePlaceholder',
                  defaultMessage: `Do {item} with`,
                },
                {
                  item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
                },
              )}
              enterKeyHint="next"
              onSubmitEditing={() => locationInputRef.current?.focus()}
            />
          </View>
          <View row spacing={16}>
            <Icon name="location" color={theme.color.gray500} size={24} />
            <TextInput
              {...bind(['location'], {
                'aria-label': $t({
                  id: 'EditActivityEvent_locationAccessibilityLabel',
                  defaultMessage: 'Location',
                }),
              })}
              style={{ flex: 1 }}
              placeholder={$t({
                id: 'EditActivityEvent_locationPlaceholder',
                defaultMessage: 'Activity location',
              })}
              ref={locationInputRef}
              enterKeyHint="next"
              onSubmitEditing={() => notesInputRef.current?.focus()}
            />
          </View>
          <View row spacing={16}>
            <Icon name="sessions" color={theme.color.gray500} size={24} />
            <TextInput
              {...bind(['notes'], {
                'aria-label': $t({
                  id: 'EditActivityEvent_notesAccessibilityLabel',
                  defaultMessage: 'Notes',
                }),
              })}
              style={{ flex: 1 }}
              placeholder={$t(
                {
                  id: 'EditActivityEvent_notesPlaceholder',
                  defaultMessage: `Things to prepare for {item}`,
                },
                {
                  item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
                },
              )}
              multiline
              ref={notesInputRef}
            />
          </View>
        </View>
      </ScrollView>
      <ConfirmationModal
        onCancel={() => {
          goBack();
          setShowConfirmationModal(false);
        }}
        onConfirm={() => {
          return save().then(() => {
            setShowConfirmationModal(false);
          });
        }}
        visible={showConfirmationModal}
        confirmText={$t({
          id: 'EditActivityEvent_confirmationConfirmButton',
          defaultMessage: 'Save',
        })}
        cancelText={$t({
          id: 'EditActivityEvent_confirmationCancelButton',
          defaultMessage: 'Discard',
        })}
        title={$t({ id: 'EditActivityEvent_confirmationTitle', defaultMessage: 'Save changes?' })}
        description={$t(
          {
            id: 'EditActivityEvent_confirmationDescription',
            defaultMessage: `You've added info to this {item}. Would you like to save it before leaving?`,
          },
          {
            item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
          },
        )}
      />
    </>
  );
}
