import { useNavigation } from '@react-navigation/core';
import noop from 'lodash/noop';

import { PracticeItem } from '@oui/app-core/src/components/PracticeItem';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';

export const SleepDiaryPracticeItemFragment = graphql(`
  fragment SleepDiaryPracticeItem on PendingAction {
    __typename
    ... on PendingSleepDiaryEntryNightAction {
      date
    }
    ... on PendingSleepDiaryEntryMorningAction {
      date
    }
  }
`);

export const SleepDiaryPracticeItem = ({
  complete,
  type,
  data,
}: {
  complete: boolean;
  type:
    | 'PendingSleepDiaryEntryNightAction'
    | 'SleepDiaryEntryNightAction'
    | 'PendingSleepDiaryEntryMorningAction'
    | 'SleepDiaryEntryMorningAction';
  data: FragmentOf<typeof SleepDiaryPracticeItemFragment> | null;
}) => {
  const { $t } = useI18n();
  const { navigate } = useNavigation();
  const item = readFragment(SleepDiaryPracticeItemFragment, data);

  if (
    item?.__typename === 'PendingSleepDiaryEntryNightAction' ||
    type === 'SleepDiaryEntryNightAction'
  ) {
    return (
      <PracticeItem
        complete={complete}
        icon="moon"
        text={$t({
          id: 'ActionTodoItem_sleepDiaryNight',
          defaultMessage: 'Night sleep check-in',
        })}
        color="#2c6ec9"
        onPress={
          item?.__typename === 'PendingSleepDiaryEntryNightAction'
            ? () => {
                navigate('SleepDiary', {});
                navigate('EditSleepDiaryEntry', { step: 'night', date: item.date });
              }
            : noop
        }
      />
    );
  }

  return (
    <PracticeItem
      complete={complete}
      icon="sun"
      text={$t({
        id: 'ActionTodoItem_sleepDiaryMorning',
        defaultMessage: 'Morning sleep check-in',
      })}
      color="#e8b613"
      onPress={
        item?.__typename === 'PendingSleepDiaryEntryMorningAction'
          ? () => {
              navigate('SleepDiary', {});
              navigate('EditSleepDiaryEntry', { step: 'morning', date: item.date });
            }
          : noop
      }
    />
  );
};
