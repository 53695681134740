{
  "ID": "v2-session-12",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "POST_AVIVA"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Welcome back!",
          "Congratulations on making it to the last session. You've done so well.",
          "In this session, I'm going to make some suggestions about life after this app.",
          "Let's get started!"
        ]
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Continue"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Hopefully by doing all the sessions in this app, you have learned many skills to keep you safe.",
          "In this final session, I want to give you some final suggestions.",
          "And of course, I will show you how you can keep benefiting from using this app."
        ]
      }
    },
    {
      "ID": "intro-pause-2",
      "type": "InputButton",
      "props": {
        "label": "Keep going"
      }
    },
    {
      "ID": "intro-3",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "POSTAVIVA_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "POSTAVIVA_VOICEOVER"
        },
        "linkText": "Post-Aviva"
      }
    },
    {
      "ID": "quiz-question-1-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "Please continue to use Aviva to review what you've learned and use your new skills to help lower your suicidal risk whenever it begins to rise.",
          "Check with your doctor to see if there are other issues in regard to your mental health that might be good to get help with."
        ]
      }
    },
    {
      "ID": "postaviva-vignette",
      "type": "ChatText",
      "props": {
        "text": ["Let's now watch someone talking about how they feel after finishing Aviva."]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "POSTAVIVA_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "POSTAVIVA_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "transition-to-outro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Good job. You've now done some planning for life after Aviva.",
          "Hopefully you'll continue to use Aviva to practice your skills, and to access MyPlan if you need it."
        ]
      }
    },
    {
      "ID": "transition-to-outro-1-pause",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of every session, there are two final tasks.",
          "First, please write the lessons you learned from this session."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what you've learned.",
          "As I always say, using what you have learned is the best way to get benefit from this app.",
          "Please select which skills you'd like to practice more of this week."
        ]
      }
    },
    {
      "ID": "practice-choices-store",
      "type": "Store",
      "param": {
        "key": "practiceChoices",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputChoice",
          "props": {
            "max": 7,
            "min": 1,
            "label": {
              "0": "MyPlan Review",
              "1": "Hope Kit",
              "2": "Getting going",
              "3": "Relaxation",
              "4": "Sleep",
              "5": "Spot it / Test it / Switch it",
              "6": "Coping cards"
            }
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "This week, I'll make sure the skills you selected are on your to do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of all your Aviva sessions. Well done on finishing! ",
          "There's been a lot covered in this course, and we've asked you to do a lot.",
          "You should feel really proud to have finished it all.",
          "I hope you stay safe and take care of yourself."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
