import { useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import hexToRgba from 'hex-to-rgba';
import { ComponentProps, Fragment, memo, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { CardStack, FlippableCard as CardStackCard } from '@oui/app-core/src/components/CardStack';
import { Divider } from '@oui/app-core/src/components/Divider';
import { ReviewCompleteCard } from '@oui/app-core/src/components/ReviewCompleteCard';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { Text } from '@oui/app-core/src/components/Text';
import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { View } from '@oui/app-core/src/components/View';
import { useAddAction } from '@oui/app-core/src/hooks/practices';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';
import { ActionType } from '@oui/lib/src/types';
import { CompositionTemplates } from '@oui/lib/src/types/compositionTemplates';

import Background from '../../assets/reviewBackground.svg';
import { ContactListItem } from '../../components/Contact';
import {
  IsEnvironmentSafetyLockedFragment,
  useIsEnvironmentSafetyLocked,
} from '../../hooks/useIsEnvironmentSafetyLocked';
import { StackScreenProps } from '../../types/navigation';

export const MyPlanReviewQuery = graphql(
  `
    query MyPlanReview {
      user {
        ID
        role {
          ID
          composition(template: "MYSTORYMYPLAN") {
            ID
            json
          }
          ...IsEnvironmentSafetyLocked
        }
      }
    }
  `,
  [IsEnvironmentSafetyLockedFragment],
);

const LinearGradientMemo = memo(() => {
  const { theme } = useTheme();
  return (
    <LinearGradient
      colors={[hexToRgba(theme.color.accentThree100, 0), '#9b469f']}
      style={[StyleSheet.absoluteFillObject, { top: 40 }]}
      start={[0, 0]}
      end={[0, 1]}
    />
  );
});

function InnerCard(props: ComponentProps<typeof View>) {
  return (
    <View
      {...props}
      style={[
        { justifyContent: 'center', minHeight: 80, paddingTop: 20, paddingHorizontal: 20 },
        props.style,
      ]}
    />
  );
}

export function MyPlanReview(props: { preview?: boolean }) {
  const { theme } = useTheme();
  const { loading, data } = useQuery(MyPlanReviewQuery);
  const { goBack, navigate } = useNavigation<StackScreenProps<'MyPlanReview'>['navigation']>();
  const route = useRoute<StackScreenProps<'MyPlanReview'>['route']>();
  const [cardStackKey, setCardStackKey] = useState('0');
  const onReset = () => setCardStackKey((k) => k + '1');
  const [addAction] = useAddAction();
  const { $t } = useI18n();

  const isEnvironmentSafetyLocked = useIsEnvironmentSafetyLocked(data?.user?.role ?? null);

  useArtifactRequest(route.name, true);

  const hasData = !!data;
  useEffect(() => {
    if (hasData) {
      void addAction({
        actionType: ActionType.MY_PLAN_REVIEW,
      });
    }
  }, [hasData, addAction]);

  const compositionData = data?.user?.role?.composition?.json
    ? CompositionTemplates.MYSTORYMYPLAN.parse(data?.user?.role?.composition?.json)
    : undefined;

  function getCards() {
    if (!compositionData) return null;
    const hasReasonsForLiving = !!compositionData.REASONS_FOR_LIVING;

    let baseCards = [];

    if (hasReasonsForLiving) {
      baseCards = [
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard0_front',
            defaultMessage: 'What is MyPlan?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_v2_flashcard0_back',
            defaultMessage:
              'MyPlan is step-by-step guide that helps you manage a crisis and regain control by helping you notice when things start feeling off and giving you specific things to do to stay safe.',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard1_front',
            defaultMessage: 'What are Warning Signs?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_v2_flashcard1_back',
            defaultMessage:
              'Warning signs are thoughts, feelings, or behaviors that serve as cues that you may be going into a crisis.',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard2_front',
            defaultMessage: 'What are your Warning Signs?',
          }),
          backChildren:
            compositionData.WARNING_SIGNS.length === 0 ? null : (
              <UnorderedList
                items={compositionData.WARNING_SIGNS.map(({ text }) => text) ?? []}
                color="#CB3D3D"
              />
            ),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard3_front',
            defaultMessage:
              'What should you try first when you recognize you’re experiencing your warning signs?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_v2_flashcard3_back',
            defaultMessage:
              'Try to keep things from getting worse by doing the things in ‘Step 2 Things I can do myself’',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard4_front',
            defaultMessage: 'What are your ‘Step 2 Things I can do myself’?',
          }),
          backChildren:
            compositionData.COPING_STRATEGIES.length === 0 ? null : (
              <UnorderedList
                items={compositionData.COPING_STRATEGIES.map(({ text }) => text) ?? []}
                color={theme.color.accentTwo100}
                weight="normal"
              />
            ),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard5_front',
            defaultMessage: 'What are your ‘Step 3 Reasons for living’?',
          }),
          backChildren:
            compositionData.REASONS_FOR_LIVING?.length === 0 ? null : (
              <UnorderedList
                items={compositionData.REASONS_FOR_LIVING?.map(({ text }) => text) ?? []}
                color={theme.color.accentThree100}
                weight="normal"
              />
            ),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard6_front',
            defaultMessage: 'Who are your ‘Step 4 People I can talk to’?',
          }),
          backChildren: compositionData.HELP_CONTACTS.length ? (
            <View>
              {compositionData.HELP_CONTACTS.map((contact, i, arr) => (
                <Fragment key={contact.ID}>
                  <ContactListItem
                    imageSize={40}
                    imageBorderRadius
                    nameWeight="normal"
                    name={{ first: contact.firstName, last: contact.lastName }}
                    image={
                      contact.imageAssetKey
                        ? { context: 'HELP_CONTACTS', key: contact.imageAssetKey }
                        : contact.imageBase64
                    }
                  />
                  {i === arr.length - 1 ? null : <Divider />}
                </Fragment>
              ))}
            </View>
          ) : null,
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard7_front',
            defaultMessage:
              'What is the difference between ‘Step 4 People I can talk to’ and ‘Step 5 Professional help’?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_v2_flashcard7_back',
            defaultMessage:
              '‘People you can talk to’ are friends and family that can give you support in a crisis. ‘Professional help’ are the 988 Lifeline, doctors, therapists, and other healthcare professionals.',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_v2_flashcard8_front',
            defaultMessage:
              'If you’re in an escalating crisis and you’ve tried other steps, what should you do?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_v2_flashcard8_back',
            defaultMessage: 'Call 988 for National Suicide Prevention Lifeline',
          }),
        },
      ];
    } else {
      baseCards = [
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard0_front',
            defaultMessage: 'What are Warning Signs?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_flashcard0_back',
            defaultMessage:
              'Warning signs are thoughts, feelings, or behaviors that serve as cues that you may be going into a crisis.',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard1_front',
            defaultMessage: 'What are your warning signs?',
          }),
          backChildren:
            compositionData.WARNING_SIGNS.length === 0 ? null : (
              <UnorderedList
                items={compositionData.WARNING_SIGNS.map(({ text }) => text) ?? []}
                color="#CB3D3D"
              />
            ),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard2_front',
            defaultMessage:
              "What should you try first when you recognize you're experiencing your warning signs?",
          }),
          backChildren: $t({
            id: 'MyPlanReview_flashcard2_back',
            defaultMessage: 'Try to calm yourself by doing the things in Step 2 Coping Strategies',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard3_front',
            defaultMessage: 'What are your Step 2 Coping Strategies?',
          }),
          backChildren:
            compositionData.COPING_STRATEGIES.length === 0 ? null : (
              <UnorderedList
                items={compositionData.COPING_STRATEGIES.map(({ text }) => text) ?? []}
                color={theme.color.accentTwo100}
                weight="normal"
              />
            ),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard4_front',
            defaultMessage:
              'What is the difference between Step 2 Coping Strategies and Step 3 Social distractions?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_flashcard4_back',
            defaultMessage:
              'Step 2 Coping Strategies are meant to do alone, whereas Step 3 Social distractions are social activities with or around others.',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard5_front',
            defaultMessage: 'What or who are your Step 3 Social distractions?',
          }),
          backChildren:
            compositionData.SOCIAL_DISTRACTIONS.contacts.length ||
            compositionData.SOCIAL_DISTRACTIONS.places.length ? (
              <View>
                {compositionData.SOCIAL_DISTRACTIONS.contacts.map((c, i, arr) => (
                  <Fragment key={i}>
                    <ContactListItem
                      imageSize={40}
                      imageBorderRadius
                      name={{ first: c.firstName, last: c.lastName }}
                      nameWeight="normal"
                      image={
                        c.imageAssetKey
                          ? { context: 'SOCIAL_DISTRACTIONS', key: c.imageAssetKey }
                          : c.imageBase64
                      }
                    />
                    {i < arr.length - 1 || compositionData.SOCIAL_DISTRACTIONS.places.length ? (
                      <Divider />
                    ) : null}
                  </Fragment>
                ))}
                {compositionData.SOCIAL_DISTRACTIONS.places.map((place, i, arr) => (
                  <Fragment key={place.ID}>
                    <ContactListItem
                      imageSize={40}
                      key={place.ID}
                      name={{ first: place.name, last: '' }}
                      nameWeight="normal"
                    />
                    {i < arr.length - 1 ? <Divider /> : null}
                  </Fragment>
                ))}
              </View>
            ) : null,
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard6_front',
            defaultMessage:
              'What is the difference between Step 3 Social distractions and Step 4 People I can ask for help?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_flashcard6_back',
            defaultMessage:
              'People in Step 3 are not to be used to share or discuss a crisis. People in Step 4 are ones you can reach out to in a crisis.',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard7_front',
            defaultMessage: 'Who are your Step 4 People I can ask for help?',
          }),
          backChildren: compositionData.HELP_CONTACTS.length ? (
            <View>
              {compositionData.HELP_CONTACTS.map((contact, i, arr) => (
                <Fragment key={contact.ID}>
                  <ContactListItem
                    imageSize={40}
                    imageBorderRadius
                    nameWeight="normal"
                    name={{ first: contact.firstName, last: contact.lastName }}
                    image={
                      contact.imageAssetKey
                        ? { context: 'HELP_CONTACTS', key: contact.imageAssetKey }
                        : contact.imageBase64
                    }
                  />
                  {i === arr.length - 1 ? null : <Divider />}
                </Fragment>
              ))}
            </View>
          ) : null,
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard8_front',
            defaultMessage:
              "If you're in an escalating crisis and you've tried other steps, what should you do?",
          }),
          backChildren: $t({
            id: 'MyPlanReview_flashcard8_back',
            defaultMessage: 'Call the National Suicide Preventation Lifeline',
          }),
        },
      ];
    }

    if (!isEnvironmentSafetyLocked) {
      return [
        ...baseCards,
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard9_front',
            defaultMessage: 'What does it mean to make your environment safe?',
          }),
          backChildren: $t({
            id: 'MyPlanReview_flashcard9_back',
            defaultMessage:
              'Making your environment safe means securing items or lethal means that you may use for suicide.',
          }),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard10_front',
            defaultMessage: 'What ways are you securing lethal means?',
          }),
          backChildren:
            compositionData.ENVIRONMENT_SAFETY.removalSteps.length === 0 ? null : (
              <UnorderedList
                items={
                  compositionData.ENVIRONMENT_SAFETY.removalSteps.map(({ text }) => text) ?? []
                }
                color={theme.color.accentTwo100}
                weight="normal"
              />
            ),
        },
        {
          frontChildren: $t({
            id: 'MyPlanReview_flashcard11_front',
            defaultMessage: 'Who will help you secure these items?',
          }),
          backChildren: compositionData.ENVIRONMENT_SAFETY.supportContacts.length ? (
            <View>
              {compositionData.ENVIRONMENT_SAFETY.supportContacts.map((contact, i, arr) => (
                <Fragment key={contact.ID}>
                  <ContactListItem
                    imageSize={40}
                    imageBorderRadius
                    nameWeight="normal"
                    name={{ first: contact.firstName, last: contact.lastName }}
                    image={
                      contact.imageAssetKey
                        ? { context: 'ENVIRONMENT_SAFETY', key: contact.imageAssetKey }
                        : contact.imageBase64
                    }
                  />
                  {i === arr.length - 1 ? null : <Divider />}
                </Fragment>
              ))}
            </View>
          ) : null,
        },
      ];
    }

    return baseCards;
  }

  const cards = getCards();

  return (
    <View style={{ flex: 1 }}>
      <View
        pointerEvents="none"
        style={[
          {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1,
          },
        ]}
      >
        <Background preserveAspectRatio="xMaxYMin" width="100%" aria-label={undefined} />
      </View>
      <RoundedSection
        color="white"
        secondaryColor={theme.color.accentThree100}
        bottomSafeAreaColor="#9b469f"
        title={$t({
          id: 'MyPlanReview_title',
          defaultMessage: 'MyPlan Review',
        })}
        preview={props.preview}
        applyHeaderOptions
      >
        <LinearGradientMemo />
        {cards?.length ? (
          <CardStack
            hideNextButtonIndexes={[cards.length]}
            pageColor="white"
            key={cardStackKey}
            hasCompleteCard
          >
            {cards.map(({ frontChildren, backChildren }, i) => (
              <CardStackCard
                key={i.toString()}
                frontChildren={
                  <InnerCard>
                    {typeof frontChildren === 'string' ? (
                      <Text text={frontChildren} size={21} weight="semibold" textAlign="center" />
                    ) : (
                      frontChildren
                    )}
                  </InnerCard>
                }
                backChildren={
                  <InnerCard>
                    {typeof backChildren === 'string' ? (
                      <Text text={backChildren} size={21} weight="semibold" textAlign="center" />
                    ) : backChildren === null ? (
                      <Button
                        text={$t({
                          id: 'MyPlanReview_editMyPlanButton',
                          defaultMessage: 'Edit MyPlan to add',
                        })}
                        variant="text"
                        alignSelf="center"
                        onPress={() => {
                          navigate('EditMyPlan', {});
                        }}
                      />
                    ) : (
                      backChildren
                    )}
                  </InnerCard>
                }
              />
            ))}
            <ReviewCompleteCard onDone={goBack} onReset={onReset} />
          </CardStack>
        ) : loading ? (
          <ActivityIndicator />
        ) : null}
        <View flex={1} style={{ justifyContent: 'flex-end' }} pointerEvents="box-none">
          <Button
            variant="text"
            color="white"
            text={$t({
              id: 'MyPlanReview_myPlanButton',
              defaultMessage: 'Full MyPlan',
            })}
            alignSelf="center"
            icon="my-plan"
            onPress={() => navigate('MyPlan', {})}
          />
        </View>
      </RoundedSection>
    </View>
  );
}
