import omit from 'lodash/omit';

import { CMSNode, CMSPrimaryExchange, PrimaryExchange } from './cmsEditor';
import { encodeHostedQuizSetSlug } from './quizSetSlug';
import { ArgNodeFunc, ConvoNode } from './stateMachine';
import type { ChatArtifactPreviewProps } from './types';

function omitExtraCmsFields<T extends CMSNode>(node: T) {
  return omit(node, 'flow', 'store');
}

export class CmsToConvoError extends Error {
  details: ReturnType<(typeof PrimaryExchange)['prototype']['isValid']>;
  constructor(validity: ReturnType<(typeof PrimaryExchange)['prototype']['isValid']>) {
    super('CMSPrimaryExchange is not valid');
    this.details = validity;
  }
}

export function cmsToConvo({
  sessionID,
  contentType,
  primaryExchange,
}: {
  sessionID: string;
  contentType: string;
  primaryExchange: CMSPrimaryExchange;
}): Extract<ConvoNode, { type: 'Seq' }> {
  const exchange = new PrimaryExchange(primaryExchange);

  const validity = exchange.isValid();
  if (!validity.valid) {
    throw new CmsToConvoError(validity);
  }

  const convoNode: ConvoNode = {
    ID: sessionID,
    type: 'Seq',
    children: [],
  };

  exchange.bfs((node) => {
    // add node to convoNode
    // find outgoing edges + add nextNode handling
    const outgoers = exchange.getOutgoingEdges(node);
    switch (node.type) {
      case 'SessionStart': {
        convoNode.children.push({
          ID: 'setSessionName',
          type: 'Arg',
          param: {
            key: 'sessionName',
            func: ArgNodeFunc.ArgAlways,
            value: contentType,
          },
        });
        convoNode.children.push({
          ID: 'setup',
          type: 'Exchange',
          param: {
            target: 'setup',
          },
        });
        break;
      }
      case 'Exchange': {
        const args = Object.entries(node.param.args);

        // Need node.ID to be before the args so that any GoTos setup args properly. This is a noop
        convoNode.children.push({
          ID: node.ID,
          type: 'Arg',
          param: { func: ArgNodeFunc.ArgCopyFromNodeResult, key: '__NODE_RESULT' },
        });

        args.forEach(([key, value]) => {
          // In order to be able to pass non-string parameters to exchanges we run all values through
          // JSON.parse. If the value is not a valid stringified JSON value, we fallback to the plain
          // string. This results in the following logic:
          // `["foo"]` -> ["foo"]
          // `1` -> 1
          // `"1"` -> "1"
          let maybeParsedValue = value;
          try {
            maybeParsedValue = JSON.parse(value);
          } catch (e) {
            // noop
          }

          convoNode.children.push({
            ID: `${node.ID}-arg-${key}`,
            type: 'Arg',
            param: { func: ArgNodeFunc.ArgAlways, key: `__param:${key}`, value: maybeParsedValue },
          });
        });

        convoNode.children.push({
          ID: `${node.ID}-exchange`,
          type: 'Exchange',
          param: {
            target: node.param.target,
          },
        });
        break;
      }
      case 'ChatText': {
        convoNode.children.push(omitExtraCmsFields(node));
        break;
      }
      case 'InputSlider': {
        convoNode.children.push(omitExtraCmsFields(node));
        break;
      }
      case 'InputText': {
        convoNode.children.push(omitExtraCmsFields(node));
        break;
      }
      case 'InputButton': {
        convoNode.children.push(omitExtraCmsFields(node));
        break;
      }
      case 'InputSingleChoice': {
        convoNode.children.push(omitExtraCmsFields(node));
        break;
      }
      case 'InputMultipleChoice': {
        convoNode.children.push(omitExtraCmsFields(node));
        break;
      }
      case 'ChatActivityPreview': {
        convoNode.children.push(omitExtraCmsFields(node));
        break;
      }
      case 'CMSWidget': {
        const incompleteEdge = outgoers.find((e) => e.sourceHandle === 'incomplete');

        convoNode.children.push({
          ID: node.ID,
          type: 'InputArtifact',
          props: node.props,
          param: {
            ...node.param,
            skipButton: incompleteEdge ? node.param.skipButton : undefined,
            completeNode: {
              ID: `${node.ID}-complete`,
              type: 'Identity',
              value: 'complete',
            },
            skipNode: incompleteEdge
              ? {
                  ID: `${node.ID}-incomplete`,
                  type: 'Identity',
                  value: 'incomplete',
                }
              : undefined,
          },
        });

        let artifactName: ChatArtifactPreviewProps['artifactName'];

        if (node.props.artifactName === 'SoloMyPlan') {
          artifactName = 'MyPlan';
        } else if (node.props.artifactName === 'SoloRiskCurve') {
          artifactName = 'RiskCurve';
        } else if (node.props.artifactName === 'SoloSuicideMode') {
          artifactName = 'SoloSuicideMode';
        } else if (node.props.artifactName === 'SuicideMode') {
          artifactName = 'SoloSuicideMode';
        } else {
          artifactName = node.props.artifactName;
        }

        convoNode.children.push({
          ID: `${node.ID}-artifact`,
          type: 'ChatActivityPreview',
          props: {
            artifactName,
            params: node.props.params,
            linkText: node.param.previewLinkText,
          },
        });

        break;
      }
      case 'CMSQuizSet': {
        const slug = encodeHostedQuizSetSlug({
          quizSetCollectionID: node.props.ID,
        });
        convoNode.children.push({
          ID: node.ID,
          type: 'InputArtifact',
          props: {
            buttonText: node.param.startButton ?? 'Continue',
            artifactName: 'QuizSet',
            params: {
              slug,
            },
          },
          param: {
            retryText: ["Looks like you didn't complete a video."],
            retryButton: 'Retry',
            skipButton: 'Skip',
            skipNode: {
              ID: `${node.ID}-skipText`,
              type: 'ChatText',
              props: { text: ['No problem. You can always come back and watch later.'] },
            },
          },
        });

        convoNode.children.push({
          ID: `${node.ID}-artifact`,
          type: 'ChatActivityPreview',
          props: {
            artifactName: 'QuizSet',
            params: {
              slug,
            },
            linkText: node.param.rewatchButton ?? 'Watch Video',
          },
        });

        break;
      }
      case 'SessionEnd': {
        convoNode.children.push({
          ID: node.ID,
          type: 'GoTo',
          param: { nextNodeID: 'exit' },
        });
        convoNode.children.push({
          ID: 'exit',
          type: 'Exchange',
          param: {
            target: 'exit',
          },
        });
        break;
      }
      case 'WriteProgress': {
        convoNode.children.push(omitExtraCmsFields(node));
        break;
      }
      case 'Invoke': {
        convoNode.children.push({
          ID: node.ID,
          type: 'Arg',
          param: { func: 'ArgInvoke', service: node.param.service, args: node.param.args },
        });
        break;
      }
      default: {
        // @ts-ignore When all node types are covered by switch cases, typescript complains that
        // default can never occur. However, if content is out of sync with the TS definition,
        // we want to throw an error
        throw new Error(`traversed unhandled node type in cmsToConvo: ${node.type}`);
      }
    }

    if (node.store) {
      // Performs 2 actions
      // 1. take __NODE_RESULT from corresponding node and set as context.[key] in state machine
      // 2. take __NODE_RESULT and store as response in GQL API
      convoNode.children.push({
        ID: `${node.ID}-store`,
        type: 'Store',
        param: {
          target: {
            ID: `${node.ID}-store-target`,
            type: 'Arg',
            param: { func: ArgNodeFunc.ArgCopyFromNodeResult, key: node.store.key },
          },
          context: '{{sessionName}}',
          key: node.store.key,
        },
      });
    }

    if (outgoers.length > 1) {
      const resultMap: Extract<ConvoNode, { type: 'Mux' }>['param']['map'] = {};

      for (let edge of outgoers) {
        resultMap[edge.sourceHandle!] = {
          ID: `${node.ID}-edge-${edge.sourceHandle!}`,
          type: 'GoTo',
          param: { nextNodeID: edge.target },
        };
      }

      const lookup =
        node.type === 'InputSingleChoice' && node.param?.valueMap
          ? '__NODE_RESULT.originalKey'
          : '__NODE_RESULT';

      convoNode.children.push({
        ID: `${node.ID}-mux`,
        type: 'Mux',
        param: {
          body: {
            ID: `${node.ID}-mux-body`,
            type: 'Arg',
            param: { func: ArgNodeFunc.ArgTake, lookup },
          },
          map: resultMap,
        },
      });
    } else if (outgoers.length === 1) {
      const [edge] = outgoers;
      convoNode.children.push({ ID: edge.id, type: 'GoTo', param: { nextNodeID: edge.target } });
    } else if (node.type !== 'SessionEnd') {
      throw new Error(
        `CMSPrimaryExchange is not valid: invalid end node ${node.ID} (${node.type})`,
      );
    }
  });

  return convoNode;
}
