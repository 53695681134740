import { FunctionComponent } from 'react';

import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import PatientSpeaking from '../assets/mystorymyplan/patient-speaking.svg';
import Shield from '../assets/mystorymyplan/shield.svg';
import TakingNotes from '../assets/mystorymyplan/taking-notes.svg';
import {
  ExplanationVideos,
  PatientMyStoryMyPlanContainer,
  PatientMyStoryMyPlanContainerSection,
  PatientMyStoryMyPlanQuestionAnswer,
} from '../components/PatientMyStoryMyPlanContainer';

function Feature({
  icon: Icon,
  text,
}: {
  icon: FunctionComponent<{ 'aria-label': string | undefined }>;
  text: string;
}) {
  return (
    <View row spacing={12}>
      <View style={{ width: 71 }}>
        <Icon aria-label={undefined} />
      </View>
      <Text text={text} style={{ flex: 1 }} />
    </View>
  );
}

export function PatientMyStoryIntroduction() {
  const { $t } = useI18n();

  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientMyStoryIntroduction_heading', defaultMessage: 'MyStory' })}
      testID="PatientMyStoryIntroduction"
    >
      <PatientMyStoryMyPlanContainerSection>
        <View spacing={25}>
          <PatientMyStoryMyPlanQuestionAnswer
            question="What is it?"
            answer="In your own words, tell the story of your last suicide crisis."
          />
          <PatientMyStoryMyPlanQuestionAnswer
            question="Why are we doing this?"
            answer="Talking can be hard. But if we understand what happened, we can try stop it happening again."
          />
          <Feature icon={Shield} text="Everything discussed is confidential." />
          <Feature icon={PatientSpeaking} text="This time is yours. Take as long as you need." />
          <Feature icon={TakingNotes} text="I will be taking notes but will always be listening." />
        </View>
      </PatientMyStoryMyPlanContainerSection>
      <ExplanationVideos
        videos={[
          {
            caption: 'Watching others tell their stories might help you tell yours.',
            slug: 'MYSTORY_LEARN_VIGNETTE',
          },
        ]}
      />
    </PatientMyStoryMyPlanContainer>
  );
}
