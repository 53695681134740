import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import { Learn as LearnScreen, LearnSessionsFragment } from '@oui/app-core/src/screens/Learn';
import { useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';
import { ContentType, OnboardingVariant } from '@oui/lib/src/types';

import { StackScreenProps } from '@src/types/navigation';

export const LearnQuery = graphql(
  `
    query Learn {
      user {
        ID
        role {
          ID
          ...LearnSessions
          productConfig {
            ... on RoleAvivaConfig {
              onboardingVariant
            }
          }
        }
      }
    }
  `,
  [LearnSessionsFragment],
);

export function Learn(_props: {}) {
  const navigation = useNavigation<StackScreenProps<'MyPlan'>['navigation']>();
  const { data } = useQuery(LearnQuery);
  const isCollaborativeOnboarding =
    data?.user?.role?.productConfig?.__typename === 'RoleAvivaConfig' &&
    data?.user?.role?.productConfig?.onboardingVariant === OnboardingVariant.COLLABORATIVE;
  const { theme } = useTheme();

  return (
    <LearnScreen
      progressBackgroundColor={theme.color.accent100}
      onSessionCardPress={(session, sessionNum) => {
        const slug = session.session.contentType as ContentType;
        const isComplete = session.status === 'COMPLETED';

        if (isCollaborativeOnboarding && slug === ContentType.MYPLAN) {
          if (isComplete) {
            navigation.navigate('MyStoryMyPlan', {
              screen: 'MyStoryMyPlanOverview',
            });
          } else {
            navigation.navigate('ControlledMyStoryMyPlan');
          }
        } else {
          navigation.navigate('Conversation', {
            num: sessionNum.toString(),
            title: session.session.name,
            ID: session.session.contentType || 'TEST::convo',
            completed: isComplete ? ('true' as const) : ('false' as const),
          });
        }
      }}
    />
  );
}
