import { TouchableOpacity } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

function Star(props: {
  'aria-label'?: string;
  size?: number;
  value?: boolean;
  onChangeValue: (value: boolean) => void;
  testID?: string;
}) {
  const { theme } = useTheme();
  const size = props.size ?? 40;

  return (
    <TouchableOpacity
      onPress={() => {
        props.onChangeValue(!props.value);
      }}
      aria-label={props['aria-label']}
      role="radio"
      aria-checked={!!props.value}
      style={{ marginHorizontal: 10 }}
      testID={props.testID}
    >
      <Icon
        name={props.value ? 'star-selected' : 'star'}
        size={size}
        color={props.value ? theme.color.warning : theme.color.gray500}
      />
    </TouchableOpacity>
  );
}

export function StarRating(props: {
  'aria-label'?: string;
  size?: number;
  value?: number;
  onChangeValue: (value: number) => void;
  testID?: string;
}) {
  const value = props.value ?? 0;
  return (
    <View
      row
      style={{ alignSelf: 'center' }}
      role="radiogroup"
      aria-label={props['aria-label'] ?? 'Star rating'}
      testID={props.testID}
    >
      <Star
        aria-label="1 star"
        size={props.size}
        value={value >= 1}
        onChangeValue={() => props.onChangeValue(1)}
        testID="StarRating_1"
      />
      <Star
        aria-label="2 stars"
        size={props.size}
        value={value >= 2}
        onChangeValue={() => props.onChangeValue(2)}
        testID="StarRating_2"
      />
      <Star
        aria-label="3 stars"
        size={props.size}
        value={value >= 3}
        onChangeValue={() => props.onChangeValue(3)}
        testID="StarRating_3"
      />
      <Star
        aria-label="4 stars"
        size={props.size}
        value={value >= 4}
        onChangeValue={() => props.onChangeValue(4)}
        testID="StarRating_4"
      />
      <Star
        aria-label="5 stars"
        size={props.size}
        value={value >= 5}
        onChangeValue={() => props.onChangeValue(5)}
        testID="StarRating_5"
      />
    </View>
  );
}
