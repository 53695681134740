import { produce } from 'immer';
import { validate, ValidationError } from 'jsonschema';
import { Definition } from 'ts-json-schema-generator';

import _Schema from './metadata/envelopes.json';
import { Envelopes } from './types';

const Schema = _Schema as unknown as Definition;

export const EnvelopeDefinitionByKind: { [key: string]: Definition | undefined } = {};

function addDefinition(def: Definition) {
  if (
    def.properties &&
    def.properties.kind &&
    (def.properties.kind as Definition).type === 'number'
  ) {
    const kindEnum = (def.properties.kind as Definition).const as number;

    // ts-json-schema-generator will create some definitions with a nested anyOf in a property.
    // The validate function doesn't like that so make it combine
    // anyOf [{enum1,type}, {enum2,type}] into { type, enum: [...enum1, ...enum2] }
    const normalizedDefinition = produce(def, (draft) => {
      const properties = draft.properties;
      if (properties) {
        Object.keys(properties).forEach((propName) => {
          const property = properties[propName] as Definition;
          if (property.anyOf && property.anyOf.every((subDef) => !!(subDef as Definition).enum)) {
            properties[propName] = {
              enum: property.anyOf.reduce<Array<string | number | boolean | {} | null>>(
                (carry, el) => {
                  return [...carry, ...(el as Definition).enum!];
                },
                [],
              ),
              type: (property.anyOf[0] as Definition).type,
            };
          }

          if (property.required && property.properties) {
            property.required.forEach((key) => {
              (property.properties![key] as any).required = true;
            });
          }
        });
      }
    });

    EnvelopeDefinitionByKind[kindEnum] = normalizedDefinition;
  }
  if (def.anyOf) {
    def.anyOf.forEach((d) => addDefinition(d as Definition));
  }
}

Object.values(Schema.definitions!).forEach((def) => {
  addDefinition(def as Definition);
});

export function validateEnvelope(env: Envelopes): {
  errors: (string | ValidationError)[];
  valid: boolean;
} {
  const definition = EnvelopeDefinitionByKind[env.kind];
  if (definition) {
    return validate(env, definition);
  }

  return {
    errors: ['kind is not valid'],
    valid: false,
  };
}
