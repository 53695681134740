{
  "ID": "v2-session-01",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "MYPLAN"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "¡Bienvenido! Soy Aviva. Soy un chatbot no humano que te guiará por esta aplicación.",
          "Si te han dado esta aplicación, probablemente significa que las cosas no han sido buenas para ti últimamente.",
          "Esta aplicación está aquí para ayudar."
        ]
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "¿Cómo puede ayudar?",
          "1": "Continúe, por favor"
        }
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Esta aplicación utiliza una terapia llamada Terapia Cognitiva Conductual (TCC) para reducir tu riesgo de suicidio.",
          "En 12 sesiones, te enseñará habilidades y sugerirá cambios en tu vida para que no intentes hacerte daño.",
          "La aplicación me utiliza a mí, a Aviva (un chat bot), vídeos animados y las experiencias de otros pacientes para hacerlo.",
          "También tiene una serie de ejercicios diferentes, diarios y recordatorios para ayudarte a seguir tu progreso."
        ]
      }
    },
    {
      "ID": "intro-pause-2",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "¿Cuál es la prueba de que esto funciona?",
          "1": "Digame mas"
        }
      }
    },
    {
      "ID": "intro-3",
      "type": "ChatText",
      "props": {
        "text": [
          "Esta aplicación se basa en investigaciones científicas publicadas.",
          "La investigación ha demostrado que este tratamiento puede reducir los intentos de suicidio en más del 50%.",
          "Si puedes usar esta aplicación durante 3-5 minutos cada día, esta aplicación puede ayudarte realmente."
        ]
      }
    },
    {
      "ID": "intro-pause-3",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "¿Y cómo funciona?",
          "1": "Siguiente"
        }
      }
    },
    {
      "ID": "intro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "Cada sesión utiliza el mismo formato",
          "Primero, introduciré una nueva habilidad y explicaré por qué es útil. Y habrá algunos vídeos para completar el aprendizaje.",
          "Segundo, te daremos la oportunidad de usar la habilidad en la sesión.",
          "Tercero, después de cada sesión, recibirás recordatorios regulares para practicar la habilidad."
        ]
      }
    },
    {
      "ID": "intro-pause-4",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "¿Y qué es lo siguiente?",
          "1": "Continúe, por favor"
        }
      }
    },
    {
      "ID": "intro-5",
      "type": "ChatText",
      "props": {
        "text": [
          "Vamos a sumergirnos. Esta es la sesión 1 de 12. En esta sesión, haremos tres cosas:",
          "Primero, hablaremos de la curva de riesgo de suicidio",
          "Segundo, trabajaremos juntos para dibujar tu curva de riesgo",
          "Tercero, construiremos un plan para ayudar a prevenir el intento de suicidio."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_VOICEOVER"
        },
        "buttonText": "Reproducir el vídeo de la curva de riesgo"
      },
      "param": {
        "retryText": ["Parece que no has completado el vídeo"],
        "retryButton": "Reintento",
        "skipButton": "Saltar",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No hay problema. Siempre puedes volver a mirar más tarde."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_VOICEOVER"
        },
        "linkText": "Mirar el vídeo de aprendizaje"
      }
    },
    {
      "ID": "transition-to-vignette",
      "type": "ChatText",
      "props": {
        "text": [
          "Antes de dibujar tu curva de riesgo, vamos a ver un vídeo de alguien que comparte su historia de crisis de suicidio, y cómo le ayudó la curva de riesgo.",
          "A lo largo de esta aplicación, estas personas compartirán sus experiencias con el suicidio, y lo que están haciendo para reducir su riesgo.",
          "Abajo hay una serie de historias para que elijas.",
          "Puedes ver todas las que quieras."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_LEARN_VIGNETTE"
        },
        "buttonText": "Mira"
      },
      "param": {
        "retryText": ["Parece que no has completado el vídeo."],
        "retryButton": "Reintento",
        "skipButton": "Saltar",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No hay problema. Siempre puedes volver a mirar más tarde."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_LEARN_VIGNETTE"
        },
        "linkText": "Mirar el vídeo de aprendizaje"
      }
    },
    {
      "ID": "transition-to-risk-curve-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "Espero que ahora tengas una buena idea sobre la curva de riesgo, y cómo puede ayudar.",
          "En el siguiente ejercicio, les pediré que piensen en su reciente crisis de suicidio.",
          "Organizarás lo que sucedió a lo largo de la curva de riesgo.",
          "Está bien si no recuerdas todo perfectamente. Puedes editar las cosas en cualquier momento después de hoy. Inténtalo."
        ]
      }
    },
    {
      "ID": "prompt-start-risk-curve",
      "type": "InputArtifact",
      "props": {
        "artifactName": "SoloRiskCurve",
        "buttonText": "Construir mi curva de riesgo"
      },
      "param": {
        "retryButton": "Completar la curva de riesgo",
        "retryText": [
          "Parece que no has terminado tu Curva de Riesgo.",
          "Es importante completar su Curva de Riesgo para continuar.",
          "Volvamos y terminemos."
        ],
        "completeNode": {
          "ID": "risk-curve-preview",
          "type": "ChatActivityPreview",
          "props": {
            "artifactName": "RiskCurve",
            "linkText": "Ver curva de riesgo"
          }
        }
      }
    },
    {
      "ID": "intro-myplan-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Gran trabajo al trazar tu curva de riesgo.",
          "Espero que te haya ayudado a contextualizar cómo cambia tu riesgo en las diferentes etapas.",
          "Ahora está listo para hacer la última parte de esta sesión - la construcción de MyPlan."
        ]
      }
    },
    {
      "ID": "intro-myplan-pause-1",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "¿Qué es MiPlan?"
        }
      }
    },
    {
      "ID": "intro-myplan-2",
      "type": "ChatText",
      "props": {
        "text": ["Un plan para evitar que la curva de riesgo aumente demasiado."]
      }
    },
    {
      "ID": "intro-myplan-pause-2",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "Por que es importante MiPlan?"
        }
      }
    },
    {
      "ID": "intro-myplan-3",
      "type": "ChatText",
      "props": {
        "text": [
          "Durante una crisis, a menudo es difícil pensar en otra cosa que no sea la propia crisis",
          "MyPlan tiene todos los pasos que puedes dar para no llegar al punto de crisis y así mantenerte a salvo."
        ]
      }
    },
    {
      "ID": "intro-myplan-pause-3",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "¿Cómo funciona?"
        }
      }
    },
    {
      "ID": "intro-myplan-4",
      "type": "ChatText",
      "props": {
        "text": [
          "Cuando las señales de alarma le indiquen que su riesgo está aumentando, puede utilizar las medidas de afrontamiento del plan.",
          "Y si sientes que el riesgo sigue aumentando, el plan tiene varias formas de ayudarte.",
          "Aquí tienes un breve vídeo con más información sobre MyPlan."
        ]
      }
    },
    {
      "ID": "prompt-myplan-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MYPLAN_VOICEOVER"
        },
        "buttonText": "Reproducir el vídeo de MyPlan"
      },
      "param": {
        "retryText": ["Parece que no has completado el vídeo."],
        "retryButton": "Reintento",
        "skipButton": "Saltar",
        "skipNode": {
          "ID": "myplan-learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "myplan-learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No hay problema. Siempre puedes volver a mirar más tarde."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "myplan-learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MYPLAN_VOICEOVER"
        },
        "linkText": "Ver el vídeo sobre MiPlan"
      }
    },
    {
      "ID": "intro-myplan-5",
      "type": "ChatText",
      "props": {
        "text": ["Antes de crear MyPlan, veamos a alguien compartir cómo le ayuda MyPlan."]
      }
    },
    {
      "ID": "prompt-myplan-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MYPLAN_LEARN_VIGNETTE"
        },
        "buttonText": "Mirar"
      },
      "param": {
        "retryText": ["Parece que no has completado el vídeo"],
        "retryButton": "Reintento",
        "skipButton": "Saltar",
        "skipNode": {
          "ID": "myplan-learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "myplan-learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No hay problema. Siempre puedes volver a mirar más tarde."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "myplan-learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MYPLAN_LEARN_VIGNETTE"
        },
        "linkText": "Un ejemplo de MiPlan"
      }
    },
    {
      "ID": "intro-myplan-6",
      "type": "ChatText",
      "props": {
        "text": [
          "Ahora vamos a construir tu MiPlan",
          "Este será el último ejercicio por hoy, así que ya casi has terminado."
        ]
      }
    },
    {
      "ID": "prompt-start-myplan",
      "type": "InputArtifact",
      "props": {
        "artifactName": "SoloMyPlan",
        "buttonText": "Construir MiPlan"
      },
      "param": {
        "retryButton": "Completar MiPlan",
        "retryText": [
          "Parece que no has completado su MiPlan",
          "Es importante completar su MiPlan para poder continuar",
          "Volvamos y terminemos."
        ],
        "completeNode": {
          "ID": "myplan-preview",
          "type": "ChatActivityPreview",
          "props": {
            "artifactName": "MyPlan",
            "linkText": "Ver MiPlan"
          }
        }
      }
    },
    {
      "ID": "outro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "¡Bien hecho!",
          "Has aprendido sobre la curva de riesgo, has dibujado tu propia curva, y has construido MiPlan",
          "Casi has terminado la sesión 1."
        ]
      }
    },
    {
      "ID": "outro-pause-1",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "¿Qué más queda?"
        }
      }
    },
    {
      "ID": "outro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Al final de cada sesión, hay dos tareas finales: escribir las lecciones aprendidas y planificar tu práctica.",
          "Primero, te haré escribir las lecciones aprendidas."
        ]
      }
    },
    {
      "ID": "outro-lessons-learned-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "outro-lessons-learned-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "¿Cuáles son las lecciones aprendidas?",
              "1": "Entiendo. ¿Dónde voy a hacer esto?"
            }
          }
        },
        "map": {
          "0": {
            "ID": "outro-lessons-learned-mux-0",
            "type": "ChatText",
            "props": {
              "text": [
                "Las lecciones aprendidas son la una o dos cosas más importantes que te has llevado de la sesión de hoy.",
                "Harás esto al final de cada sesión.",
                "Al final, tendrás un resumen de todas las cosas importantes que has aprendido.",
                "Cada vez que quieras editarlo o revisarlo, ve a la sección \"Perfil\" de la aplicación."
              ]
            }
          },
          "1": {
            "ID": "outro-lessons-learned-mux-1",
            "type": "ChatText",
            "props": {
              "text": [
                "Escribirás las lecciones aprendidas aquí en este chat.",
                "Cada vez que quieras editarlas o revisarlas, ve a la sección \"Perfil\" de la aplicación."
              ]
            }
          }
        }
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "outro-3",
      "type": "ChatText",
      "props": {
        "text": [
          "¡Genial! Lo último que vamos a hacer hoy es hablar de practicar lo que habéis aprendido.",
          "Para esta sesión, las únicas tareas que tendrán son revisar su Curva de Riesgo y su MiPlan para asegurarse de que todo está bien."
        ]
      }
    },
    {
      "ID": "outro-pause-3",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "Entiendo. ¿Qué más queda?"
        }
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "Esta aplicación tiene 12 sesiones, divididas en tres partes.",
          "La parte 1 tiene dos sesiones - construye herramientas que puedes usar en tiempos realmente difíciles.",
          "La parte 2 tiene siete sesiones - aprende formas de cambiar tus pensamientos y acciones para reducir tu riesgo.",
          "La parte 3 tiene tres sesiones - revisa lo que se ha cubierto y prepárate para el futuro."
        ]
      }
    },
    {
      "ID": "outro-pause-4",
      "type": "InputSingleChoice",
      "props": {
        "variant": "button",
        "label": {
          "0": "¿Algo más?"
        }
      }
    },
    {
      "ID": "outro-5",
      "type": "ChatText",
      "props": {
        "text": [
          "No, eso es todo. Has hecho muy bien en superar esto",
          "Es totalmente normal si no todo tiene sentido de inmediato",
          "Puedes volver a leer o ver cualquier cosa en esta sesión."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
