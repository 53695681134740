const AZURE_KEY = '3e7dd73f2cc54d4690f79b1337ba49e9';

export interface ImageObject {
  /**
   * Polymorphic Discriminator
   */
  _type: 'ImageObject';
  /**
   * A String identifier.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly id?: string;
  /**
   * The URL that returns this resource.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly readLink?: string;
  /**
   * The URL To Bing's search result for this item.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly webSearchUrl?: string;
  /**
   * The name of the thing represented by this object.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly name?: string;
  /**
   * The URL to get more information about the thing represented by this object.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly url?: string;
  /**
   * An image of the item.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly image?: ImageObject;
  /**
   * A short description of the item.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly description?: string;
  /**
   * An alias for the item
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly alternateName?: string;
  /**
   * An ID that uniquely identifies this item.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly bingId?: string;
  /**
   * The URL to a thumbnail of the item.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly thumbnailUrl?: string;
  /**
   * The source of the creative work.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  // readonly provider?: ThingUnion[];
  /**
   * The date on which the CreativeWork was published.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly datePublished?: string;
  /**
   * Text content of this creative work
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly text?: string;
  /**
   * Original URL to retrieve the source (file) for the media object (e.g the source URL for the
   * image).
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly contentUrl?: string;
  /**
   * URL of the page that hosts the media object.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hostPageUrl?: string;
  /**
   * Size of the media object content (use format "value unit" e.g "1024 B").
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly contentSize?: string;
  /**
   * Encoding format (e.g mp3, mp4, jpeg, etc).
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly encodingFormat?: string;
  /**
   * Display URL of the page that hosts the media object.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly hostPageDisplayUrl?: string;
  /**
   * The width of the source media object, in pixels.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly width?: number;
  /**
   * The height of the source media object, in pixels.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly height?: number;
  /**
   * The URL to a thumbnail of the image
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly thumbnail?: ImageObject;
  /**
   * The token that you use in a subsequent call to the Image Search API to get additional
   * information about the image. For information about using this token, see the insightsToken
   * query parameter.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly imageInsightsToken?: string;
  /**
   * A count of the number of websites where you can shop or perform other actions related to the
   * image. For example, if the image is of an apple pie, this object includes a count of the
   * number of websites where you can buy an apple pie. To indicate the number of offers in your
   * UX, include badging such as a shopping cart icon that contains the count. When the user clicks
   * on the icon, use imageInsightsToken to get the list of websites.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  // readonly insightsMetadata?: ImagesImageMetadata;
  /**
   * Unique Id for the image
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly imageId?: string;
  /**
   * A three-byte hexadecimal number that represents the color that dominates the image. Use the
   * color as the temporary background in your client until the image is loaded.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly accentColor?: string;
  /**
   * Visual representation of the image. Used for getting more sizes
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly visualWords?: string;
}

export interface Images {
  /**
   * Polymorphic Discriminator
   */
  _type: 'Images';
  /**
   * A String identifier.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly id?: string;
  /**
   * The URL that returns this resource.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly readLink?: string;
  /**
   * The URL To Bing's search result for this item.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly webSearchUrl?: string;
  /**
   * The estimated number of webpages that are relevant to the query. Use this number along with
   * the count and offset query parameters to page the results.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalEstimatedMatches?: number;
  /**
   * Used as part of deduping. Tells client the next offset that client should use in the next
   * pagination request
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly nextOffset?: number;
  /**
   * A list of image objects that are relevant to the query. If there are no results, the List is
   * empty.
   */
  value: ImageObject[];
  /**
   * A list of expanded queries that narrows the original query. For example, if the query was
   * Microsoft Surface, the expanded queries might be: Microsoft Surface Pro 3, Microsoft Surface
   * RT, Microsoft Surface Phone, and Microsoft Surface Hub.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  // readonly queryExpansions?: Query[];
  /**
   * A list of segments in the original query. For example, if the query was Red Flowers, Bing
   * might segment the query into Red and Flowers. The Flowers pivot may contain query suggestions
   * such as Red Peonies and Red Daisies, and the Red pivot may contain query suggestions such as
   * Green Flowers and Yellow Flowers.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  // readonly pivotSuggestions?: PivotSuggestions[];
  /**
   * A list of terms that are similar in meaning to the user's query term.
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  // readonly similarTerms?: Query[];
}

type ErrorResponse = {
  _type: 'ErrorResponse';
  errors: Array<{ code: string; subCode: string; message: string; moreDetails: string }>;
};

export async function searchImages(
  search: string,
  options: { count: number; safeSearch: 'Moderate' | 'Strict' | 'Off' } = {
    count: 40,
    safeSearch: 'Strict',
  },
): Promise<Images | ErrorResponse | null> {
  // fetch doesn't exist in tsconfig.node types
  // @ts-ignore
  return fetch(
    `https://api.bing.microsoft.com/v7.0/images/search?safeSearch=${options.safeSearch}&count=${
      options.count
    }&license=public&imageType=photo&q=${encodeURIComponent(search)}`,
    {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': AZURE_KEY,
      },
    },
    // @ts-ignore
  ).then((r) => r.json());
}
