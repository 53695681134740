import type { RouteProp } from '@react-navigation/core';
import type { CompositeNavigationProp } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import type { MyStoryMyPlanCompositionSectionHashKeys } from '@oui/app-core/src/hooks/useComposition';
import {
  CoreRootStackParamList,
  DeeplinkConfigShape,
  NavigatorScreenParams,
} from '@oui/app-core/src/types/navigation';

export type StackParamList = {
  MyPlan: {};
  MyPlanReview: {};
  EditMyPlan: { editingSection?: MyStoryMyPlanCompositionSectionHashKeys };
  SoloSuicideMode: {
    step:
      | ''
      | 'overview'
      | 'a-overview'
      | 'a-riskfactors'
      | 'a-review'
      | 'b-overview'
      | 'b-events'
      | 'b-review'
      | 'c-overview'
      | 'c-inputs'
      | 'c-review'
      | 'review';
  };
  SoloRiskCurve: {
    step:
      | ''
      | 'overview'
      | 'a-overview'
      | 'a-riskfactors'
      | 'a-review'
      | 'b-overview'
      | 'b-peak'
      | 'b-rising'
      | 'b-rising-review'
      | 'b-falling'
      | 'b-falling-review'
      | 'review'
      | 'edit';
  };
  SoloMyPlan: {
    step: 'overview' | 'edit';
    initialEditingSection?: string;
  };
  SuicideMode: { step: 'review' };
  EditSuicideMode: undefined;
  RiskCurve: { step: 'review' | 'edit' };
  MyStoryMyPlan: NavigatorScreenParams<MyStoryMyPlanStackParamList>;
  ControlledMyStoryMyPlan: undefined;
  PlacesPicker: { returnRoute: string; label?: string };
};

type FullStackParamList = StackParamList & CoreRootStackParamList;

const mystorymyplanConfig: DeeplinkConfigShape<keyof MyStoryMyPlanStackParamList> = {
  MyStoryMyPlanOverview: 'mystorymyplan/overview',
  MyStoryIntroduction: 'mystorymyplan/story/introduction',
  MyStoryTimelineFinal: 'mystorymyplan/timeline',
  MyStoryRiskCurveIntroduction: 'mystorymyplan/risk-curve/introduction',
  MyStoryRiskCurveReview: 'mystorymyplan/risk-curve/review',
  MyStoryMyPlanIntroduction: 'mystorymyplan/myplan/introduction',
  MyStoryMyPlanReview: 'mystorymyplan/myplan/review',
  MyStoryMyPlanComplete: 'mystorymyplan/myplan/complete',
};

export const MYPLAN_DEEPLINK_CONFIG = {
  MyPlan: 'myplan',
  MyPlanReview: 'myplan/review',
  MyStoryMyPlan: { screens: mystorymyplanConfig },
  SuicideMode: 'suicide-mode',
  EditSuicideMode: 'suicide-mode/edit',
  SoloSuicideMode: 'solo-suicide-mode',
  SoloRiskCurve: 'solo-risk-curve',
  SoloMyPlan: 'solo-myplan',
  RiskCurve: 'risk-curve',
  ControlledMyStoryMyPlan: 'controlled-mystory-myplan',
  EditMyPlan: 'myplan/edit',
  PlacesPicker: 'places/choose',
} as const satisfies DeeplinkConfigShape<keyof StackParamList>;

export type StackScreenProps<T extends keyof StackParamList, Other extends object = {}> = {
  navigation: StackNavigationProp<FullStackParamList, T>;
  route: RouteProp<FullStackParamList, T>;
} & Other;

export type MyStoryMyPlanStackParamList = {
  MyStoryMyPlanOverview: undefined;
  MyStoryIntroduction: undefined;
  MyStoryTimelineFinal: undefined;
  MyStoryRiskCurveIntroduction: undefined;
  MyStoryRiskCurveReview: undefined;
  MyStoryMyPlanIntroduction: undefined;
  MyStoryMyPlanReview: undefined;
  MyStoryMyPlanComplete: undefined;
};

export type MyStoryMyPlanScreenProps<
  T extends keyof MyStoryMyPlanStackParamList,
  Other extends object = {},
> = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<MyStoryMyPlanStackParamList, T>,
    StackNavigationProp<FullStackParamList>
  >;
  route: RouteProp<MyStoryMyPlanStackParamList, T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends FullStackParamList {}
  }
}
