{
  "ID": "v2-session-07",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "TEST_IT"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": ["It's good to see you again.", "I hope you've been well."]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["THOUGHT_DIARY_SPOT"],
                "previousSession": "SPOT_IT"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "SPOT_IT"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to practice 'Spot It' since your last session? ",
                          "Not to worry. 'Spot It' is a very important skill so I'll make sure to put it in your practice to do list."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I saw you did some 'Spot It' practice. Great job.",
                    "How confident did you feel with the practice?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "Very unconfident",
                        "2": "Unconfident",
                        "3": "OK",
                        "4": "Confident",
                        "5": "Very confident"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "Practice makes perfect.",
                    "I'll make sure there are reminders to use 'Spot It' in the coming weeks."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Let's move to today's session.",
          "Today, we're going to do work on a skill called 'Test It'.",
          "It is a skill that builds on the last session, 'Spot It'."
        ]
      }
    },
    {
      "ID": "intro-what-is-testit-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-what-is-testit-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "What will I be testing?",
              "1": "Continue"
            }
          }
        },
        "map": {}
      }
    },
    {
      "ID": "intro-explain-testit",
      "type": "ChatText",
      "props": {
        "text": [
          "The goal will be to test the negative thoughts you have spotted.",
          "You will test how true these thoughts are.",
          "And then you will test how helpful they are."
        ]
      }
    },
    {
      "ID": "intro-pause-4",
      "type": "InputButton",
      "props": {
        "label": "How do I learn how to 'Test It'?"
      }
    },
    {
      "ID": "intro-5",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "TEST_IT_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "TEST_IT_VOICEOVER"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "learn-voiceover-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "Finding and testing a negative thought can open your mind to other ways of thinking, help you feel better, and thus lower your suicide risk.",
          "Let's now watch someone talking about their experience with 'Test It'."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "TEST_IT_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "TEST_IT_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "testit-vignette-apply",
      "type": "ChatText",
      "props": {
        "text": ["Let's now watch someone using the Thought Diary to practice 'Test It'."]
      }
    },
    {
      "ID": "prompt-start-apply-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "TEST_IT_APPLY_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "apply-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "apply-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "apply-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "TEST_IT_APPLY_VIGNETTE"
        },
        "linkText": "Watch Apply Video"
      }
    },
    {
      "ID": "transition-to-testit-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "You've now seen how to use the Thought Diary to 'test it', and how it can be helpful.",
          "Let's continue using your Thought Diary to test your thoughts."
        ]
      }
    },
    {
      "ID": "prompt-start-testit",
      "type": "InputArtifact",
      "props": {
        "artifactName": "ThoughtDiary",
        "buttonText": "Use Thought Diary"
      },
      "param": {
        "retryText": [
          "Looks like no unhelpful thoughts were tested.",
          "I know it can be hard to think of something at first. It doesn't have to be perfect.",
          "Try again"
        ],
        "retryButton": "Test thought",
        "skipButton": "I'll do it later",
        "skipNode": {
          "ID": "testit-skip",
          "type": "ChatText",
          "props": {
            "text": ["Got it. Let's wrap up for today."]
          }
        },
        "completeNode": {
          "ID": "testit-complete",
          "type": "Seq",
          "children": [
            {
              "ID": "testit-preview",
              "type": "ChatActivityPreview",
              "props": {
                "artifactName": "ThoughtDiary",
                "linkText": "View Thought Diary"
              }
            },
            {
              "ID": "testit-complete-text",
              "type": "ChatText",
              "props": {
                "text": [
                  "Good job. You've now learned some ways to test negative thoughts.",
                  "This is an important step in being flexible with negative thoughts."
                ]
              }
            },
            {
              "ID": "testit-complete-pause",
              "type": "InputButton",
              "props": {
                "label": "Got it"
              }
            }
          ]
        }
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of every session, there are two final tasks.",
          "First, let's add to your 'Lessons Learned'."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what you've learned.",
          "Using what you have learned is the best way to get benefit from this app.",
          "To help, set how many times this week you think you'll try to practice testing thoughts?"
        ]
      }
    },
    {
      "ID": "practice-frequency-store",
      "type": "Store",
      "param": {
        "key": "practiceFrequency",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputSlider",
          "props": {
            "minValue": 1,
            "maxValue": 7,
            "step": 1,
            "value": 3,
            "labels": {}
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "This week, I'll make sure that spotting and testing negative thoughts is on your to do list at least {{plural practiceFrequency \"# time\" \"# times\"}}.",
          "I'll also be sending you reminders to do the other items on your to do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "Well done on finishing!",
          "'Spot It' and 'Test It' can be a little hard at first.",
          "But I hope you stick with it and soon see its value.",
          "Take care!"
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
