import type { RouteProp } from '@react-navigation/core';
import type { StackNavigationProp } from '@react-navigation/stack';

import { CoreRootStackParamList, DeeplinkConfigShape } from '@oui/app-core/src/types/navigation';
import { GQLUUID } from '@oui/lib/src/types/scalars';

export type ActivityDiaryStackParamList = {
  ActivityDiary: {};
  ActivityPractice: { practiceID: GQLUUID };
  EditActivityPractice: {
    practiceID: GQLUUID;
    fromActivityDiary?: true; // used to help with navigation after update
    fromActivityPractice?: true; // used to help with navigation after update
  };
  EditActivityEvent: { practiceID: GQLUUID };
  NewActivityEvent: undefined;
};

export const ACTIVITY_DIARY_DEEPLINK_CONFIG = {
  ActivityDiary: 'activity-diary',
  ActivityPractice: 'activity-diary/:practiceID',
  EditActivityEvent: 'activity-diary/:practiceID/event/edit',
  EditActivityPractice: 'activity-diary/:practiceID/edit',
  NewActivityEvent: 'activity-diary/new',
} as const satisfies DeeplinkConfigShape<keyof ActivityDiaryStackParamList>;

type FullStackParamList = ActivityDiaryStackParamList & CoreRootStackParamList;

export type StackScreenProps<
  T extends keyof ActivityDiaryStackParamList,
  Other extends object = {},
> = {
  navigation: StackNavigationProp<FullStackParamList, T>;
  route: RouteProp<FullStackParamList, T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends FullStackParamList {}
  }
}
