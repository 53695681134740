import { useState } from 'react';

import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

import { getXForYOnEllipse, RiskCurve } from './RiskCurve';

const VIEWPORT_HEIGHT = 160;

export function RiskCurveBaselineGraph({
  'aria-label': ariaLabel,
  backgroundColor,
  numRiskFactors = 0,
  baselineText = 'Baseline',
}: {
  'aria-label'?: string;
  backgroundColor?: string;
  numRiskFactors?: number;
  baselineText?: string;
}) {
  const { theme } = useTheme();
  const [height, setHeight] = useState(VIEWPORT_HEIGHT);
  const baselineY = [0, 15, 25, 35, 45, 55, 65, 65, 65][numRiskFactors];
  const riskQualification = [
    'with no baseline risk',
    'with low baseline risk',
    'with some baseline risk',
    'with some baseline risk',
    'with higher baseline risk',
    'with higher baseline risk',
    'with severe baseline risk',
    'with severe baseline risk',
  ][numRiskFactors];
  return (
    <View
      row
      accessible
      aria-label={ariaLabel ?? `Your baseline risk curve ${riskQualification}`}
      role="img"
    >
      <View style={{ width: 1, alignSelf: 'stretch', backgroundColor: theme.color.gray600 }} />
      <Text
        text="Risk"
        style={{
          position: 'absolute',
          transform: [{ rotateZ: '-90deg' }, { translateY: -25 }],
          backgroundColor: backgroundColor ?? 'white',
          paddingHorizontal: 10,
        }}
        weight="semibold"
      />
      <View
        style={{ flex: 1 }}
        onLayout={(e) => {
          if (e.nativeEvent.layout.height !== height) setHeight(e.nativeEvent.layout.height);
        }}
      >
        <Text text="Peak of suicide crisis" textAlign="center" size={13} />
        <RiskCurve
          timeline={[{ ID: '0', text: '', isWarningSign: false }]}
          crisisPeakID="0"
          baselineY={baselineY}
        />
        <View
          style={{
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            transform: [
              { translateY: baselineY ? -baselineY * (height / VIEWPORT_HEIGHT) + 5 : 0 },
            ],
          }}
        >
          <Text
            text={baselineText}
            style={{
              backgroundColor: backgroundColor ?? 'white',
              marginLeft: Math.max(30 + getXForYOnEllipse({ y: VIEWPORT_HEIGHT - baselineY }), 40),
              alignSelf: 'flex-start',
            }}
            weight="semibold"
          />
          <View
            style={{
              alignSelf: 'stretch',
              height: 2,
              backgroundColor: 'black',
            }}
          />
        </View>
        <View style={{ alignSelf: 'stretch', height: 1, backgroundColor: theme.color.gray600 }} />
      </View>
    </View>
  );
}
