{
  "ID": "v2-session-05",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "SLEEP"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Welcome back to Aviva.",
          "It's good to see you again.",
          "As always, let's start with a check of your practice."
        ]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["RELAX"],
                "previousSession": "RELAXATION"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "RELAXATION"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to do any relaxation and mindfulness practice since your last session? ",
                          "Not to worry. Relaxation and mindfulness is very helpful so I'll make sure to put it in your practice to do list."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I saw you did some practice with relaxation and mindfulness. Great job.",
                    "How confident did you feel about doing the practice?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "Very unconfident",
                        "2": "Unconfident",
                        "3": "OK",
                        "4": "Confident",
                        "5": "Very confident"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "They get easier with practice ",
                    "I'll make sure there's more of this practice in the coming weeks."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Let's move to today's session.",
          "Today, we're going to learn about how to improve your sleep.",
          "There are usually three ways people suffer from bad sleep."
        ]
      }
    },
    {
      "ID": "intro-pause-2",
      "type": "InputButton",
      "props": {
        "label": "Tell me more"
      }
    },
    {
      "ID": "intro-3",
      "type": "ChatText",
      "props": {
        "text": [
          "Some people find it hard to fall asleep. They'll lie in bed but just can't sleep.",
          "Others sleep but wake up very early. Earlier than they'd like.",
          "The third group wakes up many times during the night. They have disrupted sleep.",
          "Which best describes your sleep?"
        ]
      }
    },
    {
      "ID": "intro-what-is-sleepdiary-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-what-is-sleepdiary-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "radio",
            "label": {
              "0": "Find it hard to fall asleep",
              "1": "Wake up earlier than I'd like",
              "2": "Wake up during the night and often find it hard to get back to sleep",
              "3": "I don't really have sleep problems"
            }
          }
        },
        "map": {
          "3": {
            "ID": "intro-what-is-sleepdiary-mux-3",
            "type": "ChatText",
            "props": {
              "text": [
                "That's great. You're very lucky!",
                "I suggest you continue with this session anyway as you might learn some ways to make your sleep even better."
              ]
            }
          }
        }
      }
    },
    {
      "ID": "intro-explain-sleepdiary",
      "type": "ChatText",
      "props": {
        "text": [
          "Not sleeping well is often both a cause, and effect, of other problems. ",
          "When you are tired, it is usually more difficult to deal with stress.",
          "And if you are stressed or depressed, it's often hard to sleep well.",
          "Certain medications and physical disorders can also disrupt your sleep. (Check with your primary care doctor to be sure your sleep is not being disrupted by any of these factors.)"
        ]
      }
    },
    {
      "ID": "intro-pause-4",
      "type": "InputButton",
      "props": {
        "label": "How can I improve my sleep?"
      }
    },
    {
      "ID": "intro-5",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SLEEP_DIARY_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SLEEP_DIARY_VOICEOVER"
        },
        "linkText": "Improving sleep"
      }
    },
    {
      "ID": "quiz-question-2-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "Improving your sleep helps you to better deal with life's stresses.",
          "It can help prevent a downward spiral into suicidal thoughts.",
          "Let's now watch someone sharing their experience with improving their sleep."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SLEEP_DIARY_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SLEEP_DIARY_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "sleepdiary-vignette-apply",
      "type": "ChatText",
      "props": {
        "text": ["Let's see how someone uses the Sleep Diary to improve their sleep."]
      }
    },
    {
      "ID": "prompt-start-apply-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SLEEP_DIARY_APPLY_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "apply-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "apply-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "apply-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SLEEP_DIARY_APPLY_VIGNETTE"
        },
        "linkText": "Watch Apply Video"
      }
    },
    {
      "ID": "transition-to-sleepdiary-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "You've now seen how to use the Sleep Diary, and how it can be helpful.",
          "Let's start using yours."
        ]
      }
    },
    {
      "ID": "prompt-start-sleepdiary",
      "type": "InputArtifact",
      "props": {
        "artifactName": "SleepDiary",
        "buttonText": "Use Sleep Diary"
      },
      "param": {
        "retryText": [
          "Looks like your Sleep Diary is not fully set up.",
          "Would you like to try again?"
        ],
        "retryButton": "Setup Sleep Diary",
        "skipButton": "I'll do it later",
        "skipNode": {
          "ID": "sleepdiary-skip",
          "type": "ChatText",
          "props": {
            "text": ["Got it. Let's wrap up for today."]
          }
        },
        "completeNode": {
          "ID": "sleepdiary-complete",
          "type": "Seq",
          "children": [
            {
              "ID": "sleepdiary-preview",
              "type": "ChatActivityPreview",
              "props": {
                "artifactName": "SleepDiary",
                "linkText": "View Sleep Diary"
              }
            },
            {
              "ID": "sleepdiary-complete-text",
              "type": "ChatText",
              "props": {
                "text": [
                  "Good job. You've now learned some ways to improve your sleep.",
                  "Getting good sleep is such an effective way to feel better.",
                  "It can give you the energy to then tackle other issues."
                ]
              }
            },
            {
              "ID": "sleepdiary-complete-pause",
              "type": "InputButton",
              "props": {
                "label": "Got it"
              }
            }
          ]
        }
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of every session, there are two final tasks.",
          "First, let's add to your 'Lessons Learned'."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what you've learned.",
          "Normally, I ask you how many times a week you think you can practice a new skill."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "But not for improving sleep?"
      }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "For improving sleep, we'll be doing something different.",
          "I'll ask you to fill in your sleep diary every morning and night so we can help you track sleep habits.",
          "And based on this, I'll show you some statistics and suggestions for how to further improve your sleep."
        ]
      }
    },
    {
      "ID": "practice-pause-2",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "Well done on finishing!",
          "Better sleep habits can make a big difference. I hope you can see its benefits soon."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
