import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationOptions } from '@react-navigation/stack';
import { createDraft, finishDraft } from 'immer';
import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IntlShape } from 'react-intl';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { RoundedSectionNavigationOptions } from '@oui/app-core/src/components/RoundedSection';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { logEvent } from '@oui/app-core/src/lib/log';
import { card, Theme, useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';
import { CompositionTemplates } from '@oui/lib/src/types/compositionTemplates';

import { EnvironmentSafety, PatientMyPlan } from '../components/PatientMyPlan';
import {
  IsEnvironmentSafetyLockedFragment,
  useIsEnvironmentSafetyLocked,
} from '../hooks/useIsEnvironmentSafetyLocked';
import { useMaybeReplaceImageAssetKeyURIWithResumableUpload } from '../hooks/useMaybeReplaceImageAssetKeyURIWithResumableUpload';
import { StackScreenProps } from '../types/navigation';

function getHeaderOptions({
  $t,
  isEditing,
  onPressBack,
  onPressCancel,
  onPressSave,
}: {
  $t: IntlShape['$t'];
  isEditing: boolean;
  onPressBack?: () => void;
  onPressCancel?: ComponentProps<typeof Button>['onPress'];
  onPressSave?: ComponentProps<typeof Button>['onPress'];
}): Partial<StackNavigationOptions> {
  return isEditing
    ? {
        headerLeft: ({ tintColor }) => {
          return (
            <HeaderButtons left>
              <HeaderItem
                iconName="close"
                color={tintColor}
                title=""
                onPress={onPressCancel}
                aria-label={$t({ id: 'EditMyPlan_cancelButton', defaultMessage: 'Cancel' })}
              />
            </HeaderButtons>
          );
        },
        headerRight: ({ tintColor }) => (
          <HeaderButtons>
            <Button
              testID="EditMyPlan_saveButton"
              text={$t({ id: 'EditMyPlan_saveButton', defaultMessage: 'Save' })}
              color={tintColor}
              onPress={onPressSave}
              style={{
                backgroundColor: 'transparent',
                borderColor: tintColor,
              }}
            />
          </HeaderButtons>
        ),
      }
    : {
        headerLeft: ({ tintColor }) => {
          return (
            <HeaderButtons left>
              <HeaderItem
                iconName="arrow-left"
                color={tintColor}
                title=""
                onPress={onPressBack}
                aria-label={$t({ id: 'EditMyPlan_backButton', defaultMessage: 'Back' })}
              />
            </HeaderButtons>
          );
        },
        headerRight: () => null,
      };
}

export const MyPlanOptions = ({
  $t,
  onPressSave,
  defaultHeaderHeight,
  theme,
}: {
  $t: IntlShape['$t'];
  onPressSave: () => Promise<unknown>;
  defaultHeaderHeight: number;

  theme: Theme;
}) => ({
  ...RoundedSectionNavigationOptions({
    defaultHeaderHeight,
    tintColor: 'white',
    backgroundColor: theme.color.accentThree100,
  }),
  ...getHeaderOptions({ $t, isEditing: false }),
});

export const EditMyPlanQuery = graphql(
  `
    query EditMyPlan {
      user {
        ID
        role {
          ID
          composition(template: "MYSTORYMYPLAN") {
            ID
            json
          }
          ...IsEnvironmentSafetyLocked
        }
      }
    }
  `,
  [IsEnvironmentSafetyLockedFragment],
);

export const EditMyPlanMutation = graphql(`
  mutation EditMyPlan($json: Map!) {
    setComposition(json: $json, template: MYSTORYMYPLAN) {
      ID
      json
      # Load sections so that other screens that rely on cached data are
      # updated when this mutation is finished
      sections {
        ID
        json
      }
    }
  }
`);

export function EditMyPlan() {
  const navigation = useNavigation<StackScreenProps<'EditMyPlan'>['navigation']>();
  const route = useRoute<StackScreenProps<'EditMyPlan'>['route']>();
  const { theme } = useTheme();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { data: queryData, loading } = useQuery(EditMyPlanQuery);
  const [update] = useMutation(EditMyPlanMutation);

  const data = useMemo(() => {
    return loading
      ? undefined
      : CompositionTemplates.MYSTORYMYPLAN.parse(queryData?.user?.role?.composition?.json);
  }, [queryData, loading]);
  const dataRef = useRef(data);
  dataRef.current = data;

  const updateMyPlan = useCallback(
    (updated: Partial<NonNullable<typeof data>>) => {
      // json needs to be a full MYSTORYMYPLAN composition so we spread the
      // existing document to ensure all sections are accounted for
      return update({ variables: { json: { ...dataRef.current, ...updated } } });
    },
    [update],
  );

  const defaultEditingSection = route?.params?.editingSection ?? null;
  const [editingSection, setEditingSection] = useState<keyof NonNullable<typeof data> | null>(
    defaultEditingSection,
  );
  const [unsavedData, setUnsavedData] = useState<Partial<NonNullable<typeof data>>>({});
  const setOptions = navigation?.setOptions;
  const goBack = navigation?.goBack;
  const maybeReplaceImageAssetKeyURIWithResumableUpload =
    useMaybeReplaceImageAssetKeyURIWithResumableUpload();
  const environmentSafetyLocked = useIsEnvironmentSafetyLocked(queryData?.user?.role);
  const { $t } = useI18n();

  const cancel = useCallback(() => {
    // if defaultEditingSection is present, that means we've navigated here from SoloMyPlan
    if (defaultEditingSection) {
      goBack?.();
    } else {
      setUnsavedData({});
      setEditingSection(null);
    }
  }, [goBack, defaultEditingSection]);

  const save = useCallback(async () => {
    logEvent('update_myplan');

    const draft = createDraft(unsavedData);
    await Promise.all([
      ...(draft.SOCIAL_DISTRACTIONS?.contacts ?? []).map(async (contact) => {
        return maybeReplaceImageAssetKeyURIWithResumableUpload('SOCIAL_DISTRACTIONS', contact);
      }),
      ...(draft.HELP_CONTACTS ?? []).map(async (contact) => {
        return maybeReplaceImageAssetKeyURIWithResumableUpload('HELP_CONTACTS', contact);
      }),
      ...(draft.PROFESSIONAL_HELP_CONTACTS ?? []).map(async (contact) => {
        return maybeReplaceImageAssetKeyURIWithResumableUpload(
          'PROFESSIONAL_HELP_CONTACTS',
          contact.contact,
        );
      }),
      ...(draft.ENVIRONMENT_SAFETY?.supportContacts ?? []).map(async (contact) => {
        return maybeReplaceImageAssetKeyURIWithResumableUpload('ENVIRONMENT_SAFETY', contact);
      }),
    ]);
    const unsavedDataWithCleanedAssetKeys = finishDraft(draft);

    return updateMyPlan(unsavedDataWithCleanedAssetKeys).then(() => {
      if (defaultEditingSection) {
        goBack?.();
      } else {
        setEditingSection(null);
      }
    });
  }, [
    unsavedData,
    updateMyPlan,
    goBack,
    defaultEditingSection,
    maybeReplaceImageAssetKeyURIWithResumableUpload,
  ]);

  useEffect(() => {
    if (setOptions) {
      const isDirty = Object.keys(unsavedData).length > 0;
      setOptions(
        getHeaderOptions({
          $t,
          isEditing: !!editingSection,
          onPressBack: () => goBack?.(),
          onPressCancel: () => {
            if (isDirty) {
              setShowConfirmationModal(true);
            } else {
              cancel();
            }
          },
          onPressSave: () => {
            return save();
          },
        }),
      );
    }
  }, [setOptions, data, unsavedData, goBack, editingSection, cancel, save, $t]);

  const onEditMyPlan = useCallback((toMerge: typeof unsavedData) => {
    setUnsavedData((curr) => ({ ...curr, ...toMerge }));
  }, []);

  return data ? (
    <ScrollView
      testID="EditMyPlan"
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      contentContainerStyle={[
        {
          alignSelf: 'stretch',
          padding: 20,
        },
      ]}
    >
      {editingSection !== 'ENVIRONMENT_SAFETY' ? (
        <View style={[card, { padding: 20, borderColor: theme.color.primary100, borderWidth: 2 }]}>
          <View
            style={{
              paddingHorizontal: 20,
              paddingBottom: 10,
              marginBottom: 10,
              marginTop: -10,
              marginHorizontal: -20,
              borderBottomWidth: 1,
              borderColor: theme.color.gray600,
            }}
          >
            <Text
              text={$t({ id: 'EditMyPlan_stepsHeading', defaultMessage: 'Safety steps' })}
              color={theme.color.gray300}
              weight="semibold"
              role="heading"
            />
          </View>
          <PatientMyPlan
            data={{ ...data, ...unsavedData }}
            onStartEditingSection={(section) => {
              setEditingSection(section);
              setUnsavedData({});
            }}
            editingSection={editingSection}
            focusedSection={defaultEditingSection}
            isEditing={true}
            onEdit={onEditMyPlan}
          />
        </View>
      ) : null}
      {!environmentSafetyLocked && (!editingSection || editingSection === 'ENVIRONMENT_SAFETY') ? (
        <View
          style={[
            card,
            { marginTop: 30, padding: 20, borderColor: theme.color.primary100, borderWidth: 2 },
          ]}
        >
          <EnvironmentSafety
            data={{ ...data, ...unsavedData }}
            isEditing={true}
            editingSection={editingSection}
            focusedSection={defaultEditingSection}
            onStartEditingSection={(section) => {
              setEditingSection(section);
              setUnsavedData({});
            }}
            onEdit={onEditMyPlan}
          />
        </View>
      ) : null}
      <ConfirmationModal
        onCancel={() => {
          cancel();
          setShowConfirmationModal(false);
        }}
        onConfirm={() => {
          return save().then(() => {
            setShowConfirmationModal(false);
          });
        }}
        visible={showConfirmationModal}
        confirmText={$t({
          id: 'EditMyPlan_confirmationModal_confirmButton',
          defaultMessage: 'Save',
        })}
        cancelText={$t({
          id: 'EditMyPlan_confirmationModal_cancelButton',
          defaultMessage: 'Discard',
        })}
        title={$t({ id: 'EditMyPlan_confirmationModal_title', defaultMessage: 'Save changes?' })}
        description={$t({
          id: 'EditMyPlan_confirmationModal_description',
          defaultMessage: "You've made edits to MyPlan. Would you like to save them?",
        })}
      />
    </ScrollView>
  ) : loading ? (
    <ActivityIndicator />
  ) : null;
}
