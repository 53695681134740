{
  "ID": "v2-session-02",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "HOPE_KIT"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Welcome back!",
          "I'm really happy to see you here.",
          "Let's start by looking at what you've been able to practice."
        ]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["MY_PLAN_REVIEW"],
                "previousSession": "MYPLAN"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "MYPLAN"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to review MyPlan since your last session?",
                          "Not to worry. MyPlan is really important so I'll make sure to put it in your practice to do list."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I saw you did some practice with MyPlan. Great job.",
                    "How did you feel doing the practice?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "I didn't get it",
                        "2": "Bit unsure",
                        "3": "OK",
                        "4": "I got it",
                        "5": "I fully got it"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "Practice makes perfect so I'll make sure there's more practice with MyPlan in the coming weeks."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": ["Let's now talk about today's session.", "Today, we're going to build a Hope Kit."]
      }
    },
    {
      "ID": "intro-what-is-hopekit-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-what-is-hopekit-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "What's a Hope Kit?",
              "1": "I don't feel very hopeful."
            }
          }
        },
        "map": {
          "1": {
            "ID": "intro-what-is-hopekit-mux-1",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-what-is-hopekit-mux-1-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I can see how this might not sound so helpful.",
                    "At the same time, research has proven the Hope Kit has helped thousands of people with their suicide risk.",
                    "Even people in the military have made one and found it useful."
                  ]
                }
              },
              {
                "ID": "intro-what-is-hopekit-mux-1-pause",
                "type": "InputButton",
                "props": { "label": "Ok. What is a Hope Kit?" }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-explain-hopekit",
      "type": "ChatText",
      "props": {
        "text": [
          "It's a place to store things that remind you of things that give you hope about the future.",
          "In this app, it will include things like family photos, favorite quotes and places you like to go.",
          "The idea is that when you're feeling terrible, your mind can become negative or dark.",
          "The Hope Kit is a way to turn that around - a way to give you something positive to focus on."
        ]
      }
    },
    {
      "ID": "intro-learn-more-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-learn-more-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "Can I learn more about this?",
              "1": "Continue"
            }
          }
        },
        "map": {
          "0": {
            "ID": "intro-learn-more-mux-0-text",
            "type": "ChatText",
            "props": {
              "text": ["Yes, of course!"]
            }
          }
        }
      }
    },
    {
      "ID": "intro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "In every session, there are two types of videos.",
          "First, there are Learn Videos. These include videos to explain a skill you'll learn, as well as a real world person talking about their experience.",
          "Second, there are Apply Videos. Here, the real world person will show you how to use the app to apply the skill.",
          "When you're ready for the Learn Videos, select continue."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "HOPE_KIT_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "HOPE_KIT_VOICEOVER"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "learn-voiceover-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "The Hope Kit will remind you of your reasons for living.",
          "Let's now watch someone talking about their experience with the Hope Kit."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "HOPE_KIT_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "HOPE_KIT_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "learn-vignette-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "The Hope Kit reminds people that there are lots of good things in life and that life is worth living.",
          "Let's now watch someone making and using the Hope Kit."
        ]
      }
    },
    {
      "ID": "prompt-start-apply-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "HOPE_KIT_APPLY_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "apply-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "apply-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "apply-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "HOPE_KIT_APPLY_VIGNETTE"
        },
        "linkText": "Watch Apply Video"
      }
    },
    {
      "ID": "transition-to-hopekit-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "You've now seen how to make the Hope Kit, and how it can be helpful.",
          "Let's make your Hope Kit."
        ]
      }
    },
    {
      "ID": "prompt-start-hopekit",
      "type": "InputArtifact",
      "props": {
        "artifactName": "HopeKit",
        "buttonText": "Make Hope Kit"
      },
      "param": {
        "retryText": [
          "Looks like no items were added.",
          "I know it can be hard to think of something to add at first. Start with one person or thing in your life that gives with hope.",
          "Would you like to add it now?"
        ],
        "retryButton": "Yes, let's add it",
        "skipButton": "I'll do it later",
        "skipNode": {
          "ID": "hopekit-skip",
          "type": "ChatText",
          "props": {
            "text": ["Got it. Let's wrap up for today."]
          }
        },
        "completeNode": {
          "ID": "hopekit-complete",
          "type": "Seq",
          "children": [
            {
              "ID": "hopekit-preview",
              "type": "ChatActivityPreview",
              "props": {
                "artifactName": "HopeKit",
                "linkText": "View Hope Kit"
              }
            },
            {
              "ID": "hopekit-complete-text",
              "type": "ChatText",
              "props": {
                "text": [
                  "Good job. You've now built a Hope Kit!",
                  "You can use it as a reminder of things to look forward to.",
                  "And you can add more items to it at any time."
                ]
              }
            },
            {
              "ID": "hopekit-complete-pause",
              "type": "InputButton",
              "props": {
                "label": "Got it"
              }
            }
          ]
        }
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": ["Before we end this session, let's add to your 'Lessons Learned'."]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "The last thing to do today is talk about practicing what you've learned.",
          "Using what you have learned is the best way to get benefit from this app.",
          "To help you get started, how many times this week do you think you'll be able to use your Hope Kit?"
        ]
      }
    },
    {
      "ID": "practice-frequency-store",
      "type": "Store",
      "param": {
        "key": "practiceFrequency",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputSlider",
          "props": {
            "minValue": 1,
            "maxValue": 7,
            "step": 1,
            "value": 3,
            "labels": {}
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "This week, I'll make sure that using the Hope Kit is on your to do list at least {{plural practiceFrequency \"# time\" \"# times\"}}.",
          "I'll also be sending you reminders to do the items on your to-do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "Well done on finishing this session.",
          "The Hope Kit has helped a lot of people and it can help you too.",
          "Take care and see you next session!"
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
