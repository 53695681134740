import { createContext, PropsWithChildren, useContext } from 'react';

import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';

type ActivityDiaryTheme = {
  mainColor: string;
  secondaryColor: string;
  name: string;
  item: {
    singular: string;
    plural: string;
  };
};

const ActivityDiaryContext = createContext<ActivityDiaryTheme>({
  mainColor: 'black',
  secondaryColor: 'black',
  name: '-----',
  item: {
    singular: '----',
    plural: '----',
  },
});

export function ActivityDiaryProvider({
  children,
  ...props
}: PropsWithChildren<Partial<ActivityDiaryTheme>>) {
  const { $t } = useI18n();
  const { theme } = useTheme();
  return (
    <ActivityDiaryContext.Provider
      value={{
        mainColor: theme.color.accentTwo100,
        secondaryColor: theme.color.gray600,
        name: 'Activity Diary',
        item: {
          singular: $t({ id: 'ActivityDiaryContex_itemSingular', defaultMessage: 'Activity' }),
          plural: $t({ id: 'ActivityDiaryContex_itemPlural', defaultMessage: 'Activities' }),
        },
        ...props,
      }}
    >
      {children}
    </ActivityDiaryContext.Provider>
  );
}

export function useActivityDiaryContext() {
  return useContext(ActivityDiaryContext);
}
