import { ParamListBase, StackNavigationState, TypedNavigator } from '@react-navigation/core';
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack';

import { EditSleepDiaryEntry, SetupSleepDiary, SleepDiary, SleepDiaryEntry } from './screens/index';
import { SleepDiaryStackParamList } from './types/navigation';

export function registerSleepDiaryScreens<T extends ParamListBase>(
  stack: TypedNavigator<
    T,
    StackNavigationState<SleepDiaryStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >,
) {
  const Stack = stack as TypedNavigator<
    SleepDiaryStackParamList,
    StackNavigationState<SleepDiaryStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >;
  return (
    <>
      <Stack.Screen name="SleepDiary" component={SleepDiary} />
      <Stack.Screen
        name="SetupSleepDiary"
        component={SetupSleepDiary}
        options={{ title: 'Setup sleep diary' }}
      />
      <Stack.Screen name="EditSleepDiaryEntry" component={EditSleepDiaryEntry} />
      <Stack.Screen name="SleepDiaryEntry" component={SleepDiaryEntry} />
    </>
  );
}
