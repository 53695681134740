import { useNavigation } from '@react-navigation/core';

import { PracticeItem } from '@oui/app-core/src/components/PracticeItem';
import { useI18n } from '@oui/app-core/src/lib/i18n';

export const RelaxPracticeItem = ({ complete }: { complete: boolean }) => {
  const { $t } = useI18n();
  const { navigate } = useNavigation();

  return (
    <PracticeItem
      complete={complete}
      icon="relax"
      text={$t({ id: 'ActionTodoItem_relax', defaultMessage: 'Try a relaxation exercise' })}
      color="#104f51"
      onPress={() => navigate('Relaxation')}
    />
  );
};
