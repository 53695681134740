import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';

import TellingMyStory from '../assets/mystorymyplan/telling-mystory.svg';
import {
  PatientMyStoryMyPlanContainer,
  PatientMyStoryMyPlanContainerSection,
  PatientMyStoryMyPlanQuestionAnswer,
} from '../components/PatientMyStoryMyPlanContainer';

function Dot() {
  const { theme } = useTheme();
  return (
    <View
      style={{
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: theme.color.accentThree100,
      }}
    />
  );
}
function MockTimeline() {
  const { theme } = useTheme();
  return (
    <View row style={{ justifyContent: 'space-between' }}>
      <Dot />
      <Dot />
      <Dot />
      <View
        style={{
          backgroundColor: theme.color.accentThree100,
          height: 1,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 4,
        }}
      />
    </View>
  );
}

export function PatientMyStoryNarrative() {
  const { $t } = useI18n();
  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientMyStoryNarrative_heading', defaultMessage: 'Tell your story' })}
      testID="PatientMyStoryNarrative"
    >
      <PatientMyStoryMyPlanContainerSection>
        <View spacing={25}>
          <PatientMyStoryMyPlanQuestionAnswer
            question="How do I tell MyStory?"
            answer="Focus on the hours before, and after, your suicide crisis."
          />
        </View>
      </PatientMyStoryMyPlanContainerSection>
      <PatientMyStoryMyPlanContainerSection alternate>
        <PatientMyStoryMyPlanQuestionAnswer
          question="What should I cover?"
          answer={
            <View spacing={95}>
              <View spacing={8}>
                <View row style={{ justifyContent: 'space-between' }}>
                  <Text
                    text="What led up to the crisis?"
                    textAlign="left"
                    style={{ maxWidth: '45%' }}
                  />
                  <Text text="What happened after?" textAlign="right" style={{ maxWidth: '45%' }} />
                </View>
                <MockTimeline />
                <Text
                  text="What happened the moment you thought about hurting yourself?"
                  textAlign="center"
                  style={{ maxWidth: '70%', alignSelf: 'center' }}
                />
              </View>
              <TellingMyStory aria-label={undefined} />
            </View>
          }
        />
      </PatientMyStoryMyPlanContainerSection>
    </PatientMyStoryMyPlanContainer>
  );
}
