import { MutationTuple } from '@apollo/client';
import { useState } from 'react';

import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { PracticeRatingsInput } from '@oui/app-core/src/components/PracticeRatingsInput';
import { useSnackbarContext } from '@oui/app-core/src/components/SnackbarProvider';
import { Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { getRatingsLabels } from '@oui/app-core/src/hooks/practices';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { ResultOf, VariablesOf } from '@oui/lib/src/graphql/tada';
import { RatingType } from '@oui/lib/src/types';

import type { AddRelaxDiaryEntryMutation, UpdateRelaxDiaryEntryMutation } from '../RelaxExercises';

const DEFAULT_RATING = 3;

export function MoodRatingModal({
  completion,
  entry,
  onRequestClose,
  updatePractice,
}: {
  completion: number;
  entry: ResultOf<
    typeof AddRelaxDiaryEntryMutation
  >['saveRelaxDiaryEntry']['relaxDiaryEntryPractice'];
  onRequestClose: () => void;
  updatePractice: MutationTuple<
    ResultOf<typeof UpdateRelaxDiaryEntryMutation>,
    VariablesOf<typeof UpdateRelaxDiaryEntryMutation>
  >[0];
}) {
  const [step, setStep] = useState<'before' | 'after'>('before');
  const { data, bind } = useForm(entry.practiceValues);
  completion = Math.floor(completion * 100) / 100;
  const { addSnackbarItem } = useSnackbarContext();
  const { $t } = useI18n();

  return (
    <ConfirmationModal
      onCancel={() => {
        onRequestClose();
      }}
      onConfirm={async () => {
        if (step === 'before') {
          setStep('after');
        } else {
          await updatePractice({
            variables: {
              input: {
                practiceID: entry.practiceID,
                practiceValues: {
                  ratings: [
                    {
                      type: RatingType.RATING_BEFORE,
                      value:
                        data.ratings.find((r) => r.type === RatingType.RATING_BEFORE)?.value ??
                        DEFAULT_RATING,
                    },
                    {
                      type: RatingType.RATING_AFTER,
                      value:
                        data.ratings.find((r) => r.type === RatingType.RATING_AFTER)?.value ??
                        DEFAULT_RATING,
                    },
                  ],
                  date: entry.practiceValues.date,
                  patientID: entry.practiceValues.role.ID,
                },
                relaxDiaryEntry: { completion, slug: entry.relaxDiaryEntry.slug },
              },
            },
          });
          onRequestClose();
          addSnackbarItem({
            text: $t({
              id: 'Relax_ratingCompletedSnackbar',
              defaultMessage: 'Mood rating recorded',
            }),
          });
        }
      }}
      title={
        step === 'before'
          ? $t({ id: 'Relax_moodRatingModal_beforeTitle', defaultMessage: 'Mood rating before' })
          : $t({ id: 'Relax_moodRatingModal_afterTitle', defaultMessage: 'Mood rating after' })
      }
      visible
      confirmText={
        step === 'before'
          ? $t({ id: 'Relax_moodRatingModal_nextButton', defaultMessage: 'Next' })
          : $t({ id: 'Relax_moodRatingModal_doneButton', defaultMessage: 'Done' })
      }
      confirmTestID={
        step === 'before' ? 'MoodRatingModal_nextButton' : 'MoodRatingModal_doneButton'
      }
      key={step}
    >
      <View style={{ paddingVertical: 30 }} spacing={30}>
        <Label
          text={
            step === 'before'
              ? $t({
                  id: 'Relax_moodRatingModal_before',
                  defaultMessage: 'What was your mood before doing this?',
                })
              : $t({
                  id: 'Relax_moodRatingModal_after',
                  defaultMessage: 'What was your mood after doing this?',
                })
          }
          textAlign="center"
          role="none"
        />
        <PracticeRatingsInput
          ratingType={step === 'before' ? RatingType.RATING_BEFORE : RatingType.RATING_AFTER}
          defaultRating={DEFAULT_RATING}
          labels={getRatingsLabels($t)}
          {...bind(['ratings'], {
            'aria-label': $t({
              id: 'Relax_moodRatingModal_ratingAccessibilityLabel',
              defaultMessage: 'How helpful is this thought to you?',
            }),
          })}
          testID={
            step === 'before' ? 'MoodRatingModal_ratingBefore' : 'MoodRatingModal_ratingAfter'
          }
        />
      </View>
    </ConfirmationModal>
  );
}
