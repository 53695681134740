import { PracticeTile } from '@oui/app-core/src/components/PracticeTile';

import { useHopeKitContext } from './HopeKitContext';

type HopeKitPracticeTileProps = {
  lockedUntil?: number;
  practiceCount?: number;
  onPress: () => unknown;
};

export const HopeKitPracticeTile: React.FC<HopeKitPracticeTileProps> = ({
  onPress,
  lockedUntil,
  practiceCount,
}) => {
  const hopeKitContext = useHopeKitContext();

  return (
    <PracticeTile
      testID="PracticeTile_hopeKit"
      title={hopeKitContext.name}
      description={hopeKitContext.description}
      color={hopeKitContext.mainPracticeTileColor}
      icon="kit"
      onPress={onPress}
      lockedUntil={lockedUntil}
      practiceCount={practiceCount}
    />
  );
};
