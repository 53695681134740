import { useMutation } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as Calendar from 'expo-calendar';
import * as Localization from 'expo-localization';
import { useEffect, useState } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { Divider } from '@oui/app-core/src/components/Divider';
import { HeaderButtons } from '@oui/app-core/src/components/HeaderButtons';
import { Icon } from '@oui/app-core/src/components/Icon';
import { OverflowMenu, OverflowMenuOption } from '@oui/app-core/src/components/OverflowMenu';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { getRatingsLabels, useActivityPractice } from '@oui/app-core/src/hooks/practices';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import Sentry from '@oui/app-core/src/sentry';
import { useTheme } from '@oui/app-core/src/styles';
import { parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { RatingType } from '@oui/lib/src/types/graphql.generated';
import { GQLDateTime } from '@oui/lib/src/types/scalars';

import { type ActivityDiaryEntriesQueryName } from './ActivityDiary';
import MoodAfter from '../assets/activityDiary/moodAfter.svg';
import MoodBefore from '../assets/activityDiary/moodBefore.svg';
import { useActivityDiaryContext } from '../components/ActivityDiaryContext';
import { CalendarPicker } from '../components/CalendarPicker';
import { StackScreenProps } from '../types/navigation';

export const DeleteActivityPracticeMutation = graphql(`
  mutation DeleteActivityPractice($practiceID: UUID!) {
    deleteActivityPractice(practiceID: $practiceID)
  }
`);

export function ActivityEventInstanceTiming({
  startTime,
  endTime,
}: {
  startTime: GQLDateTime;
  endTime: GQLDateTime;
}) {
  const activityDiaryTheme = useActivityDiaryContext();
  const { formatDate, formatTime } = useI18n();
  const startDate = parseGQLDateTime(startTime);
  const endDate = parseGQLDateTime(endTime);
  return (
    <View row spacing={16} style={{ alignItems: 'flex-start' }}>
      <Icon name="clock" color={activityDiaryTheme.mainColor} size={24} />
      <View flex={1}>
        <Text
          text={formatDate(startDate, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
          weight="semibold"
          size={17}
        />
        <Text
          text={`${formatTime(startDate, {
            hour: 'numeric',
            minute: 'numeric',
          })} - ${formatTime(endDate, {
            hour: 'numeric',
            minute: 'numeric',
          })}`}
          weight="semibold"
        />
      </View>
    </View>
  );
}

export function ActivityPractice() {
  const { theme } = useTheme();
  const { goBack, setOptions, navigate } =
    useNavigation<StackScreenProps<'ActivityPractice'>['navigation']>();
  const { params } = useRoute<StackScreenProps<'ActivityPractice'>['route']>();
  const { activityPractice } = useActivityPractice();
  const [addedToCalendar, setAddedToCalendar] = useState(false);
  const [showCalendarPicker, setShowCalendarPicker] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [deleteActivityPractice] = useMutation(DeleteActivityPracticeMutation);
  const { $t } = useI18n();
  const activityDiaryTheme = useActivityDiaryContext();

  const activityPlan = activityPractice?.activity;

  const isInPast = activityPlan && parseGQLDateTime(activityPlan.endTime) < new Date();

  const beforeRating = activityPractice?.practiceValues?.ratings.find(
    (r) => r.type === RatingType.RATING_BEFORE,
  )?.value;
  const afterRating = activityPractice?.practiceValues?.ratings.find(
    (r) => r.type === RatingType.RATING_AFTER,
  )?.value;

  useEffect(() => {
    setOptions({
      headerRight: params.practiceID
        ? ({ tintColor }) => (
            <HeaderButtons>
              <OverflowMenu
                triggerTestID="ActivityPractice_overflowMenuTrigger"
                headerItem
                triggerColor={tintColor}
              >
                <OverflowMenuOption
                  text={$t({ id: 'ActivityPractice_editButton', defaultMessage: 'Edit' })}
                  icon="edit"
                  testID="ActivityPractice_editButton"
                  onPress={() => {
                    if (isInPast) {
                      navigate('EditActivityPractice', {
                        practiceID: params.practiceID,
                        fromActivityPractice: true,
                      });
                    } else {
                      navigate('EditActivityEvent', {
                        practiceID: params.practiceID,
                      });
                    }
                  }}
                />
                <OverflowMenuOption
                  icon="bin"
                  text={$t({ id: 'ActivityPractice_deleteButton', defaultMessage: 'Delete' })}
                  testID="ActivityPractice_deleteButton"
                  onPress={() => setShowDeleteConfirmationModal(true)}
                />
              </OverflowMenu>
            </HeaderButtons>
          )
        : undefined,
    });
  }, [setOptions, navigate, params.practiceID, isInPast, $t]);

  return (
    <RoundedSection
      color={activityDiaryTheme.mainColor}
      title={activityDiaryTheme.item.singular}
      applyHeaderOptions
      subtitle={activityPlan?.title}
      testID="ActivityPractice_scrollView"
    >
      <View spacing={30} style={{ flexGrow: 1 }}>
        {activityPlan ? (
          <ActivityEventInstanceTiming
            startTime={activityPlan.startTime}
            endTime={activityPlan.endTime}
          />
        ) : null}
        {activityPlan?.attendee ? (
          <View row spacing={16}>
            <Icon name="people" color={activityDiaryTheme.mainColor} size={24} />
            <Text text={activityPlan.attendee} weight="semibold" size={17} />
          </View>
        ) : null}
        {activityPlan?.location ? (
          <View row spacing={16}>
            <Icon name="location" color={activityDiaryTheme.mainColor} size={24} />
            <Text text={activityPlan.location} weight="semibold" size={17} />
          </View>
        ) : null}
        {activityPlan?.notes ? (
          <View row spacing={16} style={{ alignItems: 'flex-start' }}>
            <Icon name="sessions" color={activityDiaryTheme.mainColor} size={24} />
            <View spacing={8}>
              <Text
                text={$t({ id: 'ActivityPractice_notesLabel', defaultMessage: 'Prepare' })}
                weight="semibold"
                color={theme.color.gray300}
              />
              <Text text={activityPlan.notes} weight="semibold" size={17} />
            </View>
          </View>
        ) : null}
        <Divider />
        <View spacing={20}>
          <Text
            text={$t(
              {
                id: 'ActivityPractice_moodRatingsPrompt',
                defaultMessage: `What was your mood before and after this {item}?`,
              },
              {
                item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
              },
            )}
            textAlign="center"
            size={17}
            weight="semibold"
            style={{ maxWidth: 300, alignSelf: 'center', opacity: isInPast ? undefined : 0.5 }}
          />
          {beforeRating && afterRating ? (
            <View row childFlex={1} spacing={20}>
              <View
                style={{
                  padding: 12,
                  borderRadius: 20,
                  backgroundColor: '#f1f8f9',
                }}
              >
                <View row style={{ alignItems: 'flex-start' }} spacing={8}>
                  <MoodBefore aria-label={undefined} />
                  <View style={{ alignItems: 'center', flex: 1 }}>
                    <Text
                      text={$t({
                        id: 'ActivityPractice_moodBefore',
                        defaultMessage: 'Mood before',
                      })}
                      size={15}
                      color={theme.color.gray300}
                      weight="semibold"
                      style={{ marginBottom: 8 }}
                    />
                    <Text
                      text={`${beforeRating} ${
                        getRatingsLabels($t)[beforeRating as 1 | 2 | 3 | 4 | 5]
                      }`}
                      color={activityDiaryTheme.mainColor}
                      weight="bold"
                      size={17}
                    />
                  </View>
                </View>
              </View>
              <View
                style={{
                  padding: 12,
                  borderRadius: 20,
                  backgroundColor: '#f1f8f9',
                }}
              >
                <View row style={{ alignItems: 'flex-start' }} spacing={8}>
                  <MoodAfter aria-label={undefined} />
                  <View style={{ alignItems: 'center', flex: 1 }}>
                    <Text
                      text={$t({
                        id: 'ActivityPractice_moodAfter',
                        defaultMessage: 'Mood after',
                      })}
                      size={15}
                      color={theme.color.gray300}
                      weight="semibold"
                      style={{ marginBottom: 8 }}
                    />
                    <Text
                      text={`${afterRating} ${
                        getRatingsLabels($t)[afterRating as 1 | 2 | 3 | 4 | 5]
                      }`}
                      color={activityDiaryTheme.mainColor}
                      weight="bold"
                      size={17}
                    />
                  </View>
                </View>
              </View>
            </View>
          ) : (
            <Button
              testID={
                isInPast ? 'ActivityPractice_rateButton' : 'ActivityPractice_rateButtonDisabled'
              }
              alignSelf="center"
              text={$t({ id: 'ActivityPractice_rateButton', defaultMessage: 'Rate' })}
              disabled={!isInPast}
              onPress={() => {
                navigate('EditActivityPractice', {
                  practiceID: params.practiceID,
                  fromActivityPractice: true,
                });
              }}
              variant="solid"
            />
          )}
        </View>
        <View style={{ flexGrow: 1 }} />
        {addedToCalendar ? (
          <Button
            alignSelf="center"
            icon="check"
            text={$t({
              id: 'ActivityPractice_alreadyAddedToCalendar',
              defaultMessage: 'Added to calendar',
            })}
            disabled
            onPress={() => {}}
          />
        ) : (
          <Button
            alignSelf="center"
            icon="calendar"
            text={$t({
              id: 'ActivityPractice_addToCalendarButton',
              defaultMessage: 'Add to calendar',
            })}
            onPress={() => setShowCalendarPicker(true)}
          />
        )}
      </View>
      {showCalendarPicker ? (
        <CalendarPicker
          onRequestClose={() => setShowCalendarPicker(false)}
          onChooseCalendar={(calendar) => {
            if (activityPlan) {
              Calendar.createEventAsync(calendar.id, {
                title: activityPlan.title,
                location: activityPlan.location ?? undefined,
                notes: activityPlan.notes ?? undefined,
                startDate: parseGQLDateTime(activityPlan.startTime),
                endDate: parseGQLDateTime(activityPlan.endTime),
                timeZone: Localization.timezone,
                // TODO handle recurrenceRule if necessary. See day of week logic from ActivityPlan
                // recurrenceRule: activityPlan.frequency
                //   ? {
                //       frequency: Calendar.Frequency[activityPlan.frequency],
                //       interval: activityPlan.interval || 1,
                //     }
                //   : undefined,
              })
                .then(() => {
                  setAddedToCalendar(true);
                })
                .catch((e) => Sentry.captureException(e));
              setShowCalendarPicker(false);
            }
          }}
        />
      ) : null}
      <ConfirmationModal
        visible={!!showDeleteConfirmationModal}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        onConfirm={async () => {
          await deleteActivityPractice({
            variables: { practiceID: params.practiceID },
            refetchQueries: ['ActivityDiaryEntries' satisfies ActivityDiaryEntriesQueryName],
          });
          goBack();
        }}
        cancelText={$t({
          id: 'ActivityPractice_unsavedChanges_cancelButton',
          defaultMessage: "No, don't",
        })}
        confirmText={$t({
          id: 'ActivityPractice_unsavedChanges_confirmButton',
          defaultMessage: 'Yes, delete',
        })}
        confirmTestID="ActivityPractice_confirmDeleteButton"
        title={$t({ id: 'ActivityPractice_unsavedChanges_title', defaultMessage: 'Delete?' })}
        description={$t(
          {
            id: 'ActivityPractice_unsavedChanges_description',
            defaultMessage: `Please confirm you'd like to delete this {item}.`,
          },
          {
            item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
          },
        )}
      />
    </RoundedSection>
  );
}
