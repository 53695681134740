{
  "$ref": "#/definitions/Envelopes",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "Envelopes": {
      "anyOf": [
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "const": "",
              "type": "string"
            },
            "from": {
              "const": 0,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 0,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "type": "object"
            },
            "t": {
              "const": 0,
              "type": "number"
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props",
            "t"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "enum": [
                0,
                1,
                2,
                3,
                4
              ],
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 1,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "backgroundColor": {
                  "type": "string"
                },
                "text": {
                  "items": {
                    "type": "string"
                  },
                  "type": "array"
                }
              },
              "required": [
                "text"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "enum": [
                0,
                1,
                2,
                3,
                4
              ],
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 2,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "linkText": {
                  "type": "string"
                },
                "uri": {
                  "type": "string"
                }
              },
              "required": [
                "uri"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "enum": [
                0,
                1,
                2,
                3,
                4
              ],
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 3,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "artifactName": {
                  "enum": [
                    "ActivityDiary",
                    "BingeEatingMyPlan",
                    "CopingCards",
                    "EatingLog",
                    "EditEatingSchedule",
                    "HopeKit",
                    "MyPlan",
                    "MyPlanReview",
                    "MyProgress",
                    "QuizSet",
                    "Relaxation",
                    "RiskCurve",
                    "SleepDiary",
                    "SoloMyPlan",
                    "SoloRiskCurve",
                    "SoloSuicideMode",
                    "SuicideMode",
                    "ThoughtDiary"
                  ],
                  "type": "string"
                },
                "linkText": {
                  "type": "string"
                },
                "params": {
                  "type": "object"
                },
                "testID": {
                  "type": "string"
                }
              },
              "required": [
                "artifactName",
                "linkText"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "enum": [
                0,
                1,
                2,
                3,
                4
              ],
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 4,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "data": {
                  "items": {
                    "items": {
                      "type": "string"
                    },
                    "type": "array"
                  },
                  "type": "array"
                },
                "markdown": {
                  "type": "boolean"
                }
              },
              "required": [
                "data"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 101,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "autoFocus": {
                  "type": "boolean"
                },
                "keyboardType": {
                  "enum": [
                    "default",
                    "email-address",
                    "numeric",
                    "phone-pad"
                  ],
                  "type": "string"
                },
                "placeholder": {
                  "type": "string"
                }
              },
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 102,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "label": {
                  "additionalProperties": {
                    "type": "string"
                  },
                  "type": "object"
                },
                "max": {
                  "type": "number"
                },
                "min": {
                  "type": "number"
                },
                "variant": {
                  "enum": [
                    "radio",
                    "button"
                  ],
                  "type": "string"
                }
              },
              "required": [
                "min",
                "max",
                "label"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 107,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "label": {
                  "type": "string"
                }
              },
              "required": [
                "label"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 104,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "allowSkip": {
                  "type": "boolean"
                },
                "items": {
                  "items": {
                    "additionalProperties": false,
                    "properties": {
                      "ID": {
                        "type": "string"
                      },
                      "label": {
                        "type": "string"
                      },
                      "thumbnail": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "ID",
                      "label",
                      "thumbnail"
                    ],
                    "type": "object"
                  },
                  "type": "array"
                }
              },
              "required": [
                "items"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 105,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "defaultTime": {
                  "type": "string"
                }
              },
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 112,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 106,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "allowSkip": {
                  "type": "boolean"
                }
              },
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 103,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "displayStyle": {
                  "enum": [
                    "value",
                    "label",
                    "both"
                  ],
                  "type": "string"
                },
                "labels": {
                  "additionalProperties": {
                    "type": "string"
                  },
                  "type": "object"
                },
                "maxLabel": {
                  "type": "string"
                },
                "maxValue": {
                  "type": "number"
                },
                "minLabel": {
                  "type": "string"
                },
                "minValue": {
                  "type": "number"
                },
                "step": {
                  "type": "number"
                },
                "value": {
                  "type": "number"
                }
              },
              "required": [
                "maxValue",
                "minValue"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 108,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 109,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "allowSkip": {
                  "type": "boolean"
                },
                "context": {
                  "type": "string"
                },
                "keyPrefix": {
                  "type": "string"
                }
              },
              "required": [
                "context",
                "keyPrefix"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 110,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "label": {
                  "additionalProperties": {
                    "type": "string"
                  },
                  "type": "object"
                },
                "variant": {
                  "enum": [
                    "radio",
                    "button"
                  ],
                  "type": "string"
                }
              },
              "required": [
                "label"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 111,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "label": {
                  "additionalProperties": {
                    "type": "string"
                  },
                  "type": "object"
                },
                "max": {
                  "type": "number"
                },
                "min": {
                  "type": "number"
                }
              },
              "required": [
                "min",
                "max",
                "label"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "const": 1,
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 114,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "artifactName": {
                  "enum": [
                    "ActivityDiary",
                    "BingeEatingMyPlan",
                    "CopingCards",
                    "EatingLog",
                    "EditEatingSchedule",
                    "HopeKit",
                    "MyPlanReview",
                    "QuizSet",
                    "Relaxation",
                    "RiskCurve",
                    "SleepDiary",
                    "SoloMyPlan",
                    "SoloRiskCurve",
                    "SoloSuicideMode",
                    "SuicideMode",
                    "TestWidget",
                    "ThoughtDiary"
                  ],
                  "type": "string"
                },
                "buttonText": {
                  "type": "string"
                },
                "params": {
                  "type": "object"
                },
                "retry": {
                  "const": true,
                  "type": "boolean"
                }
              },
              "required": [
                "artifactName",
                "buttonText"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        },
        {
          "additionalProperties": false,
          "properties": {
            "ID": {
              "type": "string"
            },
            "from": {
              "enum": [
                0,
                1,
                2,
                3,
                4
              ],
              "type": "number"
            },
            "history": {
              "type": "boolean"
            },
            "kind": {
              "const": 400,
              "type": "number"
            },
            "props": {
              "additionalProperties": false,
              "properties": {
                "params": {
                  "type": "object"
                },
                "routeName": {
                  "type": "string"
                }
              },
              "required": [
                "routeName"
              ],
              "type": "object"
            },
            "t": {
              "type": [
                "number",
                "string"
              ]
            }
          },
          "required": [
            "ID",
            "from",
            "kind",
            "props"
          ],
          "type": "object"
        }
      ]
    }
  }
}
