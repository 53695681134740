import * as ImageManipulator from 'expo-image-manipulator';
import * as MediaLibrary from 'expo-media-library';
import { Platform } from 'react-native';
import Url from 'url-parse';

import Sentry from '@oui/app-core/src/sentry';

export async function getUploadUriForExpoAsset({
  asset: value,
}: {
  asset: MediaLibrary.AssetInfo;
}) {
  let webMimeType =
    Platform.OS === 'web' && value.uri.startsWith('data:')
      ? value.uri.substr(5, value.uri.indexOf(';') - 5)
      : undefined;

  const mimeTypePrefix = webMimeType
    ? webMimeType.split('/')[0]
    : value.mediaType === MediaLibrary.MediaType.photo
      ? 'image'
      : 'video';

  const originalUri = value.localUri || value.uri;
  const url = new Url(originalUri);
  const suffix = url.pathname.split('.').reverse()[0].toLowerCase();
  const finalUri = await (['heic', 'jpeg', 'jpg', 'png'].includes(suffix)
    ? ImageManipulator.manipulateAsync(originalUri, [], {
        format:
          suffix === 'png' ? ImageManipulator.SaveFormat.PNG : ImageManipulator.SaveFormat.JPEG,
        compress: 0.7,
      }).then((data) => data.uri)
    : originalUri);

  const finalSuffix = finalUri.split('.').reverse()[0].toLowerCase();
  const mimeType = webMimeType ?? `${mimeTypePrefix}/${finalSuffix}`;

  Sentry.addBreadcrumb({
    message: 'add hope kit asset',
    data: {
      finalSuffix,
      finalUri,
      localUri: value.localUri,
      mimeType,
      mimeTypePrefix,
      originalUri,
      suffix,
      uri: value.uri,
    },
  });

  if (finalUri.includes('.heic')) {
    Sentry.captureMessage('hope kit item with HEIC extension', {
      extra: {
        finalSuffix,
        finalUri,
        mimeType,
        mimeTypePrefix,
        suffix,
        uri: value.uri,
        localUri: value.localUri,
        value,
      },
    });
  }

  return { uri: finalUri, mimeTypePrefix, mimeType };
}
