import { ApolloClient, useApolloClient } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { HeaderBackButton } from '@react-navigation/elements';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as Sentry from '@sentry/core';
import { AsyncStorageWrapper, CachePersistor, MMKVWrapper } from 'apollo3-cache-persist';
import { ComponentProps, ComponentType, forwardRef, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { ACTIVITY_DIARY_DEEPLINK_CONFIG, registerActivityDiaryScreens } from '@oui/activity-diary';
import { avivaTheme } from '@oui/app-core/src/avivaTheme';
import { AppContainer } from '@oui/app-core/src/components/AppContainer';
import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { RoundedSectionNavigationOptions } from '@oui/app-core/src/components/RoundedSection';
import {
  DEFAULT_HEADER_MODE,
  FLAGS,
  IS_PRODUCTION,
  SESSION_TIMEOUT,
} from '@oui/app-core/src/constants';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { createApolloCache, createApolloClient } from '@oui/app-core/src/lib/apolloClient';
import { createSessionTimeoutStackNavigator } from '@oui/app-core/src/lib/createSessionTimeoutNavigator';
import { useDefaultHeaderHeight } from '@oui/app-core/src/lib/getDefaultHeaderHeight';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { shouldShowWebBlocker } from '@oui/app-core/src/lib/initApp';
import { getMmkv, initMmkv } from '@oui/app-core/src/lib/mmkv';
import { getConfigString, initLastConfig } from '@oui/app-core/src/lib/remoteConfig';
import { AccountSettings } from '@oui/app-core/src/screens/AccountSettings';
import Auth from '@oui/app-core/src/screens/Auth';
import { Conversation } from '@oui/app-core/src/screens/Conversation';
import { CreateTestUser } from '@oui/app-core/src/screens/CreateTestUser';
import { EditLessonsLearned } from '@oui/app-core/src/screens/EditLessonsLearned';
import LocalAuthenticationPrompt from '@oui/app-core/src/screens/LocalAuthenticationPrompt';
import MediaPicker from '@oui/app-core/src/screens/MediaPicker';
import { QuizSet } from '@oui/app-core/src/screens/QuizSet';
import { TermsAndPrivacy } from '@oui/app-core/src/screens/TermsAndPrivacy';
import { TestIntl } from '@oui/app-core/src/screens/TestIntl';
import { ThemeProvider, useTheme } from '@oui/app-core/src/styles';
import { CORE_DEEPLINK_CONFIG, DeeplinkConfigShape } from '@oui/app-core/src/types/navigation';
import { StaticReview } from '@oui/app-static/src/screens/StaticReview';
import { StaticSession } from '@oui/app-static/src/screens/StaticSession';
import { StaticTabNavigator } from '@oui/app-static/src/StaticTabNavigator';
import { StaticTabParamList } from '@oui/app-static/src/types/navigation';
import { HOPE_KIT_DEEPLINK_CONFIG, registerHopeKitScreens } from '@oui/hope-kit';
import {
  ClinicianControlledMyStoryMyPlanPreview,
  MYPLAN_DEEPLINK_CONFIG,
  registerMyPlanScreens,
} from '@oui/myplan';
import { registerRelaxDiaryScreens, RELAX_DIARY_DEEPLINK_CONFIG } from '@oui/relax-diary';
import { registerSleepDiaryScreens, SLEEP_DIARY_DEEPLINK_CONFIG } from '@oui/sleep-diary';

import FullLogo from '@src/assets/Full_logo.svg';
import { OnboardingGraphic } from '@src/components/OnboardingGraphic';
import { TabNavigator } from '@src/components/TabNavigator';
import { WidgetProviders } from '@src/components/WidgetProviders';
import { setOrganizationUserProperties } from '@src/lib/setOrganizationUserProperties';
import { CMSConversationPreview } from '@src/screens/CMSConversationPreview';
import { Confidentiality } from '@src/screens/Confidentiality';
import ContactsPicker from '@src/screens/ContactsPicker';
import { CopingCards } from '@src/screens/CopingCards';
import { EditCopingCards } from '@src/screens/EditCopingCards';
import { EditThoughtDiaryEntry } from '@src/screens/EditThoughtDiaryEntry';
import { FinishPatientRegistration } from '@src/screens/FinishPatientRegistration';
import { TestArtifactResult } from '@src/screens/TestArtifactResult';
import { TestWidget } from '@src/screens/TestWidget';
import { ThoughtDiary } from '@src/screens/ThoughtDiary';
import { ThoughtDiaryEntry } from '@src/screens/ThoughtDiaryEntry';
import WebBlocker from '@src/screens/WebBlocker';
import { Welcome } from '@src/screens/Welcome';
import { RootStackParamList, TabParamList } from '@src/types';

registerChatArtifactPreviewComponent('CopingCards', () => CopingCards);
registerChatArtifactPreviewComponent('ThoughtDiary', () => ThoughtDiary);

const GoToAidIcon = ({ onPress, color: iconColor }: { onPress: () => void; color?: string }) => {
  const { $t } = useI18n();
  const { theme } = useTheme();
  return (
    <HeaderButtons>
      <HeaderItem
        iconName="my-plan"
        color={iconColor || theme.color.primary100}
        title=""
        onPress={onPress}
        aria-label={$t({ id: 'GoToAidIcon_button', defaultMessage: 'View MyPlan' })}
        testID="GoToAidIcon"
      />
    </HeaderButtons>
  );
};

const Stack = createSessionTimeoutStackNavigator<RootStackParamList>(SESSION_TIMEOUT);
const MainNavigator = () => {
  const [hasSeenLocalAuthenticationPrompt] = usePersistedState(
    'SeenLocalAuthenticationPrompt',
    false,
  );
  const { scheme, theme } = useTheme();
  const apollo = useApolloClient();
  const { data: user, isLoggedIn } = useCurrentUser();
  const defaultHeaderHeight = useDefaultHeaderHeight();
  const { $t } = useI18n();

  let homePath: 'home' | 'homeStatic' = 'home';
  if (user?.currentUser?.user?.__typename === 'Patient') {
    if (user?.currentUser.user.productStatic) {
      homePath = 'homeStatic';
    }
  }
  useEffect(() => {
    if (isLoggedIn) {
      void setOrganizationUserProperties(apollo);
    }
  }, [isLoggedIn, apollo]);

  useEffect(() => {
    if (isLoggedIn && !user?.currentUser?.user) {
      Sentry.captureException('App loaded without user');
    }
  }, [isLoggedIn, user?.currentUser?.user]);

  return (
    <Stack.Navigator
      {...Platform.select({ web: { cardStyle: { flex: 1 } } })} // So web screens grow properly
      screenOptions={({ navigation }) => {
        const options: StackNavigationOptions = {
          headerStyle: {
            height: defaultHeaderHeight,
          },
          headerBackTestID: 'header-back',
          headerTruncatedBackTitle: $t({ id: 'App_backButtonLabel', defaultMessage: 'Back' }),
          headerTintColor: theme.color.primary100,
          headerTitleStyle: { color: theme.color.dark, fontFamily: 'OpenSansSemiBold' },
          headerRight: () => (
            <GoToAidIcon
              onPress={() => navigation.navigate(homePath === 'homeStatic' ? 'Aid' : 'MyPlan')}
            />
          ),
          headerLeft:
            navigation.canGoBack() || !isLoggedIn
              ? undefined
              : ({ tintColor }) => (
                  <HeaderBackButton
                    onPress={() => {
                      navigation.replace(homePath);
                    }}
                    tintColor={tintColor}
                  />
                ),
          headerMode: DEFAULT_HEADER_MODE,
        };
        return options;
      }}
    >
      {isLoggedIn ? (
        shouldShowWebBlocker() ? (
          <Stack.Screen name="WebBlocker" component={WebBlocker} options={{ headerShown: false }} />
        ) : (
          <>
            {hasSeenLocalAuthenticationPrompt ? null : (
              <Stack.Screen
                name="LocalAuthenticationPrompt"
                component={LocalAuthenticationPrompt}
                options={{ headerShown: false }}
              />
            )}
            {homePath === 'home' ? (
              <Stack.Screen
                name="home"
                component={TabNavigator}
                options={{ headerShown: false, title: '' }}
              />
            ) : (
              <Stack.Screen name="homeStatic" options={{ headerShown: false, title: '' }}>
                {() => <StaticTabNavigator AccountSettings={AccountSettings as ComponentType} />}
              </Stack.Screen>
            )}
            <Stack.Screen
              name="StaticSession"
              component={StaticSession}
              options={({ route }) => ({
                headerTitle: route.params.title,
                headerBackTitleVisible: false,
              })}
            />
            <Stack.Screen
              name="StaticReview"
              component={StaticReview}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                headerTintColor: theme.color.gray100,
                tintColor: theme.color.primary100,
                backgroundColor: 'white',
              })}
            />
            <Stack.Screen
              // initialParams={{ ID: 'TEST::convo' }}
              name="Conversation"
              component={Conversation}
              options={({ route }) => ({
                headerBackTitleVisible: false,
                title:
                  route.params?.title ??
                  route.params?.ID ??
                  $t({ id: 'Conversation_title', defaultMessage: 'Conversation' }),
              })}
            />

            {registerActivityDiaryScreens(Stack)}
            {registerHopeKitScreens(Stack)}
            {registerRelaxDiaryScreens(Stack)}
            {registerSleepDiaryScreens(Stack)}
            {registerMyPlanScreens(Stack)}

            <Stack.Screen
              name="CopingCards"
              component={CopingCards}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: theme.color.primary100,
                backgroundColor: 'white',
              })}
            />
            <Stack.Screen
              name="EditCopingCards"
              component={EditCopingCards}
              options={(props) => {
                return props.route.params?.copingCardIndex
                  ? {
                      title: $t({
                        id: 'EditCopingCards_editCardTitle',
                        defaultMessage: 'Edit card',
                      }),
                      headerRight: () => null,
                    }
                  : {
                      title: $t({
                        id: 'EditCopingCards_title',
                        defaultMessage: 'Add to cards',
                      }),
                      headerRight: () => null,
                    };
              }}
            />
            <Stack.Screen name="EditLessonsLearned" component={EditLessonsLearned} />
            <Stack.Screen
              name="ContactsPicker"
              component={ContactsPicker}
              options={() => ({
                title: $t({ id: 'ContactsPicker_title', defaultMessage: 'Contacts' }),
                headerLeft: () => <HeaderBackButton onPress={() => {}} />,
                headerRight: undefined,
              })}
            />
            <Stack.Screen
              name="MediaPicker"
              component={MediaPicker}
              options={{ headerShown: false }}
            />
            <Stack.Screen name="AccountSettings" component={AccountSettings} />
            <Stack.Screen
              name="TermsAndPrivacy"
              component={TermsAndPrivacy}
              options={{
                // https://github.com/react-native-community/react-native-webview/issues/575#issuecomment-587267906
                animationEnabled: false,
                title: $t({ id: 'TermsAndPrivacy_title', defaultMessage: 'Terms and Privacy' }),
              }}
            />
            <Stack.Screen
              name="FinishPatientRegistration"
              component={FinishPatientRegistration}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="ThoughtDiary"
              component={ThoughtDiary}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: theme.color.gray100,
                backgroundColor: '#c1e3c9',
              })}
            />
            <Stack.Screen
              name="ThoughtDiaryEntry"
              component={ThoughtDiaryEntry}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: theme.color.gray100,
                backgroundColor: '#c1e3c9',
              })}
            />
            <Stack.Screen name="NewThoughtDiaryEntry" component={EditThoughtDiaryEntry} />
            <Stack.Screen
              name="EditThoughtDiaryEntry"
              component={EditThoughtDiaryEntry}
              options={{
                title: $t({ id: 'EditThoughtDiaryEntry_title', defaultMessage: 'Edit thought' }),
              }}
            />
            <Stack.Screen
              name="QuizSet"
              component={QuizSet}
              options={RoundedSectionNavigationOptions({
                defaultHeaderHeight,
                tintColor: 'white',
                backgroundColor: 'black',
              })}
              initialParams={{}}
            />
            <Stack.Screen
              name="Confidentiality"
              component={Confidentiality}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen name="TestWidget" component={TestWidget} />
            <Stack.Screen name="TestIntl" component={TestIntl} />
            <Stack.Screen
              name="TestArtifactResult"
              component={TestArtifactResult}
              // initialParams={{ artifactName: 'SoloMyPlan' }}
            />
          </>
        )
      ) : (
        <>
          {Auth({ $t, Screen: Stack.Screen, scheme })}
          <Stack.Screen name="Welcome" component={Welcome} options={{ headerShown: false }} />
          <Stack.Screen
            name="ClinicianControlledMyStoryMyPlanPreview"
            component={ClinicianControlledMyStoryMyPlanPreview}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CMSConversationPreview"
            component={CMSConversationPreview}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="CreateTestUser"
            component={CreateTestUser}
            options={{ headerShown: false }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

const homeConfig: DeeplinkConfigShape<keyof TabParamList> = {
  Home: 'home',
  Learn: 'learn',
  Practice: 'practice',
  Aid: 'aid',
  Profile: 'profile',
};
const homeStaticConfig: DeeplinkConfigShape<keyof StaticTabParamList> = {
  Learn: 'static/learn',
  Practice: 'static/practice',
  Aid: 'static/aid',
  Profile: 'static/account',
};

export const DEEPLINK_CONFIG = {
  CreateTestUser: 'auth/create-test-user',
  home: { screens: homeConfig },
  homeStatic: { screens: homeStaticConfig },
  StaticSession: 'static/session',
  StaticReview: 'static/review',
  CopingCards: 'coping-cards',
  EditCopingCards: 'coping-cards/edit',
  Welcome: 'welcome',
  WebBlocker: 'web-blocker',
  Confidentiality: 'confidentiality',
  ThoughtDiary: 'thought-diary',
  ThoughtDiaryEntry: 'thought-diary/:practiceID',
  NewThoughtDiaryEntry: 'thought-diary/new',
  EditThoughtDiaryEntry: 'thought-diary/:practiceID/edit',
  TestWidget: 'test/widget',
  TestIntl: 'test/intl',
  ...ACTIVITY_DIARY_DEEPLINK_CONFIG,
  ...CORE_DEEPLINK_CONFIG,
  ...HOPE_KIT_DEEPLINK_CONFIG,
  ...RELAX_DIARY_DEEPLINK_CONFIG,
  ...SLEEP_DIARY_DEEPLINK_CONFIG,
  ...MYPLAN_DEEPLINK_CONFIG,
  // iframeable screens used by Oui Platform features
  ClinicianControlledMyStoryMyPlanPreview: 'ClinicianControlledMyStoryMyPlanPreview',
  CMSConversationPreview: 'CMSConversationPreview',
} satisfies DeeplinkConfigShape<keyof RootStackParamList>;

const getMessages: ComponentProps<typeof AppContainer>['getMessages'] = ({ lang }) => {
  switch (lang) {
    case 'en': {
      return require('./messages/compiled/en.json');
    }
    default:
      return {};
  }
};

type Props = {};
export default forwardRef<AppContainer, Props>(function App({}: Props, ref) {
  const [apollo, setApollo] = useState<ApolloClient<unknown>>();

  useEffect(() => {
    // This will only run in development when fast-refresh kicks in
    let cleanupRef = { current: () => {} };

    async function initApollo() {
      const cache = createApolloCache();

      // TODO move to mountApp?
      await initMmkv();

      try {
        const options =
          Platform.OS === 'web'
            ? IS_PRODUCTION
              ? undefined
              : {
                  cache,
                  trigger: 'write' as const,
                  storage: new AsyncStorageWrapper(AsyncStorage),
                }
            : {
                cache,
                trigger: 'background' as const,
                storage: new MMKVWrapper(getMmkv('apollo')),
              };

        if (options) {
          const persistor = new CachePersistor(options);
          await persistor.restore();
          cleanupRef.current = () => persistor.remove();
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      await initLastConfig();
      const client = createApolloClient(getConfigString('apiUrl'), {
        subscriptionUri: getConfigString('subscriptionUri'),
        cache,
        connectToDevTools: true,
      });
      setApollo(client);
      const start = Date.now();
      const removedKeys = cache.gc();
      const duration = Date.now() - start;
      Sentry.addBreadcrumb({
        category: 'apollo',
        message: 'cache.gc',
        data: { removedKeys, duration },
      });
    }

    initApollo().catch(Sentry.captureException);

    return () => cleanupRef.current();
  }, []);

  if (Platform.OS === 'web' && IS_PRODUCTION) {
    return <WebBlocker />;
  }

  return apollo ? (
    <ThemeProvider theme={avivaTheme}>
      <AppContainer
        Logo={FullLogo}
        flags={FLAGS}
        app={() => (
          <WidgetProviders>
            <MainNavigator />
          </WidgetProviders>
        )}
        apollo={apollo}
        ref={ref}
        initialPath={({
          ouiUser,
        }): Parameters<GeneratedClientRoutes.GetDeeplinkPath['aviva']>[0] => {
          if (!ouiUser) return '/welcome';
          if (ouiUser.user?.__typename === 'Patient') {
            if (ouiUser.user.productStatic) {
              return '/static/learn';
            }
          }
          return '/home';
        }}
        deeplinkConfig={{ screens: DEEPLINK_CONFIG }}
        getMessages={getMessages}
        onboardingGraphic={<OnboardingGraphic />}
      />
    </ThemeProvider>
  ) : null;
});
