{
  "ID": "v2-session-04",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "RELAXATION"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Good to see you back.",
          "Hope things have been going well for you.",
          "Let's start with the usual check of your practices."
        ]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["ACTIVITY_ADD", "ACTIVITY_RATE"],
                "previousSession": "ACTIVITY_PLANNING"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "ACTIVITY_PLANNING"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to do any 'get going' practice since your last session?",
                          "Not to worry. 'Get going' can be very helpful. I'll make sure it is in your practice list."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I saw you did some practice with 'get going'. Great job.",
                    "How good did you feel with the practice?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "Poor",
                        "2": "Fair",
                        "3": "OK",
                        "4": "Good",
                        "5": "Very good"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "Practice makes perfect.",
                    "I'll make sure there's more 'get going' practice in the coming weeks."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Let's now talk about today's session.",
          "We're going to learn about two different techniques - relaxation and mindfulness."
        ]
      }
    },
    {
      "ID": "intro-what-is-relaxation-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-what-is-relaxation-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "What's this about?",
              "1": "Continue",
              "2": "Is this really going to help me?"
            }
          }
        },
        "map": {
          "2": {
            "ID": "intro-what-is-relaxation-mux-1",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-what-is-relaxation-mux-1-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "It is! They are real tools proven to help lots of people.",
                    "These can take a little time to learn. The more you practice them the more helpful they will be.",
                    "If you've tried them in the past, but haven't seen benefits, try our ideas and see if they work any better."
                  ]
                }
              },
              {
                "ID": "intro-what-is-relaxation-mux-1-pause",
                "type": "InputButton",
                "props": { "label": "Ok, let's take a look" }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-explain-relaxation",
      "type": "ChatText",
      "props": {
        "text": [
          "If you often feel stressed or worried, it can be hard to think straight.",
          "Problems might seem really big or hard to solve.",
          "This is when these can help."
        ]
      }
    },
    {
      "ID": "intro-learn-more-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-learn-more-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "How do I learn more about this?",
              "1": "Continue"
            }
          }
        },
        "map": {}
      }
    },
    {
      "ID": "intro-4",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RELAXATION_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RELAXATION_VOICEOVER"
        },
        "linkText": "Relaxation"
      }
    },
    {
      "ID": "learn-voiceover-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "Relaxation skills should be practiced as much as you can to help lower stress levels. Over time this will help you handle more difficult moments and decrease your risk.",
          "Now that we've talked a little about relaxation, let's learn more about mindfulness.",
          "When you're ready, click continue."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover-2",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MINDFULNESS_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-2-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-2-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-2-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "MINDFULNESS_VOICEOVER"
        },
        "linkText": "Mindfulness"
      }
    },
    {
      "ID": "relaxation-vignette-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "Learning a way to stop thinking negative thoughts about yourself and decrease the emotion attached to them when they do occur can do a lot to decrease your stress level.",
          "Lowering stress helps your state of mind and reduces risk.",
          "Let's now watch someone talking about their experience with relaxation and mindfulness."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RELAXATION_MINDFULNESS_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RELAXATION_MINDFULNESS_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "relaxation-vignette-apply",
      "type": "ChatText",
      "props": {
        "text": ["Let's now watch someone using the app to practice relaxation and mindfulness."]
      }
    },
    {
      "ID": "prompt-start-apply-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RELAXATION_MINDFULNESS_APPLY_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "apply-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "apply-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "apply-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RELAXATION_MINDFULNESS_APPLY_VIGNETTE"
        },
        "linkText": "Watch Apply Video"
      }
    },
    {
      "ID": "transition-to-relaxation-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "You've now seen how to practice relaxation or mindfulness in the app, and how it can be helpful.",
          "Let's do a practice now."
        ]
      }
    },
    {
      "ID": "prompt-start-relaxation",
      "type": "InputArtifact",
      "props": {
        "artifactName": "Relaxation",
        "buttonText": "Practice relaxation & mindfulness"
      },
      "param": {
        "retryText": [
          "Looks like you weren't able to try any of the relaxation or mindfulness exercises",
          "I know it can seem boring at first, but these exercises can help you in times of stress",
          "Trying them now help you see what they're like"
        ],
        "retryButton": "Try exercises",
        "skipButton": "I'll do them later",
        "skipNode": {
          "ID": "relaxation-skip",
          "type": "ChatText",
          "props": {
            "text": ["Got it. Let's wrap up for today."]
          }
        },
        "completeNode": {
          "ID": "relaxation-complete",
          "type": "Seq",
          "children": [
            {
              "ID": "relaxation-preview",
              "type": "ChatActivityPreview",
              "props": {
                "artifactName": "Relaxation",
                "linkText": "View exercises"
              }
            },
            {
              "ID": "relaxation-complete-text",
              "type": "ChatText",
              "props": {
                "text": [
                  "Good job. You've now learned relaxation and mindfulness skills.",
                  "It might seem strange to think that a bit of breathing or focusing on a simple task and learning to take some distance from bothersome thoughts when they occur can help.",
                  "But hopefully, by trying them today, you have already experienced their value."
                ]
              }
            },
            {
              "ID": "relaxation-complete-pause",
              "type": "InputButton",
              "props": {
                "label": "Got it"
              }
            }
          ]
        }
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of every session, there are two final tasks.",
          "First, write the lessons you learned from this session.",
          "As you know, lessons learned are the one or two most important things you took away from today's session."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what you've learned.",
          "We all know that practice makes perfect.",
          "To help, set how many times this week you think you'll try to use these skills?"
        ]
      }
    },
    {
      "ID": "practice-frequency-store",
      "type": "Store",
      "param": {
        "key": "practiceFrequency",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputSlider",
          "props": {
            "minValue": 1,
            "maxValue": 7,
            "step": 1,
            "value": 3,
            "labels": {}
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "I'll make sure that using relaxation or mindfulness is on your to do list at least {{plural practiceFrequency \"# time\" \"# times\"}} this week.",
          "I'll also be sending you reminders to do the other items on your to do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "We're at the end of today's session.",
          "Mindfulness and relaxation seem so simple, but they work.",
          "I hope you can try them soon. Take care."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
