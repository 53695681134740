import { createElement } from 'react';

import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';

import HopeKitArtifactPreview from './assets/hopeKitArtifactPreview.svg';
import HopeKitEmpty from './assets/hopeKitEmpty.svg';

registerChatArtifactPreviewComponent(
  'HopeKit',
  ({ width, height, defaultHeight }) =>
    () =>
      createElement(HopeKitArtifactPreview, {
        height: height * 2,
        width: width * 2,
        viewBox: '0 0 210 125',
        'aria-label': undefined,
        preserveAspectRatio: height !== defaultHeight ? 'xMinYMin slice' : undefined,
      }),
);

export { HopeKitArtifactPreview, HopeKitEmpty };

export * from './screens';
export * from './components';
export * from './hooks';
export * from './types/navigation';
export * from './register';
