import { createContext, PropsWithChildren, useContext } from 'react';

import { useTheme } from '@oui/app-core/src/styles';

type RelaxTheme = {
  backgroundColor: string;
  accentColor: string;
  showBreathingCircleIllustration: boolean;
};

const RelaxContext = createContext<RelaxTheme>({
  backgroundColor: 'black',
  accentColor: 'black',
  showBreathingCircleIllustration: true,
});

export function RelaxProvider({ children, ...props }: PropsWithChildren<Partial<RelaxTheme>>) {
  const { theme } = useTheme();
  return (
    <RelaxContext.Provider
      value={{
        backgroundColor: theme.color.accent300,
        accentColor: theme.color.accentTwo100,
        showBreathingCircleIllustration: true,
        ...props,
      }}
    >
      {children}
    </RelaxContext.Provider>
  );
}

export function useRelaxContext() {
  return useContext(RelaxContext);
}
