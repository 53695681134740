import { ParamListBase, StackNavigationState, TypedNavigator } from '@react-navigation/core';
import { HeaderBackButton } from '@react-navigation/elements';
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack';

import { PatientControlledMyStoryMyPlan } from './screens/ControlledMyStoryMyPlan';
import { EditMyPlan } from './screens/EditMyPlan';
import { EditSuicideMode } from './screens/EditSuicideMode';
import { PlacesPicker } from './screens/LocationPicker';
import { MyPlanReview } from './screens/MyPlanReview/MyPlanReview';
import { MyPlanRounded } from './screens/MyPlanRounded';
import { PatientMyStoryMyPlan } from './screens/PatientMyStoryMyPlan';
import { SoloMyPlan } from './screens/SoloMyPlan';
import { SoloRiskCurve } from './screens/SoloRiskCurve';
import { SoloSuicideMode } from './screens/SoloSuicideMode';
import { StackParamList } from './types/navigation';

export function registerMyPlanScreens<T extends ParamListBase>(
  stack: TypedNavigator<
    T,
    StackNavigationState<StackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >,
) {
  const Stack = stack as TypedNavigator<
    StackParamList,
    StackNavigationState<StackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >;
  return (
    <>
      <Stack.Screen name="RiskCurve" component={SoloRiskCurve} initialParams={{ step: 'review' }} />
      <Stack.Screen name="SoloRiskCurve" component={SoloRiskCurve} />
      <Stack.Screen
        name="SuicideMode"
        component={SoloSuicideMode}
        initialParams={{ step: 'review' }}
      />
      <Stack.Screen
        name="EditSuicideMode"
        component={EditSuicideMode}
        options={{ title: 'Edit Suicide Mode' }}
      />
      <Stack.Screen name="SoloSuicideMode" component={SoloSuicideMode} />
      <Stack.Screen name="SoloMyPlan" component={SoloMyPlan} />
      <Stack.Screen name="MyPlan" component={MyPlanRounded} />
      <Stack.Screen name="EditMyPlan" component={EditMyPlan} options={{ title: 'Edit MyPlan' }} />
      <Stack.Screen name="MyPlanReview" component={MyPlanReview} />
      <Stack.Screen
        name="PlacesPicker"
        component={PlacesPicker}
        options={() => ({
          title: 'Places',
          headerLeft: () => <HeaderBackButton onPress={() => {}} />,
          headerRight: undefined,
        })}
      />
      <Stack.Screen
        name="MyStoryMyPlan"
        component={PatientMyStoryMyPlan}
        options={{ title: 'MyStory & MyPlan' }}
      />
      <Stack.Screen
        name="ControlledMyStoryMyPlan"
        component={PatientControlledMyStoryMyPlan}
        options={{ headerShown: false }}
      />
    </>
  );
}
