import { useMutation, useQuery } from '@apollo/client';

import { Button } from '@oui/app-core/src/components/Button';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentPatientID } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { PatientSupporterCard } from '@src/components/PatientSupporterCard';

import { PatientSupportersQuery } from './PatientSupporters';

export const ReviewPatientSupporterMutation = graphql(`
  mutation ReviewPatientSupporter($patientSupporterID: UUID!) {
    reviewPatientSupporter(patientSupporterID: $patientSupporterID) {
      supporterID
      patientID
      reviewed
    }
  }
`);

export function NewPatientSupporterNotification() {
  const { $t } = useI18n();

  const patientID = useCurrentPatientID()!;

  const [reviewPatientSupporter] = useMutation(ReviewPatientSupporterMutation);

  const { data, loading } = useQuery(PatientSupportersQuery, {
    variables: {
      patientID,
    },
    skip: !patientID,
  });

  const onDismiss = (patientSupporterID: GQLUUID) => {
    return reviewPatientSupporter({
      variables: {
        patientSupporterID,
      },
    });
  };

  if (loading || !data?.user?.role?.supporters.length) {
    return null;
  }

  return (
    <View spacing={20} testID="NewPatientSupporterNotification">
      {data?.user?.role?.supporters
        .filter((i) => i.claimed && !i.reviewed)
        .map(({ supporter: { ID, user }, relation }) => (
          <View
            testID={`NewPatientSupporterNotification_patientSupporter_${ID}`}
            key={ID}
            style={{
              backgroundColor: 'white',
              borderRadius: 20,
              paddingHorizontal: 20,
              paddingVertical: 15,
            }}
            spacing={22}
          >
            <Text
              weight="semibold"
              text={$t({
                id: 'NewPatientSupporterNotification_newSupporter',
                defaultMessage: 'A caregiver has been added',
              })}
              textAlign="center"
            />

            <View
              style={{
                alignItems: 'center',
              }}
            >
              <PatientSupporterCard
                name={`${user.givenName} ${user.familyName}`}
                relation={relation}
              />
            </View>

            <Text
              text={$t({
                id: 'NewPatientSupporterNotification_visiblity',
                defaultMessage:
                  'Heads up, your completed plan to keep your environment safe is visible to them.',
              })}
              textAlign="center"
            />

            <Button
              testID="NewPatientSupporterNotification_dismissButton"
              onPress={() => {
                return onDismiss(ID);
              }}
              text={$t({
                id: 'NewPatientSupporterNotification_dismiss',
                defaultMessage: 'Dismiss',
              })}
              variant="text"
              alignSelf="center"
            />
          </View>
        ))}
    </View>
  );
}
