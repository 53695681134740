import { useMutation, useQuery } from '@apollo/client';
import { Header } from '@react-navigation/elements';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Platform, StyleSheet } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import LoginFlower from '@oui/app-core/src/assets/Login_flower.svg';
import LoginPlant from '@oui/app-core/src/assets/Login_plant.svg';
import { ArtifactButton } from '@oui/app-core/src/components/ArtifactButton';
import { Button } from '@oui/app-core/src/components/Button';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import MediaPlayer from '@oui/app-core/src/components/MediaPlayer';
import { Pill } from '@oui/app-core/src/components/Pill';
import { SvgUri } from '@oui/app-core/src/components/SvgUri';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { PracticeFragment } from '@oui/app-core/src/hooks/practices';
import { useCurrentPatientID } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { logEvent } from '@oui/app-core/src/lib/log';
import { Shadow } from '@oui/app-core/src/styles';
import { FragmentOf, graphql, readFragment, ResultOf } from '@oui/lib/src/graphql/tada';

import { BreathingCircle, BreathingCircleFragment, Handles } from './BreathingCircle';
import { MoodRatingModal } from './MoodRatingModal/MoodRatingModal';
import { useRelaxContext } from './RelaxContext';

export const RelaxDiaryEntryFragment = graphql(`
  fragment RelaxDiaryEntryFragment on RelaxDiaryEntry @_unmask {
    __typename
    slug
    completion
  }
`);

export const AddRelaxDiaryEntryMutation = graphql(
  `
    mutation AddRelaxDiaryEntry($input: AddRelaxDiaryEntryPracticeInput!) {
      saveRelaxDiaryEntry: addRelaxDiaryEntry(input: $input) {
        relaxDiaryEntryPractice {
          ...PracticeFragment
          relaxDiaryEntry {
            ...RelaxDiaryEntryFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, RelaxDiaryEntryFragment],
);

export const UpdateRelaxDiaryEntryMutation = graphql(
  `
    mutation UpdateRelaxDiaryEntry($input: UpdateRelaxDiaryEntryPracticeInput!) {
      saveRelaxDiaryEntry: updateRelaxDiaryEntry(input: $input) {
        relaxDiaryEntryPractice {
          ...PracticeFragment
          relaxDiaryEntry {
            ...RelaxDiaryEntryFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, RelaxDiaryEntryFragment],
);

const RelaxExercisesQueryName = 'RelaxExercises';
export const RelaxExercisesQuery = graphql(`
  query RelaxExercises {
    relaxDiaryEntryCounts
  }
`);

type Exercise = ResultOf<typeof RelaxExercisesFragment>['mindfulnessExercises'][number];

function MindfulnessModal({
  onRequestClose,
  exercise,
}: {
  exercise: Exercise;
  onRequestClose: (completion: number) => void;
}) {
  const mindfulnessPlayer = useRef<MediaPlayer>(null);
  const completionRef = useRef(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const { $t } = useI18n();
  const insets = useSafeAreaInsets();

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (mindfulnessPlayer.current) {
          void mindfulnessPlayer.current.setIsPlayingAsync(false);
        }
      };
    }, []),
  );

  function onRequestCloseWithCompletion() {
    onRequestClose(completionRef.current);
  }

  return (
    <Modal visible onRequestClose={onRequestCloseWithCompletion}>
      <SystemBars style="light" />
      <View
        style={{
          flex: 1,
          backgroundColor: '#28352c',
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
        }}
      >
        <Header
          title={$t({
            id: 'Relax_mindfulnessModal_exerciseTitle',
            defaultMessage: 'Mindfulness exercise',
          })}
          headerTintColor="white"
          headerLeft={() => {
            return (
              <HeaderButtons left>
                <HeaderItem
                  testID="Relax_closeMindfulnessExerciseButton"
                  aria-label={$t({
                    id: 'Relax_mindfulnessModal_closeButton',
                    defaultMessage: 'Stop mindfulness exercise',
                  })}
                  iconName="close"
                  color="white"
                  iconSize={24}
                  title=""
                  onPress={onRequestCloseWithCompletion}
                />
              </HeaderButtons>
            );
          }}
          headerStyle={{ backgroundColor: '#28352c' }}
        />
        <View
          flex={1}
          style={{
            justifyContent: 'space-around',
            paddingHorizontal: 20,
          }}
        >
          <View
            style={[
              {
                borderRadius: 20,
              },
              Shadow.high,
            ]}
          >
            <View
              style={{
                backgroundColor: '#405446',
                borderRadius: 20,
                overflow: 'hidden',
              }}
            >
              <View
                style={{
                  width: '100%',
                  height: 200,
                  backgroundColor: '#ebf1ed',
                  borderRadius: 20,
                  overflow: 'hidden',
                }}
              >
                {/* SvgUri does not exist on web */}
                {Platform.OS === 'web' ? (
                  <img src={exercise.illustrationUrl!} style={StyleSheet.absoluteFillObject} />
                ) : (
                  <SvgUri
                    uri={exercise.illustrationUrl!}
                    height="101%"
                    width="101%"
                    preserveAspectRatio={'xMinYMax meet'}
                  />
                )}
              </View>
              <Text
                text={exercise.title}
                textAlign="center"
                color="white"
                style={{ marginTop: 20 }}
              />
              <View>
                <MediaPlayer
                  _hidePlayPauseButton
                  onProgress={(progress, isPlaying) => {
                    completionRef.current = progress.positionMillis / progress.durationMillis;
                    setIsPlaying(isPlaying);
                    if (!isLoaded) setIsLoaded(true);
                  }}
                  onEnd={() => (completionRef.current = 1)}
                  backgroundColor="#405446"
                  miniPlayer={false}
                  miniPlayerControlsColor="white"
                  ref={mindfulnessPlayer}
                  uri={exercise.audioUrl}
                  subtitleUri={exercise.subtitleUrl}
                  controlsContainerStyle={{ paddingHorizontal: 5 }}
                />
                {isLoaded ? (
                  <View
                    style={{
                      position: 'absolute',
                      alignItems: 'center',
                      left: 0,
                      right: 0,
                      top: 100,
                    }}
                    pointerEvents="box-none"
                  >
                    <Button
                      testID={
                        isPlaying ? 'Relax_mindfulnessPauseButton' : 'Relax_mindfulnessPlayButton'
                      }
                      size="large"
                      icon={isPlaying ? 'pause' : 'play'}
                      alignSelf="center"
                      onPress={() => {
                        void mindfulnessPlayer.current?.setIsPlayingAsync(!isPlaying);
                      }}
                      text=""
                      style={[{ borderWidth: 0 }, Shadow.high]}
                    />
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
}

export const RelaxExercisesFragment = graphql(
  `
    fragment RelaxExercises on RelaxDiaryConfig {
      breathingCircleExercise {
        title
        ...BreathingCircle
      }
      mindfulnessTitle
      mindfulnessExercises {
        audioUrl
        illustrationUrl
        slug
        subtitleUrl
        title
      }
    }
  `,
  [BreathingCircleFragment],
);

export function RelaxExercises(props: {
  onStartExercise?: () => void;
  config: FragmentOf<typeof RelaxExercisesFragment>;
  initialExerciseSlug?: string;
}) {
  const config = readFragment(RelaxExercisesFragment, props.config);
  const breathingCircle = useRef<Handles>(null);
  const [showMindfulnessModal, setShowMindfulnessModal] = useState<Exercise | undefined>();
  const [showMoodRatingModal, setShowMoodRatingModal] = useState<{ completion: number }>();
  const { data: counts } = useQuery(RelaxExercisesQuery);
  const [addPractice, { data: addData, reset: resetAdd, error: addError }] = useMutation(
    AddRelaxDiaryEntryMutation,
    {
      refetchQueries: [RelaxExercisesQueryName],
    },
  );
  const [updatePractice, { data: updateData, reset: resetUpdate, error: updateError }] =
    useMutation(UpdateRelaxDiaryEntryMutation);
  const patientID = useCurrentPatientID();
  const data = updateData ?? addData;
  const { $t } = useI18n();
  const relaxTheme = useRelaxContext();

  function getCount(slug: string) {
    return counts?.relaxDiaryEntryCounts[slug] ?? 0;
  }

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (breathingCircle.current) {
          void breathingCircle.current?.pauseAsync();
        }
      };
    }, []),
  );

  function addNewPractice(slug: string) {
    resetAdd();
    resetUpdate();
    props.onStartExercise?.();
    void addPractice({
      variables: {
        input: {
          practiceValues: { patientID: patientID!, ratings: [] },
          relaxDiaryEntry: { slug, completion: 0 },
        },
      },
    });
  }

  function startExercise(exercise: Exercise) {
    void breathingCircle.current?.pauseAsync();
    addNewPractice(exercise.slug);
    setShowMindfulnessModal(exercise);
    logEvent('mindfulness_exercise', { slug: exercise.slug });
  }

  useEffect(() => {
    const initialExercise = config.mindfulnessExercises.find(
      (m) => m.slug === props.initialExerciseSlug,
    );
    if (initialExercise) {
      startExercise(initialExercise);
    }
    // Only want to run this on initial mount
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <View style={{ zIndex: 1 }}>
        {addError || updateError ? (
          <View style={{ padding: 12 }}>
            <ErrorPresenter error={addError || updateError} />
          </View>
        ) : null}
        <Text
          text={config.breathingCircleExercise.title}
          size={17}
          weight="semibold"
          textAlign="center"
          role="heading"
          style={{ marginTop: 10, marginBottom: 40 }}
        />
        <BreathingCircle
          config={config.breathingCircleExercise}
          ref={breathingCircle}
          onStart={() => addNewPractice('breathe')}
          onPause={(completion) => {
            setShowMoodRatingModal({ completion });
          }}
          onEnd={() => {
            setShowMoodRatingModal({ completion: 1 });
          }}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignSelf: 'stretch',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          paddingRight: 40,
          borderBottomWidth: 1,
        }}
      >
        {relaxTheme.showBreathingCircleIllustration ? (
          <LoginPlant height={110} aria-label={undefined} />
        ) : (
          <View style={{ height: 110 }} />
        )}
        <View style={{ position: 'absolute', left: 0, right: 0, alignItems: 'center', bottom: 40 }}>
          <Pill
            text={$t(
              { id: 'Relax_breatheDoneCount', defaultMessage: '{count} done' },
              { count: getCount('breathe') },
            )}
            color="#104f51"
            backgroundColor="#ebf1ed"
          />
        </View>
        {relaxTheme.showBreathingCircleIllustration ? (
          <LoginFlower height={110} aria-label={undefined} />
        ) : (
          <View style={{ height: 110 }} />
        )}
      </View>
      <View style={{ paddingTop: 30, paddingHorizontal: 20 }} spacing={20}>
        <Heading level={2} text={config.mindfulnessTitle} textAlign="center" />
        {config.mindfulnessExercises.map((exercise) => (
          <ArtifactButton
            shadow={false}
            backgroundColor={relaxTheme.backgroundColor}
            key={exercise.slug}
            testID={`Relax_exerciseButton__${exercise.slug}`}
            assetName={exercise.illustrationUrl!}
            title={exercise.title}
            locked={false}
            badgeText={$t(
              { id: 'Relax_exerciseDoneCount', defaultMessage: '{count} done' },
              { count: getCount(exercise.slug) },
            )}
            onPress={() => {
              startExercise(exercise);
            }}
          />
        ))}
      </View>
      {showMindfulnessModal ? (
        <MindfulnessModal
          onRequestClose={(completion) => {
            setShowMindfulnessModal(undefined);
            setShowMoodRatingModal({ completion });
          }}
          exercise={showMindfulnessModal}
        />
      ) : null}
      {showMoodRatingModal && data?.saveRelaxDiaryEntry ? (
        <MoodRatingModal
          entry={data.saveRelaxDiaryEntry.relaxDiaryEntryPractice}
          completion={showMoodRatingModal.completion}
          onRequestClose={() => setShowMoodRatingModal(undefined)}
          updatePractice={updatePractice}
        />
      ) : null}
    </>
  );
}
