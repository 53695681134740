import { useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import hexToRgba from 'hex-to-rgba';
import { Fragment, useCallback, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import Divider from '@oui/app-core/src/components/Divider';
import { Icon } from '@oui/app-core/src/components/Icon';
import { PillGroup } from '@oui/app-core/src/components/PillGroup';
import { RatingGraph } from '@oui/app-core/src/components/RatingGraph';
import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  ActivityFragment,
  PracticeFragment,
  usePracticeRatings,
} from '@oui/app-core/src/hooks/practices';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql, ResultOf } from '@oui/lib/src/graphql/tada';
import { PracticeType, RatingType } from '@oui/lib/src/types/graphql.generated';

import { useActivityDiaryContext } from '../components/ActivityDiaryContext';
import { StackScreenProps } from '../types/navigation';

export const ActivityDiaryEntriesQueryName = 'ActivityDiaryEntries';
export type ActivityDiaryEntriesQueryName = typeof ActivityDiaryEntriesQueryName;
export const ActivityDiaryEntriesQuery = graphql(
  `
    query ActivityDiaryEntries($before: Date, $after: Date) {
      practices(practiceTypes: [ACTIVITY], before: $before, after: $after) {
        ...PracticeFragment
        ... on ActivityPractice {
          activity {
            ...ActivityFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, ActivityFragment],
);

function ActivityList({
  loading,
  data,
}: {
  loading: boolean;
  data?: ResultOf<typeof ActivityDiaryEntriesQuery>;
}) {
  const { navigate } = useNavigation<StackScreenProps<'ActivityDiary'>['navigation']>();
  const route = useRoute<StackScreenProps<'ActivityDiary'>['route']>();
  const { theme } = useTheme();
  const { $t, formatDate } = useI18n();
  const activityDiaryTheme = useActivityDiaryContext();

  const getActivityListRow = useCallback(
    ({
      practiceID,
      practiceValues,
      activity,
    }: {
      readonly activity: ResultOf<typeof ActivityFragment>;
    } & Extract<ResultOf<typeof PracticeFragment>, { __typename: 'ActivityPractice' }>) => {
      const showRatings = () => {
        navigate('EditActivityPractice', {
          practiceID,
        });
      };

      const showDetails = () => {
        navigate('ActivityPractice', {
          practiceID,
        });
      };

      const isInPast = parseGQLDateTime(activity.endTime) < new Date();
      const beforeRating = practiceValues.ratings
        .find((t) => t.type === RatingType.RATING_BEFORE)
        ?.value.toString();
      const afterRating = practiceValues.ratings
        .find((t) => t.type === RatingType.RATING_AFTER)
        ?.value.toString();

      return (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity
            style={{ flexBasis: '50%' }}
            accessible={false}
            onPress={showDetails}
            testID="ActivityDiary_row_title"
          >
            <Text
              text={activity.title}
              weight="semibold"
              size={17}
              color={theme.color.gray100}
              style={{ flex: 1 }}
            />
            <Text
              size={15}
              text={formatDate(activity.startTime, { month: 'short', day: 'numeric' })}
              color={theme.color.gray300}
            />
          </TouchableOpacity>
          {!isInPast && !beforeRating && !afterRating ? (
            <View
              style={{ flexBasis: '40%', flex: 1, justifyContent: 'center', alignItems: 'center' }}
            >
              <Text text="Upcoming" size={15} />
            </View>
          ) : isInPast && !beforeRating && !afterRating ? (
            <View
              style={{ flexBasis: '40%', flex: 1, justifyContent: 'center', alignItems: 'center' }}
            >
              <Button
                text="Rate"
                variant="solid"
                onPress={() => {
                  showRatings();
                }}
              />
            </View>
          ) : (
            <>
              <View
                style={{
                  flexBasis: '20%',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {beforeRating ? (
                  <Text text={beforeRating} size={17} />
                ) : isInPast ? (
                  <Button
                    text="Rate"
                    variant="solid"
                    onPress={() => {
                      showRatings();
                    }}
                  />
                ) : null}
              </View>
              <View
                style={{
                  flexBasis: '20%',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {afterRating ? (
                  <Text text={afterRating} size={17} />
                ) : isInPast ? (
                  <Button
                    text="Rate"
                    variant="solid"
                    onPress={() => {
                      showRatings();
                    }}
                  />
                ) : null}
              </View>
            </>
          )}
          <View style={{ flexBasis: 40, justifyContent: 'center', alignItems: 'flex-end' }}>
            <Icon
              name="caret-right"
              color={theme.color.primary100}
              onPress={showDetails}
              aria-label="View details"
            />
          </View>
        </View>
      );
    },
    [theme, navigate, formatDate],
  );

  const listRowData = (data?.practices ?? []).map((p) => {
    return p.__typename === 'ActivityPractice' ? getActivityListRow(p) : undefined!;
  });

  const isEmpty = listRowData.length === 0;
  useArtifactRequest(route.name, !isEmpty);

  return (
    <View>
      <View
        row
        style={{
          padding: 20,
          justifyContent: 'center',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <Button
          text={$t(
            {
              id: 'ActivityDiary_addActivityButton',
              defaultMessage: `Add {item}`,
            },
            {
              item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
            },
          )}
          icon="plus"
          onPress={() => navigate('NewActivityEvent')}
          testID="ActivityDiary_addActivityButton"
        />
      </View>
      <View style={{ padding: 20 }}>
        <View style={{ flexDirection: 'row' }}>
          <Text
            key="activity"
            text={activityDiaryTheme.item.singular}
            weight="semibold"
            color={theme.color.gray300}
            size={15}
            style={{ flexBasis: '50%', flexGrow: 1 }}
          />
          <Text
            key="before"
            text={$t({ id: 'ActivityDiary_moodBeforeLabel', defaultMessage: 'Mood Before' })}
            weight="semibold"
            color={theme.color.gray300}
            size={15}
            style={{ flexBasis: '20%' }}
          />
          <Text
            key="after"
            text={$t({ id: 'ActivityDiary_moodAfterLabel', defaultMessage: 'Mood After' })}
            weight="semibold"
            color={theme.color.gray300}
            size={15}
            style={{ flexBasis: '20%' }}
          />
          <View
            style={{
              // space for the caret-right icon
              flexBasis: 20,
            }}
          />
        </View>
        <Divider />
        {listRowData.length === 0 && loading ? (
          <View
            style={{
              alignSelf: 'stretch',
              padding: 10,
              justifyContent: 'center',
            }}
          >
            <ActivityIndicator />
          </View>
        ) : listRowData.length ? (
          listRowData.map((d, i) => (
            <Fragment key={d.key}>
              <View testID={`ActivityDiary_row_${i + 1}`}>{d}</View>
              <Divider />
            </Fragment>
          ))
        ) : (
          <Text
            text={$t(
              {
                id: 'ActivityDiary_tableAddActivityLabel',
                defaultMessage: `Add {item} to get started`,
              },
              {
                item: activityDiaryTheme.item.singular.toLocaleLowerCase(),
              },
            )}
            key="Add"
            size={15}
            color={theme.color.gray300}
          />
        )}
      </View>
    </View>
  );
}

export function ActivityDiary() {
  const { loading, data } = useQuery(ActivityDiaryEntriesQuery, {
    variables: {},
  });
  const { $t } = useI18n();
  const activityDiaryTheme = useActivityDiaryContext();
  const { theme } = useTheme();

  const [timeScale, setTimeScale] = useState<'WEEK' | 'MONTH' | 'YEAR'>('WEEK');
  const payload = usePracticeRatings({
    practiceType: PracticeType.ACTIVITY,
    ratingType: RatingType.RATING_BEFORE,
    timeScale,
  });
  const afterPayload = usePracticeRatings({
    practiceType: PracticeType.ACTIVITY,
    ratingType: RatingType.RATING_AFTER,
    timeScale,
  });

  return (
    <RoundedSection
      color={activityDiaryTheme.mainColor}
      secondaryColor="white"
      applyHeaderOptions
      title={$t({
        id: 'ActivityDiary_header',
        defaultMessage: activityDiaryTheme.name,
      })}
      preview={false}
      testID="ActivityDiary_scrollView"
    >
      <RoundedSectionTopChild backgroundColor="transparent">
        <LinearGradient
          colors={[hexToRgba(theme.color.accent200, 0), hexToRgba(theme.color.accent200, 0.3)]}
          style={StyleSheet.absoluteFillObject}
          start={[0, 0]}
          end={[0, 1]}
        />
        <PillGroup
          testID="ActivityDiary_timeScale"
          aria-label="Chart time scale"
          value={timeScale}
          onChangeValue={setTimeScale}
          items={[
            {
              label: $t({ id: 'ActivityDiary_graphWeekScaleLabel', defaultMessage: 'Week' }),
              value: 'WEEK',
            },
            {
              label: $t({ id: 'ActivityDiary_graphMonthScaleLabel', defaultMessage: 'Month' }),
              value: 'MONTH',
            },
            {
              label: $t({ id: 'ActivityDiary_graphYearScaleLabel', defaultMessage: 'Year' }),
              value: 'YEAR',
            },
          ]}
        />
        <View style={{ height: 300, marginTop: 20 }}>
          <RatingGraph
            testID="ActivityDiary_graph"
            aria-label={$t(
              {
                id: 'ActivityDiary_graphAccessibilityLabel',
                defaultMessage: `A graph comparing mood ratings before and after {items}.`,
              },
              {
                items: activityDiaryTheme.item.plural.toLocaleLowerCase(),
              },
            )}
            showDots={timeScale === 'WEEK'}
            xLabels={payload.xLabels}
            ratings={[payload.data, afterPayload.data]}
            legend={[
              {
                color: activityDiaryTheme.secondaryColor,
                text: $t({ id: 'ActivityDiary_graphBeforeLabel', defaultMessage: 'Before' }),
              },
              {
                color: activityDiaryTheme.mainColor,
                text: $t({ id: 'ActivityDiary_graphAfterLabel', defaultMessage: 'After' }),
              },
            ]}
            yAxisLabel={$t({ id: 'ActivityDiary_graphYAxisLabel', defaultMessage: 'Mood Ratings' })}
            xAxisLabel={payload.xAxisLabel}
          />
        </View>
      </RoundedSectionTopChild>
      <View
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          marginTop: -14,
          marginHorizontal: -20,
          backgroundColor: 'white',
        }}
      >
        <ActivityList loading={loading} data={data} />
        <View style={{ width: '100%', height: 50 }} />
      </View>
    </RoundedSection>
  );
}
