{
  "AccountSettings_accountHeading": [
    {
      "type": 0,
      "value": "Account"
    }
  ],
  "AccountSettings_addressLabel": [
    {
      "type": 0,
      "value": "Home address"
    }
  ],
  "AccountSettings_authentication_biometricLabel": [
    {
      "type": 0,
      "value": "Fingerprint or Face unlock"
    }
  ],
  "AccountSettings_authentication_faceLabel": [
    {
      "type": 0,
      "value": "Face unlock"
    }
  ],
  "AccountSettings_authentication_fingerprintLabel": [
    {
      "type": 0,
      "value": "Fingerprint unlock"
    }
  ],
  "AccountSettings_authentication_irisLabel": [
    {
      "type": 0,
      "value": "Iris unlock"
    }
  ],
  "AccountSettings_authentication_pinLabel": [
    {
      "type": 0,
      "value": "PIN unlock"
    }
  ],
  "AccountSettings_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "AccountSettings_confirmPasswordLabel": [
    {
      "type": 0,
      "value": "Confirm new password"
    }
  ],
  "AccountSettings_contactHeading": [
    {
      "type": 0,
      "value": "Contact information"
    }
  ],
  "AccountSettings_currentPasswordLabel": [
    {
      "type": 0,
      "value": "Current password"
    }
  ],
  "AccountSettings_editButton": [
    {
      "type": 0,
      "value": "Edit"
    }
  ],
  "AccountSettings_editHeading": [
    {
      "type": 0,
      "value": "Edit account settings"
    }
  ],
  "AccountSettings_email": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "AccountSettings_emailLabel": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "AccountSettings_familyNameLabel": [
    {
      "type": 0,
      "value": "Last name"
    }
  ],
  "AccountSettings_givenNameLabel": [
    {
      "type": 0,
      "value": "First name"
    }
  ],
  "AccountSettings_heading": [
    {
      "type": 0,
      "value": "Account"
    }
  ],
  "AccountSettings_instructionsForUseLink": [
    {
      "type": 0,
      "value": "Instructions for use"
    }
  ],
  "AccountSettings_investigationalDevice": [
    {
      "type": 0,
      "value": "CAUTION—Investigational device. Limited by Federal law to investigational use."
    }
  ],
  "AccountSettings_investigationalDeviceVersion": [
    {
      "type": 0,
      "value": "Version: "
    },
    {
      "type": 1,
      "value": "version"
    }
  ],
  "AccountSettings_legalHeading": [
    {
      "type": 0,
      "value": "Legal"
    }
  ],
  "AccountSettings_logOutLink": [
    {
      "type": 0,
      "value": "Log out"
    }
  ],
  "AccountSettings_nameLabel": [
    {
      "type": 0,
      "value": "Name"
    }
  ],
  "AccountSettings_newPasswordLabel": [
    {
      "type": 0,
      "value": "New password"
    }
  ],
  "AccountSettings_notificationsHeading": [
    {
      "type": 0,
      "value": "Notification settings"
    }
  ],
  "AccountSettings_passwordHeading": [
    {
      "type": 0,
      "value": "Password"
    }
  ],
  "AccountSettings_phoneLabel": [
    {
      "type": 0,
      "value": "Phone number"
    }
  ],
  "AccountSettings_phonePlaceholder": [
    {
      "type": 0,
      "value": "Phone number"
    }
  ],
  "AccountSettings_pushNotificationsLabel": [
    {
      "type": 0,
      "value": "Allow push notifications"
    }
  ],
  "AccountSettings_termsOfServiceLink": [
    {
      "type": 0,
      "value": "Terms of service & privacy policy"
    }
  ],
  "AccountSettings_unknownValue": [
    {
      "type": 0,
      "value": "Unknown"
    }
  ],
  "Account_connectedHeading": [
    {
      "type": 0,
      "value": "Connected"
    }
  ],
  "Account_patientLabel": [
    {
      "type": 0,
      "value": "Patient"
    }
  ],
  "Account_patientRelationHeading": [
    {
      "type": 0,
      "value": "Your relation"
    }
  ],
  "Account_patientRelationLabel": [
    {
      "type": 0,
      "value": "Relation"
    }
  ],
  "ActionTodoItem_activityAdd": [
    {
      "type": 0,
      "value": "Add an activity"
    }
  ],
  "ActionTodoItem_activityRate": [
    {
      "type": 0,
      "value": "Rate "
    },
    {
      "type": 1,
      "value": "title"
    }
  ],
  "ActionTodoItem_copingCardAdd": [
    {
      "type": 0,
      "value": "Add to your Coping cards"
    }
  ],
  "ActionTodoItem_copingCardReview": [
    {
      "type": 0,
      "value": "Review your Coping cards"
    }
  ],
  "ActionTodoItem_hopeKitAdd": [
    {
      "type": 0,
      "value": "Add to your "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    }
  ],
  "ActionTodoItem_hopeKitReview": [
    {
      "type": 0,
      "value": "Review your "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    }
  ],
  "ActionTodoItem_myPlanReview": [
    {
      "type": 0,
      "value": "Review using MyPlan"
    }
  ],
  "ActionTodoItem_relax": [
    {
      "type": 0,
      "value": "Try a relaxation exercise"
    }
  ],
  "ActionTodoItem_sleepDiaryMorning": [
    {
      "type": 0,
      "value": "Morning sleep check-in"
    }
  ],
  "ActionTodoItem_sleepDiaryNight": [
    {
      "type": 0,
      "value": "Night sleep check-in"
    }
  ],
  "ActionTodoItem_thoughtDiarySpot": [
    {
      "type": 0,
      "value": "Spot a negative thought"
    }
  ],
  "ActionTodoItem_thoughtDiarySwitch": [
    {
      "type": 0,
      "value": "Switch your thought from "
    },
    {
      "type": 1,
      "value": "date"
    }
  ],
  "ActionTodoItem_thoughtDiaryTest": [
    {
      "type": 0,
      "value": "Test your thought from "
    },
    {
      "type": 1,
      "value": "date"
    }
  ],
  "ActivityDiaryContex_itemPlural": [
    {
      "type": 0,
      "value": "Activities"
    }
  ],
  "ActivityDiaryContex_itemSingular": [
    {
      "type": 0,
      "value": "Activity"
    }
  ],
  "ActivityDiary_addActivityButton": [
    {
      "type": 0,
      "value": "Add "
    },
    {
      "type": 1,
      "value": "item"
    }
  ],
  "ActivityDiary_graphAccessibilityLabel": [
    {
      "type": 0,
      "value": "A graph comparing mood ratings before and after "
    },
    {
      "type": 1,
      "value": "items"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "ActivityDiary_graphAfterLabel": [
    {
      "type": 0,
      "value": "After"
    }
  ],
  "ActivityDiary_graphBeforeLabel": [
    {
      "type": 0,
      "value": "Before"
    }
  ],
  "ActivityDiary_graphMonthScaleLabel": [
    {
      "type": 0,
      "value": "Month"
    }
  ],
  "ActivityDiary_graphWeekScaleLabel": [
    {
      "type": 0,
      "value": "Week"
    }
  ],
  "ActivityDiary_graphYAxisLabel": [
    {
      "type": 0,
      "value": "Mood Ratings"
    }
  ],
  "ActivityDiary_graphYearScaleLabel": [
    {
      "type": 0,
      "value": "Year"
    }
  ],
  "ActivityDiary_moodAfterLabel": [
    {
      "type": 0,
      "value": "Mood After"
    }
  ],
  "ActivityDiary_moodBeforeLabel": [
    {
      "type": 0,
      "value": "Mood Before"
    }
  ],
  "ActivityDiary_tableAddActivityLabel": [
    {
      "type": 0,
      "value": "Add "
    },
    {
      "type": 1,
      "value": "item"
    },
    {
      "type": 0,
      "value": " to get started"
    }
  ],
  "ActivityPractice_addToCalendarButton": [
    {
      "type": 0,
      "value": "Add to calendar"
    }
  ],
  "ActivityPractice_alreadyAddedToCalendar": [
    {
      "type": 0,
      "value": "Added to calendar"
    }
  ],
  "ActivityPractice_deleteButton": [
    {
      "type": 0,
      "value": "Delete"
    }
  ],
  "ActivityPractice_editButton": [
    {
      "type": 0,
      "value": "Edit"
    }
  ],
  "ActivityPractice_moodAfter": [
    {
      "type": 0,
      "value": "Mood after"
    }
  ],
  "ActivityPractice_moodBefore": [
    {
      "type": 0,
      "value": "Mood before"
    }
  ],
  "ActivityPractice_moodRatingsPrompt": [
    {
      "type": 0,
      "value": "What was your mood before and after this "
    },
    {
      "type": 1,
      "value": "item"
    },
    {
      "type": 0,
      "value": "?"
    }
  ],
  "ActivityPractice_notesLabel": [
    {
      "type": 0,
      "value": "Prepare"
    }
  ],
  "ActivityPractice_rateButton": [
    {
      "type": 0,
      "value": "Rate"
    }
  ],
  "ActivityPractice_unsavedChanges_cancelButton": [
    {
      "type": 0,
      "value": "No, don't"
    }
  ],
  "ActivityPractice_unsavedChanges_confirmButton": [
    {
      "type": 0,
      "value": "Yes, delete"
    }
  ],
  "ActivityPractice_unsavedChanges_description": [
    {
      "type": 0,
      "value": "Please confirm you'd like to delete this "
    },
    {
      "type": 1,
      "value": "item"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "ActivityPractice_unsavedChanges_title": [
    {
      "type": 0,
      "value": "Delete?"
    }
  ],
  "AddHopeKit_addImagesButton": [
    {
      "type": 0,
      "value": "Choose more photos"
    }
  ],
  "AddHopeKit_addQuoteButton": [
    {
      "type": 0,
      "value": "Add another quote"
    }
  ],
  "AddHopeKit_albumFilterAccessibilityLabel": [
    {
      "type": 0,
      "value": "Filter files. Current filter: "
    },
    {
      "type": 1,
      "value": "filter"
    }
  ],
  "AddHopeKit_backButton": [
    {
      "type": 0,
      "value": "Close"
    }
  ],
  "AddHopeKit_chooseDateFilterHint": [
    {
      "type": 0,
      "value": "choose date range filter"
    }
  ],
  "AddHopeKit_chooseDateFilterLabel": [
    {
      "type": 0,
      "value": "All dates"
    }
  ],
  "AddHopeKit_chooseImageFileButton": [
    {
      "type": 0,
      "value": "Choose image"
    }
  ],
  "AddHopeKit_dateFilterDefault": [
    {
      "type": 0,
      "value": "All dates"
    }
  ],
  "AddHopeKit_editQuote_authorLabel": [
    {
      "type": 0,
      "value": "Author"
    }
  ],
  "AddHopeKit_editQuote_authorPlaceholder": [
    {
      "type": 0,
      "value": "Who said this quote?"
    }
  ],
  "AddHopeKit_editQuote_removeButton": [
    {
      "type": 0,
      "value": "Remove"
    }
  ],
  "AddHopeKit_editQuote_textLabel": [
    {
      "type": 0,
      "value": "Quote"
    }
  ],
  "AddHopeKit_editQuote_textPlaceholder": [
    {
      "type": 0,
      "value": "What's the quote?"
    }
  ],
  "AddHopeKit_nextButton": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "AddHopeKit_previousButton": [
    {
      "type": 0,
      "value": "Previous"
    }
  ],
  "AddHopeKit_reasonPlaceholder": [
    {
      "type": 0,
      "value": "Does this inspire you? Motivate you? Give you a reason to keep living?"
    }
  ],
  "AddHopeKit_requestPermissionCancelButton": [
    {
      "type": 0,
      "value": "Not now"
    }
  ],
  "AddHopeKit_requestPermissionDescription": [
    {
      "type": 0,
      "value": "Please accept permissions in order to select photos or videos."
    }
  ],
  "AddHopeKit_requestPermissionRetryButton": [
    {
      "type": 0,
      "value": "Ask again"
    }
  ],
  "AddHopeKit_requestPermissionTitle": [
    {
      "type": 0,
      "value": "Permissions requested"
    }
  ],
  "AddHopeKit_requestPermissionsButton": [
    {
      "type": 0,
      "value": "Allow media library access"
    }
  ],
  "AddHopeKit_skipReasonButton": [
    {
      "type": 0,
      "value": "Skip"
    }
  ],
  "AddHopeKit_tabs_gallery": [
    {
      "type": 0,
      "value": "Gallery"
    }
  ],
  "AddHopeKit_tabs_imageSearch": [
    {
      "type": 0,
      "value": "Search"
    }
  ],
  "AddHopeKit_tabs_quote": [
    {
      "type": 0,
      "value": "Quote"
    }
  ],
  "AddHopeKit_title": [
    {
      "type": 0,
      "value": "Add to "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    }
  ],
  "AllDoneRibbon": [
    {
      "type": 0,
      "value": "All done for today!"
    }
  ],
  "App_backButtonLabel": [
    {
      "type": 0,
      "value": "Back"
    }
  ],
  "ArtifactButton_locked": [
    {
      "type": 0,
      "value": "Locked"
    }
  ],
  "Assessment_beckSuicide_question0_choice0": [
    {
      "type": 0,
      "value": "I have a moderate to strong wish to live."
    }
  ],
  "Assessment_beckSuicide_question0_choice1": [
    {
      "type": 0,
      "value": "I have a weak wish to live."
    }
  ],
  "Assessment_beckSuicide_question0_choice2": [
    {
      "type": 0,
      "value": "I have no wish to live."
    }
  ],
  "Assessment_beckSuicide_question10_choice0": [
    {
      "type": 0,
      "value": "My reasons for wanting to commit suicide are primarily aimed at influencing other people, such as getting even with people, making people happier, making people pay attention to me, etc."
    }
  ],
  "Assessment_beckSuicide_question10_choice1": [
    {
      "type": 0,
      "value": "My reasons for wanting to commit suicide are not only aimed at influencing other people, but also represent a way of solving my problems."
    }
  ],
  "Assessment_beckSuicide_question10_choice2": [
    {
      "type": 0,
      "value": "My reasons for wanting to commit suicide are primarily based upon escaping from my problems."
    }
  ],
  "Assessment_beckSuicide_question11_choice0": [
    {
      "type": 0,
      "value": "I have no specific plan about how to kill myself."
    }
  ],
  "Assessment_beckSuicide_question11_choice1": [
    {
      "type": 0,
      "value": "I have considered ways of killing myself but have not worked out the details."
    }
  ],
  "Assessment_beckSuicide_question11_choice2": [
    {
      "type": 0,
      "value": "I have a specific plan for killing myself."
    }
  ],
  "Assessment_beckSuicide_question12_choice0": [
    {
      "type": 0,
      "value": "I do not have access to a method or an opportunity to kill myself."
    }
  ],
  "Assessment_beckSuicide_question12_choice1": [
    {
      "type": 0,
      "value": "The method that I would use for committing suicide takes time, and I really do not have a good opportunity to use this method."
    }
  ],
  "Assessment_beckSuicide_question12_choice2": [
    {
      "type": 0,
      "value": "I have access or anticipate having access to the method that I would choose for killing myself and also have or shall have the opportunity to use it."
    }
  ],
  "Assessment_beckSuicide_question13_choice0": [
    {
      "type": 0,
      "value": "I do not have the courage or the ability to commit suicide."
    }
  ],
  "Assessment_beckSuicide_question13_choice1": [
    {
      "type": 0,
      "value": "I am unsure that I have the courage or the ability to commit suicide."
    }
  ],
  "Assessment_beckSuicide_question13_choice2": [
    {
      "type": 0,
      "value": "I have the courage and the ability to commit suicide."
    }
  ],
  "Assessment_beckSuicide_question14_choice0": [
    {
      "type": 0,
      "value": "I do not expect to make a suicide attempt."
    }
  ],
  "Assessment_beckSuicide_question14_choice1": [
    {
      "type": 0,
      "value": "I am unsure that I shall make a suicide attempt."
    }
  ],
  "Assessment_beckSuicide_question14_choice2": [
    {
      "type": 0,
      "value": "I am sure that I will make a suicide attempt."
    }
  ],
  "Assessment_beckSuicide_question15_choice0": [
    {
      "type": 0,
      "value": "I have made no preparations for committing suicide."
    }
  ],
  "Assessment_beckSuicide_question15_choice1": [
    {
      "type": 0,
      "value": "I have made some preparations for committing suicide."
    }
  ],
  "Assessment_beckSuicide_question15_choice2": [
    {
      "type": 0,
      "value": "I have almost finished or completed my preparations for committing suicide."
    }
  ],
  "Assessment_beckSuicide_question16_choice0": [
    {
      "type": 0,
      "value": "I have not written a suicide note."
    }
  ],
  "Assessment_beckSuicide_question16_choice1": [
    {
      "type": 0,
      "value": "I have thought about writing a suicide note or have started to write one, but have not completed it."
    }
  ],
  "Assessment_beckSuicide_question16_choice2": [
    {
      "type": 0,
      "value": "I have completed a suicide note."
    }
  ],
  "Assessment_beckSuicide_question17_choice0": [
    {
      "type": 0,
      "value": "I have made no arrangements for what will happen after I have committed suicide."
    }
  ],
  "Assessment_beckSuicide_question17_choice1": [
    {
      "type": 0,
      "value": "I have thought about making arrangements for what will happen after I have committed suicide."
    }
  ],
  "Assessment_beckSuicide_question17_choice2": [
    {
      "type": 0,
      "value": "I have made definite arrangements for what will happen after I have committed suicide."
    }
  ],
  "Assessment_beckSuicide_question18_choice0": [
    {
      "type": 0,
      "value": "I have not hidden my desire to kill myself from people."
    }
  ],
  "Assessment_beckSuicide_question18_choice1": [
    {
      "type": 0,
      "value": "I have held back telling people about wanting to kill myself."
    }
  ],
  "Assessment_beckSuicide_question18_choice2": [
    {
      "type": 0,
      "value": "I have attempted to hide, conceal, or lie about wanting to commit suicide."
    }
  ],
  "Assessment_beckSuicide_question19_choice0": [
    {
      "type": 0,
      "value": "I have never attempted suicide."
    }
  ],
  "Assessment_beckSuicide_question19_choice1": [
    {
      "type": 0,
      "value": "I have attempted suicide once."
    }
  ],
  "Assessment_beckSuicide_question19_choice2": [
    {
      "type": 0,
      "value": "I have attempted suicide two or more times."
    }
  ],
  "Assessment_beckSuicide_question1_choice0": [
    {
      "type": 0,
      "value": "I have no wish to die."
    }
  ],
  "Assessment_beckSuicide_question1_choice1": [
    {
      "type": 0,
      "value": "I have a weak wish to die."
    }
  ],
  "Assessment_beckSuicide_question1_choice2": [
    {
      "type": 0,
      "value": "I have a moderate to strong wish to die."
    }
  ],
  "Assessment_beckSuicide_question20_choice0": [
    {
      "type": 0,
      "value": "My wish to die during the last suicide attempt was low."
    }
  ],
  "Assessment_beckSuicide_question20_choice1": [
    {
      "type": 0,
      "value": "My wish to die during the last suicide attempt was moderate."
    }
  ],
  "Assessment_beckSuicide_question20_choice2": [
    {
      "type": 0,
      "value": "My wish to die during the last suicide attempt was high."
    }
  ],
  "Assessment_beckSuicide_question2_choice0": [
    {
      "type": 0,
      "value": "My reasons for living outweigh my reasons for dying."
    }
  ],
  "Assessment_beckSuicide_question2_choice1": [
    {
      "type": 0,
      "value": "My reasons for living or dying are about equal."
    }
  ],
  "Assessment_beckSuicide_question2_choice2": [
    {
      "type": 0,
      "value": "My reasons for dying outweigh my reasons for living."
    }
  ],
  "Assessment_beckSuicide_question3_choice0": [
    {
      "type": 0,
      "value": "I have no desire to kill myself."
    }
  ],
  "Assessment_beckSuicide_question3_choice1": [
    {
      "type": 0,
      "value": "I have a weak desire to kill myself."
    }
  ],
  "Assessment_beckSuicide_question3_choice2": [
    {
      "type": 0,
      "value": "I have a moderate to strong desire to kill myself."
    }
  ],
  "Assessment_beckSuicide_question4_choice0": [
    {
      "type": 0,
      "value": "I would try to save my life if I found myself in a life-threatening situation."
    }
  ],
  "Assessment_beckSuicide_question4_choice1": [
    {
      "type": 0,
      "value": "I would take a chance on life or death if I found myself in a life threatening situation."
    }
  ],
  "Assessment_beckSuicide_question4_choice2": [
    {
      "type": 0,
      "value": "I would not take the steps necessary to avoid death if I found myself in a life threatening situation."
    }
  ],
  "Assessment_beckSuicide_question5_choice0": [
    {
      "type": 0,
      "value": "I have brief periods of thinking about killing myself which pass quickly."
    }
  ],
  "Assessment_beckSuicide_question5_choice1": [
    {
      "type": 0,
      "value": "I have periods of thinking about killing myself which last for moderate amounts of time."
    }
  ],
  "Assessment_beckSuicide_question5_choice2": [
    {
      "type": 0,
      "value": "I have long periods of thinking about killing myself."
    }
  ],
  "Assessment_beckSuicide_question6_choice0": [
    {
      "type": 0,
      "value": "I rarely or only occasionally think about killing myself."
    }
  ],
  "Assessment_beckSuicide_question6_choice1": [
    {
      "type": 0,
      "value": "I have frequent thoughts about killing myself."
    }
  ],
  "Assessment_beckSuicide_question6_choice2": [
    {
      "type": 0,
      "value": "I continuously think about killing myself."
    }
  ],
  "Assessment_beckSuicide_question7_choice0": [
    {
      "type": 0,
      "value": "I do not accept the idea of killing myself."
    }
  ],
  "Assessment_beckSuicide_question7_choice1": [
    {
      "type": 0,
      "value": "I neither accept nor reject the idea of killing myself."
    }
  ],
  "Assessment_beckSuicide_question7_choice2": [
    {
      "type": 0,
      "value": "I accept the idea of killing myself."
    }
  ],
  "Assessment_beckSuicide_question8_choice0": [
    {
      "type": 0,
      "value": "I can keep myself from committing suicide."
    }
  ],
  "Assessment_beckSuicide_question8_choice1": [
    {
      "type": 0,
      "value": "I am unsure that I can keep myself from committing suicide."
    }
  ],
  "Assessment_beckSuicide_question8_choice2": [
    {
      "type": 0,
      "value": "I cannot keep myself from committing suicide."
    }
  ],
  "Assessment_beckSuicide_question9_choice0": [
    {
      "type": 0,
      "value": "I would not kill myself because of my family, friends, religion, possible injury from an unsuccessful attempt, etc."
    }
  ],
  "Assessment_beckSuicide_question9_choice1": [
    {
      "type": 0,
      "value": "I am somewhat concerned about killing myself because of my family, friends, religion, possible injury from an unsuccessful attempt, etc."
    }
  ],
  "Assessment_beckSuicide_question9_choice2": [
    {
      "type": 0,
      "value": "I am not or only a little concerned about killing myself because of my family, friends, religion, possible injury from an unsuccessful attempt, etc."
    }
  ],
  "Assessment_phq9_choice0": [
    {
      "type": 0,
      "value": "Not at all"
    }
  ],
  "Assessment_phq9_choice1": [
    {
      "type": 0,
      "value": "Several days"
    }
  ],
  "Assessment_phq9_choice2": [
    {
      "type": 0,
      "value": "More than half the days"
    }
  ],
  "Assessment_phq9_choice3": [
    {
      "type": 0,
      "value": "Nearly every day"
    }
  ],
  "Assessment_phq9_question0": [
    {
      "type": 0,
      "value": "Little interest or pleasure in doing things"
    }
  ],
  "Assessment_phq9_question1": [
    {
      "type": 0,
      "value": "Feeling down, depressed, or hopeless"
    }
  ],
  "Assessment_phq9_question2": [
    {
      "type": 0,
      "value": "Trouble falling or staying asleep, or sleeping too much"
    }
  ],
  "Assessment_phq9_question3": [
    {
      "type": 0,
      "value": "Feeling tired or having little energy"
    }
  ],
  "Assessment_phq9_question4": [
    {
      "type": 0,
      "value": "Poor appetite or overeating"
    }
  ],
  "Assessment_phq9_question5": [
    {
      "type": 0,
      "value": "Feeling bad about yourself or that you are a failure or have let yourself or your family down"
    }
  ],
  "Assessment_phq9_question6": [
    {
      "type": 0,
      "value": "Trouble concentrating on things, such as reading the newspaper or watching television"
    }
  ],
  "Assessment_phq9_question7": [
    {
      "type": 0,
      "value": "Moving or speaking so slowly that other people could have noticed. Or the opposite being so fidgety or restless that you have been moving around a lot more than usual"
    }
  ],
  "Assessment_phq9_question8": [
    {
      "type": 0,
      "value": "Thoughts that you would be better off dead, or of hurting yourself"
    }
  ],
  "Assessment_scs_choice0": [
    {
      "type": 0,
      "value": "Strongly Disagree"
    }
  ],
  "Assessment_scs_choice1": [
    {
      "type": 0,
      "value": "Disagree"
    }
  ],
  "Assessment_scs_choice2": [
    {
      "type": 0,
      "value": "Neutral"
    }
  ],
  "Assessment_scs_choice3": [
    {
      "type": 0,
      "value": "Agree"
    }
  ],
  "Assessment_scs_choice4": [
    {
      "type": 0,
      "value": "Strongly Agree"
    }
  ],
  "Assessment_scs_question0": [
    {
      "type": 0,
      "value": "The world would be better off without me."
    }
  ],
  "Assessment_scs_question1": [
    {
      "type": 0,
      "value": "Suicide is the only way to solve my problems."
    }
  ],
  "Assessment_scs_question10": [
    {
      "type": 0,
      "value": "It is impossible to describe how badly I feel."
    }
  ],
  "Assessment_scs_question11": [
    {
      "type": 0,
      "value": "I can't cope with my problems any longer."
    }
  ],
  "Assessment_scs_question12": [
    {
      "type": 0,
      "value": "I can't imagine anyone being able to withstand this kind of pain."
    }
  ],
  "Assessment_scs_question13": [
    {
      "type": 0,
      "value": "There is nothing redeeming about me."
    }
  ],
  "Assessment_scs_question14": [
    {
      "type": 0,
      "value": "Suicide is the only way to end this pain."
    }
  ],
  "Assessment_scs_question15": [
    {
      "type": 0,
      "value": "I don't deserve to live another moment."
    }
  ],
  "Assessment_scs_question16": [
    {
      "type": 0,
      "value": "I would rather die now than feel this unbearable pain."
    }
  ],
  "Assessment_scs_question17": [
    {
      "type": 0,
      "value": "No one is as loathsome as me."
    }
  ],
  "Assessment_scs_question2": [
    {
      "type": 0,
      "value": "I can't stand this pain anymore."
    }
  ],
  "Assessment_scs_question3": [
    {
      "type": 0,
      "value": "I've never been successful at anything"
    }
  ],
  "Assessment_scs_question4": [
    {
      "type": 0,
      "value": "I can't tolerate being this upset any longer."
    }
  ],
  "Assessment_scs_question5": [
    {
      "type": 0,
      "value": "I can never be forgiven for the mistakes I have made."
    }
  ],
  "Assessment_scs_question6": [
    {
      "type": 0,
      "value": "No one can help solve my problems."
    }
  ],
  "Assessment_scs_question7": [
    {
      "type": 0,
      "value": "It is unbearable when I get this upset."
    }
  ],
  "Assessment_scs_question8": [
    {
      "type": 0,
      "value": "I am completely unworthy of love."
    }
  ],
  "Assessment_scs_question9": [
    {
      "type": 0,
      "value": "Nothing can help solve my problems."
    }
  ],
  "AuthScreenContainer_logoLabel": [
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": " Logo"
    }
  ],
  "AuthenticationError_emailExists": [
    {
      "type": 0,
      "value": "This email has already been taken."
    }
  ],
  "AuthenticationError_emailNotFound": [
    {
      "type": 0,
      "value": "This email + password is incorrect."
    }
  ],
  "AuthenticationError_invalidEmail": [
    {
      "type": 0,
      "value": "This email is not valid."
    }
  ],
  "AuthenticationError_invalidPassword": [
    {
      "type": 0,
      "value": "This email + password is incorrect."
    }
  ],
  "AuthenticationError_invalidPasswordNoEmail": [
    {
      "type": 0,
      "value": "This password is incorrect."
    }
  ],
  "AuthenticationError_missingEmail": [
    {
      "type": 0,
      "value": "This user does not exist."
    }
  ],
  "AuthenticationError_missingPassword": [
    {
      "type": 0,
      "value": "Please enter your password."
    }
  ],
  "AuthenticationError_tooManyAttempts": [
    {
      "type": 0,
      "value": "Too many failed login attempts. Try again in a few minutes."
    }
  ],
  "AuthenticationError_userDisabled": [
    {
      "type": 0,
      "value": "Your account has been disabled."
    }
  ],
  "AuthenticationError_userNotFound": [
    {
      "type": 0,
      "value": "Sorry, can't find that account."
    }
  ],
  "CalendarPicker_heading": [
    {
      "type": 0,
      "value": "Choose a calendar"
    }
  ],
  "CardStack_endAccessibilityLabel": [
    {
      "type": 0,
      "value": "End of cards"
    }
  ],
  "CardStack_flippableCard_flipButton": [
    {
      "type": 0,
      "value": "Flip"
    }
  ],
  "CardStack_indexAccessibilityLabel": [
    {
      "type": 0,
      "value": "Card "
    },
    {
      "type": 1,
      "value": "index"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "count"
    }
  ],
  "CardStack_nextButton": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "CardStack_nextButtonAccessibilityLabel": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "CardStack_nextCardAccessibilityLabel": [
    {
      "type": 0,
      "value": "Next card"
    }
  ],
  "CardStack_previousButtonAccessibilityLabel": [
    {
      "type": 0,
      "value": "Previous"
    }
  ],
  "CardStack_progress": [
    {
      "type": 1,
      "value": "index"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "count"
    }
  ],
  "CompleteMyStoryMyPlanCard_doneHeading": [
    {
      "type": 0,
      "value": "Onboarding"
    }
  ],
  "CompleteMyStoryMyPlanCard_doneTitle": [
    {
      "type": 0,
      "value": "MyStory &\nMyPlan"
    }
  ],
  "CompleteMyStoryMyPlanCard_heading": [
    {
      "type": 0,
      "value": "Complete with Clinician"
    }
  ],
  "CompleteMyStoryMyPlanCard_sessionUnlockingExplanation": [
    {
      "type": 0,
      "value": "Each session will unlock one at a time after you complete MyStory and MyPlan"
    }
  ],
  "CompleteMyStoryMyPlanCard_title": [
    {
      "type": 0,
      "value": "Tell MyStory & Create MyPlan"
    }
  ],
  "ConfirmationModal_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "ConfirmationModal_closeButtonAccessibilityLabel": [
    {
      "type": 0,
      "value": "Close modal"
    }
  ],
  "ConfirmationModal_confirmButton": [
    {
      "type": 0,
      "value": "OK"
    }
  ],
  "ContactsPicker_chooseButton": [
    {
      "type": 0,
      "value": "Add "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 contact"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " contacts"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "numContacts"
    }
  ],
  "ContactsPicker_searchPlaceholder": [
    {
      "type": 0,
      "value": "Search"
    }
  ],
  "ContactsPicker_title": [
    {
      "type": 0,
      "value": "Contacts"
    }
  ],
  "Conversation_chatTabText": [
    {
      "type": 0,
      "value": "Chat"
    }
  ],
  "Conversation_markSessionComplete": [
    {
      "type": 0,
      "value": "Mark session complete"
    }
  ],
  "Conversation_title": [
    {
      "type": 0,
      "value": "Conversation"
    }
  ],
  "Conversation_videoTabActivitiesHeadline": [
    {
      "type": 0,
      "value": "Activities"
    }
  ],
  "Conversation_videoTabText": [
    {
      "type": 0,
      "value": "Videos & activities"
    }
  ],
  "Conversation_videoTabVideosHeadline": [
    {
      "type": 0,
      "value": "Videos"
    }
  ],
  "CopingCards_addCardsButton": [
    {
      "type": 0,
      "value": "Add cards"
    }
  ],
  "CopingCards_addMoreCardsButton": [
    {
      "type": 0,
      "value": "Add cards"
    }
  ],
  "CopingCards_cancelDeleteButton": [
    {
      "type": 0,
      "value": "No, don't"
    }
  ],
  "CopingCards_card_backHeader": [
    {
      "type": 0,
      "value": "Belief"
    }
  ],
  "CopingCards_card_frontHeader": [
    {
      "type": 0,
      "value": "Thought or situation"
    }
  ],
  "CopingCards_confirmDeleteButton": [
    {
      "type": 0,
      "value": "Yes, delete"
    }
  ],
  "CopingCards_createCardsTitle": [
    {
      "type": 0,
      "value": "Create your coping cards"
    }
  ],
  "CopingCards_delete": [
    {
      "type": 0,
      "value": "Please confirm you'd like to delete this coping card."
    }
  ],
  "CopingCards_deleteButton": [
    {
      "type": 0,
      "value": "Delete"
    }
  ],
  "CopingCards_deleteTitle": [
    {
      "type": 0,
      "value": "Delete?"
    }
  ],
  "CopingCards_editButton": [
    {
      "type": 0,
      "value": "Edit"
    }
  ],
  "CopingCards_title": [
    {
      "type": 0,
      "value": "Coping cards"
    }
  ],
  "DateTimeInput_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "DateTimeInput_confirmButton": [
    {
      "type": 0,
      "value": "Confirm"
    }
  ],
  "DateTimeInput_dateHeading": [
    {
      "type": 0,
      "value": "Pick a date"
    }
  ],
  "DateTimeInput_timeHeading": [
    {
      "type": 0,
      "value": "Pick a time"
    }
  ],
  "EatingCommitmentHealthyFood_fruits": [
    {
      "type": 0,
      "value": "Fruits"
    }
  ],
  "EatingCommitmentHealthyFood_grains": [
    {
      "type": 0,
      "value": "Grains"
    }
  ],
  "EatingCommitmentHealthyFood_healthyFats": [
    {
      "type": 0,
      "value": "Healthy fats (nuts, avocados, fish)"
    }
  ],
  "EatingCommitmentHealthyFood_leanMeats": [
    {
      "type": 0,
      "value": "Lean meats"
    }
  ],
  "EatingCommitmentHealthyFood_other": [
    {
      "type": 0,
      "value": "Other"
    }
  ],
  "EatingCommitmentHealthyFood_vegetables": [
    {
      "type": 0,
      "value": "Vegetables"
    }
  ],
  "EatingCommitmentUnhealthyFood_candy": [
    {
      "type": 0,
      "value": "Candy"
    }
  ],
  "EatingCommitmentUnhealthyFood_desserts": [
    {
      "type": 0,
      "value": "Desserts"
    }
  ],
  "EatingCommitmentUnhealthyFood_junkFood": [
    {
      "type": 0,
      "value": "Junk food"
    }
  ],
  "EatingCommitmentUnhealthyFood_other": [
    {
      "type": 0,
      "value": "Other"
    }
  ],
  "EatingCommitmentUnhealthyFood_soda": [
    {
      "type": 0,
      "value": "Soda"
    }
  ],
  "EatingCommitmentUnhealthyFood_takeout": [
    {
      "type": 0,
      "value": "Take out"
    }
  ],
  "EditActivityEvent_addHeader": [
    {
      "type": 0,
      "value": "Add "
    },
    {
      "type": 1,
      "value": "item"
    }
  ],
  "EditActivityEvent_attendeeAccessibilityLabel": [
    {
      "type": 0,
      "value": "Attendees"
    }
  ],
  "EditActivityEvent_attendeePlaceholder": [
    {
      "type": 0,
      "value": "Do "
    },
    {
      "type": 1,
      "value": "item"
    },
    {
      "type": 0,
      "value": " with"
    }
  ],
  "EditActivityEvent_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "EditActivityEvent_confirmationCancelButton": [
    {
      "type": 0,
      "value": "Discard"
    }
  ],
  "EditActivityEvent_confirmationConfirmButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditActivityEvent_confirmationDescription": [
    {
      "type": 0,
      "value": "You've added info to this "
    },
    {
      "type": 1,
      "value": "item"
    },
    {
      "type": 0,
      "value": ". Would you like to save it before leaving?"
    }
  ],
  "EditActivityEvent_confirmationTitle": [
    {
      "type": 0,
      "value": "Save changes?"
    }
  ],
  "EditActivityEvent_durationPrepositionLabel": [
    {
      "type": 0,
      "value": "to"
    }
  ],
  "EditActivityEvent_editHeader": [
    {
      "type": 0,
      "value": "Edit "
    },
    {
      "type": 1,
      "value": "item"
    }
  ],
  "EditActivityEvent_endTimeAccessibilityLabel": [
    {
      "type": 0,
      "value": "End time"
    }
  ],
  "EditActivityEvent_endTimeValidationError": [
    {
      "type": 0,
      "value": "End time cannot be before start time"
    }
  ],
  "EditActivityEvent_locationAccessibilityLabel": [
    {
      "type": 0,
      "value": "Location"
    }
  ],
  "EditActivityEvent_locationPlaceholder": [
    {
      "type": 0,
      "value": "Activity location"
    }
  ],
  "EditActivityEvent_notesAccessibilityLabel": [
    {
      "type": 0,
      "value": "Notes"
    }
  ],
  "EditActivityEvent_notesPlaceholder": [
    {
      "type": 0,
      "value": "Things to prepare for "
    },
    {
      "type": 1,
      "value": "item"
    }
  ],
  "EditActivityEvent_optional": [
    {
      "type": 0,
      "value": "Optional"
    }
  ],
  "EditActivityEvent_saveButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditActivityEvent_startDate": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "EditActivityEvent_startTimeAccessibilityLabel": [
    {
      "type": 0,
      "value": "Start time"
    }
  ],
  "EditActivityEvent_titlePlaceholder": [
    {
      "type": 0,
      "value": "Name of "
    },
    {
      "type": 1,
      "value": "item"
    }
  ],
  "EditActivityPractice_editButton": [
    {
      "type": 0,
      "value": "Edit "
    },
    {
      "type": 1,
      "value": "item"
    }
  ],
  "EditActivityPractice_ratingAfterLabel": [
    {
      "type": 0,
      "value": "What was your mood after doing this?"
    }
  ],
  "EditActivityPractice_ratingBeforeLabel": [
    {
      "type": 0,
      "value": "What was your mood before doing this?"
    }
  ],
  "EditActivityPractice_saveButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "EditCopingCards_addCardButton": [
    {
      "type": 0,
      "value": "Add card"
    }
  ],
  "EditCopingCards_cardAccessibilityLabel": [
    {
      "type": 0,
      "value": "Coping card "
    },
    {
      "type": 1,
      "value": "cardNum"
    }
  ],
  "EditCopingCards_card_backLabel": [
    {
      "type": 0,
      "value": "Response"
    }
  ],
  "EditCopingCards_card_backPlaceholder": [
    {
      "type": 0,
      "value": "What is a helpful way to think about this?"
    }
  ],
  "EditCopingCards_card_frontLabel": [
    {
      "type": 0,
      "value": "Thought or situation"
    }
  ],
  "EditCopingCards_card_frontPlaceholder": [
    {
      "type": 0,
      "value": "Write a thought or situtation you might find yourself in"
    }
  ],
  "EditCopingCards_editCardTitle": [
    {
      "type": 0,
      "value": "Edit card"
    }
  ],
  "EditCopingCards_saveButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "EditCopingCards_title": [
    {
      "type": 0,
      "value": "Add to cards"
    }
  ],
  "EditLessonsLearned_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "EditLessonsLearned_confirmationModal_cancelButton": [
    {
      "type": 0,
      "value": "Discard"
    }
  ],
  "EditLessonsLearned_confirmationModal_confirmButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditLessonsLearned_confirmationModal_description": [
    {
      "type": 0,
      "value": "You’ve made changes. Would you like to save them before leaving?"
    }
  ],
  "EditLessonsLearned_confirmationModal_title": [
    {
      "type": 0,
      "value": "Save changes?"
    }
  ],
  "EditLessonsLearned_headerTitle": [
    {
      "type": 0,
      "value": "Edit lessons learned"
    }
  ],
  "EditLessonsLearned_saveButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditMyPlan_backButton": [
    {
      "type": 0,
      "value": "Back"
    }
  ],
  "EditMyPlan_cancelButton": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "EditMyPlan_confirmationModal_cancelButton": [
    {
      "type": 0,
      "value": "Discard"
    }
  ],
  "EditMyPlan_confirmationModal_confirmButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditMyPlan_confirmationModal_description": [
    {
      "type": 0,
      "value": "You've made edits to MyPlan. Would you like to save them?"
    }
  ],
  "EditMyPlan_confirmationModal_title": [
    {
      "type": 0,
      "value": "Save changes?"
    }
  ],
  "EditMyPlan_saveButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditMyPlan_stepsHeading": [
    {
      "type": 0,
      "value": "Safety steps"
    }
  ],
  "EditSleepDiaryEntry_alcoholAcessibilityLabel": [
    {
      "type": 0,
      "value": "Had alcohol?"
    }
  ],
  "EditSleepDiaryEntry_alcoholTitle": [
    {
      "type": 0,
      "value": "Had alcohol?"
    }
  ],
  "EditSleepDiaryEntry_caffeineAcessibilityLabel": [
    {
      "type": 0,
      "value": "Had caffeine?"
    }
  ],
  "EditSleepDiaryEntry_caffeineTitle": [
    {
      "type": 0,
      "value": "Had caffeine?"
    }
  ],
  "EditSleepDiaryEntry_deviceInBedAcessibilityLabel": [
    {
      "type": 0,
      "value": "Used a device (cell, laptop, TV) in bed"
    }
  ],
  "EditSleepDiaryEntry_deviceInBedTitle": [
    {
      "type": 0,
      "value": "Used a device (cell, laptop, TV) in bed"
    }
  ],
  "EditSleepDiaryEntry_doneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "EditSleepDiaryEntry_endTimeAccessibilityLabel": [
    {
      "type": 0,
      "value": "Out of bed at"
    }
  ],
  "EditSleepDiaryEntry_endTimeLabel": [
    {
      "type": 0,
      "value": "Out of bed at"
    }
  ],
  "EditSleepDiaryEntry_exerciseAcessibilityLabel": [
    {
      "type": 0,
      "value": "Exercised during the day?"
    }
  ],
  "EditSleepDiaryEntry_exerciseTitle": [
    {
      "type": 0,
      "value": "Exercised 20+ min?"
    }
  ],
  "EditSleepDiaryEntry_heading": [
    {
      "type": 0,
      "value": "Sleep"
    }
  ],
  "EditSleepDiaryEntry_influencerAmountAccessibilityLabel": [
    {
      "type": 0,
      "value": "How much?"
    }
  ],
  "EditSleepDiaryEntry_influencerAmountLabel": [
    {
      "type": 0,
      "value": "How much?"
    }
  ],
  "EditSleepDiaryEntry_influencerAmountPlaceholder": [
    {
      "type": 0,
      "value": "Choose amount"
    }
  ],
  "EditSleepDiaryEntry_influencerDurationAccessibilityLabel": [
    {
      "type": 0,
      "value": "How long?"
    }
  ],
  "EditSleepDiaryEntry_influencerDurationLabel": [
    {
      "type": 0,
      "value": "How long?"
    }
  ],
  "EditSleepDiaryEntry_influencerDurationPlaceholder": [
    {
      "type": 0,
      "value": "Choose duration"
    }
  ],
  "EditSleepDiaryEntry_influencerKindAccessibilityLabel": [
    {
      "type": 0,
      "value": "What kind?"
    }
  ],
  "EditSleepDiaryEntry_influencerKindLabel": [
    {
      "type": 0,
      "value": "What kind?"
    }
  ],
  "EditSleepDiaryEntry_influencerKindPlaceholder": [
    {
      "type": 0,
      "value": "Name of meds"
    }
  ],
  "EditSleepDiaryEntry_influencerNextButton": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "EditSleepDiaryEntry_influencerNoButton": [
    {
      "type": 0,
      "value": "No"
    }
  ],
  "EditSleepDiaryEntry_influencerTimeOfDayAccessibilityLabel": [
    {
      "type": 0,
      "value": "When?"
    }
  ],
  "EditSleepDiaryEntry_influencerTimeOfDayLabel": [
    {
      "type": 0,
      "value": "When?"
    }
  ],
  "EditSleepDiaryEntry_influencerYesButton": [
    {
      "type": 0,
      "value": "Yes"
    }
  ],
  "EditSleepDiaryEntry_lastNightHeading": [
    {
      "type": 0,
      "value": "Last night..."
    }
  ],
  "EditSleepDiaryEntry_medicineAcessibilityLabel": [
    {
      "type": 0,
      "value": "Took sleep medication?"
    }
  ],
  "EditSleepDiaryEntry_medicineTitle": [
    {
      "type": 0,
      "value": "Took sleep medication?"
    }
  ],
  "EditSleepDiaryEntry_morningDiaryTab": [
    {
      "type": 0,
      "value": "Morning"
    }
  ],
  "EditSleepDiaryEntry_nappingAcessibilityLabel": [
    {
      "type": 0,
      "value": "Napped during the day?"
    }
  ],
  "EditSleepDiaryEntry_nappingTitle": [
    {
      "type": 0,
      "value": "Napped during the day?"
    }
  ],
  "EditSleepDiaryEntry_nightCheckinDoneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "EditSleepDiaryEntry_nightDiaryTab": [
    {
      "type": 0,
      "value": "Night"
    }
  ],
  "EditSleepDiaryEntry_nightNotTodayHeading": [
    {
      "type": 0,
      "value": "This day, I..."
    }
  ],
  "EditSleepDiaryEntry_nightTodayHeading": [
    {
      "type": 0,
      "value": "Today, I..."
    }
  ],
  "EditSleepDiaryEntry_qualityAccessibilityLabel": [
    {
      "type": 0,
      "value": "When I woke up I felt how rested"
    }
  ],
  "EditSleepDiaryEntry_qualityHeading": [
    {
      "type": 0,
      "value": "This morning..."
    }
  ],
  "EditSleepDiaryEntry_qualityLabel": [
    {
      "type": 0,
      "value": "When I woke up, I felt"
    }
  ],
  "EditSleepDiaryEntry_qualityPlaceholder": [
    {
      "type": 0,
      "value": "How refreshed?"
    }
  ],
  "EditSleepDiaryEntry_ratingAccessibilityLabel": [
    {
      "type": 0,
      "value": "I'd rate my sleep"
    }
  ],
  "EditSleepDiaryEntry_ratingLabel": [
    {
      "type": 0,
      "value": "I'd rate my sleep"
    }
  ],
  "EditSleepDiaryEntry_sleepDelayAccessibilityLabel": [
    {
      "type": 0,
      "value": "I fell asleep within how long"
    }
  ],
  "EditSleepDiaryEntry_sleepDelayLabel": [
    {
      "type": 0,
      "value": "I fell asleep"
    }
  ],
  "EditSleepDiaryEntry_sleepDelayPlaceholder": [
    {
      "type": 0,
      "value": "Within how long?"
    }
  ],
  "EditSleepDiaryEntry_startTimeAccessibilityLabel": [
    {
      "type": 0,
      "value": "In bed at"
    }
  ],
  "EditSleepDiaryEntry_startTimeLabel": [
    {
      "type": 0,
      "value": "In bed at"
    }
  ],
  "EditSleepDiaryEntry_timeInBedLabel": [
    {
      "type": 1,
      "value": "hoursInBed"
    },
    {
      "type": 0,
      "value": "h "
    },
    {
      "type": 1,
      "value": "minsInBed"
    },
    {
      "type": 0,
      "value": "m in bed"
    }
  ],
  "EditSleepDiaryEntry_tobaccoAcessibilityLabel": [
    {
      "type": 0,
      "value": "Had tobacco?"
    }
  ],
  "EditSleepDiaryEntry_tobaccoTitle": [
    {
      "type": 0,
      "value": "Smoked tobacco?"
    }
  ],
  "EditSleepDiaryEntry_todayTitle": [
    {
      "type": 0,
      "value": "Today"
    }
  ],
  "EditSleepDiaryEntry_wakeupCountAccessibilityLabel": [
    {
      "type": 0,
      "value": "I woke up during the night how many times"
    }
  ],
  "EditSleepDiaryEntry_wakeupCountLabel": [
    {
      "type": 0,
      "value": "I woke up during the night"
    }
  ],
  "EditSleepDiaryEntry_wakeupCountPlaceholder": [
    {
      "type": 0,
      "value": "How many times?"
    }
  ],
  "EditSleepDiaryEntry_wakeupDurationAccessibilityLabel": [
    {
      "type": 0,
      "value": "I was awake for how long in total"
    }
  ],
  "EditSleepDiaryEntry_wakeupDurationLabel": [
    {
      "type": 0,
      "value": "I was awake for"
    }
  ],
  "EditSleepDiaryEntry_wakeupDurationPlaceholder": [
    {
      "type": 0,
      "value": "How long in total?"
    }
  ],
  "EditThoughtDiaryEntry_addThoughtTitle": [
    {
      "type": 0,
      "value": "Add thought"
    }
  ],
  "EditThoughtDiaryEntry_backButtonAccessibilityLabel": [
    {
      "type": 0,
      "value": "Back"
    }
  ],
  "EditThoughtDiaryEntry_behaviorLabel": [
    {
      "type": 0,
      "value": "Behavior"
    }
  ],
  "EditThoughtDiaryEntry_behaviorPlaceholder": [
    {
      "type": 0,
      "value": "Describe how you conducted yourself"
    }
  ],
  "EditThoughtDiaryEntry_closeKeyboardButton": [
    {
      "type": 0,
      "value": "Close keyboard"
    }
  ],
  "EditThoughtDiaryEntry_confirmationModalDescription": [
    {
      "type": 0,
      "value": "You've added info to this entry. Would you like to save it before leaving?"
    }
  ],
  "EditThoughtDiaryEntry_confirmationModalDiscardButton": [
    {
      "type": 0,
      "value": "Discard"
    }
  ],
  "EditThoughtDiaryEntry_confirmationModalSaveButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "EditThoughtDiaryEntry_confirmationModalTitle": [
    {
      "type": 0,
      "value": "Save changes?"
    }
  ],
  "EditThoughtDiaryEntry_dateLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "EditThoughtDiaryEntry_doneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "EditThoughtDiaryEntry_editThoughtTitle": [
    {
      "type": 0,
      "value": "Thought"
    }
  ],
  "EditThoughtDiaryEntry_eventLabel": [
    {
      "type": 0,
      "value": "Event"
    }
  ],
  "EditThoughtDiaryEntry_eventPlaceholder": [
    {
      "type": 0,
      "value": "Describe the situation"
    }
  ],
  "EditThoughtDiaryEntry_evidenceAgainst": [
    {
      "type": 0,
      "value": "Evidence against"
    }
  ],
  "EditThoughtDiaryEntry_evidenceAgainstPlaceholder": [
    {
      "type": 0,
      "value": "List your reasons against this thought"
    }
  ],
  "EditThoughtDiaryEntry_evidenceForLabel": [
    {
      "type": 0,
      "value": "Evidence for"
    }
  ],
  "EditThoughtDiaryEntry_evidenceForPlaceholder": [
    {
      "type": 0,
      "value": "List your reasons for this thought"
    }
  ],
  "EditThoughtDiaryEntry_feelingsAfterLabel": [
    {
      "type": 0,
      "value": "New feelings"
    }
  ],
  "EditThoughtDiaryEntry_feelingsAfterPlaceholder": [
    {
      "type": 0,
      "value": "List emotions you feel now"
    }
  ],
  "EditThoughtDiaryEntry_feelingsLabel": [
    {
      "type": 0,
      "value": "Feelings"
    }
  ],
  "EditThoughtDiaryEntry_feelingsPlaceholder": [
    {
      "type": 0,
      "value": "List emotions you felt"
    }
  ],
  "EditThoughtDiaryEntry_formViewHeader": [
    {
      "type": 0,
      "value": "Edit thought"
    }
  ],
  "EditThoughtDiaryEntry_helpful": [
    {
      "type": 0,
      "value": "How helpful is this thought to you?"
    }
  ],
  "EditThoughtDiaryEntry_nextButton": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "EditThoughtDiaryEntry_oneFormAccessibilityLabel": [
    {
      "type": 0,
      "value": "Show as one form"
    }
  ],
  "EditThoughtDiaryEntry_oneFormSwitch": [
    {
      "type": 0,
      "value": "Show as one form"
    }
  ],
  "EditThoughtDiaryEntry_rating1": [
    {
      "type": 0,
      "value": "Not at all"
    }
  ],
  "EditThoughtDiaryEntry_rating2": [
    {
      "type": 0,
      "value": "A little"
    }
  ],
  "EditThoughtDiaryEntry_rating3": [
    {
      "type": 0,
      "value": "Somewhat"
    }
  ],
  "EditThoughtDiaryEntry_rating4": [
    {
      "type": 0,
      "value": "Mostly"
    }
  ],
  "EditThoughtDiaryEntry_rating5": [
    {
      "type": 0,
      "value": "Completely"
    }
  ],
  "EditThoughtDiaryEntry_ratingAfter": [
    {
      "type": 0,
      "value": "How helpful is this new thought to you?"
    }
  ],
  "EditThoughtDiaryEntry_ratingAfterAccessibilityLabel": [
    {
      "type": 0,
      "value": "How helpful is this new thought to you?"
    }
  ],
  "EditThoughtDiaryEntry_ratingBeforePlaceholder": [
    {
      "type": 0,
      "value": "How helpful is this thought to you?"
    }
  ],
  "EditThoughtDiaryEntry_spotHeading": [
    {
      "type": 0,
      "value": "Spot it"
    }
  ],
  "EditThoughtDiaryEntry_spotTab": [
    {
      "type": 0,
      "value": "Spot it"
    }
  ],
  "EditThoughtDiaryEntry_spot_behaviorAccessibilityLabel": [
    {
      "type": 0,
      "value": "Behavior. What did you do?"
    }
  ],
  "EditThoughtDiaryEntry_spot_behaviorDescription": [
    {
      "type": 0,
      "value": "What did you do?"
    }
  ],
  "EditThoughtDiaryEntry_spot_behaviorLabel": [
    {
      "type": 0,
      "value": "Behavior"
    }
  ],
  "EditThoughtDiaryEntry_spot_behaviorPlaceholder": [
    {
      "type": 0,
      "value": "Describe how you conducted yourself"
    }
  ],
  "EditThoughtDiaryEntry_spot_dateAccessibilityLabel": [
    {
      "type": 0,
      "value": "Date. When did this thought happen?"
    }
  ],
  "EditThoughtDiaryEntry_spot_dateDescription": [
    {
      "type": 0,
      "value": "When did this thought happen?"
    }
  ],
  "EditThoughtDiaryEntry_spot_dateLabel": [
    {
      "type": 0,
      "value": "Date"
    }
  ],
  "EditThoughtDiaryEntry_spot_doneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "EditThoughtDiaryEntry_spot_eventAccessibilityLabel": [
    {
      "type": 0,
      "value": "Event. What happened? Where were you?"
    }
  ],
  "EditThoughtDiaryEntry_spot_eventDescription": [
    {
      "type": 0,
      "value": "What happened? Where were you?"
    }
  ],
  "EditThoughtDiaryEntry_spot_eventLabel": [
    {
      "type": 0,
      "value": "Event"
    }
  ],
  "EditThoughtDiaryEntry_spot_eventPlaceholder": [
    {
      "type": 0,
      "value": "Describe the situation"
    }
  ],
  "EditThoughtDiaryEntry_spot_feelingAccessibilityLabel": [
    {
      "type": 0,
      "value": "Feelings. What did you feel?"
    }
  ],
  "EditThoughtDiaryEntry_spot_feelingDescription": [
    {
      "type": 0,
      "value": "What did you feel?"
    }
  ],
  "EditThoughtDiaryEntry_spot_feelingLabel": [
    {
      "type": 0,
      "value": "Feelings"
    }
  ],
  "EditThoughtDiaryEntry_spot_feelingPlaceholder": [
    {
      "type": 0,
      "value": "List emotions you felt"
    }
  ],
  "EditThoughtDiaryEntry_spot_ratingBefore": [
    {
      "type": 0,
      "value": "How helpful is this thought to you?"
    }
  ],
  "EditThoughtDiaryEntry_spot_ratingBeforeAccessibilityLabel": [
    {
      "type": 0,
      "value": "How helpful is this thought to you?"
    }
  ],
  "EditThoughtDiaryEntry_spot_thoughtAccessibilityLabel": [
    {
      "type": 0,
      "value": "Thought. What thoughts went through your mind?"
    }
  ],
  "EditThoughtDiaryEntry_spot_thoughtDescription": [
    {
      "type": 0,
      "value": "What thoughts went through your mind?"
    }
  ],
  "EditThoughtDiaryEntry_spot_thoughtLabel": [
    {
      "type": 0,
      "value": "Thought"
    }
  ],
  "EditThoughtDiaryEntry_spot_thoughtPlaceholder": [
    {
      "type": 0,
      "value": "Write your thoughts"
    }
  ],
  "EditThoughtDiaryEntry_switchHeading": [
    {
      "type": 0,
      "value": "Switch it"
    }
  ],
  "EditThoughtDiaryEntry_switchTab": [
    {
      "type": 0,
      "value": "Switch it"
    }
  ],
  "EditThoughtDiaryEntry_switchThoughtTitle": [
    {
      "type": 0,
      "value": "Switch thought"
    }
  ],
  "EditThoughtDiaryEntry_switch_doneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "EditThoughtDiaryEntry_switch_evidenceAgainstLabel": [
    {
      "type": 0,
      "value": "Evidence against"
    }
  ],
  "EditThoughtDiaryEntry_switch_feelingsAccessibilityLabel": [
    {
      "type": 0,
      "value": "New feelings. How do you feel when you consider the more balanced thought?"
    }
  ],
  "EditThoughtDiaryEntry_switch_feelingsDescription": [
    {
      "type": 0,
      "value": "How do you feel when you consider the more balanced thought?"
    }
  ],
  "EditThoughtDiaryEntry_switch_feelingsLabel": [
    {
      "type": 0,
      "value": "New feelings"
    }
  ],
  "EditThoughtDiaryEntry_switch_feelingsPlaceholder": [
    {
      "type": 0,
      "value": "List emotions you feel now"
    }
  ],
  "EditThoughtDiaryEntry_switch_ratingAfter": [
    {
      "type": 0,
      "value": "How helpful is this new thought to you?"
    }
  ],
  "EditThoughtDiaryEntry_switch_ratingAfterAccessibilityLabel": [
    {
      "type": 0,
      "value": "How helpful is this thought to you?"
    }
  ],
  "EditThoughtDiaryEntry_switch_thoughtAfterAccessibilityLabel": [
    {
      "type": 0,
      "value": "New thought. Given all of the evidence, is there a more helpful way of thinking about yourself or this situation?"
    }
  ],
  "EditThoughtDiaryEntry_switch_thoughtAfterDescription": [
    {
      "type": 0,
      "value": "Given all of the evidence, is there a more helpful way of thinking about yourself or this situation?"
    }
  ],
  "EditThoughtDiaryEntry_switch_thoughtAfterLabel": [
    {
      "type": 0,
      "value": "New thought"
    }
  ],
  "EditThoughtDiaryEntry_switch_thoughtAfterPlaceholder": [
    {
      "type": 0,
      "value": "Try to form a fairer, more balanced way of summing this up"
    }
  ],
  "EditThoughtDiaryEntry_switch_thoughtLabel": [
    {
      "type": 0,
      "value": "Thought"
    }
  ],
  "EditThoughtDiaryEntry_testHeading": [
    {
      "type": 0,
      "value": "Test it"
    }
  ],
  "EditThoughtDiaryEntry_testTab": [
    {
      "type": 0,
      "value": "Test it"
    }
  ],
  "EditThoughtDiaryEntry_testThoughtTitle": [
    {
      "type": 0,
      "value": "Test thought"
    }
  ],
  "EditThoughtDiaryEntry_test_doneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "EditThoughtDiaryEntry_test_evidenceAgainstAccessibilityLabel": [
    {
      "type": 0,
      "value": "Evidence against. What facts or proof make you suspect that this isn't completely true?"
    }
  ],
  "EditThoughtDiaryEntry_test_evidenceAgainstDescription": [
    {
      "type": 0,
      "value": "What facts or proof make you suspect that this isn't completely true?"
    }
  ],
  "EditThoughtDiaryEntry_test_evidenceAgainstLabel": [
    {
      "type": 0,
      "value": "Evidence against"
    }
  ],
  "EditThoughtDiaryEntry_test_evidenceAgainstPlaceholder": [
    {
      "type": 0,
      "value": "List your reasons against this thought"
    }
  ],
  "EditThoughtDiaryEntry_test_evidenceForAccesibilityLabel": [
    {
      "type": 0,
      "value": "Evidence for. What evidence makes you think this thought is true?"
    }
  ],
  "EditThoughtDiaryEntry_test_evidenceForDescription": [
    {
      "type": 0,
      "value": "What evidence makes you think this thought is true?"
    }
  ],
  "EditThoughtDiaryEntry_test_evidenceForLabel": [
    {
      "type": 0,
      "value": "Evidence for"
    }
  ],
  "EditThoughtDiaryEntry_test_evidenceForPlaceholder": [
    {
      "type": 0,
      "value": "List your reasons for this thought"
    }
  ],
  "EditThoughtDiaryEntry_thoughtAfterLabel": [
    {
      "type": 0,
      "value": "New thought"
    }
  ],
  "EditThoughtDiaryEntry_thoughtAfterPlaceholder": [
    {
      "type": 0,
      "value": "Try to form a fairer, more balanced way of summing this up"
    }
  ],
  "EditThoughtDiaryEntry_thoughtLabel": [
    {
      "type": 0,
      "value": "Thought"
    }
  ],
  "EditThoughtDiaryEntry_thoughtPlaceholder": [
    {
      "type": 0,
      "value": "Write your thoughts"
    }
  ],
  "EditThoughtDiaryEntry_title": [
    {
      "type": 0,
      "value": "Edit thought"
    }
  ],
  "EmailInput_label": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "EnvironmentSafetyLocked_banner": [
    {
      "type": 0,
      "value": "Complete session "
    },
    {
      "type": 1,
      "value": "sessionNum"
    }
  ],
  "EnvironmentSafetyLocked_title": [
    {
      "type": 0,
      "value": "Make your environment safe"
    }
  ],
  "ErrorPresenter_fieldErrorsHeading": [
    {
      "type": 0,
      "value": "**"
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 error"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " errors"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "numErrors"
    },
    {
      "type": 0,
      "value": "** in the following fields:"
    }
  ],
  "FinishSession1Ribbon": [
    {
      "type": 0,
      "value": "Finish session 1 to start practicing"
    }
  ],
  "GoToAidIcon_button": [
    {
      "type": 0,
      "value": "View MyPlan"
    }
  ],
  "Home_dailyPracticeHeading": [
    {
      "type": 0,
      "value": "Daily practice"
    }
  ],
  "Home_quickActionActivityEntryButton": [
    {
      "type": 0,
      "value": "Add activity"
    }
  ],
  "Home_quickActionMorningSleepEntryButton": [
    {
      "type": 0,
      "value": "Morning"
    }
  ],
  "Home_quickActionNightSleepEntryButton": [
    {
      "type": 0,
      "value": "Night"
    }
  ],
  "Home_quickActionThoughtEntryButton": [
    {
      "type": 0,
      "value": "Add thought"
    }
  ],
  "Home_quickActionsHeading": [
    {
      "type": 0,
      "value": "Add to diaries"
    }
  ],
  "Home_refreshIndicator": [
    {
      "type": 0,
      "value": "Update"
    }
  ],
  "Home_sessionProgress": [
    {
      "type": 1,
      "value": "completedCount"
    },
    {
      "type": 0,
      "value": "/"
    },
    {
      "type": 1,
      "value": "totalCount"
    }
  ],
  "Home_sessionsDone": [
    {
      "type": 0,
      "value": "Sessions done"
    }
  ],
  "Home_sessionsDoneAccessibilityLabel": [
    {
      "type": 0,
      "value": "Session progress: "
    },
    {
      "type": 1,
      "value": "completedCount"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "totalCount"
    },
    {
      "type": 0,
      "value": " complete"
    }
  ],
  "Home_todoListHeading": [
    {
      "type": 0,
      "value": "Up next"
    }
  ],
  "Home_weeklyProgressHeading": [
    {
      "type": 0,
      "value": "This week's progress"
    }
  ],
  "HopeKitContext_description": [
    {
      "type": 0,
      "value": "Remind yourself of your hopes"
    }
  ],
  "HopeKitContext_question": [
    {
      "type": 0,
      "value": "Why does this give me hope?"
    }
  ],
  "HopeKitItem_deleteButton": [
    {
      "type": 0,
      "value": "Delete"
    }
  ],
  "HopeKitItem_editButton": [
    {
      "type": 0,
      "value": "Edit"
    }
  ],
  "HopeKitItem_quote_authorLabel": [
    {
      "type": 0,
      "value": "Author"
    }
  ],
  "HopeKitItem_quote_authorPlaceholder": [
    {
      "type": 0,
      "value": "Who said this quote?"
    }
  ],
  "HopeKitItem_quote_reasonPlaceholder": [
    {
      "type": 0,
      "value": "Does this inspire you? Motivate you? Give you a reason to keep living?"
    }
  ],
  "HopeKitItem_quote_textLabel": [
    {
      "type": 0,
      "value": "Quote"
    }
  ],
  "HopeKitItem_quote_textPlaceholder": [
    {
      "type": 0,
      "value": "What is the quote?"
    }
  ],
  "HopeKitItem_reason": [
    {
      "type": 0,
      "value": "Why this gives me hope?"
    }
  ],
  "HopeKitItem_saveButton": [
    {
      "type": 0,
      "value": "Save"
    }
  ],
  "HopeKit_addEmptyButton": [
    {
      "type": 0,
      "value": "Add"
    }
  ],
  "HopeKit_addMoreButton": [
    {
      "type": 0,
      "value": "Add"
    }
  ],
  "HopeKit_deleteConfirmation_cancelButton": [
    {
      "type": 0,
      "value": "No, don't"
    }
  ],
  "HopeKit_deleteConfirmation_confirmButton": [
    {
      "type": 0,
      "value": "Yes, delete"
    }
  ],
  "HopeKit_deleteConfirmation_description": [
    {
      "type": 0,
      "value": "Please confirm you'd like to delete this "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    },
    {
      "type": 0,
      "value": " item."
    }
  ],
  "HopeKit_deleteConfirmation_title": [
    {
      "type": 0,
      "value": "Delete?"
    }
  ],
  "HopeKit_emptyHeader": [
    {
      "type": 0,
      "value": "Create your "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    }
  ],
  "ImageInput_choosePhotoCameraOption": [
    {
      "type": 0,
      "value": "Take a photo"
    }
  ],
  "ImageInput_choosePhotoCancelOption": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "ImageInput_choosePhotoGalleryOption": [
    {
      "type": 0,
      "value": "Choose from gallery"
    }
  ],
  "LearnProgress_accessibilityLabel": [
    {
      "type": 0,
      "value": "Learn progress. "
    },
    {
      "type": 1,
      "value": "numCompleted"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "numTotal"
    },
    {
      "type": 0,
      "value": " completed"
    }
  ],
  "LearnProgress_progressText": [
    {
      "type": 1,
      "value": "numCompleted"
    },
    {
      "type": 0,
      "value": "/"
    },
    {
      "type": 1,
      "value": "numTotal"
    }
  ],
  "Learn_heading": [
    {
      "type": 0,
      "value": "Learn"
    }
  ],
  "Learn_refreshIndicator": [
    {
      "type": 0,
      "value": "Update"
    }
  ],
  "LocalAuthenticationPrompt_acceptButton": [
    {
      "type": 0,
      "value": "Yes, enable"
    }
  ],
  "LocalAuthenticationPrompt_biometricMethod": [
    {
      "type": 0,
      "value": "fingerprint or face unlock"
    }
  ],
  "LocalAuthenticationPrompt_denyButton": [
    {
      "type": 0,
      "value": "Not now"
    }
  ],
  "LocalAuthenticationPrompt_description": [
    {
      "type": 0,
      "value": "Use "
    },
    {
      "type": 1,
      "value": "method"
    },
    {
      "type": 0,
      "value": " to log in securely and easily. You can enable or disable it in your account settings at any time."
    }
  ],
  "LocalAuthenticationPrompt_faceMethod": [
    {
      "type": 0,
      "value": "FaceID"
    }
  ],
  "LocalAuthenticationPrompt_fingerprintMethod": [
    {
      "type": 0,
      "value": "Fingerprint"
    }
  ],
  "LocalAuthenticationPrompt_heading": [
    {
      "type": 0,
      "value": "Enable "
    },
    {
      "type": 1,
      "value": "method"
    },
    {
      "type": 0,
      "value": "?"
    }
  ],
  "LocalAuthenticationPrompt_irisMethod": [
    {
      "type": 0,
      "value": "Iris unlock"
    }
  ],
  "LocalAuthenticationPrompt_pinMethod": [
    {
      "type": 0,
      "value": "PIN"
    }
  ],
  "LocationPicker_chooseButton": [
    {
      "type": 0,
      "value": "Add "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 place"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " places"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "numLocations"
    }
  ],
  "LocationPicker_chooseButtonDisabled": [
    {
      "type": 0,
      "value": "Add places"
    }
  ],
  "LocationPicker_contentPlaceholder": [
    {
      "type": 0,
      "value": "Search for a place"
    }
  ],
  "LocationPicker_label": [
    {
      "type": 0,
      "value": "Places"
    }
  ],
  "LocationPicker_search_placeholder": [
    {
      "type": 0,
      "value": "Search"
    }
  ],
  "Login_description": [
    {
      "type": 0,
      "value": "You've been gone for a bit. For your privacy please re-enter your password."
    }
  ],
  "Login_emailLabel": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "Login_forgotPasswordButton": [
    {
      "type": 0,
      "value": "Forgot password?"
    }
  ],
  "Login_heading": [
    {
      "type": 0,
      "value": "Welcome back"
    }
  ],
  "Login_localAuthentication_biometricDescription": [
    {
      "type": 0,
      "value": "Use your fingerprint or face to login"
    }
  ],
  "Login_localAuthentication_biometricFailed": [
    {
      "type": 0,
      "value": "Not recognized"
    }
  ],
  "Login_localAuthentication_faceDescription": [
    {
      "type": 0,
      "value": "Use Face ID to login to app"
    }
  ],
  "Login_localAuthentication_faceFailed": [
    {
      "type": 0,
      "value": "Face not recognized"
    }
  ],
  "Login_localAuthentication_fingerprintDescription": [
    {
      "type": 0,
      "value": "Touch sensor to login to app"
    }
  ],
  "Login_localAuthentication_fingerprintFailed": [
    {
      "type": 0,
      "value": "Fingerprint not recognized"
    }
  ],
  "Login_localAuthentication_irisDescription": [
    {
      "type": 0,
      "value": "Use your irises to login"
    }
  ],
  "Login_localAuthentication_irisFailed": [
    {
      "type": 0,
      "value": "Not recognized"
    }
  ],
  "Login_localAuthentication_pinDescription": [
    {
      "type": 0,
      "value": "Enter PIN to login to app"
    }
  ],
  "Login_localAuthentication_pinFailed": [
    {
      "type": 0,
      "value": "The PIN is invalid"
    }
  ],
  "Login_localAuthentication_promptMessage": [
    {
      "type": 0,
      "value": "Login to app"
    }
  ],
  "Login_loginButton": [
    {
      "type": 0,
      "value": "Login"
    }
  ],
  "Login_logoutButton": [
    {
      "type": 0,
      "value": "Log out"
    }
  ],
  "Login_passwordLabel": [
    {
      "type": 0,
      "value": "Password"
    }
  ],
  "Login_passwordPlaceholder": [
    {
      "type": 0,
      "value": "Password"
    }
  ],
  "Login_passwordResetHint": [
    {
      "type": 0,
      "value": "Password reset successfully. Please log in."
    }
  ],
  "Login_reauthenticate_tryAgain": [
    {
      "type": 0,
      "value": "Try again"
    }
  ],
  "Login_reauthenticate_usePasswordButton": [
    {
      "type": 0,
      "value": "Type password"
    }
  ],
  "Login_reauthenticate_usePinButton": [
    {
      "type": 0,
      "value": "Login with PIN"
    }
  ],
  "Login_reauthenticate_usePinFallbackButton": [
    {
      "type": 0,
      "value": "Login with PIN"
    }
  ],
  "MinimumAppVersion_dismissButton": [
    {
      "type": 0,
      "value": "Not now"
    }
  ],
  "MinimumAppVersion_optionalUpgrade": [
    {
      "type": 0,
      "value": "There's a newer version of "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": " that's available for download. Please update to continue using "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "MinimumAppVersion_requiredUpgrade": [
    {
      "type": 0,
      "value": "There's a newer version of "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": " that's available for download. We recommend updating to ensure you have the best experience possible."
    }
  ],
  "MinimumAppVersion_title": [
    {
      "type": 0,
      "value": "Update Available"
    }
  ],
  "MinimumAppVersion_upgradeButton": [
    {
      "type": 0,
      "value": "Update"
    }
  ],
  "MultipleMediaLibrary_allMediaAlbumTitle": [
    {
      "type": 0,
      "value": "All media"
    }
  ],
  "MyPlanExport_export": [
    {
      "type": 0,
      "value": "Export MyPlan via"
    }
  ],
  "MyPlanExport_exportDownloadButton": [
    {
      "type": 0,
      "value": "Download"
    }
  ],
  "MyPlanExport_exportEmailButton": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "MyPlanReview_editMyPlanButton": [
    {
      "type": 0,
      "value": "Edit MyPlan to add"
    }
  ],
  "MyPlanReview_flashcard0_back": [
    {
      "type": 0,
      "value": "Warning signs are thoughts, feelings, or behaviors that serve as cues that you may be going into a crisis."
    }
  ],
  "MyPlanReview_flashcard0_front": [
    {
      "type": 0,
      "value": "What are Warning Signs?"
    }
  ],
  "MyPlanReview_flashcard10_front": [
    {
      "type": 0,
      "value": "What ways are you securing lethal means?"
    }
  ],
  "MyPlanReview_flashcard11_front": [
    {
      "type": 0,
      "value": "Who will help you secure these items?"
    }
  ],
  "MyPlanReview_flashcard1_front": [
    {
      "type": 0,
      "value": "What are your warning signs?"
    }
  ],
  "MyPlanReview_flashcard2_back": [
    {
      "type": 0,
      "value": "Try to calm yourself by doing the things in Step 2 Coping Strategies"
    }
  ],
  "MyPlanReview_flashcard2_front": [
    {
      "type": 0,
      "value": "What should you try first when you recognize you're experiencing your warning signs?"
    }
  ],
  "MyPlanReview_flashcard3_front": [
    {
      "type": 0,
      "value": "What are your Step 2 Coping Strategies?"
    }
  ],
  "MyPlanReview_flashcard4_back": [
    {
      "type": 0,
      "value": "Step 2 Coping Strategies are meant to do alone, whereas Step 3 Social distractions are social activities with or around others."
    }
  ],
  "MyPlanReview_flashcard4_front": [
    {
      "type": 0,
      "value": "What is the difference between Step 2 Coping Strategies and Step 3 Social distractions?"
    }
  ],
  "MyPlanReview_flashcard5_front": [
    {
      "type": 0,
      "value": "What or who are your Step 3 Social distractions?"
    }
  ],
  "MyPlanReview_flashcard6_back": [
    {
      "type": 0,
      "value": "People in Step 3 are not to be used to share or discuss a crisis. People in Step 4 are ones you can reach out to in a crisis."
    }
  ],
  "MyPlanReview_flashcard6_front": [
    {
      "type": 0,
      "value": "What is the difference between Step 3 Social distractions and Step 4 People I can ask for help?"
    }
  ],
  "MyPlanReview_flashcard7_front": [
    {
      "type": 0,
      "value": "Who are your Step 4 People I can ask for help?"
    }
  ],
  "MyPlanReview_flashcard8_back": [
    {
      "type": 0,
      "value": "Call the National Suicide Preventation Lifeline"
    }
  ],
  "MyPlanReview_flashcard8_front": [
    {
      "type": 0,
      "value": "If you're in an escalating crisis and you've tried other steps, what should you do?"
    }
  ],
  "MyPlanReview_flashcard9_back": [
    {
      "type": 0,
      "value": "Making your environment safe means securing items or lethal means that you may use for suicide."
    }
  ],
  "MyPlanReview_flashcard9_front": [
    {
      "type": 0,
      "value": "What does it mean to make your environment safe?"
    }
  ],
  "MyPlanReview_myPlanButton": [
    {
      "type": 0,
      "value": "Full MyPlan"
    }
  ],
  "MyPlanReview_title": [
    {
      "type": 0,
      "value": "MyPlan Review"
    }
  ],
  "MyPlanReview_v2_flashcard0_back": [
    {
      "type": 0,
      "value": "MyPlan is step-by-step guide that helps you manage a crisis and regain control by helping you notice when things start feeling off and giving you specific things to do to stay safe."
    }
  ],
  "MyPlanReview_v2_flashcard0_front": [
    {
      "type": 0,
      "value": "What is MyPlan?"
    }
  ],
  "MyPlanReview_v2_flashcard1_back": [
    {
      "type": 0,
      "value": "Warning signs are thoughts, feelings, or behaviors that serve as cues that you may be going into a crisis."
    }
  ],
  "MyPlanReview_v2_flashcard1_front": [
    {
      "type": 0,
      "value": "What are Warning Signs?"
    }
  ],
  "MyPlanReview_v2_flashcard2_front": [
    {
      "type": 0,
      "value": "What are your Warning Signs?"
    }
  ],
  "MyPlanReview_v2_flashcard3_back": [
    {
      "type": 0,
      "value": "Try to keep things from getting worse by doing the things in ‘Step 2 Things I can do myself’"
    }
  ],
  "MyPlanReview_v2_flashcard3_front": [
    {
      "type": 0,
      "value": "What should you try first when you recognize you’re experiencing your warning signs?"
    }
  ],
  "MyPlanReview_v2_flashcard4_front": [
    {
      "type": 0,
      "value": "What are your ‘Step 2 Things I can do myself’?"
    }
  ],
  "MyPlanReview_v2_flashcard5_front": [
    {
      "type": 0,
      "value": "What are your ‘Step 3 Reasons for living’?"
    }
  ],
  "MyPlanReview_v2_flashcard6_front": [
    {
      "type": 0,
      "value": "Who are your ‘Step 4 People I can talk to’?"
    }
  ],
  "MyPlanReview_v2_flashcard7_back": [
    {
      "type": 0,
      "value": "‘People you can talk to’ are friends and family that can give you support in a crisis. ‘Professional help’ are the 988 Lifeline, doctors, therapists, and other healthcare professionals."
    }
  ],
  "MyPlanReview_v2_flashcard7_front": [
    {
      "type": 0,
      "value": "What is the difference between ‘Step 4 People I can talk to’ and ‘Step 5 Professional help’?"
    }
  ],
  "MyPlanReview_v2_flashcard8_back": [
    {
      "type": 0,
      "value": "Call 988 for National Suicide Prevention Lifeline"
    }
  ],
  "MyPlanReview_v2_flashcard8_front": [
    {
      "type": 0,
      "value": "If you’re in an escalating crisis and you’ve tried other steps, what should you do?"
    }
  ],
  "MyPlan_aidIcon": [
    {
      "type": 0,
      "value": "Aid icon"
    }
  ],
  "MyPlan_editButton": [
    {
      "type": 0,
      "value": "Edit MyPlan"
    }
  ],
  "MyPlan_exportDownloadSuccess": [
    {
      "type": 0,
      "value": "MyPlan downloaded to media library"
    }
  ],
  "MyPlan_exportEmailSuccess": [
    {
      "type": 0,
      "value": "MyPlan sent to "
    },
    {
      "type": 1,
      "value": "email"
    }
  ],
  "MyPlan_heading": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "MyPlan_lockedDescription1": [
    {
      "type": 0,
      "value": "This is where you can come look at and edit your MyPlan or safety plan after you complete session 1."
    }
  ],
  "MyPlan_lockedDescription2": [
    {
      "type": 0,
      "value": "This icon "
    },
    {
      "type": 1,
      "value": "icon"
    },
    {
      "type": 0,
      "value": " will always take you to your MyPlan."
    }
  ],
  "MyPlan_lockedUntil": [
    {
      "type": 0,
      "value": "Finish session 1"
    }
  ],
  "MyPlan_refresh": [
    {
      "type": 0,
      "value": "Update"
    }
  ],
  "MyPlan_stepsHeading": [
    {
      "type": 0,
      "value": "Safety steps"
    }
  ],
  "MyPlan_title": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "NewPatientSupporterNotification_dismiss": [
    {
      "type": 0,
      "value": "Dismiss"
    }
  ],
  "NewPatientSupporterNotification_newSupporter": [
    {
      "type": 0,
      "value": "A caregiver has been added"
    }
  ],
  "NewPatientSupporterNotification_visiblity": [
    {
      "type": 0,
      "value": "Heads up, your completed plan to keep your environment safe is visible to them."
    }
  ],
  "NextSession_heading": [
    {
      "type": 0,
      "value": "Learn"
    }
  ],
  "PatientMyPlanEditContacts_addButton": [
    {
      "type": 0,
      "value": "Add from contacts"
    }
  ],
  "PatientMyPlanEditContacts_addManuallyButton": [
    {
      "type": 0,
      "value": "Or add manually"
    }
  ],
  "PatientMyPlanEditContacts_familyNameLabel": [
    {
      "type": 0,
      "value": "Last name*"
    }
  ],
  "PatientMyPlanEditContacts_familyNamePlaceholder": [
    {
      "type": 0,
      "value": "Smith"
    }
  ],
  "PatientMyPlanEditContacts_givenNameLabel": [
    {
      "type": 0,
      "value": "First name*"
    }
  ],
  "PatientMyPlanEditContacts_givenNamePlaceholder": [
    {
      "type": 0,
      "value": "Sally"
    }
  ],
  "PatientMyPlanEditContacts_phoneLabel": [
    {
      "type": 0,
      "value": "Phone number"
    }
  ],
  "PatientMyPlanEditContacts_placeholder": [
    {
      "type": 0,
      "value": "Add a contact"
    }
  ],
  "PatientMyPlanEditContacts_placeholderAccessibilityLabel": [
    {
      "type": 0,
      "value": "Contact placeholder"
    }
  ],
  "PatientMyPlanEditContacts_removeButton": [
    {
      "type": 0,
      "value": "Remove contact"
    }
  ],
  "PatientMyPlanEditContacts_saveManuallyButton": [
    {
      "type": 0,
      "value": "Add contact"
    }
  ],
  "PatientMyPlanEditContacts_saveManuallyTitle": [
    {
      "type": 0,
      "value": "Contact details"
    }
  ],
  "PatientMyPlanEditPlaces_addButton": [
    {
      "type": 0,
      "value": "Add from map"
    }
  ],
  "PatientMyPlanEditPlaces_addManuallyButton": [
    {
      "type": 0,
      "value": "Or add manually"
    }
  ],
  "PatientMyPlanEditPlaces_locationInputPlaceholder": [
    {
      "type": 0,
      "value": "Location name"
    }
  ],
  "PatientMyPlanEditPlaces_placeholder": [
    {
      "type": 0,
      "value": "Add a location"
    }
  ],
  "PatientMyPlanEditPlaces_placeholderAccessibilityLabel": [
    {
      "type": 0,
      "value": "Location placeholder"
    }
  ],
  "PatientMyPlanEditPlaces_removeButton": [
    {
      "type": 0,
      "value": "Remove place"
    }
  ],
  "PatientMyPlanEditPlaces_saveManuallyButton": [
    {
      "type": 0,
      "value": "Add place"
    }
  ],
  "PatientMyPlanEditPlaces_saveManuallyTitle": [
    {
      "type": 0,
      "value": "Place"
    }
  ],
  "PatientMyPlanIntroduction_answer1": [
    {
      "type": 0,
      "value": "A plan you can use to stop the risk curve getting too high."
    }
  ],
  "PatientMyPlanIntroduction_answer2": [
    {
      "type": 0,
      "value": "During a crisis, often it is hard to think what to do. MyPlan has all the steps you can take to keep you safe."
    }
  ],
  "PatientMyPlanIntroduction_answer3": [
    {
      "type": 0,
      "value": "When your warning signs tell you your risk is rising, you can use the coping steps in the plan. And if you feel the risk still rising, the plan has a number of ways to help you."
    }
  ],
  "PatientMyPlanIntroduction_heading": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "PatientMyPlanIntroduction_question1": [
    {
      "type": 0,
      "value": "What is it?"
    }
  ],
  "PatientMyPlanIntroduction_question2": [
    {
      "type": 0,
      "value": "Why is this important?"
    }
  ],
  "PatientMyPlanIntroduction_question3": [
    {
      "type": 0,
      "value": "How does it work?"
    }
  ],
  "PatientMyPlanIntroduction_vignetteCaption": [
    {
      "type": 0,
      "value": "Watching how others make their MyPlan may help you create yours."
    }
  ],
  "PatientMyPlanIntroduction_voiceoverCaption": [
    {
      "type": 0,
      "value": "See how we'll create MyPlan and how it'll keep you safe."
    }
  ],
  "PatientMyPlanReview_heading": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "PatientMyPlan_environmentSafety_removalStepsHeading": [
    {
      "type": 0,
      "value": "Ways to secure lethal means"
    }
  ],
  "PatientMyPlan_environmentSafety_removalStepsLabel": [
    {
      "type": 0,
      "value": "Ways to secure lethal means"
    }
  ],
  "PatientMyPlan_environmentSafety_supportContactsHeading": [
    {
      "type": 0,
      "value": "Who will help me secure these"
    }
  ],
  "PatientMyPlan_environmentSafety_title": [
    {
      "type": 0,
      "value": "Making your environment safe"
    }
  ],
  "PatientMyPlan_helpContacts_description": [
    {
      "type": 0,
      "value": "Connecting with others helps you to not think about your problems which allows time for the suicidal thoughts to subside. The people in this step can either help distract you or can help you in a crisis."
    }
  ],
  "PatientMyPlan_helpContacts_title": [
    {
      "type": 0,
      "value": "People I can ask for help"
    }
  ],
  "PatientMyPlan_helpContacts_titleV2": [
    {
      "type": 0,
      "value": "People I can talk to"
    }
  ],
  "PatientMyPlan_professionalHelpContacts_description": [
    {
      "type": 0,
      "value": "These are professionals who can help you in a crisis. We’ve added the 988 Suicide & Crisis Lifeline to help you with this step."
    }
  ],
  "PatientMyPlan_professionalHelpContacts_title": [
    {
      "type": 0,
      "value": "Professional help"
    }
  ],
  "PatientMyPlan_reasonsForLiving_description": [
    {
      "type": 0,
      "value": "With all that has been going on in your life, think about what helps to keep you alive and going on a day-to-day basis. These are your reasons for living."
    }
  ],
  "PatientMyPlan_reasonsForLiving_selectButton": [
    {
      "type": 0,
      "value": "List of common reasons for living"
    }
  ],
  "PatientMyPlan_reasonsForLiving_title": [
    {
      "type": 0,
      "value": "Reasons for living"
    }
  ],
  "PatientMyPlan_selfManagement_description": [
    {
      "type": 0,
      "value": "These are activities you can do and places you can go to alone that are helpful for coping during times of stress."
    }
  ],
  "PatientMyPlan_selfManagement_selectButton": [
    {
      "type": 0,
      "value": "Select from suggested strategies"
    }
  ],
  "PatientMyPlan_selfManagement_title": [
    {
      "type": 0,
      "value": "Coping strategies"
    }
  ],
  "PatientMyPlan_selfManagement_titleV2": [
    {
      "type": 0,
      "value": "Things I can do myself"
    }
  ],
  "PatientMyPlan_socialDistractions_description": [
    {
      "type": 0,
      "value": "Talking to others or going to places with other people helps you not think about your problems which allows time for the suicidal thoughts to subside. People in this step are not to be used to share or discuss a crisis."
    }
  ],
  "PatientMyPlan_socialDistractions_peopleHeader": [
    {
      "type": 0,
      "value": "People that provide distraction"
    }
  ],
  "PatientMyPlan_socialDistractions_placesHeader": [
    {
      "type": 0,
      "value": "Places that provide distraction"
    }
  ],
  "PatientMyPlan_socialDistractions_title": [
    {
      "type": 0,
      "value": "Social distractions"
    }
  ],
  "PatientMyPlan_step": [
    {
      "type": 0,
      "value": "Step "
    },
    {
      "type": 1,
      "value": "number"
    }
  ],
  "PatientMyPlan_warningSigns_description": [
    {
      "type": 0,
      "value": "Warning signs are thoughts, feelings, or behaviors that you have before a suicidal crisis escalates. These serve as a cue to use the MyPlan."
    }
  ],
  "PatientMyPlan_warningSigns_selectButton": [
    {
      "type": 0,
      "value": "Select from your risk curve"
    }
  ],
  "PatientMyPlan_warningSigns_title": [
    {
      "type": 0,
      "value": "Warning signs"
    }
  ],
  "PatientMyStoryIntroduction_heading": [
    {
      "type": 0,
      "value": "MyStory"
    }
  ],
  "PatientMyStoryMyPlanContainer_myPlan": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "PatientMyStoryMyPlanContainer_myStory": [
    {
      "type": 0,
      "value": "MyStory"
    }
  ],
  "PatientMyStoryMyPlanContainer_riskCurve": [
    {
      "type": 0,
      "value": "Risk Curve"
    }
  ],
  "PatientMyStoryMyPlanContainer_videoHeading": [
    {
      "type": 0,
      "value": "Videos"
    }
  ],
  "PatientMyStoryMyPlanContainer_watchVideoButton": [
    {
      "type": 0,
      "value": "Watch"
    }
  ],
  "PatientMyStoryMyPlanOverview_goHomeButton": [
    {
      "type": 0,
      "value": "Go to homepage"
    }
  ],
  "PatientMyStoryMyPlanOverview_heading": [
    {
      "type": 0,
      "value": "Overview"
    }
  ],
  "PatientMyStoryMyPlanOverview_headingComplete": [
    {
      "type": 0,
      "value": "That's all for today"
    }
  ],
  "PatientMyStoryMyPlanOverview_myplanDescription": [
    {
      "type": 0,
      "value": "Create a plan to keep you safe during a crisis"
    }
  ],
  "PatientMyStoryMyPlanOverview_myplanDescriptionComplete": [
    {
      "type": 0,
      "value": "Create a plan to keep you safe during a crisis"
    }
  ],
  "PatientMyStoryMyPlanOverview_myplanTitle": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "PatientMyStoryMyPlanOverview_mystoryDescription": [
    {
      "type": 0,
      "value": "Tell the story of your recent suicide crisis"
    }
  ],
  "PatientMyStoryMyPlanOverview_mystoryDescriptionComplete": [
    {
      "type": 0,
      "value": "Tell the story of your recent suicide crisis"
    }
  ],
  "PatientMyStoryMyPlanOverview_mystoryTitle": [
    {
      "type": 0,
      "value": "MyStory"
    }
  ],
  "PatientMyStoryMyPlanOverview_riskCurveDescription": [
    {
      "type": 0,
      "value": "Learn how suicide risk rises and falls"
    }
  ],
  "PatientMyStoryMyPlanOverview_riskCurveDescriptionComplete": [
    {
      "type": 0,
      "value": "Learn how suicide risk rises and falls"
    }
  ],
  "PatientMyStoryMyPlanOverview_riskCurveTitle": [
    {
      "type": 0,
      "value": "MyRiskCurve"
    }
  ],
  "PatientMyStoryNarrative_heading": [
    {
      "type": 0,
      "value": "Tell your story"
    }
  ],
  "PatientMyStoryTimeline_description": [
    {
      "type": 0,
      "value": "To check I understand, I'm going to summarize some of the main points from your story."
    }
  ],
  "PatientMyStoryTimeline_heading": [
    {
      "type": 0,
      "value": "Suicide crisis timeline"
    }
  ],
  "PatientNotification_actionDescription_activityAdd": [
    {
      "type": 0,
      "value": "add an activity"
    }
  ],
  "PatientNotification_actionDescription_activityRate": [
    {
      "type": 0,
      "value": "rate an activity"
    }
  ],
  "PatientNotification_actionDescription_copingCardAdd": [
    {
      "type": 0,
      "value": "add a coping card"
    }
  ],
  "PatientNotification_actionDescription_copingCardReview": [
    {
      "type": 0,
      "value": "review your coping cards"
    }
  ],
  "PatientNotification_actionDescription_eatingLogAdd": [
    {
      "type": 0,
      "value": "add to your eating log"
    }
  ],
  "PatientNotification_actionDescription_hopeKitAdd": [
    {
      "type": 0,
      "value": "add to your "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    }
  ],
  "PatientNotification_actionDescription_hopeKitReview": [
    {
      "type": 0,
      "value": "review your "
    },
    {
      "type": 1,
      "value": "hopeKitName"
    }
  ],
  "PatientNotification_actionDescription_myPlanReview": [
    {
      "type": 0,
      "value": "review MyPlan"
    }
  ],
  "PatientNotification_actionDescription_relax": [
    {
      "type": 0,
      "value": "do a relaxation exercise"
    }
  ],
  "PatientNotification_actionDescription_sleepDiaryMorning": [
    {
      "type": 0,
      "value": "complete your morning Sleep Diary"
    }
  ],
  "PatientNotification_actionDescription_sleepDiaryNight": [
    {
      "type": 0,
      "value": "complete your night Sleep Diary"
    }
  ],
  "PatientNotification_actionDescription_thoughtDiarySpot": [
    {
      "type": 0,
      "value": "spot a new thought"
    }
  ],
  "PatientNotification_actionDescription_thoughtDiarySwitch": [
    {
      "type": 0,
      "value": "switch a thought"
    }
  ],
  "PatientNotification_actionDescription_thoughtDiaryTest": [
    {
      "type": 0,
      "value": "test a thought"
    }
  ],
  "PatientRiskCurveIntroduction_answer1": [
    {
      "type": 0,
      "value": "Below is a diagram that introduces the 'suicide risk curve'."
    }
  ],
  "PatientRiskCurveIntroduction_answer2": [
    {
      "type": 0,
      "value": "We want to help you to spot your warning signs when your risk starts to go up. That way, we can try stop the risk rising to keep you safe."
    }
  ],
  "PatientRiskCurveIntroduction_heading": [
    {
      "type": 0,
      "value": "Risk curve"
    }
  ],
  "PatientRiskCurveIntroduction_question1": [
    {
      "type": 0,
      "value": "What is it?"
    }
  ],
  "PatientRiskCurveIntroduction_question2": [
    {
      "type": 0,
      "value": "Why is this important?"
    }
  ],
  "PatientRiskCurveIntroduction_videoDescription": [
    {
      "type": 0,
      "value": "The curve shows how over time, risk escalates to the point you want to take your life. Then the risk falls as time passes."
    }
  ],
  "PatientRiskCurveIntroduction_videoLabel": [
    {
      "type": 0,
      "value": "Diagram"
    }
  ],
  "PatientRiskCurveIntroduction_vignetteCaption": [
    {
      "type": 0,
      "value": "Watch how others organize what happened into their suicide risk curve. It may help you think about yours."
    }
  ],
  "PatientRiskCurveIntroduction_voiceoverCaption": [
    {
      "type": 0,
      "value": "Learn more about what the suicide risk curve is and how it applies to you."
    }
  ],
  "PatientRiskCurveReview_heading": [
    {
      "type": 0,
      "value": "My risk curve"
    }
  ],
  "PatientSupporterRelation_clinician": [
    {
      "type": 0,
      "value": "Clinician"
    }
  ],
  "PatientSupporterRelation_other": [
    {
      "type": 0,
      "value": "Other"
    }
  ],
  "PatientSupporterRelation_parentGuardian": [
    {
      "type": 0,
      "value": "Parent/guardian"
    }
  ],
  "PatientSupporterRelation_relative": [
    {
      "type": 0,
      "value": "Relative"
    }
  ],
  "PatientSupporterRelation_socialServices": [
    {
      "type": 0,
      "value": "Social services"
    }
  ],
  "PatientSupporters_support": [
    {
      "type": 0,
      "value": "Support"
    }
  ],
  "PatientSupporters_title": [
    {
      "type": 0,
      "value": "Support people have their own app that describes what you are learning with Aviva."
    }
  ],
  "PracticeRating_label_1": [
    {
      "type": 0,
      "value": "Awful"
    }
  ],
  "PracticeRating_label_2": [
    {
      "type": 0,
      "value": "Bad"
    }
  ],
  "PracticeRating_label_3": [
    {
      "type": 0,
      "value": "Ok"
    }
  ],
  "PracticeRating_label_4": [
    {
      "type": 0,
      "value": "Good"
    }
  ],
  "PracticeRating_label_5": [
    {
      "type": 0,
      "value": "Great"
    }
  ],
  "PracticeTile_activityDiary_description": [
    {
      "type": 0,
      "value": "Do activities to lift your mood"
    }
  ],
  "PracticeTile_activityDiary_title": [
    {
      "type": 0,
      "value": "Activity diary"
    }
  ],
  "PracticeTile_copingCards_description": [
    {
      "type": 0,
      "value": "Practice your skills learned"
    }
  ],
  "PracticeTile_copingCards_title": [
    {
      "type": 0,
      "value": "Coping cards"
    }
  ],
  "PracticeTile_hopeKit_description": [
    {
      "type": 0,
      "value": "Remind yourself of your hopes"
    }
  ],
  "PracticeTile_locked": [
    {
      "type": 0,
      "value": "locked"
    }
  ],
  "PracticeTile_lockedUntilHint": [
    {
      "type": 0,
      "value": "Finish session "
    },
    {
      "type": 1,
      "value": "sessionNum"
    }
  ],
  "PracticeTile_myPlanReview_description": [
    {
      "type": 0,
      "value": "Know how to use it to stop a crisis"
    }
  ],
  "PracticeTile_myPlanReview_title": [
    {
      "type": 0,
      "value": "MyPlan Review"
    }
  ],
  "PracticeTile_practiceCount": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " done"
    }
  ],
  "PracticeTile_relax_description": [
    {
      "type": 0,
      "value": "Listen to calm yourself and relax"
    }
  ],
  "PracticeTile_relax_title": [
    {
      "type": 0,
      "value": "Relax"
    }
  ],
  "PracticeTile_sleepDiary_description": [
    {
      "type": 0,
      "value": "Track your sleep and get tips for better rest"
    }
  ],
  "PracticeTile_thoughtDiary_description": [
    {
      "type": 0,
      "value": "Classify thoughts, feelings & behaviors"
    }
  ],
  "PracticeTile_thoughtDiary_title": [
    {
      "type": 0,
      "value": "Thought diary"
    }
  ],
  "Practice_acuteCrisisHeading": [
    {
      "type": 0,
      "value": "Controlling a crisis"
    }
  ],
  "Practice_chronicCrisisHeading": [
    {
      "type": 0,
      "value": "Manage risk factors"
    }
  ],
  "Practice_heading": [
    {
      "type": 0,
      "value": "Practice"
    }
  ],
  "Practice_refreshIndicator": [
    {
      "type": 0,
      "value": "Update"
    }
  ],
  "Practice_totalCompletedPractices": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " total practices done"
    }
  ],
  "Practices_xAxisMonthLabel": [
    {
      "type": 0,
      "value": "Week beginning"
    }
  ],
  "Practices_xAxisWeekLabel": [
    {
      "type": 0,
      "value": "Days"
    }
  ],
  "Practices_xAxisYearLabel": [
    {
      "type": 0,
      "value": "Months"
    }
  ],
  "Profile_choosePhotoCameraOption": [
    {
      "type": 0,
      "value": "Take a photo"
    }
  ],
  "Profile_choosePhotoCancelOption": [
    {
      "type": 0,
      "value": "Cancel"
    }
  ],
  "Profile_choosePhotoGalleryOption": [
    {
      "type": 0,
      "value": "Choose from gallery"
    }
  ],
  "Profile_profilePhotoButton": [
    {
      "type": 0,
      "value": "Profile photo"
    }
  ],
  "Profile_settingsButton": [
    {
      "type": 0,
      "value": "Settings"
    }
  ],
  "ReauthenticationModal_submitButton": [
    {
      "type": 0,
      "value": "Submit"
    }
  ],
  "Relax_breatheDoneCount": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " done"
    }
  ],
  "Relax_exerciseDoneCount": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " done"
    }
  ],
  "Relax_exercisesTab": [
    {
      "type": 0,
      "value": "Exercises"
    }
  ],
  "Relax_mindfulnessModal_closeButton": [
    {
      "type": 0,
      "value": "Stop mindfulness exercise"
    }
  ],
  "Relax_mindfulnessModal_exerciseTitle": [
    {
      "type": 0,
      "value": "Mindfulness exercise"
    }
  ],
  "Relax_moodRatingModal_after": [
    {
      "type": 0,
      "value": "What was your mood after doing this?"
    }
  ],
  "Relax_moodRatingModal_afterTitle": [
    {
      "type": 0,
      "value": "Mood rating after"
    }
  ],
  "Relax_moodRatingModal_before": [
    {
      "type": 0,
      "value": "What was your mood before doing this?"
    }
  ],
  "Relax_moodRatingModal_beforeTitle": [
    {
      "type": 0,
      "value": "Mood rating before"
    }
  ],
  "Relax_moodRatingModal_doneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "Relax_moodRatingModal_nextButton": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "Relax_moodRatingModal_ratingAccessibilityLabel": [
    {
      "type": 0,
      "value": "How helpful is this thought to you?"
    }
  ],
  "Relax_ratingCompletedSnackbar": [
    {
      "type": 0,
      "value": "Mood rating recorded"
    }
  ],
  "Relax_ratingGraphAccessibilityLabel": [
    {
      "type": 0,
      "value": "A graph of mood ratings before and after relaxation and mindfulness exercises."
    }
  ],
  "Relax_ratingGraphNoData": [
    {
      "type": 0,
      "value": "Finish an exercise to see ratings here"
    }
  ],
  "Relax_ratingGraph_legendAfter": [
    {
      "type": 0,
      "value": "After"
    }
  ],
  "Relax_ratingGraph_legendBefore": [
    {
      "type": 0,
      "value": "Before"
    }
  ],
  "Relax_ratingGraph_yAxisLabel": [
    {
      "type": 0,
      "value": "Mood ratings"
    }
  ],
  "Relax_ratingMonthLabel": [
    {
      "type": 0,
      "value": "Month"
    }
  ],
  "Relax_ratingTimescaleAccessibilityLabel": [
    {
      "type": 0,
      "value": "Chart time scale"
    }
  ],
  "Relax_ratingWeekLabel": [
    {
      "type": 0,
      "value": "Week"
    }
  ],
  "Relax_ratingYearLabel": [
    {
      "type": 0,
      "value": "Year"
    }
  ],
  "Relax_ratingsTab": [
    {
      "type": 0,
      "value": "Mood Ratings"
    }
  ],
  "RequestResetPassword_description": [
    {
      "type": 0,
      "value": "Don't worry. Resetting your password is easy. Just tell us the email address you registered with "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "RequestResetPassword_emailLabel": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "RequestResetPassword_error": [
    {
      "type": 0,
      "value": "Something went wrong requesting a password reset email. Please make sure you've entered your email address properly."
    }
  ],
  "RequestResetPassword_heading": [
    {
      "type": 0,
      "value": "Forgot your password?"
    }
  ],
  "RequestResetPassword_submitButton": [
    {
      "type": 0,
      "value": "Request password reset"
    }
  ],
  "RequestResetPassword_success": [
    {
      "type": 0,
      "value": "Please check your email to reset your password."
    }
  ],
  "RequestResetPassword_title": [
    {
      "type": 0,
      "value": "Forgot password"
    }
  ],
  "ResetPassword_description": [
    {
      "type": 0,
      "value": "You're almost there. Enter a new password to update your account."
    }
  ],
  "ResetPassword_error": [
    {
      "type": 0,
      "value": "We couldn't reset your password. Your token may have expired. Please request a new reset password email."
    }
  ],
  "ResetPassword_heading": [
    {
      "type": 0,
      "value": "Choose a new password"
    }
  ],
  "ResetPassword_passwordConfirmationLabel": [
    {
      "type": 0,
      "value": "Confirm password"
    }
  ],
  "ResetPassword_passwordHint": [
    {
      "type": 0,
      "value": "Must be at least 8 characters"
    }
  ],
  "ResetPassword_passwordLabel": [
    {
      "type": 0,
      "value": "New password"
    }
  ],
  "ResetPassword_passwordMismatchError": [
    {
      "type": 0,
      "value": "Your password and password confirmation do not match"
    }
  ],
  "ResetPassword_requestNewEmailButton": [
    {
      "type": 0,
      "value": "Request another email"
    }
  ],
  "ResetPassword_submitButton": [
    {
      "type": 0,
      "value": "Set password"
    }
  ],
  "ResetPassword_title": [
    {
      "type": 0,
      "value": "Reset password"
    }
  ],
  "ReviewCompleteCard_complete": [
    {
      "type": 0,
      "value": "Review complete"
    }
  ],
  "ReviewCompleteCard_doneButton": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "ReviewCompleteCard_resetButton": [
    {
      "type": 0,
      "value": "Reset cards"
    }
  ],
  "SetupSleepDiary_confirmationModalTitle": [
    {
      "type": 0,
      "value": "You're almost done setting up your "
    },
    {
      "type": 1,
      "value": "item"
    },
    {
      "type": 0,
      "value": ". Would you like to finish?"
    }
  ],
  "Signup_confirmPasswordLabel": [
    {
      "type": 0,
      "value": "Confirm password"
    }
  ],
  "Signup_confirmPasswordPlaceholder": [
    {
      "type": 0,
      "value": "Confirm password"
    }
  ],
  "Signup_emailLabel": [
    {
      "type": 0,
      "value": "Email"
    }
  ],
  "Signup_loginButton": [
    {
      "type": 0,
      "value": "Login"
    }
  ],
  "Signup_passwordHint": [
    {
      "type": 0,
      "value": "Must be at least 8 characters"
    }
  ],
  "Signup_passwordLabel": [
    {
      "type": 0,
      "value": "Set a password"
    }
  ],
  "Signup_passwordPlaceholder": [
    {
      "type": 0,
      "value": "Password"
    }
  ],
  "Signup_termsOfService": [
    {
      "type": 0,
      "value": "By creating an account you agree to the "
    },
    {
      "type": 1,
      "value": "appName"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "Terms of Service & Privacy Policy"
        }
      ],
      "type": 8,
      "value": "link"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "Signup_title": [
    {
      "type": 0,
      "value": "Create your account"
    }
  ],
  "SleepConfigTips_avoidAlcoholAtNight": [
    {
      "type": 0,
      "value": "Alcohol can keep you up at night. Try not to drink it close to bedtime."
    }
  ],
  "SleepConfigTips_avoidAlcoholAtNightAdolescent": [
    {
      "type": 0,
      "value": "Alcohol can keep you up at night. Try to avoid drinking alcohol, especially close to bedtime."
    }
  ],
  "SleepConfigTips_avoidCaffeineAtNight": [
    {
      "type": 0,
      "value": "Caffeine can keep you up at night. Try to avoid it after 3pm."
    }
  ],
  "SleepConfigTips_avoidDeviceInBed": [
    {
      "type": 0,
      "value": "Screens emit blue light that keeps you up. Avoid using devices in bed."
    }
  ],
  "SleepConfigTips_avoidExtraTimeInBed": [
    {
      "type": 0,
      "value": "Go to bed only when you're sleepy and get out of bed when you wake up."
    }
  ],
  "SleepConfigTips_avoidLargeMealsAtNight": [
    {
      "type": 0,
      "value": "Eating a big meal before bed makes it hard to sleep. Try to eat earlier or less."
    }
  ],
  "SleepConfigTips_avoidMedications": [
    {
      "type": 0,
      "value": "Try to limit sleep medications. These should only be used for a short time."
    }
  ],
  "SleepConfigTips_avoidNaps": [
    {
      "type": 0,
      "value": "Try to avoid naps. If you must nap, limit it to 20 minutes and not after 3pm."
    }
  ],
  "SleepConfigTips_avoidTobaccoAtNight": [
    {
      "type": 0,
      "value": "The nicotine in tobacco keeps you up. Try not to smoke close to bedtime."
    }
  ],
  "SleepConfigTips_avoidTobaccoAtNightAdolescent": [
    {
      "type": 0,
      "value": "The nicotine in tobacco keeps you up. Try to avoid smoking, especially close to bedtime."
    }
  ],
  "SleepConfigTips_comfortableSleepEnvironment": [
    {
      "type": 0,
      "value": "Make your bedroom comfortable, like having a good pillow and mattress."
    }
  ],
  "SleepConfigTips_exerciseRegularly": [
    {
      "type": 0,
      "value": "Exercising regularly aids sleep. Aim for 30 mins in the afternoon or evening."
    }
  ],
  "SleepConfigTips_idealSleepEnvironment": [
    {
      "type": 0,
      "value": "Having a dark, quiet, and relaxing bedroom can help you sleep."
    }
  ],
  "SleepConfigTips_onlySleepInBed": [
    {
      "type": 0,
      "value": "Get out of bed when you can't fall asleep in 15 minutes."
    }
  ],
  "SleepConfigTips_sufficientSleep": [
    {
      "type": 0,
      "value": "To be at your best, try to get about 7-8 hours of sleep a night."
    }
  ],
  "SleepDiaryEntries_listHeader": [
    {
      "type": 0,
      "value": "Check-ins"
    }
  ],
  "SleepDiaryEntryDuration_120To180MinutesLabel": [
    {
      "type": 0,
      "value": "2-3 hours"
    }
  ],
  "SleepDiaryEntryDuration_15To30MinutesLabel": [
    {
      "type": 0,
      "value": "15-30 minutes"
    }
  ],
  "SleepDiaryEntryDuration_1To5MinutesLabel": [
    {
      "type": 0,
      "value": "1-5 minutes"
    }
  ],
  "SleepDiaryEntryDuration_20To45MinutesLabel": [
    {
      "type": 0,
      "value": "20-45 minutes"
    }
  ],
  "SleepDiaryEntryDuration_30To60MinutesLabel": [
    {
      "type": 0,
      "value": "30-60 minutes"
    }
  ],
  "SleepDiaryEntryDuration_45To60MinutesLabel": [
    {
      "type": 0,
      "value": "45-60 minutes"
    }
  ],
  "SleepDiaryEntryDuration_5To10MinutesLabel": [
    {
      "type": 0,
      "value": "5-10 minutes"
    }
  ],
  "SleepDiaryEntryDuration_60To120MinutesLabel": [
    {
      "type": 0,
      "value": "1-2 hours"
    }
  ],
  "SleepDiaryEntryDuration_lessThan15MinutesLabel": [
    {
      "type": 0,
      "value": "Fewer than 15 minutes"
    }
  ],
  "SleepDiaryEntryDuration_moreThan120MinutesLabel": [
    {
      "type": 0,
      "value": "More than 2 hours"
    }
  ],
  "SleepDiaryEntryDuration_moreThan180MinutesLabel": [
    {
      "type": 0,
      "value": "More than 3 hours"
    }
  ],
  "SleepDiaryEntryDuration_moreThan60MinutesLabel": [
    {
      "type": 0,
      "value": "More than 1 hour"
    }
  ],
  "SleepDiaryEntryDuration_unknownLabel": [
    {
      "type": 0,
      "value": "Unknown"
    }
  ],
  "SleepDiaryEntry_alcohol": [
    {
      "type": 0,
      "value": "Had alcohol?"
    }
  ],
  "SleepDiaryEntry_caffeine": [
    {
      "type": 0,
      "value": "Had caffeine?"
    }
  ],
  "SleepDiaryEntry_dayBeforeHeader": [
    {
      "type": 0,
      "value": "The day before, I..."
    }
  ],
  "SleepDiaryEntry_deviceInBed": [
    {
      "type": 0,
      "value": "Used a device (cell, laptop, TV) in bed?"
    }
  ],
  "SleepDiaryEntry_editNightBeforeButton": [
    {
      "type": 0,
      "value": "Night before check-in"
    }
  ],
  "SleepDiaryEntry_endTime": [
    {
      "type": 0,
      "value": "Out of bed at"
    }
  ],
  "SleepDiaryEntry_medicine": [
    {
      "type": 0,
      "value": "Took sleep medication?"
    }
  ],
  "SleepDiaryEntry_morningCheckinButton": [
    {
      "type": 0,
      "value": "Morning check-in"
    }
  ],
  "SleepDiaryEntry_morningCheckinDurationInBed": [
    {
      "type": 1,
      "value": "entryDuration"
    },
    {
      "type": 0,
      "value": " in bed"
    }
  ],
  "SleepDiaryEntry_morningCheckinNotDoneHeader": [
    {
      "type": 0,
      "value": "Not done"
    }
  ],
  "SleepDiaryEntry_nap": [
    {
      "type": 0,
      "value": "Napped during the day?"
    }
  ],
  "SleepDiaryEntry_nightCheckinExercise": [
    {
      "type": 0,
      "value": "Exercised 20+ min?"
    }
  ],
  "SleepDiaryEntry_nightCheckinNotDoneHeader": [
    {
      "type": 0,
      "value": "Not done"
    }
  ],
  "SleepDiaryEntry_nightEditButton": [
    {
      "type": 0,
      "value": "Edit day before"
    }
  ],
  "SleepDiaryEntry_notAnswered": [
    {
      "type": 0,
      "value": "Not answered"
    }
  ],
  "SleepDiaryEntry_quality": [
    {
      "type": 0,
      "value": "When I woke up, I felt"
    }
  ],
  "SleepDiaryEntry_rating": [
    {
      "type": 0,
      "value": "Sleep rating"
    }
  ],
  "SleepDiaryEntry_ratingAccessibilityLabel": [
    {
      "type": 1,
      "value": "rating"
    },
    {
      "type": 0,
      "value": " of 5 stars"
    }
  ],
  "SleepDiaryEntry_sleepDelay": [
    {
      "type": 0,
      "value": "I fell asleep"
    }
  ],
  "SleepDiaryEntry_startTime": [
    {
      "type": 0,
      "value": "In bed at"
    }
  ],
  "SleepDiaryEntry_tobacco": [
    {
      "type": 0,
      "value": "Smoked tobacco?"
    }
  ],
  "SleepDiaryEntry_todayTitle": [
    {
      "type": 0,
      "value": "Today"
    }
  ],
  "SleepDiaryEntry_wakeupCount": [
    {
      "type": 0,
      "value": "I woke up during the night"
    }
  ],
  "SleepDiaryEntry_yesterdayHeader": [
    {
      "type": 0,
      "value": "Yesterday, I..."
    }
  ],
  "SleepDiary_alcoholCountMoreThan20Times": [
    {
      "type": 0,
      "value": "20 or more drinks"
    }
  ],
  "SleepDiary_alcoholCountRange": [
    {
      "type": 1,
      "value": "rangeBegin"
    },
    {
      "type": 0,
      "value": "-"
    },
    {
      "type": 1,
      "value": "rangeEnd"
    },
    {
      "type": 0,
      "value": " drinks"
    }
  ],
  "SleepDiary_alcoholCountTimes": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "drink"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "drinks"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "SleepDiary_caffeineCountMoreThan5Times": [
    {
      "type": 0,
      "value": "6 or more drinks"
    }
  ],
  "SleepDiary_caffeineCountTimes": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "drink"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "drinks"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "SleepDiary_diffFromRecommendedLess": [
    {
      "type": 0,
      "value": "less than the recommended 8 hours"
    }
  ],
  "SleepDiary_diffFromRecommendedMore": [
    {
      "type": 0,
      "value": "more than the recommended 8 hours"
    }
  ],
  "SleepDiary_diffFromRecommendedSame": [
    {
      "type": 0,
      "value": "same as the recommended 8 hours"
    }
  ],
  "SleepDiary_finishSetupHeader": [
    {
      "type": 0,
      "value": "Let's finish your setup."
    }
  ],
  "SleepDiary_finishSetupMessage": [
    {
      "type": 0,
      "value": "You're almost done customizing your diary."
    }
  ],
  "SleepDiary_hoursSleptThisWeek": [
    {
      "type": 0,
      "value": "Average hours slept this week"
    }
  ],
  "SleepDiary_hoursSleptWeekOf": [
    {
      "type": 0,
      "value": "Average hours slept week of "
    },
    {
      "type": 1,
      "value": "formattedDate"
    }
  ],
  "SleepDiary_hoursTab": [
    {
      "type": 0,
      "value": "Hours slept"
    }
  ],
  "SleepDiary_medicineCountMoreThan4Times": [
    {
      "type": 0,
      "value": "5 or more pills"
    }
  ],
  "SleepDiary_medicineCountTimes": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "pill"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "pills"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "SleepDiary_morningAccessibilityLabel": [
    {
      "type": 0,
      "value": "Morning"
    }
  ],
  "SleepDiary_morningCompleteAccessibilityLabel": [
    {
      "type": 0,
      "value": "Morning. completed"
    }
  ],
  "SleepDiary_morningDone": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "SleepDiary_nightAccessibilityLabel": [
    {
      "type": 0,
      "value": "Night"
    }
  ],
  "SleepDiary_nightCompleteAccessibilityLabel": [
    {
      "type": 0,
      "value": "Night. completed"
    }
  ],
  "SleepDiary_nightDone": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "SleepDiary_previousWeekDiffLess": [
    {
      "type": 0,
      "value": "less than previous week"
    }
  ],
  "SleepDiary_previousWeekDiffMore": [
    {
      "type": 0,
      "value": "more than previous week"
    }
  ],
  "SleepDiary_previousWeekDiffSame": [
    {
      "type": 0,
      "value": "same as previous week"
    }
  ],
  "SleepDiary_qualityOk": [
    {
      "type": 0,
      "value": "OK"
    }
  ],
  "SleepDiary_qualityRested": [
    {
      "type": 0,
      "value": "Rested"
    }
  ],
  "SleepDiary_qualityTired": [
    {
      "type": 0,
      "value": "Tired"
    }
  ],
  "SleepDiary_qualityVeryRested": [
    {
      "type": 0,
      "value": "Very rested"
    }
  ],
  "SleepDiary_qualityVeryTired": [
    {
      "type": 0,
      "value": "Very tired"
    }
  ],
  "SleepDiary_ratingGraphAccessibilityLabel": [
    {
      "type": 0,
      "value": "A graph of sleep quality ratings."
    }
  ],
  "SleepDiary_ratingGraphLabel": [
    {
      "type": 0,
      "value": "Rating"
    }
  ],
  "SleepDiary_ratingGraphTimescaleAccessibilityLabel": [
    {
      "type": 0,
      "value": "Chart time scale"
    }
  ],
  "SleepDiary_ratingGraphTimescaleMonthButton": [
    {
      "type": 0,
      "value": "Month"
    }
  ],
  "SleepDiary_ratingGraphTimescaleWeekButton": [
    {
      "type": 0,
      "value": "Week"
    }
  ],
  "SleepDiary_ratingGraphTimescaleYearButton": [
    {
      "type": 0,
      "value": "Year"
    }
  ],
  "SleepDiary_ratingsGraphYAxisLabel": [
    {
      "type": 0,
      "value": "Ratings"
    }
  ],
  "SleepDiary_ratingsTab": [
    {
      "type": 0,
      "value": "Sleep rating"
    }
  ],
  "SleepDiary_settingsButtonAccessibilityLabel": [
    {
      "type": 1,
      "value": "item"
    },
    {
      "type": 0,
      "value": " settings"
    }
  ],
  "SleepDiary_setupButton": [
    {
      "type": 0,
      "value": "Finish Setup"
    }
  ],
  "SleepDiary_setupCompleteHeader": [
    {
      "type": 0,
      "value": "You’re all set up"
    }
  ],
  "SleepDiary_setupCompleteMessage": [
    {
      "type": 0,
      "value": "Start tracking your sleep below. We'll show you personal stats after 3 days of entries."
    }
  ],
  "SleepDiary_sleepStat": [
    {
      "type": 0,
      "value": "Sleep stat"
    }
  ],
  "SleepDiary_timeOfDayItem_afternoon": [
    {
      "type": 0,
      "value": "Afternoon"
    }
  ],
  "SleepDiary_timeOfDayItem_evening": [
    {
      "type": 0,
      "value": "Evening"
    }
  ],
  "SleepDiary_timeOfDayItem_morning": [
    {
      "type": 0,
      "value": "Morning"
    }
  ],
  "SleepDiary_timeOfDayItem_night": [
    {
      "type": 0,
      "value": "Night"
    }
  ],
  "SleepDiary_tobaccoCountMoreThan20Times": [
    {
      "type": 0,
      "value": "20 or more cigarettes"
    }
  ],
  "SleepDiary_tobaccoCountRange": [
    {
      "type": 1,
      "value": "rangeBegin"
    },
    {
      "type": 0,
      "value": "-"
    },
    {
      "type": 1,
      "value": "rangeEnd"
    },
    {
      "type": 0,
      "value": " cigarettes"
    }
  ],
  "SleepDiary_tobaccoCountTimes": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "cigarette"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "cigarettes"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "SleepDiary_todayHeader": [
    {
      "type": 0,
      "value": "Today"
    }
  ],
  "SleepDiary_viewMorningCheckinButton": [
    {
      "type": 0,
      "value": "Morning"
    }
  ],
  "SleepDiary_viewNightCheckinButton": [
    {
      "type": 0,
      "value": "Night"
    }
  ],
  "SleepDiary_wakeupCountMoreThan6Times": [
    {
      "type": 0,
      "value": "7 or more times"
    }
  ],
  "SleepDiary_wakeupCountTimes": [
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 0,
              "value": "times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "SleepTips_alcoholAtNightDecrease": [
    {
      "type": 0,
      "value": "You had alcohol at night "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " less last week. Great job!"
    }
  ],
  "SleepTips_alcoholAtNightIncrease": [
    {
      "type": 0,
      "value": "You drank alcohol at night "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Try to avoid drinking so close to bedtime."
    }
  ],
  "SleepTips_alcoholDecrease": [
    {
      "type": 0,
      "value": "You only had "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 alcoholic drink"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " alcoholic drinks"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Nice going!"
    }
  ],
  "SleepTips_alcoholIncrease": [
    {
      "type": 0,
      "value": "You had "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more drink"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more drinks"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " of alcohol last week than the week before. Try to drink less this week."
    }
  ],
  "SleepTips_alcoholIncreaseAdolescent": [
    {
      "type": 0,
      "value": "You had "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more drink"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more drinks"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " of alcohol last week than the week before. Try to avoid heavy drinking."
    }
  ],
  "SleepTips_caffeineAtNightDecrease": [
    {
      "type": 0,
      "value": "You had caffeine in the evening or at night "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " less last week. Great!"
    }
  ],
  "SleepTips_caffeineAtNightIncrease": [
    {
      "type": 0,
      "value": "You drank caffeine at night "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Try to avoid caffeine, especially so close to bedtime."
    }
  ],
  "SleepTips_caffeineDecrease": [
    {
      "type": 0,
      "value": "You only had "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 caffeinated drink"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " caffeinated drinks"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Good job!"
    }
  ],
  "SleepTips_caffeineIncrease": [
    {
      "type": 0,
      "value": "You had "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more drink"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more drinks"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " of caffeine last week than the week before. Try to drink less this week."
    }
  ],
  "SleepTips_deviceInBedDecrease": [
    {
      "type": 0,
      "value": "You only used devices in bed "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Great job!"
    }
  ],
  "SleepTips_deviceInBedDurationDecrease": [
    {
      "type": 0,
      "value": "You used devices in bed "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 fewer minute"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " fewer minutes"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Well done!"
    }
  ],
  "SleepTips_deviceInBedDurationIncrease": [
    {
      "type": 0,
      "value": "You used devices in bed an average of "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 min"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " mins"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. "
    },
    {
      "type": 1,
      "value": "delta"
    },
    {
      "type": 0,
      "value": " more than the week before. Try to avoid using devices in bed."
    }
  ],
  "SleepTips_deviceInBedIncrease": [
    {
      "type": 0,
      "value": "You used devices in bed "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " more last week. Try to avoid using devices in bed."
    }
  ],
  "SleepTips_exerciseDecrease": [
    {
      "type": 0,
      "value": "You exercised "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 fewer time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " fewer times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Exercising regularly aids sleep. Try getting in 20+ mins a day."
    }
  ],
  "SleepTips_exerciseIncrease": [
    {
      "type": 0,
      "value": "You exercised "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Keep it up!"
    }
  ],
  "SleepTips_medicationCountDecrease": [
    {
      "type": 0,
      "value": "You only took sleep medication "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Super!"
    }
  ],
  "SleepTips_medicationCountIncrease": [
    {
      "type": 0,
      "value": "You took sleep medication "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " more last week. Try to limit sleep medications."
    }
  ],
  "SleepTips_medicationDecrease": [
    {
      "type": 0,
      "value": "You took "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 fewer pill"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " fewer pills"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " pills last week. That’s the way!"
    }
  ],
  "SleepTips_medicationIncrease": [
    {
      "type": 0,
      "value": "You took "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more pill"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more pills"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week than the week before. Try to limit sleep medications."
    }
  ],
  "SleepTips_nappingAtNightIncrease": [
    {
      "type": 0,
      "value": "You napped "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " during the evening or night last week. Try to nap less and avoid naps after 3pm."
    }
  ],
  "SleepTips_nappingDecrease": [
    {
      "type": 0,
      "value": "You only napped "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. You’re on the right path!"
    }
  ],
  "SleepTips_nappingDurationDecrease": [
    {
      "type": 0,
      "value": "You napped "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 minute"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " minutes"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " less on average last week. Right on!"
    }
  ],
  "SleepTips_nappingIncrease": [
    {
      "type": 0,
      "value": "You napped an average of "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 minute"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " minutes"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. "
    },
    {
      "type": 1,
      "value": "delta"
    },
    {
      "type": 0,
      "value": " more than the week before. Try to take fewer naps."
    }
  ],
  "SleepTips_tobaccoAtNightDecrease": [
    {
      "type": 0,
      "value": "You smoked at night "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " less last week. That’s the right track!"
    }
  ],
  "SleepTips_tobaccoAtNightIncrease": [
    {
      "type": 0,
      "value": "You smoked at night "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more time"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more times"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Try to avoid smoking so close to bedtime."
    }
  ],
  "SleepTips_tobaccoDecrease": [
    {
      "type": 0,
      "value": "You only smoked "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 cigarette"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " cigarettes"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week. Good going!"
    }
  ],
  "SleepTips_tobaccoIncrease": [
    {
      "type": 0,
      "value": "You smoked "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more cigarette"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more cigarettes"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week than the week before. Try to smoke less this week."
    }
  ],
  "SleepTips_tobaccoIncreaseAdolescent": [
    {
      "type": 0,
      "value": "You smoked "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 more cigarette"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " more cigarettes"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "value"
    },
    {
      "type": 0,
      "value": " last week than the week before. Try to avoid smoking this week."
    }
  ],
  "SoloMyPlan_stepsHeading": [
    {
      "type": 0,
      "value": "Safety steps"
    }
  ],
  "SoloMyPlan_title": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "SoloSuicideModeStepBReview_description": [
    {
      "type": 0,
      "value": "You have identified "
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 0,
              "value": "1 event"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " events"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "numEvents"
    },
    {
      "type": 0,
      "value": " that led up to your recent crisis.\n\nThese events and your baseline risk factors made you vulnerable to suicide crisis."
    }
  ],
  "StaticMyPlan_heading": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "StaticSessionCover_back": [
    {
      "type": 0,
      "value": "Back"
    }
  ],
  "StaticSessionCover_next": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "SuicideLifelineCard_callButton": [
    {
      "type": 0,
      "value": "Call "
    },
    {
      "type": 1,
      "value": "phoneNumber"
    }
  ],
  "SuicideLifelineCard_callButtonAccessibilityLabel": [
    {
      "type": 0,
      "value": "Call national suicide lifeline"
    }
  ],
  "SuicideLifelineCard_description": [
    {
      "type": 0,
      "value": "Keep in mind, if you need help right away, call for free confidential help."
    }
  ],
  "SuicideLifelineCard_hideButton": [
    {
      "type": 0,
      "value": "Hide card"
    }
  ],
  "SuicideLifelineCard_lifelineLogoAccessibilityLabel": [
    {
      "type": 0,
      "value": "National Suicide Prevention Lifeline logo"
    }
  ],
  "SwitchInput_offLabel": [
    {
      "type": 0,
      "value": "Off"
    }
  ],
  "SwitchInput_onLabel": [
    {
      "type": 0,
      "value": "On"
    }
  ],
  "Tabs_homeTab": [
    {
      "type": 0,
      "value": "Home"
    }
  ],
  "Tabs_learnTab": [
    {
      "type": 0,
      "value": "Learn"
    }
  ],
  "Tabs_myPlanTab": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "Tabs_practiceTab": [
    {
      "type": 0,
      "value": "Practice"
    }
  ],
  "Tabs_profileTab": [
    {
      "type": 0,
      "value": "Profile"
    }
  ],
  "Tabs_tabAccessibilityLabel": [
    {
      "type": 1,
      "value": "tabName"
    },
    {
      "type": 0,
      "value": ", "
    },
    {
      "type": 1,
      "value": "count"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "total"
    }
  ],
  "TermsAndPrivacy_title": [
    {
      "type": 0,
      "value": "Terms and Privacy"
    }
  ],
  "ThoughDiary_addThoughtButton": [
    {
      "type": 0,
      "value": "Add thought"
    }
  ],
  "ThoughDiary_entriesHeader": [
    {
      "type": 0,
      "value": "Entries"
    }
  ],
  "ThoughDiary_switchItAccessibilityLabel": [
    {
      "type": 0,
      "value": "Switch this thought"
    }
  ],
  "ThoughDiary_switchItButton": [
    {
      "type": 0,
      "value": "Switch it"
    }
  ],
  "ThoughDiary_testItAccessibilityLabel": [
    {
      "type": 0,
      "value": "Test this thought"
    }
  ],
  "ThoughDiary_testItButton": [
    {
      "type": 0,
      "value": "Test it"
    }
  ],
  "ThoughDiary_title": [
    {
      "type": 0,
      "value": "Thought Diary"
    }
  ],
  "ThoughtDiaryEntry_beliefRating": [
    {
      "type": 0,
      "value": "Helpfulness rating"
    }
  ],
  "ThoughtDiaryEntry_beliefRatingAccessibilityLabel": [
    {
      "type": 0,
      "value": "Helpfulness rating: "
    },
    {
      "type": 1,
      "value": "rating"
    },
    {
      "type": 0,
      "value": " of 5"
    }
  ],
  "ThoughtDiaryEntry_confirmDeleteCancel": [
    {
      "type": 0,
      "value": "No, don't"
    }
  ],
  "ThoughtDiaryEntry_confirmDeleteConfirm": [
    {
      "type": 0,
      "value": "Yes, delete"
    }
  ],
  "ThoughtDiaryEntry_confirmDeleteDescription": [
    {
      "type": 0,
      "value": "Please confirm you'd like to delete this thought diary entry."
    }
  ],
  "ThoughtDiaryEntry_confirmDeleteTitle": [
    {
      "type": 0,
      "value": "Delete?"
    }
  ],
  "ThoughtDiaryEntry_deleteButton": [
    {
      "type": 0,
      "value": "Delete"
    }
  ],
  "ThoughtDiaryEntry_editButton": [
    {
      "type": 0,
      "value": "Edit"
    }
  ],
  "ThoughtDiaryEntry_eventLabel": [
    {
      "type": 0,
      "value": "Event"
    }
  ],
  "ThoughtDiaryEntry_evidenceAgainst": [
    {
      "type": 0,
      "value": "Evidence against"
    }
  ],
  "ThoughtDiaryEntry_evidenceFor": [
    {
      "type": 0,
      "value": "Evidence for"
    }
  ],
  "ThoughtDiaryEntry_spotBehavior": [
    {
      "type": 0,
      "value": "Behavior"
    }
  ],
  "ThoughtDiaryEntry_spot_feeling": [
    {
      "type": 0,
      "value": "Feelings"
    }
  ],
  "ThoughtDiaryEntry_spot_heading": [
    {
      "type": 0,
      "value": "Spot it"
    }
  ],
  "ThoughtDiaryEntry_spot_oldThoughtHeading": [
    {
      "type": 0,
      "value": "Old thought"
    }
  ],
  "ThoughtDiaryEntry_switchButton": [
    {
      "type": 0,
      "value": "Switch your thought"
    }
  ],
  "ThoughtDiaryEntry_switchHeading": [
    {
      "type": 0,
      "value": "Switch it"
    }
  ],
  "ThoughtDiaryEntry_switchNewFeeling": [
    {
      "type": 0,
      "value": "New feelings"
    }
  ],
  "ThoughtDiaryEntry_switchNewThought": [
    {
      "type": 0,
      "value": "New thought"
    }
  ],
  "ThoughtDiaryEntry_testButton": [
    {
      "type": 0,
      "value": "Test your thought"
    }
  ],
  "ThoughtDiaryEntry_testHeading": [
    {
      "type": 0,
      "value": "Test it"
    }
  ],
  "ThoughtDiaryEntry_title": [
    {
      "type": 0,
      "value": "Thought Diary"
    }
  ],
  "ThoughtDiary_beforeRatingGraph_Legend": [
    {
      "type": 0,
      "value": "Thought helpfulness"
    }
  ],
  "ThoughtDiary_beforeRatingGraph_graphAccessibilityLabel": [
    {
      "type": 0,
      "value": "A graph of thought helpfulness for spotted thoughts."
    }
  ],
  "ThoughtDiary_beforeRatingGraph_yAxisLabel": [
    {
      "type": 0,
      "value": "Helpfulness rating"
    }
  ],
  "ThoughtDiary_ratingGraph_AccessibilityLabel": [
    {
      "type": 0,
      "value": "A graph of thought helpfulness comparing old and new thoughts."
    }
  ],
  "ThoughtDiary_ratingGraph_legendAfter": [
    {
      "type": 0,
      "value": "New thought"
    }
  ],
  "ThoughtDiary_ratingGraph_legendBefore": [
    {
      "type": 0,
      "value": "Old thought"
    }
  ],
  "ThoughtDiary_ratingGraph_yAxisLabel": [
    {
      "type": 0,
      "value": "Helpfulness rating"
    }
  ],
  "ThoughtDiary_ratingMonthLabel": [
    {
      "type": 0,
      "value": "Month"
    }
  ],
  "ThoughtDiary_ratingWeekLabel": [
    {
      "type": 0,
      "value": "Week"
    }
  ],
  "ThoughtDiary_ratingYearLabel": [
    {
      "type": 0,
      "value": "Year"
    }
  ],
  "ThoughtDiary_timescaleAccessibilityLabel": [
    {
      "type": 0,
      "value": "Chart time scale"
    }
  ],
  "TreatmentList_avivaSupport_activityPlanning_subtitle": [
    {
      "type": 0,
      "value": "Do activities to feel better"
    }
  ],
  "TreatmentList_avivaSupport_activityPlanning_title": [
    {
      "type": 0,
      "value": "Getting going"
    }
  ],
  "TreatmentList_avivaSupport_copingCards_subtitle": [
    {
      "type": 0,
      "value": "Create reminder flash cards"
    }
  ],
  "TreatmentList_avivaSupport_copingCards_title": [
    {
      "type": 0,
      "value": "Coping cards"
    }
  ],
  "TreatmentList_avivaSupport_hopeKit_subtitle": [
    {
      "type": 0,
      "value": "Create a reminder of your hopes"
    }
  ],
  "TreatmentList_avivaSupport_hopeKit_title": [
    {
      "type": 0,
      "value": "Hope kit"
    }
  ],
  "TreatmentList_avivaSupport_howToHelp_subtitle": [
    {
      "type": 0,
      "value": "How to help"
    }
  ],
  "TreatmentList_avivaSupport_howToHelp_title": [
    {
      "type": 0,
      "value": "Introduction"
    }
  ],
  "TreatmentList_avivaSupport_myPlanReview_subtitle": [
    {
      "type": 0,
      "value": "Tie learnings together"
    }
  ],
  "TreatmentList_avivaSupport_myPlanReview_title": [
    {
      "type": 0,
      "value": "Risk curve & MyPlan"
    }
  ],
  "TreatmentList_avivaSupport_myPlan_subtitle": [
    {
      "type": 0,
      "value": "Make a plan to stay safe"
    }
  ],
  "TreatmentList_avivaSupport_myPlan_title": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "TreatmentList_avivaSupport_postAviva_subtitle": [
    {
      "type": 0,
      "value": "How Aviva can keep helping you"
    }
  ],
  "TreatmentList_avivaSupport_postAviva_title": [
    {
      "type": 0,
      "value": "What's next?"
    }
  ],
  "TreatmentList_avivaSupport_reduceRisk_subtitle": [
    {
      "type": 0,
      "value": "Review skills you learned"
    }
  ],
  "TreatmentList_avivaSupport_reduceRisk_title": [
    {
      "type": 0,
      "value": "Skills to reduce risk"
    }
  ],
  "TreatmentList_avivaSupport_relaxation_subtitle": [
    {
      "type": 0,
      "value": "Learn calming exercises"
    }
  ],
  "TreatmentList_avivaSupport_relaxation_title": [
    {
      "type": 0,
      "value": "Relaxation & mindfulness"
    }
  ],
  "TreatmentList_avivaSupport_sleep_subtitle": [
    {
      "type": 0,
      "value": "Develop better sleep habits"
    }
  ],
  "TreatmentList_avivaSupport_sleep_title": [
    {
      "type": 0,
      "value": "Improving sleep"
    }
  ],
  "TreatmentList_avivaSupport_spotIt_subtitle": [
    {
      "type": 0,
      "value": "Link thoughts to how you feel"
    }
  ],
  "TreatmentList_avivaSupport_spotIt_title": [
    {
      "type": 0,
      "value": "Spot it"
    }
  ],
  "TreatmentList_avivaSupport_switchIt_subtitle": [
    {
      "type": 0,
      "value": "Find more helpful thoughts"
    }
  ],
  "TreatmentList_avivaSupport_switchIt_title": [
    {
      "type": 0,
      "value": "Switch it"
    }
  ],
  "TreatmentList_avivaSupport_testIt_subtitle": [
    {
      "type": 0,
      "value": "Challenge your thoughts"
    }
  ],
  "TreatmentList_avivaSupport_testIt_title": [
    {
      "type": 0,
      "value": "Test it"
    }
  ],
  "TreatmentList_aviva_activityPlanning_description": [
    {
      "type": 0,
      "value": "What’s it mean to get going\nLink doing activities to mood\nRecord activities and mood"
    }
  ],
  "TreatmentList_aviva_activityPlanning_subtitle": [
    {
      "type": 0,
      "value": "Do activities to feel better"
    }
  ],
  "TreatmentList_aviva_activityPlanning_title": [
    {
      "type": 0,
      "value": "Getting going"
    }
  ],
  "TreatmentList_aviva_copingCards_description": [
    {
      "type": 0,
      "value": "Remember your skills learned\nHow to create Coping cards\nMake time to practice them"
    }
  ],
  "TreatmentList_aviva_copingCards_subtitle": [
    {
      "type": 0,
      "value": "Create reminder flash cards"
    }
  ],
  "TreatmentList_aviva_copingCards_title": [
    {
      "type": 0,
      "value": "Coping cards"
    }
  ],
  "TreatmentList_aviva_hopeKit_description": [
    {
      "type": 0,
      "value": "What is a Hope Kit & why it’s helpful\nStart building your kit\nMake time to use it"
    }
  ],
  "TreatmentList_aviva_hopeKit_subtitle": [
    {
      "type": 0,
      "value": "Create a reminder of your hopes"
    }
  ],
  "TreatmentList_aviva_hopeKit_title": [
    {
      "type": 0,
      "value": "Hope kit"
    }
  ],
  "TreatmentList_aviva_myPlanReview_description": [
    {
      "type": 0,
      "value": "Reviewing your risk curve\nReviewing MyPlan\nMake updates to MyPlan"
    }
  ],
  "TreatmentList_aviva_myPlanReview_subtitle": [
    {
      "type": 0,
      "value": "Tie learnings together"
    }
  ],
  "TreatmentList_aviva_myPlanReview_title": [
    {
      "type": 0,
      "value": "Risk curve & MyPlan"
    }
  ],
  "TreatmentList_aviva_myPlan_description": [
    {
      "type": 0,
      "value": "How suicide risk rises and falls\nPlot your own risk curve\nCreate a plan to stay safe"
    }
  ],
  "TreatmentList_aviva_myPlan_subtitle": [
    {
      "type": 0,
      "value": "Make a plan to stay safe"
    }
  ],
  "TreatmentList_aviva_myPlan_title": [
    {
      "type": 0,
      "value": "MyPlan"
    }
  ],
  "TreatmentList_aviva_postAviva_description": [
    {
      "type": 0,
      "value": "What happens now\nSeeking more help\nContinue using Aviva"
    }
  ],
  "TreatmentList_aviva_postAviva_subtitle": [
    {
      "type": 0,
      "value": "How Aviva can keep helping you"
    }
  ],
  "TreatmentList_aviva_postAviva_title": [
    {
      "type": 0,
      "value": "What's next?"
    }
  ],
  "TreatmentList_aviva_reduceRisk_description": [
    {
      "type": 0,
      "value": "Skills to change behaviors\nSkills to change thoughts\nWhat was most helpful"
    }
  ],
  "TreatmentList_aviva_reduceRisk_subtitle": [
    {
      "type": 0,
      "value": "Review skills you learned"
    }
  ],
  "TreatmentList_aviva_reduceRisk_title": [
    {
      "type": 0,
      "value": "Skills to reduce risk"
    }
  ],
  "TreatmentList_aviva_relaxation_description": [
    {
      "type": 0,
      "value": "What are relaxation skills\nHow to use them\nMake time to practice"
    }
  ],
  "TreatmentList_aviva_relaxation_subtitle": [
    {
      "type": 0,
      "value": "Learn calming exercises"
    }
  ],
  "TreatmentList_aviva_relaxation_title": [
    {
      "type": 0,
      "value": "Relaxation & mindfulness"
    }
  ],
  "TreatmentList_aviva_sleep_description": [
    {
      "type": 0,
      "value": "Why is sleeping well important\nWhat might disrupt your sleep\nTrack your sleep habits"
    }
  ],
  "TreatmentList_aviva_sleep_subtitle": [
    {
      "type": 0,
      "value": "Develop better sleep habits"
    }
  ],
  "TreatmentList_aviva_sleep_title": [
    {
      "type": 0,
      "value": "Improving sleep"
    }
  ],
  "TreatmentList_aviva_spotIt_description": [
    {
      "type": 0,
      "value": "Why thoughts affect feelings\nHow to spot negative thoughts\nWrite spotted thoughts"
    }
  ],
  "TreatmentList_aviva_spotIt_subtitle": [
    {
      "type": 0,
      "value": "Link thoughts to how you feel"
    }
  ],
  "TreatmentList_aviva_spotIt_title": [
    {
      "type": 0,
      "value": "Spot it"
    }
  ],
  "TreatmentList_aviva_switchIt_description": [
    {
      "type": 0,
      "value": "Why balance your thoughts\nHow to switch it\nWrite switched thoughts"
    }
  ],
  "TreatmentList_aviva_switchIt_subtitle": [
    {
      "type": 0,
      "value": "Find more helpful thoughts"
    }
  ],
  "TreatmentList_aviva_switchIt_title": [
    {
      "type": 0,
      "value": "Switch it"
    }
  ],
  "TreatmentList_aviva_testIt_description": [
    {
      "type": 0,
      "value": "Why test your thoughts\nHow to test negative thoughts\nWrite evidence for & against"
    }
  ],
  "TreatmentList_aviva_testIt_subtitle": [
    {
      "type": 0,
      "value": "Challenge your thoughts"
    }
  ],
  "TreatmentList_aviva_testIt_title": [
    {
      "type": 0,
      "value": "Test it"
    }
  ],
  "UserProfile_recordComplete": [
    {
      "type": 0,
      "value": "Done"
    }
  ],
  "UserProfile_recordLocked": [
    {
      "type": 0,
      "value": "Complete session 1"
    }
  ],
  "UserProfile_recordsHeading": [
    {
      "type": 0,
      "value": "Records"
    }
  ],
  "UserProfile_riskCurveTitle": [
    {
      "type": 0,
      "value": "Risk Curve"
    }
  ],
  "UserProfile_suicideModeTitle": [
    {
      "type": 0,
      "value": "My Suicide Mode"
    }
  ],
  "Welcome_activationTokenLabel": [
    {
      "type": 0,
      "value": "Enter claim code"
    }
  ],
  "Welcome_logoAccessibilityLabel": [
    {
      "type": 0,
      "value": "Aviva Logo"
    }
  ],
  "Welcome_navigateToLoginButton": [
    {
      "type": 0,
      "value": "I already have an account"
    }
  ],
  "Welcome_submitClaimCodeButton": [
    {
      "type": 0,
      "value": "Next"
    }
  ],
  "Welcome_title": [
    {
      "type": 0,
      "value": "Welcome to Aviva"
    }
  ],
  "patientSocialSupporterRelation_CHEERLEADER": [
    {
      "type": 0,
      "value": "Cheerleader"
    }
  ],
  "patientSocialSupporterRelation_DETRACTOR": [
    {
      "type": 0,
      "value": "Detractor"
    }
  ],
  "patientSocialSupporterRelation_PARTNER": [
    {
      "type": 0,
      "value": "Partner"
    }
  ],
  "patientSocialSupporterRelation_POSITIVE_ENABLER": [
    {
      "type": 0,
      "value": "Positive Enabler"
    }
  ]
}