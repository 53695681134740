import { ParamListBase, StackNavigationState, TypedNavigator } from '@react-navigation/core';
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack';

import { AddHopeKit, HopeKit } from './screens/index';
import { HopeKitStackParamList } from './types/navigation';

export function registerHopeKitScreens<T extends ParamListBase>(
  stack: TypedNavigator<
    T,
    StackNavigationState<HopeKitStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >,
) {
  const Stack = stack as TypedNavigator<
    HopeKitStackParamList,
    StackNavigationState<HopeKitStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >;
  return (
    <>
      <Stack.Screen name="AddHopeKit" component={AddHopeKit} />
      <Stack.Screen name="HopeKit" component={HopeKit} />
    </>
  );
}
