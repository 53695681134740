import * as Calendar from 'expo-calendar';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import Divider from '@oui/app-core/src/components/Divider';
import { Modal } from '@oui/app-core/src/components/Modal';
import { WorksheetListItem } from '@oui/app-core/src/components/WorksheetListItem';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { askAsync } from '@oui/app-core/src/lib/permissionsManager';

export function CalendarPicker({
  onChooseCalendar,
  onRequestClose,
}: {
  onChooseCalendar: (cal: Calendar.Calendar) => void;
  onRequestClose: () => void;
}) {
  const [calendars, setCalendars] = useState<Calendar.Calendar[]>([]);
  const { $t } = useI18n();
  const { height } = useWindowDimensions();
  const onRequestCloseRef = useRef(onRequestClose);
  const [isGranted, setIsGranted] = useState(false);
  onRequestCloseRef.current = onRequestClose;

  useEffect(() => {
    function loadCalendars() {
      Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT).then((cs) => {
        const titleSet = new Set();
        const cals = (Platform.OS === 'android' ? cs.filter((c) => c.isVisible) : cs)
          .filter((c) => {
            if (c.allowsModifications) {
              if (titleSet.has(c.title)) return false;
              titleSet.add(c.title);
            }
            return c.allowsModifications;
          })
          .sort((a, b) => (a.title < b.title ? -1 : 1));
        setCalendars(cals);
      });
    }

    askAsync('Calendar', () => Calendar.requestCalendarPermissionsAsync(), {
      retryIfDenied: true,
    }).then(({ status }) => {
      if (status === 'granted') {
        setIsGranted(true);
        loadCalendars();
      } else {
        onRequestCloseRef.current();
      }
    });
  }, []);

  return (
    <Modal
      heading={$t({ id: 'CalendarPicker_heading', defaultMessage: 'Choose a calendar' })}
      visible={isGranted}
      onRequestClose={onRequestClose}
      maxHeight={Math.max(height - 300, 400)}
    >
      {calendars.length ? (
        calendars.map((cal) => {
          return (
            <Fragment key={cal.id}>
              <WorksheetListItem
                key={cal.id}
                text={cal.title}
                onPress={() => {
                  onChooseCalendar(cal);
                }}
              />
              <Divider />
            </Fragment>
          );
        })
      ) : (
        <ActivityIndicator />
      )}
    </Modal>
  );
}
