import { useQuery } from '@apollo/client';
import { defineMessage } from '@formatjs/intl';
import { IntlShape } from '@formatjs/intl/src/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import addDays from 'date-fns/addDays';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import times from 'lodash/times';
import { useLayoutEffect, useState } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { Divider } from '@oui/app-core/src/components/Divider';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { Icon } from '@oui/app-core/src/components/Icon';
import {
  RoundedSection,
  RoundedSectionNavigationOptions,
} from '@oui/app-core/src/components/RoundedSection';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  PracticeFragment,
  SleepDiaryConfigInfluencerFrequency,
  SleepDiaryEntryFragment,
  useSleepDiaryConfig,
} from '@oui/app-core/src/hooks/practices';
import { useCurrentPatientID } from '@oui/app-core/src/hooks/useCurrentUser';
import { useFormatDuration } from '@oui/app-core/src/hooks/useFormatDuration';
import { useDefaultHeaderHeight } from '@oui/app-core/src/lib/getDefaultHeaderHeight';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate, parseGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql, ResultOf } from '@oui/lib/src/graphql/tada';
import { recordKeys } from '@oui/lib/src/recordKeys';
import {
  RatingType,
  SleepDiaryEntryDuration,
  SleepDiaryEntryTimeOfDay,
} from '@oui/lib/src/types/graphql.generated';
import { GQLDate } from '@oui/lib/src/types/scalars';

import { useSleepDiaryContext } from '../components';
import { StackScreenProps } from '../types/navigation';

export const SleepDiaryEntryByDateQueryName = 'SleepDiaryEntryByDate';
export type SleepDiaryEntryByDateQueryName = typeof SleepDiaryEntryByDateQueryName;
export const SleepDiaryEntryByDateQuery = graphql(
  `
    query SleepDiaryEntryByDate($patientID: UUID!, $date: Date!, $previousDate: Date!) {
      sleepDiaryEntryPracticeByDate(patientID: $patientID, date: $date) {
        ...PracticeFragment
        ... on SleepDiaryEntryPractice {
          sleepDiaryEntry {
            ...SleepDiaryEntry
          }
        }
      }
      previousSleepDiaryEntryPractice: sleepDiaryEntryPracticeByDate(
        patientID: $patientID
        date: $previousDate
      ) {
        ...PracticeFragment
        ... on SleepDiaryEntryPractice {
          sleepDiaryEntry {
            ...SleepDiaryEntry
          }
        }
      }
    }
  `,
  [PracticeFragment, SleepDiaryEntryFragment],
);

export const SLEEP_INFLUENCER_ICONS = {
  caffeine: 'coffee',
  alcohol: 'alcohol',
  tobacco: 'smoking',
  napping: 'sleep-bed',
  exercise: 'exercise',
  medicine: 'pill',
  deviceInBed: 'phone',
} as const;

export function getTimeOfDayItems($t: IntlShape['$t']) {
  return [
    {
      label: $t({ id: 'SleepDiary_timeOfDayItem_morning', defaultMessage: 'Morning' }),
      icon: 'sunrise' as const,
      value: SleepDiaryEntryTimeOfDay.MORNING,
    },
    {
      label: $t({ id: 'SleepDiary_timeOfDayItem_afternoon', defaultMessage: 'Afternoon' }),
      icon: 'sun' as const,
      value: SleepDiaryEntryTimeOfDay.AFTERNOON,
    },
    {
      label: $t({ id: 'SleepDiary_timeOfDayItem_evening', defaultMessage: 'Evening' }),
      icon: 'sunset' as const,
      value: SleepDiaryEntryTimeOfDay.EVENING,
    },
    {
      label: $t({ id: 'SleepDiary_timeOfDayItem_night', defaultMessage: 'Night' }),
      icon: 'moon' as const,
      value: SleepDiaryEntryTimeOfDay.NIGHT,
    },
  ];
}

export function getDurationItems($t: IntlShape['$t'], values: SleepDiaryEntryDuration[]) {
  return values.map((value) => {
    return {
      label: getSleepDiaryEntryDurationLabel($t, value),
      value,
    };
  });
}

export function getSleepDelayItems($t: IntlShape['$t']) {
  return getDurationItems($t, [
    SleepDiaryEntryDuration.DURATION_LESS_THAN_15,
    SleepDiaryEntryDuration.DURATION_15_TO_30,
    SleepDiaryEntryDuration.DURATION_30_TO_60,
    SleepDiaryEntryDuration.DURATION_MORE_THAN_60,
  ]);
}

export function getNappingItems($t: IntlShape['$t']) {
  return getDurationItems($t, [
    SleepDiaryEntryDuration.DURATION_LESS_THAN_15,
    SleepDiaryEntryDuration.DURATION_15_TO_30,
    SleepDiaryEntryDuration.DURATION_30_TO_60,
    SleepDiaryEntryDuration.DURATION_60_TO_120,
    SleepDiaryEntryDuration.DURATION_MORE_THAN_120,
  ]);
}

export function getExerciseItems($t: IntlShape['$t']) {
  return getDurationItems($t, [
    SleepDiaryEntryDuration.DURATION_20_TO_45,
    SleepDiaryEntryDuration.DURATION_45_TO_60,
    SleepDiaryEntryDuration.DURATION_60_TO_120,
    SleepDiaryEntryDuration.DURATION_120_TO_180,
    SleepDiaryEntryDuration.DURATION_MORE_THAN_180,
  ]);
}

export function getDeviceInBedItems($t: IntlShape['$t']) {
  return getDurationItems($t, [
    SleepDiaryEntryDuration.DURATION_1_TO_5,
    SleepDiaryEntryDuration.DURATION_5_TO_10,
    SleepDiaryEntryDuration.DURATION_15_TO_30,
    SleepDiaryEntryDuration.DURATION_30_TO_60,
    SleepDiaryEntryDuration.DURATION_60_TO_120,
    SleepDiaryEntryDuration.DURATION_MORE_THAN_120,
  ]);
}

export function getWakeupCountItems($t: IntlShape['$t']) {
  const msg = defineMessage({
    id: 'SleepDiary_wakeupCountTimes',
    defaultMessage: '{count} {count, plural, one {time} other {times}}',
  });
  const sevenOrMoreMsg = defineMessage({
    id: 'SleepDiary_wakeupCountMoreThan6Times',
    defaultMessage: '7 or more times',
  });

  const items = [
    { label: $t(msg, { count: 0 }), value: 0 },
    { label: $t(msg, { count: 1 }), value: 1 },
    { label: $t(msg, { count: 2 }), value: 2 },
    { label: $t(msg, { count: 3 }), value: 3 },
    { label: $t(msg, { count: 4 }), value: 4 },
    { label: $t(msg, { count: 5 }), value: 5 },
    { label: $t(msg, { count: 6 }), value: 6 },
    { label: $t(sevenOrMoreMsg), value: 10 },
  ];
  return items;
}

export function getQualityItems($t: IntlShape['$t']) {
  return [
    { label: $t({ id: 'SleepDiary_qualityVeryRested', defaultMessage: 'Very rested' }), value: 5 },
    { label: $t({ id: 'SleepDiary_qualityRested', defaultMessage: 'Rested' }), value: 4 },
    { label: $t({ id: 'SleepDiary_qualityOk', defaultMessage: 'OK' }), value: 3 },
    { label: $t({ id: 'SleepDiary_qualityTired', defaultMessage: 'Tired' }), value: 2 },
    { label: $t({ id: 'SleepDiary_qualityVeryTired', defaultMessage: 'Very tired' }), value: 1 },
  ];
}

export function getCaffeineItems($t: IntlShape['$t']) {
  const msg = defineMessage({
    id: 'SleepDiary_caffeineCountTimes',
    defaultMessage: '{count} {count, plural, one {drink} other {drinks}}',
  });
  const sixOrMoreMsg = defineMessage({
    id: 'SleepDiary_caffeineCountMoreThan5Times',
    defaultMessage: '6 or more drinks',
  });

  const items = [
    { label: $t(msg, { count: 1 }), value: 1 },
    { label: $t(msg, { count: 2 }), value: 2 },
    { label: $t(msg, { count: 3 }), value: 3 },
    { label: $t(msg, { count: 4 }), value: 4 },
    { label: $t(msg, { count: 5 }), value: 5 },
    { label: $t(sixOrMoreMsg), value: 10 },
  ];
  return items;
}

export function getAlcoholItems($t: IntlShape['$t']) {
  const msg = defineMessage({
    id: 'SleepDiary_alcoholCountTimes',
    defaultMessage: '{count} {count, plural, one {drink} other {drinks}}',
  });
  const rangeMsg = defineMessage({
    id: 'SleepDiary_alcoholCountRange',
    defaultMessage: '{rangeBegin}-{rangeEnd} drinks',
  });
  const twentyOrMoreMsg = defineMessage({
    id: 'SleepDiary_alcoholCountMoreThan20Times',
    defaultMessage: '20 or more drinks',
  });

  const items = [
    { label: $t(msg, { count: 1 }), value: 1 },
    { label: $t(msg, { count: 2 }), value: 2 },
    { label: $t(msg, { count: 3 }), value: 3 },
    { label: $t(msg, { count: 4 }), value: 4 },
    { label: $t(rangeMsg, { rangeBegin: 5, rangeEnd: 7 }), value: 6 },
    { label: $t(rangeMsg, { rangeBegin: 8, rangeEnd: 12 }), value: 10 },
    { label: $t(rangeMsg, { rangeBegin: 13, rangeEnd: 19 }), value: 15 },
    { label: $t(twentyOrMoreMsg), value: 25 },
  ];
  return items;
}

export function getTobaccoItems($t: IntlShape['$t']) {
  const msg = defineMessage({
    id: 'SleepDiary_tobaccoCountTimes',
    defaultMessage: '{count} {count, plural, one {cigarette} other {cigarettes}}',
  });
  const rangeMsg = defineMessage({
    id: 'SleepDiary_tobaccoCountRange',
    defaultMessage: '{rangeBegin}-{rangeEnd} cigarettes',
  });
  const twentyOrMoreMsg = defineMessage({
    id: 'SleepDiary_tobaccoCountMoreThan20Times',
    defaultMessage: '20 or more cigarettes',
  });

  const items = [
    { label: $t(msg, { count: 1 }), value: 1 },
    { label: $t(msg, { count: 2 }), value: 2 },
    { label: $t(msg, { count: 3 }), value: 3 },
    { label: $t(msg, { count: 4 }), value: 4 },
    { label: $t(msg, { count: 5 }), value: 5 },
    { label: $t(rangeMsg, { rangeBegin: 6, rangeEnd: 10 }), value: 8 },
    { label: $t(rangeMsg, { rangeBegin: 11, rangeEnd: 19 }), value: 15 },
    { label: $t(twentyOrMoreMsg), value: 25 },
  ];
  return items;
}

export function getMedicineItems($t: IntlShape['$t']) {
  const msg = defineMessage({
    id: 'SleepDiary_medicineCountTimes',
    defaultMessage: '{count} {count, plural, one {pill} other {pills}}',
  });
  const fiveOrMoreMsg = defineMessage({
    id: 'SleepDiary_medicineCountMoreThan4Times',
    defaultMessage: '5 or more pills',
  });

  const items = [
    { label: $t(msg, { count: 1 }), value: 1 },
    { label: $t(msg, { count: 2 }), value: 2 },
    { label: $t(msg, { count: 3 }), value: 3 },
    { label: $t(msg, { count: 4 }), value: 4 },
    { label: $t(fiveOrMoreMsg), value: 10 },
  ];
  return items;
}

export function getInfluencerItems($t: IntlShape['$t']) {
  const influencerItems: Record<
    keyof typeof SLEEP_INFLUENCER_ICONS,
    Array<{ label: string; value: string | number }> | undefined
  > = {
    caffeine: getCaffeineItems($t),
    alcohol: getAlcoholItems($t),
    tobacco: getTobaccoItems($t),
    napping: getSleepDelayItems($t),
    exercise: getExerciseItems($t),
    medicine: getMedicineItems($t),
    deviceInBed: getDeviceInBedItems($t),
  };
  return influencerItems;
}

type SleepDiaryEntryInfluencer = NonNullable<
  ResultOf<typeof SleepDiaryEntryByDateQuery>['sleepDiaryEntryPracticeByDate']
>['sleepDiaryEntry'][keyof typeof SLEEP_INFLUENCER_ICONS];
function getTextForSleepDiaryEntryInfluencer(
  $t: IntlShape['$t'],
  influencer: keyof typeof SLEEP_INFLUENCER_ICONS,
  value: NonNullable<SleepDiaryEntryInfluencer>,
) {
  const influencerItems = getInfluencerItems($t);
  if (value.occurred === false) return 'No';
  const items = influencerItems[influencer];
  const countOrDuration = value.count ?? value.duration;
  const label = (items ?? []).find((i) => i.value === countOrDuration)?.label;
  const kindLabel = (value.kinds ?? []).join(', ');
  const timeOfDatItems = getTimeOfDayItems($t);
  return [
    label ? `Yes, ${label}${kindLabel ? `, ${kindLabel}` : ''}` : 'Yes',
    value.timesOfDay
      .map((t) => {
        return timeOfDatItems.find((i) => i.value === t)?.label;
      })
      .join(',\n'),
  ].join('\n\n');
}

export function CheckinItem(props: {
  influencer?: keyof typeof SLEEP_INFLUENCER_ICONS;
  text: string;
  value?: SleepDiaryEntryInfluencer | string | null;
}) {
  const disabled = typeof props.value === 'string' ? false : props.value?.occurred === false;
  const { $t } = useI18n();
  const { theme } = useTheme();

  return (
    <View
      row
      spacing={24}
      style={[{ alignItems: 'flex-start' }, disabled ? { opacity: 0.5 } : null]}
    >
      <View flex={3} style={{ flexBasis: 0 }} row spacing={8}>
        {props.influencer ? (
          <Icon
            name={SLEEP_INFLUENCER_ICONS[props.influencer]}
            size={24}
            color={theme.color.gray500}
          />
        ) : null}
        <Text text={props.text} weight="semibold" style={{ flex: 1 }} />
      </View>
      <Text
        size={15}
        style={{ flex: 2, flexBasis: 0 }}
        text={
          props.value
            ? typeof props.value === 'string'
              ? props.value
              : getTextForSleepDiaryEntryInfluencer($t, props.influencer!, props.value)
            : $t({ id: 'SleepDiaryEntry_notAnswered', defaultMessage: 'Not answered' })
        }
      />
    </View>
  );
}

export function useSleepDiaryEntryPracticeByDate(defaultDate?: GQLDate) {
  const route = useRoute<StackScreenProps<'EditSleepDiaryEntry' | 'SleepDiaryEntry'>['route']>();
  const patientID = useCurrentPatientID()!;
  const date = defaultDate ?? route.params?.date;

  const previousDate = formatGQLDate(addDays(parseGQLDate(date), -1));
  const { data, ...rest } = useQuery(SleepDiaryEntryByDateQuery, {
    variables: {
      patientID,
      date,
      previousDate,
    },
    skip: !date || !patientID,
  });

  const practiceValues = data?.sleepDiaryEntryPracticeByDate?.practiceValues;
  const sleepDiaryEntry = data?.sleepDiaryEntryPracticeByDate?.sleepDiaryEntry;
  const practiceID = data?.sleepDiaryEntryPracticeByDate?.practiceID;
  return {
    data,
    ...rest,
    date,
    sleepDiaryEntryPracticeByDate: data?.sleepDiaryEntryPracticeByDate,
    practiceID,
    practiceValues,
    sleepDiaryEntry,
    previousDate,
    previousSleepDiaryEntryPractice: data?.previousSleepDiaryEntryPractice,
  };
}

export function getSleepDiaryEntryDurationLabel(
  $t: IntlShape['$t'],
  value?: SleepDiaryEntryDuration | null,
) {
  if (!value) return $t({ id: 'SleepDiaryEntryDuration_unknownLabel', defaultMessage: 'Unknown' });
  switch (value) {
    case SleepDiaryEntryDuration.DURATION_120_TO_180:
      return $t({
        id: 'SleepDiaryEntryDuration_120To180MinutesLabel',
        defaultMessage: '2-3 hours',
      });
    case SleepDiaryEntryDuration.DURATION_15_TO_30:
      return $t({
        id: 'SleepDiaryEntryDuration_15To30MinutesLabel',
        defaultMessage: '15-30 minutes',
      });
    case SleepDiaryEntryDuration.DURATION_1_TO_5:
      return $t({ id: 'SleepDiaryEntryDuration_1To5MinutesLabel', defaultMessage: '1-5 minutes' });
    case SleepDiaryEntryDuration.DURATION_20_TO_45:
      return $t({
        id: 'SleepDiaryEntryDuration_20To45MinutesLabel',
        defaultMessage: '20-45 minutes',
      });
    case SleepDiaryEntryDuration.DURATION_30_TO_60:
      return $t({
        id: 'SleepDiaryEntryDuration_30To60MinutesLabel',
        defaultMessage: '30-60 minutes',
      });
    case SleepDiaryEntryDuration.DURATION_45_TO_60:
      return $t({
        id: 'SleepDiaryEntryDuration_45To60MinutesLabel',
        defaultMessage: '45-60 minutes',
      });
    case SleepDiaryEntryDuration.DURATION_5_TO_10:
      return $t({
        id: 'SleepDiaryEntryDuration_5To10MinutesLabel',
        defaultMessage: '5-10 minutes',
      });
    case SleepDiaryEntryDuration.DURATION_60_TO_120:
      return $t({ id: 'SleepDiaryEntryDuration_60To120MinutesLabel', defaultMessage: '1-2 hours' });
    case SleepDiaryEntryDuration.DURATION_LESS_THAN_15:
      return $t({
        id: 'SleepDiaryEntryDuration_lessThan15MinutesLabel',
        defaultMessage: 'Fewer than 15 minutes',
      });
    case SleepDiaryEntryDuration.DURATION_MORE_THAN_120:
      return $t({
        id: 'SleepDiaryEntryDuration_moreThan120MinutesLabel',
        defaultMessage: 'More than 2 hours',
      });
    case SleepDiaryEntryDuration.DURATION_MORE_THAN_180:
      return $t({
        id: 'SleepDiaryEntryDuration_moreThan180MinutesLabel',
        defaultMessage: 'More than 3 hours',
      });
    case SleepDiaryEntryDuration.DURATION_MORE_THAN_60:
      return $t({
        id: 'SleepDiaryEntryDuration_moreThan60MinutesLabel',
        defaultMessage: 'More than 1 hour',
      });
  }
}

export function isMorningChecklistComplete(
  entry?: ResultOf<typeof SleepDiaryEntryByDateQuery>['sleepDiaryEntryPracticeByDate'],
) {
  if (!entry) return false;
  return !!entry.practiceValues.ratings.find((r) => r.type === RatingType.RATING)?.value;
}

export function isNightChecklistComplete(
  $t: IntlShape['$t'],
  entry?: ResultOf<typeof SleepDiaryEntryByDateQuery>['sleepDiaryEntryPracticeByDate'],
) {
  if (!entry) return false;
  const influencerItems = getInfluencerItems($t);
  return !!recordKeys(influencerItems).find((key) => {
    return entry.sleepDiaryEntry[key] !== null;
  });
}

function MorningCheckin({
  date,
  sleepDiaryEntryPractice,
}: {
  date: GQLDate;
  sleepDiaryEntryPractice:
    | ResultOf<typeof SleepDiaryEntryByDateQuery>['sleepDiaryEntryPracticeByDate']
    | undefined
    | null;
}) {
  const { theme } = useTheme();
  const navigation =
    useNavigation<StackScreenProps<'EditSleepDiaryEntry' | 'SleepDiaryEntry'>['navigation']>();
  const sleepDiaryEntry = sleepDiaryEntryPractice?.sleepDiaryEntry;
  const isComplete = isMorningChecklistComplete(sleepDiaryEntryPractice);
  const rating =
    sleepDiaryEntryPractice?.practiceValues?.ratings?.find((r) => r.type === RatingType.RATING)
      ?.value ?? 0;
  const quality =
    sleepDiaryEntryPractice?.practiceValues?.ratings?.find((r) => r.type === RatingType.QUALITY)
      ?.value ?? 0;
  const { formatDuration } = useFormatDuration();
  const { $t, formatDate } = useI18n();

  function goToEdit() {
    navigation.navigate('EditSleepDiaryEntry', { date, step: 'morning' });
  }

  const durationFromSleepDiaryEntry = (): string => {
    if (!sleepDiaryEntry?.startTime || !sleepDiaryEntry?.endTime) {
      return 'Unknown time';
    }

    const totalMinutes = Math.floor(
      differenceInMinutes(
        parseGQLDateTime(sleepDiaryEntry.endTime),
        parseGQLDateTime(sleepDiaryEntry.startTime),
      ),
    );

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return formatDuration({
      hours,
      minutes,
    });
  };

  return isComplete ? (
    <>
      <View
        spacing={20}
        style={{
          marginTop: -20,
          borderTopStartRadius: 30,
          borderTopEndRadius: 30,
          marginHorizontal: -20,
          paddingVertical: 20,
          paddingHorizontal: 60,
          backgroundColor: '#f5f8ff',
          justifyContent: 'space-between',
        }}
        row
      >
        <Text
          text={$t({ id: 'SleepDiaryEntry_rating', defaultMessage: 'Sleep rating' })}
          weight="semibold"
          size={17}
        />
        <View
          row
          spacing={8}
          accessible
          aria-label={$t(
            {
              id: 'SleepDiaryEntry_ratingAccessibilityLabel',
              defaultMessage: '{rating} of 5 stars',
            },
            {
              rating,
            },
          )}
          key={rating}
          testID="SleepDiaryEntry_ratingAccessibilityLabel"
        >
          {times(5, (i) => {
            return i + 1 <= rating ? (
              <Icon key={i} name="star-selected" color={theme.color.warning} />
            ) : (
              <Icon key={i} name="star" color={theme.color.gray500} />
            );
          })}
        </View>
      </View>
      <View style={{ paddingTop: 20 }} spacing={18}>
        <View row spacing={10}>
          <Icon name="moon" color={theme.color.dark} />
          <View style={{ flex: 1, height: 2, backgroundColor: '#c5c5d3' }} />
          <Text
            text={$t(
              {
                id: 'SleepDiaryEntry_morningCheckinDurationInBed',
                defaultMessage: '{entryDuration} in bed',
              },
              { entryDuration: durationFromSleepDiaryEntry() },
            )}
            color={theme.color.gray300}
            weight="semibold"
            testID="SleepDiaryEntry_morningCheckinDurationInBed"
          />
          <View style={{ flex: 1, height: 2, backgroundColor: '#c5c5d3' }} />
          <Icon name="sun" color="#ecbf2d" />
        </View>
      </View>
      <View row style={{ justifyContent: 'space-around' }}>
        <View style={{ padding: 20, alignItems: 'center' }} accessible>
          <Text
            weight="semibold"
            text={$t({ id: 'SleepDiaryEntry_startTime', defaultMessage: 'In bed at' })}
          />
          <Text
            text={
              sleepDiaryEntry?.startTime
                ? formatDate(parseGQLDateTime(sleepDiaryEntry.startTime), {
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                : ''
            }
          />
        </View>
        <View style={{ padding: 20, alignItems: 'center' }} accessible>
          <Text
            weight="semibold"
            text={$t({
              id: 'SleepDiaryEntry_endTime',
              defaultMessage: 'Out of bed at',
            })}
          />
          <Text
            text={
              sleepDiaryEntry?.endTime
                ? formatDate(parseGQLDateTime(sleepDiaryEntry.endTime), {
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                : ''
            }
          />
        </View>
      </View>
      <View spacing={30}>
        <CheckinItem
          text={$t({
            id: 'SleepDiaryEntry_sleepDelay',
            defaultMessage: 'I fell asleep',
          })}
          value={getSleepDiaryEntryDurationLabel($t, sleepDiaryEntry?.sleepDelay)}
        />
        <CheckinItem
          text={$t({
            id: 'SleepDiaryEntry_wakeupCount',
            defaultMessage: 'I woke up during the night',
          })}
          value={
            sleepDiaryEntry?.wakeupCount === 1
              ? '1 time'
              : `${sleepDiaryEntry?.wakeupCount ?? 0} times`
          }
        />
        <CheckinItem
          text={$t({
            id: 'SleepDiaryEntry_quality',
            defaultMessage: 'When I woke up, I felt',
          })}
          value={getQualityItems($t).find((i) => i.value === quality)?.label ?? 'Unknown'}
        />
      </View>
    </>
  ) : (
    <View spacing={16}>
      <Text
        size={17}
        text={$t({ id: 'SleepDiaryEntry_morningCheckinNotDoneHeader', defaultMessage: 'Not done' })}
        role="heading"
        weight="semibold"
        textAlign="center"
      />
      <Button
        alignSelf="center"
        text={$t({
          id: 'SleepDiaryEntry_morningCheckinButton',
          defaultMessage: 'Morning check-in',
        })}
        testID="SleepDiaryEntry_morningCheckinButton"
        icon="sun"
        onPress={goToEdit}
      />
    </View>
  );
}

function NightCheckin({
  isYesterday,
  date,
  sleepDiaryEntryPractice,
}: {
  isYesterday: boolean;
  date: GQLDate;
  sleepDiaryEntryPractice:
    | ResultOf<typeof SleepDiaryEntryByDateQuery>['sleepDiaryEntryPracticeByDate']
    | undefined
    | null;
}) {
  const navigation =
    useNavigation<StackScreenProps<'EditSleepDiaryEntry' | 'SleepDiaryEntry'>['navigation']>();
  const sleepDiaryEntry = sleepDiaryEntryPractice?.sleepDiaryEntry;
  const { $t } = useI18n();
  const isComplete = isNightChecklistComplete($t, sleepDiaryEntryPractice);
  const { theme } = useTheme();
  const { data: sleepConfig } = useSleepDiaryConfig();

  function goToEdit() {
    navigation.navigate('EditSleepDiaryEntry', { date, step: 'night' });
  }

  return isComplete ? (
    <View spacing={30}>
      <View row spacing={12} style={{ justifyContent: 'space-between' }}>
        <View aria-hidden style={{ opacity: 0 }}>
          <Button aria-label={undefined} variant="text" icon="edit" onPress={goToEdit} />
        </View>
        <Text
          testID="SleepDiaryEntry_dayBeforeHeader"
          size={17}
          text={
            isYesterday
              ? $t({ id: 'SleepDiaryEntry_yesterdayHeader', defaultMessage: 'Yesterday, I...' })
              : $t({
                  id: 'SleepDiaryEntry_dayBeforeHeader',
                  defaultMessage: 'The day before, I...',
                })
          }
          role="heading"
          weight="semibold"
          textAlign="center"
        />
        <Icon
          aria-label={$t({
            id: 'SleepDiaryEntry_nightEditButton',
            defaultMessage: 'Edit day before',
          })}
          name="edit"
          onPress={goToEdit}
          color={theme.color.gray400}
        />
      </View>
      {sleepConfig?.caffeine !== SleepDiaryConfigInfluencerFrequency.Never ? (
        <CheckinItem
          influencer="caffeine"
          text={$t({ id: 'SleepDiaryEntry_caffeine', defaultMessage: 'Had caffeine?' })}
          value={sleepDiaryEntry?.caffeine}
        />
      ) : null}
      {sleepConfig?.alcohol !== SleepDiaryConfigInfluencerFrequency.Never ? (
        <CheckinItem
          influencer="alcohol"
          text={$t({ id: 'SleepDiaryEntry_alcohol', defaultMessage: 'Had alcohol?' })}
          value={sleepDiaryEntry?.alcohol}
        />
      ) : null}
      {sleepConfig?.tobacco !== SleepDiaryConfigInfluencerFrequency.Never ? (
        <CheckinItem
          influencer="tobacco"
          text={$t({
            id: 'SleepDiaryEntry_tobacco',
            defaultMessage: 'Smoked tobacco?',
          })}
          value={sleepDiaryEntry?.tobacco}
        />
      ) : null}
      {sleepConfig?.napping !== SleepDiaryConfigInfluencerFrequency.Never ? (
        <CheckinItem
          influencer="napping"
          text={$t({
            id: 'SleepDiaryEntry_nap',
            defaultMessage: 'Napped during the day?',
          })}
          value={sleepDiaryEntry?.napping}
        />
      ) : null}
      <CheckinItem
        influencer="exercise"
        text={$t({
          id: 'SleepDiaryEntry_nightCheckinExercise',
          defaultMessage: 'Exercised 20+ min?',
        })}
        value={sleepDiaryEntry?.exercise}
      />
      {sleepConfig?.medicine !== SleepDiaryConfigInfluencerFrequency.Never ? (
        <CheckinItem
          influencer="medicine"
          text={$t({
            id: 'SleepDiaryEntry_medicine',
            defaultMessage: 'Took sleep medication?',
          })}
          value={sleepDiaryEntry?.medicine}
        />
      ) : null}
      <CheckinItem
        influencer="deviceInBed"
        text={$t({
          id: 'SleepDiaryEntry_deviceInBed',
          defaultMessage: 'Used a device (cell, laptop, TV) in bed?',
        })}
        value={sleepDiaryEntry?.deviceInBed}
      />
    </View>
  ) : (
    <View spacing={16}>
      <Text
        size={17}
        text={$t({ id: 'SleepDiaryEntry_nightCheckinNotDoneHeader', defaultMessage: 'Not done' })}
        role="heading"
        weight="semibold"
        textAlign="center"
      />
      <Button
        alignSelf="center"
        text={$t({
          id: 'SleepDiaryEntry_editNightBeforeButton',
          defaultMessage: 'Night before check-in',
        })}
        icon="moon"
        onPress={goToEdit}
        testID="SleepDiaryEntry_editNightBeforeButton"
      />
    </View>
  );
}

export function SleepDiaryEntry(props: StackScreenProps<'SleepDiaryEntry'>) {
  const {
    practiceValues,
    date,
    sleepDiaryEntryPracticeByDate,
    previousSleepDiaryEntryPractice,
    previousDate,
  } = useSleepDiaryEntryPracticeByDate();
  const [today] = useState(formatGQLDate);
  const isToday = practiceValues?.date === today;
  const { $t, formatDate } = useI18n();
  const sleepDiaryContext = useSleepDiaryContext();
  const { navigate, setOptions } =
    useNavigation<StackScreenProps<'SleepDiaryEntry'>['navigation']>();
  const route = useRoute<StackScreenProps<'SleepDiaryEntry'>['route']>();
  const defaultHeaderHeight = useDefaultHeaderHeight();

  useLayoutEffect(() => {
    setOptions({
      ...RoundedSectionNavigationOptions({
        defaultHeaderHeight,
        tintColor: 'white',
        backgroundColor: sleepDiaryContext.mainColor,
      }),
      headerRight: ({ tintColor }) => (
        <HeaderButtons>
          <HeaderItem
            title=""
            aria-label="Edit"
            iconName="edit"
            color={tintColor}
            onPress={() =>
              navigate('EditSleepDiaryEntry', {
                date: route.params.date,
                step: 'morning',
              })
            }
          />
        </HeaderButtons>
      ),
    });
  }, [setOptions, navigate, defaultHeaderHeight, route.params.date, sleepDiaryContext.mainColor]);

  return (
    <RoundedSection
      color={sleepDiaryContext.mainColor}
      secondaryColor="white"
      title={
        isToday
          ? $t({ id: 'SleepDiaryEntry_todayTitle', defaultMessage: 'Today' })
          : formatDate(parseGQLDateTime(props.route.params.date), {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
            }) ?? sleepDiaryContext.name
      }
      preview={false}
      testID="SleepDiaryEntry_scrollView"
    >
      <MorningCheckin date={date} sleepDiaryEntryPractice={sleepDiaryEntryPracticeByDate} />
      <Divider style={{ marginVertical: 36 }} />
      <NightCheckin
        date={previousDate}
        sleepDiaryEntryPractice={previousSleepDiaryEntryPractice}
        isYesterday={isToday}
      />
    </RoundedSection>
  );
}
