import type { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import type { CompositeNavigationProp } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';

import type { ActivityDiaryStackParamList } from '@oui/activity-diary';
import type {
  AuthParamList,
  BooleanParam,
  CoreRootStackParamList,
  NavigatorScreenParams,
  NumberParam,
  QuizSetParams,
  RouteProp,
} from '@oui/app-core/src/types/navigation';
import type { RootStackParamList as StaticRootStackParamList } from '@oui/app-static/src/types/navigation';
import type { HopeKitStackParamList } from '@oui/hope-kit';
import type { InputArtifactResult } from '@oui/lib/src/types/actionEnvelopes';
import type { GQLUUID } from '@oui/lib/src/types/scalars';
import type { StackParamList as MyPlanStackParamList } from '@oui/myplan/src/types/navigation';
import type { SleepDiaryStackParamList } from '@oui/sleep-diary';

import type { ContentType } from '@src/types';

export { BooleanParam, NumberParam };

/*
 * @deprecated This type used to have properties but we've since removed options specific to
 * RoundedSection. In order to preserve backwards compatible type safety we leave the empty object
 * so that react-navigation types force us to pass an empty object and avoid undefined property access
 * errors.
 */
export type RoundedSectionParams = {};

export type TabParamList = {
  Home: undefined;
  Learn: undefined;
  Practice: undefined;
  Aid: undefined;
  Profile: undefined;
};

export type RootStackParamList = {
  home: NavigatorScreenParams<TabParamList>;
  StaticSession: { slug: ContentType; page: NumberParam; title: string };
  StaticReview: {
    slug: (typeof ContentType)[
      | 'STATIC_01'
      | 'STATIC_02'
      | 'STATIC_03'
      | 'STATIC_04'
      | 'STATIC_05'
      | 'STATIC_06'
      | 'STATIC_07'
      | 'STATIC_08'
      | 'STATIC_09'
      | 'STATIC_10'
      | 'STATIC_11'
      | 'STATIC_12'];
    sessionTitle: string;
  };
  CopingCards: RoundedSectionParams;
  EditCopingCards: { copingCardIndex?: NumberParam };
  EditLessonsLearned: undefined;
  CreateTestUser: undefined;
  TermsAndPrivacy: undefined;
  Welcome: undefined;
  ClinicianControlledMyStoryMyPlanPreview: undefined;
  CMSConversationPreview: undefined;
  WebBlocker: undefined;
  LocalAuthenticationPrompt: undefined;
  FinishPatientRegistration: undefined;
  Confidentiality: undefined;
  ThoughtDiary: RoundedSectionParams;
  ThoughtDiaryEntry: { practiceID: GQLUUID };
  NewThoughtDiaryEntry: {
    step: 'spot' | 'test' | 'switch';
    cardStack: BooleanParam;
  };
  EditThoughtDiaryEntry: {
    practiceID: GQLUUID;
    step: 'spot' | 'test' | 'switch';
    cardStack: BooleanParam;
  };
  Relaxation: RoundedSectionParams;
  QuizSet: QuizSetParams;
  TestWidget: {};
  TestArtifactResult: {
    artifactName: ArtifactScreen;
    // JSON.parse to get params object back
    params?: string;
    _artifactResult?: InputArtifactResult;
  };
  TestIntl: undefined;
} & Pick<
  CoreRootStackParamList,
  'Conversation' | 'ContactsPicker' | 'MediaPicker' | 'AccountSettings'
> &
  StaticRootStackParamList &
  AuthParamList &
  ActivityDiaryStackParamList &
  HopeKitStackParamList &
  SleepDiaryStackParamList &
  MyPlanStackParamList;

/* Keep in sync with artifactName from ChatInputArtifactProps */
export type ArtifactScreen =
  | 'ActivityDiary'
  | 'CopingCards'
  | 'HopeKit'
  | 'MyPlanReview'
  | 'QuizSet'
  | 'Relaxation'
  | 'RiskCurve'
  | 'SleepDiary'
  | 'SoloMyPlan'
  | 'SoloRiskCurve'
  | 'SoloSuicideMode'
  | 'SuicideMode'
  | 'TestWidget'
  | 'ThoughtDiary';

export type StackScreenRouteProp<T extends keyof RootStackParamList> = RouteProp<
  RootStackParamList,
  T
>;
export type StackScreenProps<T extends keyof RootStackParamList, Other extends object = {}> = {
  navigation: StackNavigationProp<RootStackParamList, T>;
  route: StackScreenRouteProp<T>;
} & Other;

export type TabScreenProps<T extends keyof TabParamList, Other extends object = {}> = {
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<TabParamList, T>,
    StackNavigationProp<RootStackParamList>
  >;
  route: RouteProp<TabParamList, T>;
} & Other;

export type CMSStackParamList = {
  CMSApps: undefined;
  CMSNewApp: undefined;
  CMSEditApp: { appID: GQLUUID };
  CMSNewVariant: { appID: GQLUUID };
  CMSEditVariant: { appID: GQLUUID; variantID: GQLUUID };
  CMSEditVariantGroup: { appID: GQLUUID; variantID: GQLUUID; groupID: GQLUUID };
  CMSSessions: { appID: GQLUUID; variantID: GQLUUID };
  CMSEditSession: { appID: GQLUUID; variantID: GQLUUID; sessionID: GQLUUID };
  CMSNewSession: { appID: GQLUUID; variantID: GQLUUID };
  CMSSessionContent: { appID: GQLUUID; variantID: GQLUUID; sessionID: GQLUUID };
  CMSQuizSetCollections: undefined;
  CMSSessionQuizSetCollections: { appID: GQLUUID; variantID: GQLUUID; sessionID: GQLUUID };
  CMSQuizSetCollection: { quizSetCollectionID: GQLUUID };
};

export type CMSScreenProps<T extends keyof CMSStackParamList, Other extends object = {}> = {
  navigation: StackNavigationProp<CMSStackParamList, T>;
  route: RouteProp<CMSStackParamList, T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends RootStackParamList {}
  }
}
