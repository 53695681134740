{
  "ID": "lessonLearned",
  "type": "Seq",
  "children": [
    {
      "ID": "startStore",
      "type": "Store",
      "param": {
        "context": "{{sessionName}}",
        "key": "lessonLearned",
        "target": {
          "ID": "start",
          "type": "Reduce",
          "param": "ReduceLast",
          "children": [
            {
              "ID": "prompt",
              "type": "ChatText",
              "props": {
                "text": [
                  "Type what you have learned today.",
                  "We will store it in 'Lessons Learned' in your profile section."
                ]
              }
            },
            {
              "ID": "entry",
              "type": "InputText",
              "props": {},
              "dir": {
                "echo": true
              }
            }
          ]
        }
      }
    },
    {
      "ID": "after-input",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! Remember you can always edit your 'Lessons Learned' in your profile section."
        ]
      }
    }
  ]
}
