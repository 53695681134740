{
  "ID": "setup",
  "type": "Seq",
  "children": [
    {
      "ID": "setBotName",
      "type": "Arg",
      "param": {
        "key": "botName",
        "func": "ArgAlways",
        "value": "Aviva"
      }
    },
    {
      "ID": "write-progress-setup",
      "type": "WriteProgress",
      "props": {
        "progress": "started"
      }
    }
  ]
}
