import { captureMessage, withScope } from '@sentry/core';

import { Envelopes } from './types';
import { validateEnvelope } from './validateEnvelope';

export function filterAndWarnInvalidEnvelopes(e: Envelopes): boolean {
  const v = validateEnvelope(e);
  if (!v.valid) {
    const extra = { envelope: e, errors: v.errors };
    console.warn('Envelope is invalid', extra);
    withScope((scope) => {
      scope.setExtras(extra);
      captureMessage('Envelope is invalid', 'warning');
    });
  }
  return v.valid;
}
