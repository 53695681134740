import { useEffect, useState } from 'react';

import { PillGroup } from '@oui/app-core/src/components/PillGroup';
import { RatingGraph } from '@oui/app-core/src/components/RatingGraph';
import { View } from '@oui/app-core/src/components/View';
import { usePracticeRatings } from '@oui/app-core/src/hooks/practices';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { PracticeType, RatingType } from '@oui/lib/src/types/graphql.generated';

import { useRelaxContext } from '../RelaxContext';

type RelaxMoodRatingsProps = {};

/**
 * Loads and renders mood ratings for relax practices
 */
export const RelaxMoodRatings: React.FC<RelaxMoodRatingsProps> = ({}) => {
  const { $t } = useI18n();
  const { theme } = useTheme();
  const relaxTheme = useRelaxContext();
  const [timeScale, setTimeScale] = useState<'WEEK' | 'MONTH' | 'YEAR'>('WEEK');
  const payload = usePracticeRatings({
    practiceType: PracticeType.RELAX_DIARY_ENTRY,
    ratingType: RatingType.RATING_BEFORE,
    timeScale,
  });
  const afterPayload = usePracticeRatings({
    practiceType: PracticeType.RELAX_DIARY_ENTRY,
    ratingType: RatingType.RATING_AFTER,
    timeScale,
  });

  const beforeRefetch = payload.refetch;
  const afterRefetch = afterPayload.refetch;

  useEffect(() => {
    afterRefetch();
    beforeRefetch();
  }, [afterRefetch, beforeRefetch]);

  return (
    <>
      <PillGroup
        aria-label={$t({
          id: 'Relax_ratingTimescaleAccessibilityLabel',
          defaultMessage: 'Chart time scale',
        })}
        value={timeScale}
        onChangeValue={setTimeScale}
        items={[
          {
            label: $t({ id: 'Relax_ratingWeekLabel', defaultMessage: 'Week' }),
            value: 'WEEK',
          },
          {
            label: $t({ id: 'Relax_ratingMonthLabel', defaultMessage: 'Month' }),
            value: 'MONTH',
          },
          {
            label: $t({ id: 'Relax_ratingYearLabel', defaultMessage: 'Year' }),
            value: 'YEAR',
          },
        ]}
      />
      <View style={{ height: 300, marginTop: 20 }}>
        <RatingGraph
          aria-label={$t({
            id: 'Relax_ratingGraphAccessibilityLabel',
            defaultMessage:
              'A graph of mood ratings before and after relaxation and mindfulness exercises.',
          })}
          emptyText={$t({
            id: 'Relax_ratingGraphNoData',
            defaultMessage: 'Finish an exercise to see ratings here',
          })}
          showDots={timeScale === 'WEEK'}
          xLabels={payload.xLabels}
          ratings={[payload.data, afterPayload.data]}
          legend={[
            {
              color: theme.color.gray600,
              text: $t({ id: 'Relax_ratingGraph_legendBefore', defaultMessage: 'Before' }),
            },
            {
              color: relaxTheme.accentColor,
              text: $t({ id: 'Relax_ratingGraph_legendAfter', defaultMessage: 'After' }),
            },
          ]}
          yAxisLabel={$t({
            id: 'Relax_ratingGraph_yAxisLabel',
            defaultMessage: 'Mood ratings',
          })}
          xAxisLabel={payload.xAxisLabel}
        />
      </View>
    </>
  );
};
