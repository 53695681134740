{
  "ID": "v2-session-06",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "SPOT_IT"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great to see you again!",
          "Hope you're having an ok day.",
          "As always, let's start by looking at what you've been able to practice since our last session."
        ]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["SLEEP_DIARY_ENTRY_MORNING", "SLEEP_DIARY_ENTRY_NIGHT"],
                "previousSession": "SLEEP"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "SLEEP"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to use the sleep diary since your last session?",
                          "Not to worry. Improving sleep is very helpful so I'll make sure to put it in your practice to do list."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I saw you used your sleep diary. Great job!",
                    "How did you like using it?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "I dont get it",
                        "2": "Not sure",
                        "3": "OK",
                        "4": "I get it",
                        "5": "I totally get it"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "Practice makes perfect.",
                    "I'll make sure there are reminders to use it in the coming weeks."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Let's move to today's session.",
          "Over the next three sessions, we're going to work on skills to deal with false or unhelpful thoughts.",
          "Today, we'll start with a skill called 'Spot It'."
        ]
      }
    },
    {
      "ID": "intro-what-is-spotit-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-what-is-spotit-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "What will I be spotting?",
              "1": "Continue"
            }
          }
        },
        "map": {}
      }
    },
    {
      "ID": "intro-explain-spotit",
      "type": "ChatText",
      "props": {
        "text": [
          "The goal will be to learn to spot your negative thoughts. It can be hard to spot these thoughts.",
          "This is because we are not aware of them or we often have many thoughts at the same time.",
          "Today, we will learn how to notice your negative thoughts and how to separate them out."
        ]
      }
    },
    {
      "ID": "intro-learn-more-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-learn-more-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "Why am I spotting negative thoughts?",
              "1": "Continue"
            }
          }
        },
        "map": {}
      }
    },
    {
      "ID": "intro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "Thoughts are powerful. And sometimes they are not correct. Negative thoughts can make you feel down and discouraged, and can lead to thoughts about suicide.",
          "If we spot these kinds of thoughts, we can check if they are really true. Some part of them may be true, but often they are not.",
          "Thinking negative thoughts about yourself can become a habit and lead to you feeling that life is unbearable.",
          "This, in turn, can result in thoughts of suicide."
        ]
      }
    },
    {
      "ID": "intro-pause-4",
      "type": "InputButton",
      "props": {
        "label": "How do I learn how to Spot It?"
      }
    },
    {
      "ID": "intro-5",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SPOT_IT_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SPOT_IT_VOICEOVER"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "quiz-question-2-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "Reducing your suicide risk can be done by noticing your negative thoughts and then changing these thoughts.",
          "Spotting your thoughts is the first step in this process.",
          "Let's now watch someone talking about their experience with 'Spot It'."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SPOT_IT_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SPOT_IT_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "spotit-vignette-apply",
      "type": "ChatText",
      "props": {
        "text": ["Let's see how someone uses the app to practice 'Spot it'."]
      }
    },
    {
      "ID": "prompt-start-apply-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SPOT_IT_APPLY_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "apply-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "apply-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "apply-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "SPOT_IT_APPLY_VIGNETTE"
        },
        "linkText": "Apply Spot It"
      }
    },
    {
      "ID": "transition-to-spotit-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "You've now seen how to use the Thought Diary to 'Spot It'.",
          "The Thought Diary has two other parts ('Test It' and 'Switch It') that will unlock when you do the next two sessions.",
          "For now, let's start using your Thought Diary to 'Spot It'."
        ]
      }
    },
    {
      "ID": "prompt-start-spotit",
      "type": "InputArtifact",
      "props": {
        "artifactName": "ThoughtDiary",
        "buttonText": "Try Thought Diary"
      },
      "param": {
        "retryText": [
          "Looks like no unhelpful thoughts were spotted.",
          "I know it can be hard to think of something at first. It doesn't have to be perfect.",
          "Try again"
        ],
        "retryButton": "Spot thought",
        "skipButton": "I'll do it later",
        "skipNode": {
          "ID": "spotit-skip",
          "type": "ChatText",
          "props": {
            "text": ["Got it. Let's wrap up for today."]
          }
        },
        "completeNode": {
          "ID": "spotit-complete",
          "type": "Seq",
          "children": [
            {
              "ID": "spotit-preview",
              "type": "ChatActivityPreview",
              "props": {
                "artifactName": "ThoughtDiary",
                "linkText": "View Thought Diary"
              }
            },
            {
              "ID": "spotit-complete-text",
              "type": "ChatText",
              "props": {
                "text": [
                  "Good job. You've now learned some ways to spot negative thoughts.",
                  "Spotting these thoughts gives you a chance to test, and maybe switch, them."
                ]
              }
            },
            {
              "ID": "spotit-complete-pause",
              "type": "InputButton",
              "props": {
                "label": "Got it"
              }
            }
          ]
        }
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of every session, there are two final tasks.",
          "First, let's add to your 'Lessons Learned'."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what you've learned.",
          "As I always say, using what you have learned is the best way to get benefit from this app.",
          "To help, set how many times this week you think you'll try to practice spotting thoughts?"
        ]
      }
    },
    {
      "ID": "practice-frequency-store",
      "type": "Store",
      "param": {
        "key": "practiceFrequency",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputSlider",
          "props": {
            "minValue": 1,
            "maxValue": 7,
            "step": 1,
            "value": 3,
            "labels": {}
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "This week, I'll make sure that spotting thoughts is on your to do list. You should try to do this every time you have a negative reaction to something that has occured.",
          "I'll also be sending you reminders to do the other items on your to do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "Well done on finishing.",
          "I hope you're able to do some 'spot it' practice in the coming days.",
          "Take care."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
