import differenceInMinutes from 'date-fns/differenceInMinutes';
import startOfWeek from 'date-fns/startOfWeek';
import { IntlShape } from 'react-intl';

import {
  getDefaultSleepDiaryEntry,
  PracticeFragment,
  SleepDiaryConfig,
  SleepDiaryConfigInfluencerFrequency,
  SleepDiaryEntryFragment,
} from '@oui/app-core/src/hooks/practices';
import { formatGQLDate, parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { ResultOf } from '@oui/lib/src/graphql/tada';
import { recordKeys } from '@oui/lib/src/recordKeys';
import {
  ProductVariant,
  SleepDiaryEntryDuration,
  SleepDiaryEntryTimeOfDay,
} from '@oui/lib/src/types/graphql.generated';
import { GQLDate } from '@oui/lib/src/types/scalars';

export type SleepDiaryEntryType = {
  readonly sleepDiaryEntry: ResultOf<typeof SleepDiaryEntryFragment>;
} & Extract<ResultOf<typeof PracticeFragment>, { __typename: 'SleepDiaryEntryPractice' }>;

type SleepTip = {
  text: string;
};

function getSleepConfigTips(
  $t: IntlShape['$t'],
  config: SleepDiaryConfig,
  { productVariant }: { productVariant: ProductVariant },
): SleepTip[] {
  let tips = [];

  const entry = getDefaultSleepDiaryEntry(config, new Date());
  const diffHours =
    differenceInMinutes(parseGQLDateTime(entry.endTime), parseGQLDateTime(entry.startTime)) / 60;
  if (diffHours < 6) {
    tips.push({
      text: $t({
        id: 'SleepConfigTips_sufficientSleep',
        defaultMessage: 'To be at your best, try to get about 7-8 hours of sleep a night.',
      }),
    });
  } else if (diffHours > 8) {
    tips.push({
      text: $t({
        id: 'SleepConfigTips_avoidExtraTimeInBed',
        defaultMessage: "Go to bed only when you're sleepy and get out of bed when you wake up.",
      }),
    });
  }

  if (config.sleepDelay && config.sleepDelay !== SleepDiaryEntryDuration.DURATION_LESS_THAN_15) {
    tips.push({
      text: $t({
        id: 'SleepConfigTips_onlySleepInBed',
        defaultMessage: "Get out of bed when you can't fall asleep in 15 minutes.",
      }),
    });
  }

  if (config.wakeupCount !== SleepDiaryConfigInfluencerFrequency.Never) {
    tips.push({
      text: "If you're awake for more than 15 minutes during the night, get out of bed.",
    });
  }
  if (config.caffeine !== SleepDiaryConfigInfluencerFrequency.Never) {
    tips.push({
      text: $t({
        id: 'SleepConfigTips_avoidCaffeineAtNight',
        defaultMessage: 'Caffeine can keep you up at night. Try to avoid it after 3pm.',
      }),
    });
  }
  if (config.alcohol !== SleepDiaryConfigInfluencerFrequency.Never) {
    tips.push({
      text:
        productVariant === ProductVariant.AVIVA_ADOLESCENT
          ? $t({
              id: 'SleepConfigTips_avoidAlcoholAtNightAdolescent',
              defaultMessage:
                'Alcohol can keep you up at night. Try to avoid drinking alcohol, especially close to bedtime.',
            })
          : $t({
              id: 'SleepConfigTips_avoidAlcoholAtNight',
              defaultMessage:
                'Alcohol can keep you up at night. Try not to drink it close to bedtime.',
            }),
    });
  }
  if (config.tobacco !== SleepDiaryConfigInfluencerFrequency.Never) {
    tips.push({
      text:
        productVariant === ProductVariant.AVIVA_ADOLESCENT
          ? $t({
              id: 'SleepConfigTips_avoidTobaccoAtNightAdolescent',
              defaultMessage:
                'The nicotine in tobacco keeps you up. Try to avoid smoking, especially close to bedtime.',
            })
          : $t({
              id: 'SleepConfigTips_avoidTobaccoAtNight',
              defaultMessage:
                'The nicotine in tobacco keeps you up. Try not to smoke close to bedtime.',
            }),
    });
  }
  if (config.napping !== SleepDiaryConfigInfluencerFrequency.Never) {
    tips.push({
      text: $t({
        id: 'SleepConfigTips_avoidNaps',
        defaultMessage:
          'Try to avoid naps. If you must nap, limit it to 20 minutes and not after 3pm.',
      }),
    });
  }
  if (config.exercise !== SleepDiaryConfigInfluencerFrequency.Regularly) {
    tips.push({
      text: $t({
        id: 'SleepConfigTips_exerciseRegularly',
        defaultMessage:
          'Exercising regularly aids sleep. Aim for 30 mins in the afternoon or evening.',
      }),
    });
  } else {
    tips.push({ text: "It's great you’re already exercising regularly. Keep it up" });
  }
  if (config.medicine !== SleepDiaryConfigInfluencerFrequency.Never) {
    tips.push({
      text: $t({
        id: 'SleepConfigTips_avoidMedications',
        defaultMessage:
          'Try to limit sleep medications. These should only be used for a short time.',
      }),
    });
  }
  if (config.deviceInBed !== SleepDiaryConfigInfluencerFrequency.Never) {
    tips.push({
      text: $t({
        id: 'SleepConfigTips_avoidDeviceInBed',
        defaultMessage: 'Screens emit blue light that keeps you up. Avoid using devices in bed.',
      }),
    });
  }

  return [
    ...tips,
    {
      text: $t({
        id: 'SleepConfigTips_avoidLargeMealsAtNight',
        defaultMessage:
          'Eating a big meal before bed makes it hard to sleep. Try to eat earlier or less.',
      }),
    },
    {
      text: $t({
        id: 'SleepConfigTips_idealSleepEnvironment',
        defaultMessage: 'Having a dark, quiet, and relaxing bedroom can help you sleep.',
      }),
    },
    {
      text: $t({
        id: 'SleepConfigTips_comfortableSleepEnvironment',
        defaultMessage: 'Make your bedroom comfortable, like having a good pillow and mattress.',
      }),
    },
  ];
}

export function getSleepDiaryTips({
  $t,
  config,
  entries,
  productVariant,
}: {
  $t: IntlShape['$t'];
  config: SleepDiaryConfig;
  entries: ReadonlyArray<SleepDiaryEntryType>;
  productVariant: ProductVariant;
}): SleepTip[] {
  const stats = getSleepStats({ config, entries });

  if (stats.length <= 2) {
    return getSleepConfigTips($t, config, { productVariant });
  }

  // TODO check if the previous weeks are actually past two weeks
  const lastWeek = stats[1];
  const weekBeforeLast = stats[2];

  // @ts-expect-error
  const deltas: Record<
    keyof (typeof lastWeek)['data'],
    { sum: number; count: number; avg: number }
  > = {};
  recordKeys(lastWeek.data).forEach((key) => {
    deltas[key] = {
      sum: lastWeek.data[key].sum - weekBeforeLast.data[key].sum,
      count: lastWeek.data[key].count - weekBeforeLast.data[key].count,
      avg: lastWeek.data[key].avg - weekBeforeLast.data[key].avg,
    };
  });

  let tips: SleepTip[] = [];

  // Stopping negative behavior
  // https://docs.google.com/document/d/19mfqhkw0X2zrvC5kLoVPpk-5X7wRkNvJQp2Rqz55fsQ/edit#heading=h.izymm3agb7mn
  // Encouraging positive behavior
  // https://docs.google.com/document/d/19mfqhkw0X2zrvC5kLoVPpk-5X7wRkNvJQp2Rqz55fsQ/edit#heading=h.gh4pggrh05du
  if (deltas.caffeine.sum > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_caffeineIncrease',
          defaultMessage:
            'You had {value, plural, one{1 more drink} other{# more drinks}} of caffeine last week than the week before. Try to drink less this week.',
        },
        { value: deltas.caffeine.sum },
      ),
    });
  } else if (deltas.caffeine.sum < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_caffeineDecrease',
          defaultMessage:
            'You only had {value, plural, one{1 caffeinated drink} other{# caffeinated drinks}} last week. Good job!',
        },
        { value: lastWeek.data.caffeine.sum },
      ),
    });
  }

  if (deltas.caffeineAtNight.count > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_caffeineAtNightIncrease',
          defaultMessage:
            'You drank caffeine at night {value, plural, one{1 more time} other{# more times}} last week. Try to avoid caffeine, especially so close to bedtime.',
        },
        {
          value: lastWeek.data.caffeineAtNight.count,
        },
      ),
    });
  } else if (deltas.caffeineAtNight.count < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_caffeineAtNightDecrease',
          defaultMessage:
            'You had caffeine in the evening or at night {value, plural, one{1 time} other{# times}} less last week. Great!',
        },
        {
          value: Math.abs(deltas.caffeineAtNight.count),
        },
      ),
    });
  }

  if (deltas.alcohol.sum > 0) {
    tips.push({
      text:
        productVariant === ProductVariant.AVIVA_ADOLESCENT
          ? $t(
              {
                id: 'SleepTips_alcoholIncreaseAdolescent',
                defaultMessage:
                  'You had {value, plural, one{1 more drink} other{# more drinks}} of alcohol last week than the week before. Try to avoid heavy drinking.',
              },
              { value: deltas.alcohol.sum },
            )
          : $t(
              {
                id: 'SleepTips_alcoholIncrease',
                defaultMessage:
                  'You had {value, plural, one{1 more drink} other{# more drinks}} of alcohol last week than the week before. Try to drink less this week.',
              },
              { value: deltas.alcohol.sum },
            ),
    });
  } else if (deltas.alcohol.sum < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_alcoholDecrease',
          defaultMessage:
            'You only had {value, plural, one{1 alcoholic drink} other{# alcoholic drinks}} last week. Nice going!',
        },
        { value: lastWeek.data.alcohol.sum },
      ),
    });
  }

  if (deltas.alcoholAtNight.count > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_alcoholAtNightIncrease',
          defaultMessage:
            'You drank alcohol at night {value, plural, one{1 more time} other{# more times}} last week. Try to avoid drinking so close to bedtime.',
        },
        {
          value: lastWeek.data.alcoholAtNight.count,
        },
      ),
    });
  } else if (deltas.alcoholAtNight.count < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_alcoholAtNightDecrease',
          defaultMessage:
            'You had alcohol at night {value, plural, one{1 time} other{# times}} less last week. Great job!',
        },
        {
          value: Math.abs(deltas.alcoholAtNight.count),
        },
      ),
    });
  }

  if (deltas.tobacco.sum > 0) {
    tips.push({
      text:
        productVariant === ProductVariant.AVIVA_ADOLESCENT
          ? $t(
              {
                id: 'SleepTips_tobaccoIncreaseAdolescent',
                defaultMessage:
                  'You smoked {value, plural, one{1 more cigarette} other{# more cigarettes}} last week than the week before. Try to avoid smoking this week.',
              },
              { value: deltas.tobacco.sum },
            )
          : $t(
              {
                id: 'SleepTips_tobaccoIncrease',
                defaultMessage:
                  'You smoked {value, plural, one{1 more cigarette} other{# more cigarettes}} last week than the week before. Try to smoke less this week.',
              },
              { value: deltas.tobacco.sum },
            ),
    });
  } else if (deltas.tobacco.sum < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_tobaccoDecrease',
          defaultMessage:
            'You only smoked {value, plural, one{1 cigarette} other{# cigarettes}} last week. Good going!',
        },
        { value: lastWeek.data.tobacco.sum },
      ),
    });
  }

  if (deltas.tobaccoAtNight.count > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_tobaccoAtNightIncrease',
          defaultMessage:
            'You smoked at night {value, plural, one{1 more time} other{# more times}} last week. Try to avoid smoking so close to bedtime.',
        },
        {
          value: lastWeek.data.tobaccoAtNight.count,
        },
      ),
    });
  } else if (deltas.tobaccoAtNight.count < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_tobaccoAtNightDecrease',
          defaultMessage:
            'You smoked at night {value, plural, one{1 time} other{# times}} less last week. That’s the right track!',
        },
        {
          value: Math.abs(deltas.tobaccoAtNight.count),
        },
      ),
    });
  }

  if (deltas.napping.avg > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_nappingIncrease',
          defaultMessage:
            'You napped an average of {value, plural, one{1 minute} other{# minutes}} last week. {delta} more than the week before. Try to take fewer naps.',
        },
        {
          value: lastWeek.data.napping.avg,
          delta: deltas.napping.avg,
        },
      ),
    });
  }
  if (deltas.napping.count < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_nappingDecrease',
          defaultMessage:
            'You only napped {value, plural, one{1 time} other{# times}} last week. You’re on the right path!',
        },
        {
          value: lastWeek.data.napping.count,
        },
      ),
    });
  }
  if (deltas.nappingAtNight.count > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_nappingAtNightIncrease',
          defaultMessage:
            'You napped {value, plural, one{1 time} other{# times}} during the evening or night last week. Try to nap less and avoid naps after 3pm.',
        },
        {
          value: lastWeek.data.nappingAtNight.count,
        },
      ),
    });
  }
  if (deltas.napping.avg < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_nappingDurationDecrease',
          defaultMessage:
            'You napped {value, plural, one{1 minute} other{# minutes}} less on average last week. Right on!',
        },
        {
          value: deltas.napping.avg,
        },
      ),
    });
  }

  if (deltas.exercise.count < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_exerciseDecrease',
          defaultMessage:
            'You exercised {value, plural, one{1 fewer time} other{# fewer times}} last week. Exercising regularly aids sleep. Try getting in 20+ mins a day.',
        },
        {
          value: Math.abs(deltas.exercise.count),
        },
      ),
    });
  } else if (deltas.exercise.count > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_exerciseIncrease',
          defaultMessage:
            'You exercised {value, plural, one{1 time} other{# times}} last week. Keep it up!',
        },
        {
          value: deltas.exercise.count,
        },
      ),
    });
  }

  if (deltas.medicine.count > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_medicationCountIncrease',
          defaultMessage:
            'You took sleep medication {value, plural, one{1 time} other{# times}} more last week. Try to limit sleep medications.',
        },
        {
          value: deltas.medicine.count,
        },
      ),
    });
  } else if (deltas.medicine.count < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_medicationCountDecrease',
          defaultMessage:
            'You only took sleep medication {value, plural, one{1 time} other{# times}} last week. Super!',
        },
        {
          value: lastWeek.data.medicine.count,
        },
      ),
    });
  }

  if (deltas.medicine.sum > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_medicationIncrease',
          defaultMessage:
            'You took {value, plural, one{1 more pill} other{# more pills}} last week than the week before. Try to limit sleep medications.',
        },
        {
          value: deltas.medicine.sum,
        },
      ),
    });
  } else if (deltas.medicine.sum < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_medicationDecrease',
          defaultMessage:
            'You took {value, plural, one{1 fewer pill} other{# fewer pills}} pills last week. That’s the way!',
        },
        {
          value: deltas.medicine.sum,
        },
      ),
    });
  }

  if (deltas.deviceInBed.count > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_deviceInBedIncrease',
          defaultMessage:
            'You used devices in bed {value, plural, one{1 time} other{# times}} more last week. Try to avoid using devices in bed.',
        },
        {
          value: deltas.deviceInBed.count,
        },
      ),
    });
  } else if (deltas.deviceInBed.count < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_deviceInBedDecrease',
          defaultMessage:
            'You only used devices in bed {value, plural, one{1 time} other{# times}} last week. Great job!',
        },
        {
          value: lastWeek.data.deviceInBed.count,
        },
      ),
    });
  }

  if (deltas.deviceInBed.avg > 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_deviceInBedDurationIncrease',
          defaultMessage:
            'You used devices in bed an average of {value, plural, one{1 min} other{# mins}} last week. {delta} more than the week before. Try to avoid using devices in bed.',
        },
        {
          value: lastWeek.data.deviceInBed.avg,
          delta: deltas.deviceInBed.avg,
        },
      ),
    });
  } else if (deltas.deviceInBed.avg < 0) {
    tips.push({
      text: $t(
        {
          id: 'SleepTips_deviceInBedDurationDecrease',
          defaultMessage:
            'You used devices in bed {value, plural, one{1 fewer minute} other{# fewer minutes}} last week. Well done!',
        },
        {
          value: Math.abs(deltas.deviceInBed.avg),
        },
      ),
    });
  }

  return [...tips, ...getSleepConfigTips($t, config, { productVariant })];
}

function getDurationAsMinutes(duration?: SleepDiaryEntryDuration | null): number {
  if (!duration) return 0;
  switch (duration) {
    case SleepDiaryEntryDuration.DURATION_LESS_THAN_15:
      return 8;
    case SleepDiaryEntryDuration.DURATION_120_TO_180:
      return 150;
    case SleepDiaryEntryDuration.DURATION_15_TO_30:
      return 23;
    case SleepDiaryEntryDuration.DURATION_1_TO_5:
      return 3;
    case SleepDiaryEntryDuration.DURATION_20_TO_45:
      return 30;
    case SleepDiaryEntryDuration.DURATION_30_TO_60:
      return 45;
    case SleepDiaryEntryDuration.DURATION_45_TO_60:
      return 52;
    case SleepDiaryEntryDuration.DURATION_5_TO_10:
      return 7;
    case SleepDiaryEntryDuration.DURATION_60_TO_120:
      return 90;
    case SleepDiaryEntryDuration.DURATION_MORE_THAN_120:
      return 150;
    case SleepDiaryEntryDuration.DURATION_MORE_THAN_180:
      return 210;
    case SleepDiaryEntryDuration.DURATION_MORE_THAN_60:
      return 90;
  }
}

function getSleepStatsForEntries(entries: ReadonlyArray<SleepDiaryEntryType>) {
  const dataPoints = {
    sleepMinutes: { sum: 0, count: 0, avg: 0 },
    sleepDelay: { sum: 0, count: 0, avg: 0 },
    wakeupCount: { sum: 0, count: 0, avg: 0 },
    wakeupDuration: { sum: 0, count: 0, avg: 0 },
    caffeine: { sum: 0, count: 0, avg: 0 },
    caffeineAtNight: { sum: 0, count: 0, avg: 0 },
    alcohol: { sum: 0, count: 0, avg: 0 },
    alcoholAtNight: { sum: 0, count: 0, avg: 0 },
    tobacco: { sum: 0, count: 0, avg: 0 },
    tobaccoAtNight: { sum: 0, count: 0, avg: 0 },
    napping: { sum: 0, count: 0, avg: 0 },
    nappingAtNight: { sum: 0, count: 0, avg: 0 },
    exercise: { sum: 0, count: 0, avg: 0 },
    exerciseAtNight: { sum: 0, count: 0, avg: 0 },
    medicine: { sum: 0, count: 0, avg: 0 },
    deviceInBed: { sum: 0, count: 0, avg: 0 },
  };
  entries.forEach((e) => {
    const sleepDiaryEntry = e.sleepDiaryEntry;
    if (sleepDiaryEntry.startTime && sleepDiaryEntry.endTime) {
      dataPoints.sleepMinutes.count += 1;
      dataPoints.sleepMinutes.sum += differenceInMinutes(
        parseGQLDateTime(e.sleepDiaryEntry.endTime),
        parseGQLDateTime(e.sleepDiaryEntry.startTime),
      );
    }

    if (typeof sleepDiaryEntry.wakeupCount === 'number' && sleepDiaryEntry.wakeupCount !== -1) {
      dataPoints.wakeupCount.count += 1;
      dataPoints.wakeupCount.sum += sleepDiaryEntry.wakeupCount;
    }
    if (sleepDiaryEntry.wakeupDuration) {
      dataPoints.wakeupDuration.count += 1;
      dataPoints.wakeupDuration.sum += getDurationAsMinutes(sleepDiaryEntry.wakeupDuration) ?? 0;
    }
    if (sleepDiaryEntry.sleepDelay) {
      dataPoints.sleepDelay.count += 1;
      dataPoints.sleepDelay.sum += getDurationAsMinutes(sleepDiaryEntry.sleepDelay) ?? 0;
    }

    (['caffeine', 'alcohol', 'tobacco'] as const).map((key) => {
      const influencer = sleepDiaryEntry[key];
      if (influencer?.occurred) {
        dataPoints[key].count += 1;
        dataPoints[key].sum += influencer.count ?? 0;
        if (influencer.timesOfDay.includes(SleepDiaryEntryTimeOfDay.NIGHT)) {
          dataPoints[`${key}AtNight` as const].count += 1;
          dataPoints[`${key}AtNight` as const].sum += influencer.count ?? 0;
        }
      }
    });
    if (sleepDiaryEntry.napping?.occurred) {
      dataPoints.napping.count += 1;
      const duration = getDurationAsMinutes(sleepDiaryEntry.napping.duration) ?? 0;
      dataPoints.napping.sum += duration;
      if (sleepDiaryEntry.napping.timesOfDay.includes(SleepDiaryEntryTimeOfDay.NIGHT)) {
        dataPoints.nappingAtNight.count += 1;
        dataPoints.nappingAtNight.sum += duration;
      }
    }
    if (sleepDiaryEntry.exercise?.occurred) {
      dataPoints.exercise.count += 1;
      const duration = getDurationAsMinutes(sleepDiaryEntry.exercise.duration) ?? 0;
      dataPoints.exercise.sum += duration;
      if (sleepDiaryEntry.exercise.timesOfDay.includes(SleepDiaryEntryTimeOfDay.NIGHT)) {
        dataPoints.exerciseAtNight.count += 1;
        dataPoints.exerciseAtNight.sum += duration;
      }
    }
    if (sleepDiaryEntry.deviceInBed?.occurred) {
      dataPoints.deviceInBed.count += 1;
      dataPoints.deviceInBed.sum += getDurationAsMinutes(sleepDiaryEntry.deviceInBed.duration) ?? 0;
    }
  });

  recordKeys(dataPoints).forEach((key) => {
    if (dataPoints[key].count) {
      dataPoints[key].avg = dataPoints[key].sum / dataPoints[key].count;
    }
  });

  return dataPoints;
}

export type SleepStat = { date: GQLDate; data: ReturnType<typeof getSleepStatsForEntries> };

export function getSleepStats({
  config,
  entries,
}: {
  config: SleepDiaryConfig;
  entries: ReadonlyArray<SleepDiaryEntryType>;
}): SleepStat[] {
  // const weekStart = startOfWeek(new Date());

  // const lastEntry = entries[entries.length - 1];

  //   if (
  //     !lastEntry ||
  //     differenceInWeeks(weekStart, startOfWeek(parseISO(lastEntry.practiceValues.date))) < 2
  //   ) {
  //     return null;
  //   }

  const entriesByWeek: Record<string, SleepDiaryEntryType[]> = {};
  entries.forEach((e) => {
    const entryStartOfWeek = formatGQLDate(startOfWeek(parseGQLDateTime(e.practiceValues.date)));
    if (!entriesByWeek[entryStartOfWeek]) entriesByWeek[entryStartOfWeek] = [];
    entriesByWeek[entryStartOfWeek].push(e);
  });

  const weekData = Object.entries(entriesByWeek).map(([weekDate, weekEntries]) => {
    return {
      date: weekDate as GQLDate,
      data: getSleepStatsForEntries(weekEntries),
    };
  });

  return weekData;
}
