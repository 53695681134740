import { useNavigation } from '@react-navigation/native';
import { FunctionComponent } from 'react';
import { SvgProps } from 'react-native-svg';

import { Button } from '@oui/app-core/src/components/Button';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Environment, environment } from '@oui/app-core/src/constants';
import { usePatientID, useSetMyStoryMyPlanComplete } from '@oui/app-core/src/hooks/useComposition';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';

import OverviewMyPlan from '../assets/OverviewMyPlanSmall.svg';
import OverviewMyStory from '../assets/OverviewMyStorySmall.svg';
import OverviewSuicidalMode from '../assets/OverviewSuicidalModeSmall.svg';
import { Icon } from '../components/Icon';
import { PatientMyStoryMyPlanContainer } from '../components/PatientMyStoryMyPlanContainer';
import { MyStoryMyPlanScreenProps } from '../types/navigation';

function Card(props: {
  asset: FunctionComponent<SvgProps & { 'aria-label': string | undefined }>;
  complete: boolean;
  index: number;
  subtitle: string;
  title: string;
}) {
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const Asset = props.asset;

  return (
    <View row={isMobile}>
      <View
        style={{
          zIndex: 1,
          transform: [isMobile ? { translateX: 0 } : { translateY: 20 }],
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          height: 40,
          borderRadius: 20,
          backgroundColor: props.complete ? theme.color.accent100 : theme.color.dark,
        }}
      >
        {props.complete ? (
          <Icon name="check" size={24} color={theme.color.success} />
        ) : (
          <Text text={`${props.index}`} color="white" weight="bold" />
        )}
      </View>
      {isMobile ? (
        <View
          style={{
            overflow: 'hidden',
            marginLeft: -20,
            borderRadius: 20,
            alignSelf: 'stretch',
            flex: 1,
            backgroundColor: props.complete ? '#F4F8F5' : theme.color.gray800,
            padding: 26,
            paddingHorizontal: 30,
          }}
        >
          <View
            style={{
              alignItems: 'flex-end',
              bottom: -5,
              justifyContent: 'flex-end',
              position: 'absolute',
              right: -20,
            }}
          >
            <Asset aria-label={undefined} />
          </View>
          <View>
            <Text text={props.title} weight="bold" size={17} />
            <Text text={props.subtitle} size={17} style={{ maxWidth: width - 200 }} />
          </View>
        </View>
      ) : (
        <View
          style={{
            borderRadius: 20,
            width: 205,
            height: 336,
            backgroundColor: props.complete ? '#F4F8F5' : theme.color.gray800,
          }}
        >
          <View spacing={24} style={{ marginVertical: 48, alignSelf: 'center', padding: 20 }}>
            <Text text={props.title} weight="bold" size={21} style={{ textAlign: 'center' }} />
            <Text text={props.subtitle} size={21} style={{ textAlign: 'center' }} />
          </View>
          <View style={{ flex: 1 }} />
          <View style={{ alignItems: 'flex-end' }}>
            <Asset width="100%" aria-label={undefined} />
          </View>
        </View>
      )}
    </View>
  );
}

type HomePath = 'home' | 'homeStatic';

export function PatientMyStoryMyPlanOverview({
  testID,
  isComplete,
}: {
  testID?: string;
  isComplete?: boolean;
}) {
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const myStoryMyPlanComplete = !!isComplete;
  const { $t } = useI18n();
  const { navigate } =
    useNavigation<MyStoryMyPlanScreenProps<'MyStoryMyPlanOverview'>['navigation']>();
  const [complete] = useSetMyStoryMyPlanComplete();
  const patientID = usePatientID();
  const { data: user } = useCurrentUser();

  let homePath: HomePath = 'home' as const;
  if (user?.currentUser?.user?.__typename === 'Patient') {
    if (user?.currentUser.user.productStatic) {
      homePath = 'homeStatic' as const;
    }
  }

  return (
    <PatientMyStoryMyPlanContainer heading="" testID={testID ?? 'PatientMyStoryMyPlanOverview'}>
      <View row={!isMobile} spacing={16} style={{ paddingHorizontal: 20 }}>
        <Text
          text={
            myStoryMyPlanComplete
              ? $t({
                  id: 'PatientMyStoryMyPlanOverview_headingComplete',
                  defaultMessage: "That's all for today",
                })
              : $t({
                  id: 'PatientMyStoryMyPlanOverview_heading',
                  defaultMessage: 'Overview',
                })
          }
          style={{ alignSelf: 'center', marginBottom: 12 }}
          size={21}
          weight="bold"
          color={theme.color.dark}
        />
        <Card
          complete={myStoryMyPlanComplete}
          title={$t({ id: 'PatientMyStoryMyPlanOverview_mystoryTitle', defaultMessage: 'MyStory' })}
          subtitle={
            myStoryMyPlanComplete
              ? $t({
                  id: 'PatientMyStoryMyPlanOverview_mystoryDescriptionComplete',
                  defaultMessage: 'Tell the story of your recent suicide crisis',
                })
              : $t({
                  id: 'PatientMyStoryMyPlanOverview_mystoryDescription',
                  defaultMessage: 'Tell the story of your recent suicide crisis',
                })
          }
          index={1}
          asset={OverviewMyStory}
        />
        <Card
          complete={myStoryMyPlanComplete}
          title={$t({
            id: 'PatientMyStoryMyPlanOverview_riskCurveTitle',
            defaultMessage: 'MyRiskCurve',
          })}
          subtitle={
            myStoryMyPlanComplete
              ? $t({
                  id: 'PatientMyStoryMyPlanOverview_riskCurveDescriptionComplete',
                  defaultMessage: 'Learn how suicide risk rises and falls',
                })
              : $t({
                  id: 'PatientMyStoryMyPlanOverview_riskCurveDescription',
                  defaultMessage: 'Learn how suicide risk rises and falls',
                })
          }
          index={2}
          asset={OverviewSuicidalMode}
        />
        <Card
          complete={myStoryMyPlanComplete}
          title={$t({ id: 'PatientMyStoryMyPlanOverview_myplanTitle', defaultMessage: 'MyPlan' })}
          subtitle={
            myStoryMyPlanComplete
              ? $t({
                  id: 'PatientMyStoryMyPlanOverview_myplanDescriptionComplete',
                  defaultMessage: 'Create a plan to keep you safe during a crisis',
                })
              : $t({
                  id: 'PatientMyStoryMyPlanOverview_myplanDescription',
                  defaultMessage: 'Create a plan to keep you safe during a crisis',
                })
          }
          index={3}
          asset={OverviewMyPlan}
        />
      </View>
      {isComplete ? (
        <Button
          text={$t({
            id: 'PatientMyStoryMyPlanOverview_goHomeButton',
            defaultMessage: 'Go to homepage',
          })}
          testID="PatientMyStoryMyPlanOverview_goHomeButton"
          icon="home"
          alignSelf="center"
          onPress={() => navigate(homePath as any)} // eslint-disable-line
          style={{ marginVertical: 40 }}
        />
      ) : environment !== Environment.PRODUCTION ? (
        <Button
          text="Skip (Testing only)"
          alignSelf="center"
          onPress={async () => {
            await complete({ variables: { patientID: patientID! } });
            navigate(homePath as any); // eslint-disable-line
          }}
          style={{ marginVertical: 40 }}
        />
      ) : null}
    </PatientMyStoryMyPlanContainer>
  );
}
