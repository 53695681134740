import isObject from 'lodash/isObject';
import omit from 'lodash/omit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function omitDeep(obj: any, keys: string[]): any {
  if (Array.isArray(obj)) {
    return obj.map((innerObj) => omitDeep(innerObj, keys));
  } else if (isObject(obj)) {
    obj = omit(obj, keys);
    Object.keys(obj).forEach((key) => {
      obj[key] = omitDeep(obj[key], keys);
    });
  }
  return obj;
}
