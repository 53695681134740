import type { RouteProp } from '@react-navigation/core';
import type { StackNavigationProp } from '@react-navigation/stack';

import { CoreRootStackParamList, DeeplinkConfigShape } from '@oui/app-core/src/types/navigation';

export type RelaxDiaryStackParamList = {
  Relaxation?: { initialExerciseSlug?: string };
};

export const RELAX_DIARY_DEEPLINK_CONFIG = {
  Relaxation: 'relax',
} as const satisfies DeeplinkConfigShape<keyof RelaxDiaryStackParamList>;

type FullStackParamList = RelaxDiaryStackParamList & CoreRootStackParamList;

export type StackScreenProps<
  T extends keyof RelaxDiaryStackParamList,
  Other extends object = {},
> = {
  navigation: StackNavigationProp<FullStackParamList, T>;
  route: RouteProp<FullStackParamList, T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends FullStackParamList {}
  }
}
