/* eslint-disable @typescript-eslint/no-explicit-any */

import { SetOptional } from 'type-fest';

import * as Avro from './types/avro';
import * as Scalars from './types/scalars';
import { GQLUUID } from './types/scalars';

type Composition = {
  readonly __typename: 'Composition';
  readonly ID: Scalars.GQLUUID;
  readonly title?: string | null;
  readonly sections: ReadonlyArray<{
    readonly __typename: 'CompositionSection';
    readonly ID: Scalars.GQLUUID;
    readonly title?: string | null;
    readonly json?: Scalars.GQLAny | null;
  }>;
};

export type CompositionSection<T, Title = string | null> = {
  ID: GQLUUID;
  title: Title;
  json: T;
};

export type MyStoryMyPlanCompositionSectionHashKeys =
  | 'APP_STATE'
  | 'PROFESSIONAL_HELP_CONTACTS'
  | 'HELP_CONTACTS'
  | 'SOCIAL_DISTRACTIONS'
  | 'COPING_STRATEGIES'
  | 'WARNING_SIGNS'
  | 'CRISIS_TIMELINE'
  | 'ENVIRONMENT_SAFETY'
  | 'RISK_FACTORS'
  | 'REASONS_FOR_LIVING';
type MyStoryMyPlanCompositionSectionTitleToTypeMapping = {
  APP_STATE: Avro.MyStoryMyPlanState;
  PROFESSIONAL_HELP_CONTACTS: Avro.ProfessionalHelpContacts;
  HELP_CONTACTS: Avro.HelpContacts;
  SOCIAL_DISTRACTIONS: Avro.SocialDistractions;
  COPING_STRATEGIES: Avro.CopingStrategies;
  WARNING_SIGNS: Avro.WarningSigns;
  CRISIS_TIMELINE: Avro.CrisisTimeline;
  ENVIRONMENT_SAFETY: Avro.EnvironmentSafety;
  RISK_FACTORS: Avro.RiskFactors;
  REASONS_FOR_LIVING: Avro.ReasonsForLiving | undefined;
};
export type MyStoryMyPlanCompositionSectionHash = SetOptional<
  {
    [P in MyStoryMyPlanCompositionSectionHashKeys]: CompositionSection<
      MyStoryMyPlanCompositionSectionTitleToTypeMapping[P],
      P
    >;
  },
  'REASONS_FOR_LIVING'
>;

export type MyStoryMyPlanCompositionDataHash = SetOptional<
  {
    [P in keyof MyStoryMyPlanCompositionSectionHash]: MyStoryMyPlanCompositionSectionHash[P] extends undefined
      ? NonNullable<MyStoryMyPlanCompositionSectionHash[P]>['json'] | undefined
      : NonNullable<MyStoryMyPlanCompositionSectionHash[P]>['json'];
  },
  'REASONS_FOR_LIVING'
>;

export type MyStoryMyPlanCompositionSection =
  MyStoryMyPlanCompositionSectionHash[keyof MyStoryMyPlanCompositionSectionHash];

function getSectionHashFromComposition(
  composition: NonNullable<Composition>,
): MyStoryMyPlanCompositionSectionHash {
  return (
    composition.sections as unknown as ReadonlyArray<MyStoryMyPlanCompositionSection>
  ).reduce<MyStoryMyPlanCompositionSectionHash>((carry, section) => {
    if (section) {
      carry[section.title as any as keyof MyStoryMyPlanCompositionSectionHash] = section as any;
    }
    return carry;
  }, {} as any);
}

function getDataHashFromSectionsHash(
  sections: NonNullable<ReturnType<typeof getSectionHashFromComposition>>,
): MyStoryMyPlanCompositionDataHash {
  return Object.entries(sections).reduce<MyStoryMyPlanCompositionDataHash>(
    (carry, [key, section]) => {
      if (section) {
        (carry as any)[key as any] = section.json;
      }
      return carry;
    },
    {} as any,
  );
}

export function getDataHashFromMyPlanComposition(composition: NonNullable<Composition>) {
  return getDataHashFromSectionsHash(getSectionHashFromComposition(composition));
}
