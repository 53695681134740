import { createContext, PropsWithChildren, ReactNode, useContext } from 'react';

import { useTheme } from '@oui/app-core/src/styles';

type SleepDiaryTheme = {
  mainColor: string;
  name: string;
  practiceName: string;
  morningBackground?: ReactNode;
  nightBackground?: ReactNode;
};

const SleepDiaryContext = createContext<SleepDiaryTheme>({
  mainColor: 'black',
  name: '----',
  practiceName: '----',
  morningBackground: null,
  nightBackground: null,
});

export function SleepDiaryProvider({
  children,
  ...props
}: PropsWithChildren<Partial<SleepDiaryTheme>>) {
  const { theme } = useTheme();
  return (
    <SleepDiaryContext.Provider
      value={{
        mainColor: theme.color.dark,
        name: 'Sleep Diary',
        practiceName: 'Sleep diary',
        ...props,
      }}
    >
      {children}
    </SleepDiaryContext.Provider>
  );
}

export function useSleepDiaryContext() {
  return useContext(SleepDiaryContext);
}
