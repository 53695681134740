import { NetworkStatus, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import endOfWeek from 'date-fns/endOfWeek';
import getHours from 'date-fns/getHours';
import startOfWeek from 'date-fns/startOfWeek';
import * as Localization from 'expo-localization';
import * as Notifications from 'expo-notifications';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback, useEffect } from 'react';
import { Platform, RefreshControl, SafeAreaView } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Icon } from '@oui/app-core/src/components/Icon';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Heading, Small } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Environment, environment } from '@oui/app-core/src/constants';
import { useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { logEvent } from '@oui/app-core/src/lib/log';
import { setDeviceInfo } from '@oui/app-core/src/lib/setDeviceInfo';
import { useTheme } from '@oui/app-core/src/styles';
import { formatGQLDate } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { DailyPractice, DailyPracticeFragment } from '@src/components/DailyPractice/DailyPractice';
import { LifelineBanner } from '@src/components/LifelineBanner/LifelineBanner';
import { NewPatientSupporterNotification } from '@src/components/NewPatientSupporterNotification';
import { NextSession, NextSessionFragment } from '@src/components/NextSession/NextSession';
import { QuickActions, QuickActionsFragment } from '@src/components/QuickActions/QuickActions';
import { RequiredClinicianUpgrade } from '@src/components/RequiredClinicianUpgrade';
import Sentry from '@src/sentry';

export const HomeFeedQuery = graphql(
  `
    query HomeFeed($progressBefore: Date!, $progressAfter: Date!) {
      user {
        ID
        role {
          ID
          sessions {
            status
          }
          streak
          ...NextSession
          ...QuickActions
        }
      }
      ...DailyPractice
    }
  `,
  [DailyPracticeFragment, NextSessionFragment, QuickActionsFragment],
);

export const SetLocaleAndTimezoneMutation = graphql(`
  mutation SetLocaleAndTimezone($locale: Any!, $timezone: Any!) {
    locale: kvRespond(context: "user", key: "locale", data: $locale) {
      context
      key
    }
    timezone: kvRespond(context: "user", key: "timezone", data: $timezone) {
      context
      key
    }
  }
`);

export function Home() {
  const progressAfter = formatGQLDate(startOfWeek(new Date()));
  const progressBefore = formatGQLDate(endOfWeek(new Date()));
  const {
    data: progress,
    loading: progressLoading,
    refetch: refetchProgress,
    networkStatus,
  } = useProgressByContent();
  const apollo = useApolloClient();
  const { $t } = useI18n();
  const { theme } = useTheme();
  const insets = useSafeAreaInsets();

  const { data, refetch, loading } = useQuery(HomeFeedQuery, {
    variables: {
      progressAfter,
      progressBefore,
    },
  });

  const [setLocaleAndTimezone] = useMutation(SetLocaleAndTimezoneMutation);
  useEffect(() => {
    void setLocaleAndTimezone({
      variables: {
        locale: Localization.getLocales()[0].languageTag,
        timezone: Localization.getCalendars()[0].timeZone,
      },
    });
  }, [setLocaleAndTimezone]);

  useEffect(() => {
    async function checkPermission() {
      const currentPermissionStatus = await Notifications.getPermissionsAsync();
      if (currentPermissionStatus.granted) return;
      if (currentPermissionStatus.canAskAgain) {
        logEvent('push_permissions_request');
        const newPermissionStatus = await Notifications.requestPermissionsAsync();
        if (newPermissionStatus.granted) {
          logEvent('push_permissions_granted');
          void setDeviceInfo(apollo);
        } else {
          logEvent('push_permissions_denied');
        }
      } else {
        logEvent('push_permissions_not_enabled');
        Sentry.captureMessage('Push permissions not granted and cannot ask again', {
          extra: { currentPermissionStatus },
        });
      }
    }
    if (progress.MYPLAN?.completed && Platform.OS !== 'web') {
      void checkPermission();
    }
  }, [progress.MYPLAN?.completed, apollo]);

  const isFocused = useIsFocused();

  useFocusEffect(
    useCallback(() => {
      try {
        void refetch();
        void refetchProgress();
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }
    }, [refetch, refetchProgress]),
  );

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        void ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }
      return () => {
        if (Platform.OS !== 'web') {
          void ScreenOrientation.unlockAsync();
        }
      };
    }, []),
  );

  const myStoryMyPlanCompleteLoaded =
    !progressLoading ||
    // enabling addTypename has caused a slight change in refetch loading state behavior
    // Ideally we don't show the loading state once this data has already loaded for the first time
    networkStatus === NetworkStatus.refetch;

  if (!myStoryMyPlanCompleteLoaded) {
    return (
      <SafeAreaView>
        <View style={{ padding: 50 }}>
          <ActivityIndicator size="large" />
        </View>
      </SafeAreaView>
    );
  }

  const currentHour = getHours(new Date());

  return (
    <RequiredClinicianUpgrade>
      <View style={{ flex: 1 }}>
        {isFocused ? <SystemBars style="dark" /> : null}
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={!!(data?.user?.role?.ID && (loading || progressLoading))}
              onRefresh={() => {
                void refetch();
                void refetchProgress();
              }}
              title={$t({ id: 'Home_refreshIndicator', defaultMessage: 'Update' })}
              progressViewOffset={150}
            />
          }
          testID="Home_scrollView"
          style={{
            flex: 1,
            backgroundColor: theme.color.gray800,
          }}
          contentContainerStyle={{
            paddingTop: insets.top,
            paddingBottom: 20,
            flexGrow: 1,
          }}
          bottomOverflowColor={theme.color.gray800}
        >
          <LifelineBanner />
          <View row style={{ padding: 15, marginVertical: 15, justifyContent: 'space-between' }}>
            <Heading
              level={1}
              text={
                currentHour < 12
                  ? 'Good morning!'
                  : currentHour < 6
                    ? 'Good afternoon!'
                    : 'Good evening!'
              }
            />
            {data?.user?.role?.streak ? (
              <View row style={{ gap: 7 }}>
                <Icon name="flame" color="#F46A6A" />
                <Small text={`${data?.user?.role?.streak} day streak`} />
              </View>
            ) : null}
          </View>
          <View style={{ gap: 25 }}>
            <NewPatientSupporterNotification />
            <NextSession data={data?.user?.role} loading={loading} />
            <DailyPractice
              data={data}
              completedSession1={data?.user?.role?.sessions[0]?.status === 'COMPLETED'}
            />
            <QuickActions data={data?.user?.role} />
          </View>
        </ScrollView>
      </View>
    </RequiredClinicianUpgrade>
  );
}
