{
  "ID": "v2-session-10",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "MYPLAN_REVIEW"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Welcome back!",
          "Great to see you again.",
          "Like we've done in past sessions, let's start with a practice check."
        ]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["COPING_CARD_ADD", "COPING_CARD_REVIEW"],
                "previousSession": "COPING_CARDS"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "COPING_CARDS"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to practice with the Coping Cards since your last session? ",
                          "Not to worry. Coping Cards are very helpful so I'll make sure to put it in your practice to do list."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I saw you did some practice with Coping Cards. Great job.",
                    "How confident did you feel with the practice?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "Very unconfident",
                        "2": "Unconfident",
                        "3": "OK",
                        "4": "Confident",
                        "5": "Very confident"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "Practice makes perfect.",
                    "I'll make sure there's more practice with Coping Cards in the coming weeks."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "You've come so far. We've just got three more sessions left.",
          "The focus in these sessions will be to review what we've covered.",
          "We want to emphasize some of the key things we've talked about before.",
          "Today, we're going to review the Risk Curve and MyPlan."
        ]
      }
    },
    {
      "ID": "intro-pause-2",
      "type": "InputButton",
      "props": {
        "label": "Continue"
      }
    },
    {
      "ID": "intro-explain-riskcurvemyplan",
      "type": "ChatText",
      "props": {
        "text": [
          "If you recall, the Risk Curve is a way to describe how your suicide risk rises, and falls, over time.",
          "And we made MyPlan for you to use to decrease the risk of reaching the peak and going into crisis.",
          "When you notice your warning signs, use MyPlan to prevent you from reaching the peak."
        ]
      }
    },
    {
      "ID": "intro-pause-3",
      "type": "InputButton",
      "props": {
        "label": "Let's do the review"
      }
    },
    {
      "ID": "intro-5",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_MYPLAN_REVIEW_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_MYPLAN_REVIEW_VOICEOVER"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "quiz-question-1-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "MyPlan is for handling suicidal thoughts, not just when you're upset or down.",
          "Use it when you spot your suicidal warning signs."
        ]
      }
    },
    {
      "ID": "riskcurvemyplan-vignette",
      "type": "ChatText",
      "props": {
        "text": ["Let's now watch someone talking about reviewing the Risk Curve and MyPlan."]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "RISK_CURVE_MYPLAN_REVIEW_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "transition-to-outro-0a",
      "type": "ChatText",
      "props": {
        "text": [
          "We filled out the Risk Curve when you first started using this app.",
          "Let's now review how your risk rose, peaked and fell."
        ]
      }
    },
    {
      "ID": "risk-curve-review",
      "type": "InputArtifact",
      "props": {
        "artifactName": "RiskCurve",
        "params": {
          "step": "review"
        },
        "buttonText": "Review Risk Curve"
      },
      "param": {
        "retryText": ["Looks like you didn't review your Risk Curve."],
        "retryButton": "Retry",
        "completeNode": {
          "ID": "risk-curve-review-complete",
          "type": "ChatActivityPreview",
          "props": {
            "artifactName": "RiskCurve",
            "params": {
              "step": "review"
            },
            "linkText": "Review Risk Curve"
          }
        }
      }
    },
    {
      "ID": "transition-to-outro-0",
      "type": "ChatText",
      "props": {
        "text": [
          "Aside from reviewing your Risk Curve, it is also important to review MyPlan.",
          "Let's now give you a chance to review your MyPlan.",
          "We'll do this by using the MyPlan cards."
        ]
      }
    },
    {
      "ID": "myplan-review",
      "type": "InputArtifact",
      "props": {
        "artifactName": "MyPlanReview",
        "params": {},
        "buttonText": "Review MyPlan"
      },
      "param": {
        "retryText": ["Looks like you didn't review your MyPlan."],
        "retryButton": "Retry",
        "completeNode": {
          "ID": "myplan-review-complete",
          "type": "ChatActivityPreview",
          "props": {
            "artifactName": "MyPlanReview",
            "params": {},
            "linkText": "Review MyPlan"
          }
        }
      }
    },
    {
      "ID": "transition-to-outro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Good job. You've now reviewed the Risk Curve and MyPlan.",
          "Both are really important in keeping you safe."
        ]
      }
    },
    {
      "ID": "transition-to-outro-1-pause",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of every session, there are two final tasks.",
          "First, please write the lessons you learned from this session.",
          "As a reminder, lessons learned are the one or two most important things you took away from today's session."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what you've learned.",
          "Remember, using what you have learned is the best way to get benefit from this app.",
          "To help, set how many times this week you think you'll try to review MyPlan?"
        ]
      }
    },
    {
      "ID": "practice-frequency-store",
      "type": "Store",
      "param": {
        "key": "practiceFrequency",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputSlider",
          "props": {
            "minValue": 1,
            "maxValue": 7,
            "step": 1,
            "value": 3,
            "labels": {}
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "This week, I'll make sure reviewing MyPlan is on your to do list at least {{plural practiceFrequency \"# time\" \"# times\"}}.",
          "I'll also be sending you reminders to do the other items on your to do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "You have now learned a lot about the Risk Curve and MyPlan.",
          "Hopefully knowing this will help keep you safe. Take care."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
