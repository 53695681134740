import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback } from 'react';
import { Platform, RefreshControl } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';

import { PracticeTile } from '@oui/app-core/src/components/PracticeTile';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Environment, environment } from '@oui/app-core/src/constants';
import { useActionCounts } from '@oui/app-core/src/hooks/practices';
import { useCurrentPatient, useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { HopeKitPracticeTile, useHopeKitName } from '@oui/hope-kit';
import { RelaxPracticeTile } from '@oui/relax-diary';
import { SleepDiaryPracticeTile } from '@oui/sleep-diary';

import { Icon } from '@src/components/Icon';
import Sentry from '@src/sentry';
import { ActionType, TabScreenProps } from '@src/types';

const InpatientPraticeList = (props: {
  progress: ReturnType<typeof useProgressByContent>['data'];
  countByActionType: Partial<Record<ActionType | 'TOTAL', number>>;
}) => {
  const { navigate } = useNavigation<TabScreenProps<'Practice'>['navigation']>();
  const { $t } = useI18n();

  return (
    <>
      <View row spacing={20}>
        <PracticeTile
          testID="PracticeTile_myPlanReview"
          title={$t({ id: 'PracticeTile_myPlanReview_title', defaultMessage: 'MyPlan Review' })}
          description={$t({
            id: 'PracticeTile_myPlanReview_description',
            defaultMessage: 'Know how to use it to stop a crisis',
          })}
          color="#9f5aa3"
          icon="my-plan"
          onPress={() => navigate('MyPlanReview', {})}
          lockedUntil={props.progress.MYPLAN?.completed ? undefined : 1}
          practiceCount={props.countByActionType.MY_PLAN_REVIEW}
        />
        <RelaxPracticeTile
          onPress={() => navigate('Relaxation', {})}
          lockedUntil={props.progress.MYPLAN?.completed ? undefined : 1}
          practiceCount={props.countByActionType.RELAX}
        />
      </View>

      <View row spacing={20} style={{ marginTop: 20 }}>
        <HopeKitPracticeTile
          onPress={() => navigate('HopeKit', {})}
          lockedUntil={props.progress.HOPE_KIT?.completed ? undefined : 2}
          practiceCount={
            (props.countByActionType.HOPE_KIT_ADD ?? 0) +
            (props.countByActionType.HOPE_KIT_REVIEW ?? 0)
          }
        />
        <PracticeTile
          testID="PracticeTile_copingCards"
          title={$t({ id: 'PracticeTile_copingCards_title', defaultMessage: 'Coping cards' })}
          description={$t({
            id: 'PracticeTile_copingCards_description',
            defaultMessage: 'Practice your skills learned',
          })}
          color="#2461C3"
          icon="cards"
          onPress={() => navigate('CopingCards', {})}
          lockedUntil={props.progress.COPING_CARDS?.completed ? undefined : 3}
          practiceCount={
            (props.countByActionType.COPING_CARD_REVIEW ?? 0) +
            (props.countByActionType.COPING_CARD_ADD ?? 0)
          }
        />
      </View>
    </>
  );
};

const DefaultPraticeList = (props: {
  progress: ReturnType<typeof useProgressByContent>['data'];
  countByActionType: Partial<Record<ActionType | 'TOTAL', number>>;
}) => {
  const { navigate } = useNavigation<TabScreenProps<'Practice'>['navigation']>();
  const { $t } = useI18n();
  const hopeKitName = useHopeKitName();

  return (
    <>
      <Heading
        text={$t({
          id: 'Practice_acuteCrisisHeading',
          defaultMessage: 'Controlling a crisis',
        })}
        style={{ marginBottom: 12 }}
        level={2}
      />
      <View row spacing={20}>
        <PracticeTile
          testID="PracticeTile_myPlanReview"
          title={$t({ id: 'PracticeTile_myPlanReview_title', defaultMessage: 'MyPlan Review' })}
          description={$t({
            id: 'PracticeTile_myPlanReview_description',
            defaultMessage: 'Know how to use it to stop a crisis',
          })}
          color="#9f5aa3"
          icon="my-plan"
          onPress={() => navigate('MyPlanReview', {})}
          lockedUntil={props.progress.MYPLAN?.completed ? undefined : 1}
          practiceCount={props.countByActionType.MY_PLAN_REVIEW}
        />
        <PracticeTile
          testID="PracticeTile_hopeKit"
          title={hopeKitName}
          description={$t({
            id: 'PracticeTile_hopeKit_description',
            defaultMessage: 'Remind yourself of your hopes',
          })}
          color="#754178"
          icon="kit"
          onPress={() => navigate('HopeKit', {})}
          lockedUntil={props.progress.HOPE_KIT?.completed ? undefined : 2}
          practiceCount={
            (props.countByActionType.HOPE_KIT_ADD ?? 0) +
            (props.countByActionType.HOPE_KIT_REVIEW ?? 0)
          }
        />
      </View>

      <Heading
        text={$t({
          id: 'Practice_chronicCrisisHeading',
          defaultMessage: 'Manage risk factors',
        })}
        style={{ marginTop: 40, marginBottom: 12 }}
        level={2}
      />
      <View row spacing={20}>
        <PracticeTile
          testID="PracticeTile_activityDiary"
          title={$t({ id: 'PracticeTile_activityDiary_title', defaultMessage: 'Activity diary' })}
          description={$t({
            id: 'PracticeTile_activityDiary_description',
            defaultMessage: 'Do activities to lift your mood',
          })}
          color="#1B9FA0"
          icon="calendar"
          onPress={() => navigate('ActivityDiary', {})}
          lockedUntil={props.progress.ACTIVITY_PLANNING?.completed ? undefined : 3}
          practiceCount={
            (props.countByActionType.ACTIVITY_ADD ?? 0) +
            (props.countByActionType.ACTIVITY_RATE ?? 0)
          }
        />
        <RelaxPracticeTile
          onPress={() => navigate('Relaxation', {})}
          lockedUntil={props.progress.RELAXATION?.completed ? undefined : 4}
          practiceCount={props.countByActionType.RELAX}
        />
      </View>
      <View row spacing={20} style={{ marginTop: 20 }}>
        <SleepDiaryPracticeTile
          onPress={() => navigate('SleepDiary', {})}
          lockedUntil={props.progress.SLEEP?.completed ? undefined : 5}
          practiceCount={
            (props.countByActionType.SLEEP_DIARY_ENTRY_MORNING ?? 0) +
            (props.countByActionType.SLEEP_DIARY_ENTRY_NIGHT ?? 0)
          }
        />
        <PracticeTile
          testID="PracticeTile_thoughtDiary"
          title={$t({ id: 'PracticeTile_thoughtDiary_title', defaultMessage: 'Thought diary' })}
          description={$t({
            id: 'PracticeTile_thoughtDiary_description',
            defaultMessage: 'Classify thoughts, feelings & behaviors',
          })}
          color="#C1E3C9"
          icon="spot-it"
          onPress={() => navigate('ThoughtDiary', {})}
          lockedUntil={props.progress.SPOT_IT?.completed ? undefined : 6}
          practiceCount={
            (props.countByActionType.THOUGHT_DIARY_SPOT ?? 0) +
            (props.countByActionType.THOUGHT_DIARY_TEST ?? 0) +
            (props.countByActionType.THOUGHT_DIARY_SWITCH ?? 0)
          }
        />
      </View>
      <View row spacing={20} style={{ marginTop: 20 }}>
        <View flex={1}>
          <PracticeTile
            testID="PracticeTile_copingCards"
            title={$t({ id: 'PracticeTile_copingCards_title', defaultMessage: 'Coping cards' })}
            description={$t({
              id: 'PracticeTile_copingCards_description',
              defaultMessage: 'Practice your skills learned',
            })}
            color="#2461C3"
            icon="cards"
            onPress={() => navigate('CopingCards', {})}
            lockedUntil={props.progress.COPING_CARDS?.completed ? undefined : 9}
            practiceCount={
              (props.countByActionType.COPING_CARD_REVIEW ?? 0) +
              (props.countByActionType.COPING_CARD_ADD ?? 0)
            }
          />
        </View>
        <View flex={1} />
      </View>
    </>
  );
};

export function Practice() {
  const { theme } = useTheme();
  const isFocused = useIsFocused();
  const { data: progress, refetch, loading } = useProgressByContent();
  const { data: countByActionType, refetch: refetchActionCounts } = useActionCounts();
  const { $t } = useI18n();
  const { user } = useCurrentPatient();

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        void ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }
      try {
        void refetch();
        void refetchActionCounts();
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }
      return () => {
        if (Platform.OS !== 'web') {
          void ScreenOrientation.unlockAsync();
        }
      };
    }, [refetch, refetchActionCounts]),
  );

  return (
    <View style={{ flex: 1 }}>
      {isFocused ? <SystemBars style="dark" /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!!loading}
            onRefresh={() => {
              void Promise.all([refetch(), refetchActionCounts()]);
            }}
            title={$t({ id: 'Practice_refreshIndicator', defaultMessage: 'Update' })}
            progressViewOffset={150}
          />
        }
        testID="Practice_scrollView"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingBottom: 20,
        }}
      >
        <TabHeader heading={$t({ id: 'Practice_heading', defaultMessage: 'Practice' })} />
        <View
          style={{
            marginHorizontal: -20,
            backgroundColor: theme.color.dark,
            padding: 16,
            marginBottom: 20,
            justifyContent: 'center',
          }}
          row
          spacing={12}
        >
          <Icon name="practice" color="white" size={24} />
          <Text
            text={$t(
              {
                id: 'Practice_totalCompletedPractices',
                defaultMessage: '{count} total practices done',
              },
              { count: countByActionType.TOTAL ?? 0 },
            )}
            weight="semibold"
            size={21}
            color="white"
          />
        </View>
        {user?.role.product.slug === 'AVIVA_INPATIENT' ? (
          <InpatientPraticeList progress={progress} countByActionType={countByActionType} />
        ) : (
          <DefaultPraticeList progress={progress} countByActionType={countByActionType} />
        )}
      </ScrollView>
    </View>
  );
}
