import { PracticeTile } from '@oui/app-core/src/components/PracticeTile';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import { useSleepDiaryContext } from './SleepDiaryContext';

type SleepDiaryPracticeTileProps = {
  lockedUntil?: number;
  practiceCount?: number;
  onPress: () => unknown;
};

export const SleepDiaryPracticeTile: React.FC<SleepDiaryPracticeTileProps> = ({
  onPress,
  lockedUntil,
  practiceCount,
}) => {
  const { $t } = useI18n();
  const sleepDiaryContext = useSleepDiaryContext();

  return (
    <PracticeTile
      testID="PracticeTile_sleepDiary"
      title={sleepDiaryContext.practiceName}
      description={$t({
        id: 'PracticeTile_sleepDiary_description',
        defaultMessage: 'Track your sleep and get tips for better rest',
      })}
      color={sleepDiaryContext.mainColor}
      icon="moon"
      onPress={onPress}
      lockedUntil={lockedUntil}
      practiceCount={practiceCount}
    />
  );
};
