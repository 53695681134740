import { Icon, registerIcons } from '@oui/app-core/src/components/Icon';

import quotes from '../assets/icons/quotes.svg';

const CUSTOM_ICONS = {
  quotes,
};
type CustomIconsType = typeof CUSTOM_ICONS;

declare global {
  // eslint-disable-next-line
  namespace AppCore {
    // eslint-disable-next-line
    interface IconGlyphs extends CustomIconsType {}
  }
}

registerIcons(CUSTOM_ICONS);

export { Icon };
