import { createContext, PropsWithChildren, ReactNode, useContext } from 'react';

import { useI18n } from '@oui/app-core/src/lib/i18n';

type HopeKitTheme = {
  mainPracticeTileColor: string;
  mainColor: string;
  name: string;
  description: string;
  question: string;
  images?: {
    emptyState?: ReactNode;
    emptyStateSearch?: ReactNode;
  };
};

const HopeKitContext = createContext<HopeKitTheme>({
  mainPracticeTileColor: 'black',
  mainColor: 'black',
  name: '---',
  description: '---',
  question: '---',
  images: {
    emptyState: null,
    emptyStateSearch: null,
  },
});

export function HopeKitProvider({ children, ...props }: PropsWithChildren<Partial<HopeKitTheme>>) {
  const { $t } = useI18n();
  return (
    <HopeKitContext.Provider
      value={{
        name: 'Hope Kit',
        description: $t({
          id: 'HopeKitContext_description',
          defaultMessage: 'Remind yourself of your hopes',
        }),
        question: $t({
          id: 'HopeKitContext_question',
          defaultMessage: 'Why does this give me hope?',
        }),
        images: {
          emptyState: null,
          emptyStateSearch: null,
        },
        mainPracticeTileColor: '#754178',
        mainColor: '#FFFFFF',
        ...props,
      }}
    >
      {children}
    </HopeKitContext.Provider>
  );
}

export function useHopeKitContext() {
  return useContext(HopeKitContext);
}
