import { useMutation } from '@apollo/client';
import omit from 'lodash/omit';
import { ComponentProps, memo, useEffect, useState } from 'react';
import { Image, Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { Icon } from '@oui/app-core/src/components/Icon';
import { FullscreenMediaPlayer } from '@oui/app-core/src/components/MediaPlayer';
import { OverflowMenu, OverflowMenuOption } from '@oui/app-core/src/components/OverflowMenu';
import { Text } from '@oui/app-core/src/components/Text';
import { TextInput } from '@oui/app-core/src/components/TextInput';
import { View } from '@oui/app-core/src/components/View';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { getAuthorizationHeader } from '@oui/app-core/src/lib/auth';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { resumableUploadManager } from '@oui/app-core/src/lib/resumableUploadManager';
import Sentry from '@oui/app-core/src/sentry';
import { FragmentOf, graphql, readFragment, ResultOf } from '@oui/lib/src/graphql/tada';

import { useHopeKitContext } from '../components';
import { SwiperItemType } from '../screens/AddHopeKit';

export type Quote = { ID: string; text: string; author?: string };

export function QuoteBox(props: { quote: Quote; style?: StyleProp<ViewStyle>; preview?: boolean }) {
  return (
    <View
      style={[
        {
          borderWidth: 9,
          borderColor: '#1b9fa0',
          width: '100%',
          padding: props.preview ? 10 : '15%',
          backgroundColor: '#ecffff',
        },
        props.style,
      ]}
    >
      <View
        spacing={props.preview ? 6 : 18}
        style={{ overflow: 'hidden', height: '100%', width: '100%' }}
      >
        <Icon name="quotes" size={props.preview ? 20 : 42} color="#086365" />
        <Text
          text={props.quote.text}
          size={props.preview ? 12 : 21}
          color="#086365"
          weight="semibold"
        />
        {props.quote.author ? (
          <Text
            text={props.quote.author}
            size={props.preview ? 10 : 17}
            color="#086365"
            style={{ marginTop: 12 }}
          />
        ) : null}
      </View>
    </View>
  );
}

function WebImageWithHeaders(props: ComponentProps<typeof Image>) {
  const [source, setSource] = useState<typeof props.source>({ uri: undefined });

  useEffect(() => {
    async function followRedirect() {
      if (typeof props.source === 'object' && 'uri' in props.source) {
        if (props.source.uri?.includes('/hopeKit/')) {
          const result = await fetch(props.source.uri, {
            headers: props.source.headers,
          });
          const uri = URL.createObjectURL(await result.blob());
          return { uri };
        }
      }
      return props.source;
    }
    followRedirect().then(setSource);
  }, [props.source]);

  return <Image {...props} source={source} />;
}

export const UpdateHopeKitImageMutation = graphql(`
  mutation UpdateHopeKitImage($input: UpdateHopeKitImageInput!) {
    updateHopeKitImage(input: $input) {
      hopeKitImage {
        __typename
        hopeKitItemID
        reason
        staticUrl
      }
    }
  }
`);

export const UpdateHopeKitVideoMutation = graphql(`
  mutation UpdateHopeKitVideo($input: UpdateHopeKitVideoInput!) {
    updateHopeKitVideo(input: $input) {
      hopeKitVideo {
        __typename
        hopeKitItemID
        reason
        staticUrl
      }
    }
  }
`);

export const UpdateHopeKitQuoteMutation = graphql(`
  mutation UpdateHopeKitQuote($input: UpdateHopeKitQuoteInput!) {
    updateHopeKitQuote(input: $input) {
      hopeKitQuote {
        __typename
        hopeKitItemID
        reason
        text
        author
      }
    }
  }
`);

export const HopeKitItemFragment = graphql(`
  fragment HopeKitItem on HopeKitItem {
    ... on HopeKitImage {
      __typename
      hopeKitItemID
      reason
      staticUrl
    }
    ... on HopeKitVideo {
      __typename
      hopeKitItemID
      reason
      staticUrl
    }
    ... on HopeKitQuote {
      __typename
      hopeKitItemID
      text
      author
      reason
    }
  }
`);

export const HopeKitItem = memo(
  ({
    testID,
    item: _item,
    onDelete,
  }: {
    testID: string;
    item: FragmentOf<typeof HopeKitItemFragment>;
    onDelete: (item: ResultOf<typeof HopeKitItemFragment>) => void;
  }) => {
    const item = readFragment(HopeKitItemFragment, _item);
    const [fallbackUri, setFallbackUri] = useState<string>();
    const width = '100%';
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updateHopeKitImage] = useMutation(UpdateHopeKitImageMutation);
    const [updateHopeKitVideo] = useMutation(UpdateHopeKitVideoMutation);
    const [updateHopeKitQuote] = useMutation(UpdateHopeKitQuoteMutation);
    const { bind, data } = useForm<typeof item>(item);
    const { $t } = useI18n();
    const hopeKitContext = useHopeKitContext();

    useEffect(() => {
      const pendingItem = resumableUploadManager.getPendingUploadByCacheKey<SwiperItemType>(
        item.hopeKitItemID,
      );
      if (pendingItem?.metaData) {
        switch (pendingItem.metaData.type) {
          case 'asset': {
            let uri = pendingItem.metaData.value.uri;
            if (uri.startsWith('ph://')) {
              if (pendingItem.metaData.value.localUri) {
                uri = pendingItem.metaData.value.localUri;
              } else {
                Sentry.captureMessage('unable to fallback for HopeKitItem due to ph:// url', {
                  extra: {
                    hopeKitItemID: item.hopeKitItemID,
                    uri,
                    value: pendingItem.metaData.value,
                  },
                });
                break;
              }
            }
            setFallbackUri(uri);
            break;
          }
          case 'imageSearch': {
            setFallbackUri(
              pendingItem.metaData.value.contentUrl ?? pendingItem.metaData.value.thumbnailUrl,
            );
            break;
          }
        }
      }
    }, [item.hopeKitItemID]);

    function save() {
      switch (data.__typename) {
        case 'HopeKitQuote': {
          return updateHopeKitQuote({
            variables: {
              input: omit(data, ['__typename']),
            },
          });
        }
        case 'HopeKitImage': {
          return updateHopeKitImage({
            variables: {
              input: omit(data, ['__typename', 'staticUrl']),
            },
          });
        }
        case 'HopeKitVideo': {
          return updateHopeKitVideo({
            variables: {
              input: omit(data, ['__typename', 'staticUrl']),
            },
          });
        }
      }
    }

    const menu = (
      <OverflowMenu triggerTestID={`${testID}_moreButton`}>
        <OverflowMenuOption
          icon="edit"
          text={$t({ id: 'HopeKitItem_editButton', defaultMessage: 'Edit' })}
          onPress={() => setIsEditing(true)}
          testID={`${testID}_editButton`}
        />
        <OverflowMenuOption
          icon="bin"
          text={$t({ id: 'HopeKitItem_deleteButton', defaultMessage: 'Delete' })}
          onPress={() => onDelete(item)}
          testID={`${testID}_deleteButton`}
        />
      </OverflowMenu>
    );

    const ImageComponent = Platform.OS === 'web' ? WebImageWithHeaders : Image;
    return (
      <View spacing={15} testID={testID}>
        {data.__typename === 'HopeKitQuote' ? (
          <QuoteBox
            quote={{ text: data.text, author: data.author ?? '', ID: item.hopeKitItemID }}
            style={{ width: width, height: width }}
          />
        ) : item.__typename === 'HopeKitImage' && (item.staticUrl || fallbackUri) ? (
          <View>
            <ImageComponent
              testID={isLoading ? undefined : 'HopeKitItem_image'}
              source={{
                uri: item.staticUrl ?? fallbackUri,
                headers: {
                  Authorization: `${getAuthorizationHeader()}`,
                },
              }}
              style={{ width: width, aspectRatio: '1/1' }}
              resizeMode="cover"
              onLoad={() => setIsLoading(false)}
              onError={(e) => {
                Sentry.addBreadcrumb({
                  message: 'hope-kit-image-error',
                  data: { item, fallbackUri },
                });
                Sentry.captureException(new Error('HopeKitImage failed to load'), {
                  extra: {
                    originalError: e,
                    item,
                    fallbackUri,
                  },
                });
              }}
            />
            {isLoading ? (
              <View
                style={[
                  StyleSheet.absoluteFillObject,
                  { alignItems: 'center', justifyContent: 'center' },
                ]}
              >
                <ActivityIndicator />
              </View>
            ) : null}
          </View>
        ) : item.__typename === 'HopeKitVideo' && (item.staticUrl || fallbackUri) ? (
          <View testID="HopeKitItem_video" style={{ aspectRatio: 1, justifyContent: 'center' }}>
            <FullscreenMediaPlayer
              mediaAspectRatio={1}
              previewAspectRatio={1}
              uri={item.staticUrl || fallbackUri!}
              headers={{
                Authorization: `${getAuthorizationHeader()}`,
              }}
            />
          </View>
        ) : (
          <View style={{ width: width, height: width }}></View>
        )}
        {isEditing ? (
          <View style={{ paddingHorizontal: 20 }} spacing={12}>
            {item.__typename === 'HopeKitQuote' ? (
              <TextInput
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...bind('text' as any, {
                  label: $t({
                    id: 'HopeKitItem_quote_textLabel',
                    defaultMessage: 'Quote',
                  }),
                })}
                multiline
                inputStyle={{ minHeight: 140 }}
                placeholder={$t({
                  id: 'HopeKitItem_quote_textPlaceholder',
                  defaultMessage: 'What is the quote?',
                })}
              />
            ) : null}
            {item.__typename === 'HopeKitQuote' ? (
              <TextInput
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...bind('author' as any, {
                  label: $t({
                    id: 'HopeKitItem_quote_authorLabel',
                    defaultMessage: 'Author',
                  }),
                })}
                placeholder={$t({
                  id: 'HopeKitItem_quote_authorPlaceholder',
                  defaultMessage: 'Who said this quote?',
                })}
              />
            ) : null}
            <TextInput
              {...bind('reason', {
                label: hopeKitContext.question,
              })}
              multiline
              inputStyle={{ minHeight: 140 }}
              placeholder={$t({
                id: 'HopeKitItem_quote_reasonPlaceholder',
                defaultMessage:
                  'Does this inspire you? Motivate you? Give you a reason to keep living?',
              })}
            />
            <Button
              alignSelf="center"
              text={$t({ id: 'HopeKitItem_saveButton', defaultMessage: 'Save' })}
              onPress={async () => {
                await save();
                setIsEditing(false);
              }}
              testID="HopeKitItem_saveButton"
            />
          </View>
        ) : (
          <View
            style={{
              paddingHorizontal: 20,
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
            row
            spacing={12}
          >
            {data.reason ? (
              <View spacing={10} style={{ flex: 1 }}>
                <View
                  row
                  style={{
                    alignSelf: 'stretch',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text
                    text={$t({
                      id: 'HopeKitItem_reason',
                      defaultMessage: 'Why this gives me hope?',
                    })}
                    size={15}
                    weight="semibold"
                    style={{ flex: 1 }}
                  />
                  {menu}
                </View>
                <Text text={data.reason} size={17} />
              </View>
            ) : (
              <View />
            )}
            {data.reason ? null : menu}
          </View>
        )}
      </View>
    );
  },
);
