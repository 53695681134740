import { ParamListBase, StackNavigationState, TypedNavigator } from '@react-navigation/core';
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack';

import {
  ActivityDiary,
  ActivityPractice,
  EditActivityEvent,
  EditActivityPractice,
} from './screens/index';
import { ActivityDiaryStackParamList } from './types/navigation';

export function registerActivityDiaryScreens<T extends ParamListBase>(
  stack: TypedNavigator<
    T,
    StackNavigationState<ActivityDiaryStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >,
) {
  const Stack = stack as TypedNavigator<
    ActivityDiaryStackParamList,
    StackNavigationState<ActivityDiaryStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >;
  return (
    <>
      <Stack.Screen name="ActivityDiary" component={ActivityDiary} />
      <Stack.Screen name="ActivityPractice" component={ActivityPractice} />
      <Stack.Screen name="EditActivityEvent" component={EditActivityEvent} />
      <Stack.Screen name="NewActivityEvent" component={EditActivityEvent} />
      <Stack.Screen
        name="EditActivityPractice"
        component={EditActivityPractice}
        options={{
          title: 'Rate activity',
        }}
      />
    </>
  );
}
