import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';

import { graphql } from '@oui/lib/src/graphql/tada';
import { MyPlanFragment } from '@oui/myplan/src/components/MyPlan';
import { MyPlanTab } from '@oui/myplan/src/screens/MyPlanTab';

import { TabScreenProps } from '@src/types';

export const CRPQuery = graphql(
  `
    query CRP {
      user {
        ID
        role {
          ID
          ...MyPlan
        }
      }
    }
  `,
  [MyPlanFragment],
);

// CRP = "Crisis Response Plan". An alternative name for MyPlan to distinguish
// from other MyPlan screens
export default function CRP() {
  const navigation = useNavigation<TabScreenProps<'Aid'>['navigation']>();
  const { loading, data, refetch } = useQuery(CRPQuery);
  return (
    <MyPlanTab
      loading={loading}
      data={data?.user?.role ?? undefined}
      refetch={refetch}
      onEdit={() => navigation.navigate('EditMyPlan', {})}
    />
  );
}
