import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import { useLayoutEffect } from 'react';

import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import {
  RoundedSection,
  RoundedSectionNavigationOptions,
} from '@oui/app-core/src/components/RoundedSection';
import { useDefaultHeaderHeight } from '@oui/app-core/src/lib/getDefaultHeaderHeight';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { Theme, useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';

import { MyPlan, MyPlanFragment } from '../components/MyPlan';
import { StackScreenProps } from '../types/navigation';

function MyPlanHeaderButtons({ color, onPressEdit }: { color?: string; onPressEdit?: () => void }) {
  const { $t } = useI18n();
  return (
    <HeaderButtons>
      <HeaderItem
        iconName="edit"
        color={color}
        title=""
        onPress={onPressEdit}
        aria-label={$t({ id: 'MyPlan_editButton', defaultMessage: 'Edit MyPlan' })}
      />
    </HeaderButtons>
  );
}

const MyPlanRoundedOptions = ({
  defaultHeaderHeight,
  navigation,
  theme,
}: {
  defaultHeaderHeight: number;
  navigation: StackScreenProps<'MyPlan'>['navigation'];
  theme: Theme;
}): Partial<StackNavigationOptions> => ({
  ...RoundedSectionNavigationOptions({
    defaultHeaderHeight,
    tintColor: 'white',
    backgroundColor: theme.color.accentThree100,
  }),
  headerRight: ({ tintColor }) => {
    return (
      <MyPlanHeaderButtons
        color={tintColor}
        onPressEdit={() => {
          navigation.navigate('EditMyPlan', {});
        }}
      />
    );
  },
});

export const MyPlanRoundedQuery = graphql(
  `
    query MyPlanRounded {
      user {
        ID
        role {
          ID
          ...MyPlan
        }
      }
    }
  `,
  [MyPlanFragment],
);

export function MyPlanRounded(props: { preview?: boolean }) {
  const { theme } = useTheme();
  const { loading, data } = useQuery(MyPlanRoundedQuery);
  const { $t } = useI18n();
  const defaultHeaderHeight = useDefaultHeaderHeight();
  const navigation = useNavigation<StackScreenProps<'MyPlan'>['navigation']>();

  useLayoutEffect(() => {
    navigation.setOptions(MyPlanRoundedOptions({ defaultHeaderHeight, navigation, theme }));
  }, [defaultHeaderHeight, navigation, theme]);

  return (
    <RoundedSection
      color={theme.color.accentThree100}
      title={$t({ id: 'MyPlan_title', defaultMessage: 'MyPlan' })}
      preview={props.preview}
      testID="MyPlanRounded"
    >
      <MyPlan data={data?.user?.role ?? undefined} loading={loading} />
    </RoundedSection>
  );
}
