{
  "ID": "exit",
  "type": "Seq",
  "children": [
    {
      "ID": "write-progress-end",
      "type": "WriteProgress",
      "props": {
        "progress": "completed"
      }
    },
    {
      "ID": "start",
      "type": "ChatText",
      "props": {
        "text": [
          "{{#if (eq sessionName \"welcome\")}}We'll now take you to the main screen.{{else}}We'll now take you back to the main screen.{{/if}}"
        ]
      }
    },
    {
      "ID": "prompt-ready",
      "type": "InputChoice",
      "props": {
        "max": 1,
        "min": 1,
        "variant": "button",
        "label": {
          "0": "Ready!"
        }
      }
    },
    {
      "ID": "nav-home",
      "type": "SideEffectNavigation",
      "props": {
        "routeName": "home"
      }
    },
    {
      "ID": "END",
      "type": "End"
    }
  ]
}
