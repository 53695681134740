import { enablePatches, Patch } from 'immer';

import { getTemplateVariables } from './stateMachineTemplates';
import type { CMSEdge, CMSNode, CMSPrimaryExchange } from './types/cms';

enablePatches();

export type { CMSPrimaryExchange, CMSNode, CMSEdge };

export const ROOT_NODE_ID = 'start';

export const getDefaultCMSPrimaryExchange = (): CMSPrimaryExchange => ({
  schemaVersion: 1,
  nodes: {
    start: { ID: ROOT_NODE_ID, type: 'SessionStart', flow: { position: { x: 0, y: 0 } } },
    end: { ID: 'end', type: 'SessionEnd', flow: { position: { x: 0, y: 100 } } },
  },
  edges: {},
});

export function isCMSPrimaryExchange(maybeExchange: unknown): maybeExchange is CMSPrimaryExchange {
  return !!(
    maybeExchange &&
    typeof maybeExchange === 'object' &&
    'schemaVersion' in maybeExchange &&
    'nodes' in maybeExchange &&
    'edges' in maybeExchange
  );
}

export type SubSectionState = Record<string, { expanded: boolean } | undefined>;
export type Bounds = {
  x: number;
  y: number;
  width: number;
  height: number;
  valid?: boolean;
};

export const CANVAS_PADDING = 20;

// @ts-ignore
function isTruthy<T>(el?: T | null | undefined): el is T {
  if (el === null || typeof el === 'undefined') return false;
  return true;
}

export function immerToCMSPatches<T>([content, patches, inversePatches]: readonly [
  T,
  Patch[],
  Patch[],
]): [T, Array<{ payload: Patch; inverse: Patch }>] {
  return [content, patches.map((p, i) => ({ payload: p, inverse: inversePatches[i] }))];
}

export class PrimaryExchange {
  graph: CMSPrimaryExchange;
  private edgesBySourceNodeID: Record<string, CMSEdge[]> = {};
  private edgesByTargetNodeID: Record<string, CMSEdge[]> = {};

  constructor(graph: CMSPrimaryExchange) {
    this.graph = graph;
    this.cacheGraphLookups();
  }

  private cacheGraphLookups() {
    this.edgesBySourceNodeID = {};
    this.edgesByTargetNodeID = {};
    for (let key of Object.keys(this.graph.nodes)) {
      this.edgesBySourceNodeID[key] = [];
      this.edgesByTargetNodeID[key] = [];
    }
    for (let edge of Object.values(this.graph.edges)) {
      this.edgesBySourceNodeID[edge.source].push(edge);
      this.edgesByTargetNodeID[edge.target].push(edge);
    }
  }

  getOutgoers(node: CMSNode) {
    return this.edgesBySourceNodeID[node.ID].map((edge) => this.graph.nodes[edge.target]);
  }

  getIncomers(node: CMSNode) {
    return this.edgesByTargetNodeID[node.ID].map((edge) => this.graph.nodes[edge.source]);
  }

  getAncestors(nodeID: CMSNode['ID'], seen: Set<string> = new Set()) {
    if (seen.size === 0) {
      this.cacheGraphLookups();
    }

    const ancestors: CMSNode[] = [];

    for (let incomer of this.getIncomers(this.graph.nodes[nodeID])) {
      if (seen.has(incomer.ID)) continue;
      seen.add(incomer.ID);
      ancestors.push(incomer);
      ancestors.push(...this.getAncestors(incomer.ID, seen));
    }

    return ancestors;
  }

  getOutgoingEdges(node: CMSNode) {
    return this.edgesBySourceNodeID[node.ID];
  }

  addNode(node: CMSNode) {
    if (this.graph.nodes[node.ID]) {
      throw new Error('Graph already contains node');
    }
    this.graph.nodes[node.ID] = node;
  }

  addEdge(edge: CMSEdge) {
    if (!this.graph.nodes[edge.source]) {
      throw new Error('Edge has non-existent source node');
    }
    if (!this.graph.nodes[edge.target]) {
      throw new Error('Edge has non-existent target node');
    }
    this.graph.edges[edge.id] = edge;
  }

  private search(visitor: (node: CMSNode) => void, options: { method: 'dfs' | 'bfs' }) {
    this.cacheGraphLookups();
    const graphSize = Object.keys(this.graph.nodes).length + Object.keys(this.graph.edges).length;

    const rootNodeId = this.graph.nodes[ROOT_NODE_ID]
      ? ROOT_NODE_ID
      : Object.values(this.graph.nodes).find((n) => n.type === 'SessionStart')!.ID;
    const visitedNodes = new Set<string>();

    const rootNode = Object.values(this.graph.nodes).find((n) => n.ID === rootNodeId);
    const leafNodeIDs = [];
    if (!rootNode) throw new Error('Root node not found in graph');
    const nodesToVisit = [rootNode];
    visitedNodes.add(rootNodeId);

    function getNextNode() {
      if (options.method === 'dfs') {
        return nodesToVisit.pop()!;
      } else {
        return nodesToVisit.shift()!;
      }
    }

    let i = 0;
    while (nodesToVisit.length) {
      /* break if we're infinite looping */
      if (i > graphSize * 10) break;
      i = i + 1;
      const node = getNextNode();
      visitor?.(node);
      const outgoers = this.getOutgoers(node);

      if (outgoers.length === 0) {
        leafNodeIDs.push(node.ID);
      }

      for (let outgoer of outgoers) {
        if (visitedNodes.has(outgoer.ID)) continue;
        visitedNodes.add(outgoer.ID);
        nodesToVisit.push(outgoer);
      }
    }

    return {
      leafNodeIDs,
      visitedNodes,
    };
  }

  bfs(visitor?: (node: CMSNode) => void) {
    return this.search(visitor ?? (() => {}), { method: 'bfs' });
  }

  dfs(visitor?: (node: CMSNode) => void) {
    return this.search(visitor ?? (() => {}), { method: 'dfs' });
  }

  isValid() {
    type InvalidReason =
      | 'leaf'
      | 'orphan'
      | 'missing-variable'
      | 'missing-edge'
      | 'invalid-template';

    const customInvalidNodeIDs: Array<{ nodeID: string; reason: InvalidReason }> = [];
    const { leafNodeIDs, visitedNodes } = this.bfs((node) => {
      const outgoers = this.getOutgoingEdges(node);
      switch (node.type) {
        case 'ChatText': {
          const usedVariables = [];
          for (let text of node.props.text) {
            try {
              usedVariables.push(...getTemplateVariables(text));
            } catch (e) {
              customInvalidNodeIDs.push({ nodeID: node.ID, reason: 'invalid-template' });
            }
          }
          const ancestors = this.getAncestors(node.ID, new Set([node.ID]));
          const storesSet = new Set([
            ...ancestors.filter((a) => a.store).map((a) => a.store!.key),
            ...ancestors.filter((a) => a.type === 'Invoke').map((a) => a.param.service),
          ]);
          let invalid = false;
          for (let usedVar of usedVariables) {
            if (
              !storesSet.has(usedVar) &&
              // Sometimes customVariables can dot access into an ancestor store
              !storesSet.has(usedVar.split('.')[0])
            ) {
              invalid = true;
            }
          }
          if (invalid) customInvalidNodeIDs.push({ nodeID: node.ID, reason: 'missing-variable' });
          break;
        }
        case 'CMSWidget': {
          // require incomplete edge if we support skipping
          if (
            node.param.skipButton &&
            !outgoers.find((edge) => edge.sourceHandle === 'incomplete')
          ) {
            customInvalidNodeIDs.push({ nodeID: node.ID, reason: 'missing-edge' });
          }
        }
      }
    });

    const unvisitedNodeIDs = Object.keys(this.graph.nodes).filter((id) => !visitedNodes.has(id));

    const errors: typeof customInvalidNodeIDs = [
      ...new Set([
        ...(leafNodeIDs.length > 1
          ? leafNodeIDs.map((id) => ({ nodeID: id, reason: 'leaf' as const }))
          : []),
        ...unvisitedNodeIDs.map((id) => ({ nodeID: id, reason: 'orphan' as const })),
        ...customInvalidNodeIDs,
      ]),
    ];

    return {
      valid: errors.length === 0,
      errors,
    };
  }
}
