import { PracticeTile } from '@oui/app-core/src/components/PracticeTile';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import { useActivityDiaryContext } from '../ActivityDiaryContext';

type ActivityDiaryPracticeTileProps = {
  lockedUntil?: number;
  practiceCount?: number;
  onPress: () => unknown;
};

export const ActivityDiaryPracticeTile: React.FC<ActivityDiaryPracticeTileProps> = ({
  onPress,
  lockedUntil,
  practiceCount,
}) => {
  const { $t } = useI18n();
  const activityDiaryContext = useActivityDiaryContext();

  return (
    <PracticeTile
      testID="PracticeTile_activityDiary"
      title={$t({ id: 'PracticeTile_activityDiary_title', defaultMessage: 'Activity diary' })}
      description={$t({
        id: 'PracticeTile_activityDiary_description',
        defaultMessage: 'Do activities to lift your mood',
      })}
      color={activityDiaryContext.mainColor}
      icon="calendar"
      onPress={onPress}
      lockedUntil={lockedUntil}
      practiceCount={practiceCount}
    />
  );
};
