import { HeaderBackButton } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import { Contact } from 'expo-contacts';
import { createDraft, finishDraft, produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { SystemBars } from 'react-native-edge-to-edge';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { APP_SLUG } from '@oui/app-core/src/constants';
import { useAppState } from '@oui/app-core/src/hooks/useAppState';
import {
  MyStoryMyPlanCompositionDataContext,
  useMyStoryMyPlanCompositionSections,
  useMyStoryMyPlanCompositionSectionSubscription,
  useMyStoryMyPlanCompositionSectionSubscriptionData,
} from '@oui/app-core/src/hooks/useComposition';
import { useIframeProps } from '@oui/app-core/src/lib/iframeUtils';
import { logEvent } from '@oui/app-core/src/lib/log';
import { ContactsPicker } from '@oui/app-core/src/screens/ContactsPicker';
import { QuizSetProductVariantContext } from '@oui/app-core/src/screens/QuizSet';
import { transformExpoContactToMyStoryMyPlanContact } from '@oui/lib/src/transformExpoContactToResponseContact';
import { ProductVariant } from '@oui/lib/src/types';
import { MyStoryMyPlanState, SocialDistractions } from '@oui/lib/src/types/avro';

import { LocationPicker } from './LocationPicker';
import { useMaybeReplaceImageAssetKeyURIWithResumableUpload } from '../hooks/useMaybeReplaceImageAssetKeyURIWithResumableUpload';
import { PatientMyPlanIntroduction } from '../screens/PatientMyPlanIntroduction';
import { PatientMyPlanReview } from '../screens/PatientMyPlanReview';
import { PatientMyStoryIntroduction } from '../screens/PatientMyStoryIntroduction';
import { PatientMyStoryMyPlanOverview } from '../screens/PatientMyStoryMyPlanOverview';
import { PatientMyStoryNarrative } from '../screens/PatientMyStoryNarrative';
import { PatientMyStoryTimeline } from '../screens/PatientMyStoryTimeline';
import { PatientRiskCurveIntroduction } from '../screens/PatientRiskCurveIntroduction';
import { PatientRiskCurveReview } from '../screens/PatientRiskCurveReview';

const BLANK_LOCATION = {
  ID: '',
  name: '',
  latitude: 0,
  longitude: 0,
};
const CONTACT_EDITORS: MyStoryMyPlanState['currentEditor'][] = [
  'PATIENT__HELP_CONTACT_PICKER',
  'PATIENT__PROFESSIONAL_CONTACT_PICKER',
  'PATIENT__SOCIAL_CONTACT_PICKER',
  'PATIENT__SUPPORT_CONTACT_PICKER',
];

export const ControlledMyStoryMyPlan = ({
  hasParentSubscriptionContext,
}: {
  hasParentSubscriptionContext?: boolean;
}) => {
  const { loading, error, data } = hasParentSubscriptionContext
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      { ...useMyStoryMyPlanCompositionSectionSubscriptionData(), loading: false, error: undefined }
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useMyStoryMyPlanCompositionSectionSubscription({
        createIfUndefined: true,
      });
  const insets = useSafeAreaInsets();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return (
    <MyStoryMyPlanCompositionDataContext.Provider value={data}>
      {loading ? (
        <View
          style={{ padding: 50, paddingTop: 50 + insets.top, paddingBottom: 50 + insets.bottom }}
        >
          <ActivityIndicator size="large" />
        </View>
      ) : !data ? null : (
        <ControlledMyStoryMyPlanInner />
      )}
    </MyStoryMyPlanCompositionDataContext.Provider>
  );
};

const IS_EMBEDDED =
  // @ts-ignore
  process.env.STORYBOOK !== 'true' &&
  (global.window.parent?.location !== global.window.location || APP_SLUG !== 'oui-aviva');

const ControlledMyStoryMyPlanInner = () => {
  const { setOptions } = useNavigation();
  const { update } = useMyStoryMyPlanCompositionSections();
  const { data } = useMyStoryMyPlanCompositionSectionSubscriptionData();
  // Store isSavingRef so that cancel() doesn't overwrite changes we're saving in onChoose*
  const isSavingRef = useRef(false);
  const maybeReplaceImageAssetKeyURIWithResumableUpload =
    useMaybeReplaceImageAssetKeyURIWithResumableUpload();

  const currentEditor = data?.APP_STATE.currentEditor ?? 'CLINICIAN';
  const onChooseContacts = useCallback(
    async ({ contacts }: { contacts: Contact[] }) => {
      if (IS_EMBEDDED) return;

      function getSection() {
        if (currentEditor === 'PATIENT__HELP_CONTACT_PICKER') return 'HELP_CONTACTS';
        if (currentEditor === 'PATIENT__SOCIAL_CONTACT_PICKER') return 'SOCIAL_DISTRACTIONS';
        if (currentEditor === 'PATIENT__PROFESSIONAL_CONTACT_PICKER')
          return 'PROFESSIONAL_HELP_CONTACTS';
        if (currentEditor === 'PATIENT__SUPPORT_CONTACT_PICKER') return 'ENVIRONMENT_SAFETY';
        throw new Error('Invalid section');
      }

      const mySMyPContacts = await Promise.all(
        contacts.map(async (contact) => {
          const transformedContact = transformExpoContactToMyStoryMyPlanContact(contact);
          if (contact.image?.uri) {
            transformedContact.imageAssetKey = contact.image.uri;
          }
          const draft = createDraft(transformedContact);
          return finishDraft(
            await maybeReplaceImageAssetKeyURIWithResumableUpload(getSection(), draft),
          );
        }),
      );

      if (currentEditor) {
        isSavingRef.current = true;
        if (currentEditor === 'PATIENT__HELP_CONTACT_PICKER') {
          await update((curr) =>
            produce(curr, (draft) => {
              draft.APP_STATE.currentEditor = 'CLINICIAN';
              draft.HELP_CONTACTS.push(...mySMyPContacts);
            }),
          );
        } else if (currentEditor === 'PATIENT__SOCIAL_CONTACT_PICKER') {
          await update((curr) =>
            produce(curr, (draft) => {
              draft.APP_STATE.currentEditor = 'CLINICIAN';
              draft.SOCIAL_DISTRACTIONS.contacts.push(...mySMyPContacts);
            }),
          );
        } else if (currentEditor === 'PATIENT__PROFESSIONAL_CONTACT_PICKER') {
          await update((curr) =>
            produce(curr, (draft) => {
              draft.APP_STATE.currentEditor = 'CLINICIAN';
              draft.PROFESSIONAL_HELP_CONTACTS.push(
                ...mySMyPContacts.map((c) => ({ contact: c, location: BLANK_LOCATION })),
              );
            }),
          );
        } else if (currentEditor === 'PATIENT__SUPPORT_CONTACT_PICKER') {
          await update((curr) =>
            produce(curr, (draft) => {
              draft.APP_STATE.currentEditor = 'CLINICIAN';
              draft.ENVIRONMENT_SAFETY.supportContacts.push(...mySMyPContacts);
            }),
          );
        }
      }
    },
    [currentEditor, update, maybeReplaceImageAssetKeyURIWithResumableUpload],
  );

  const onChoosePlaces = useCallback(
    (places: SocialDistractions['places']) => {
      if (IS_EMBEDDED) return;

      isSavingRef.current = true;
      if (currentEditor === 'PATIENT__SOCIAL_PLACE_PICKER') {
        void update((curr) =>
          produce(curr, (draft) => {
            draft.APP_STATE.currentEditor = 'CLINICIAN';
            draft.SOCIAL_DISTRACTIONS.places.push(...places);
          }),
        );
      }
    },
    [currentEditor, update],
  );

  const cancel = useCallback(() => {
    if (isSavingRef.current === false) {
      void update((curr) =>
        produce(curr, (draft) => {
          draft.APP_STATE.currentEditor = 'CLINICIAN';
        }),
      );
    }
  }, [update]);

  useEffect(() => {
    if (data?.APP_STATE.currentStep === 'COMPLETE') {
      // The setOuiProgress call is performed by the clinician's software,
      // but it cannot create events in amplitude on behalf of the patient
      // so we log it here
      logEvent('session_completed', { session: 'MYPLAN' });
    }
  }, [data?.APP_STATE.currentStep]);

  useEffect(() => {
    if (IS_EMBEDDED) return;

    if (CONTACT_EDITORS.includes(currentEditor!)) {
      isSavingRef.current = false;
      setOptions({
        title: 'Select contacts',
        headerShown: true,
        headerRight: () => null,
        headerLeft: () => {
          return (
            <HeaderBackButton
              onPress={() => {
                cancel();
              }}
            />
          );
        },
      });
    } else if (currentEditor === 'PATIENT__SOCIAL_PLACE_PICKER') {
      setOptions({
        title: 'Select places',
        headerShown: true,
        headerRight: () => null,
        headerLeft: () => {
          return (
            <HeaderBackButton
              onPress={() => {
                cancel();
              }}
            />
          );
        },
      });
    } else {
      setOptions({ headerShown: false });
    }
  }, [currentEditor, setOptions, cancel]);

  if (currentEditor !== 'CLINICIAN') {
    if (IS_EMBEDDED) {
      return (
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <View
            style={[
              StyleSheet.absoluteFillObject,
              { filter: 'blur(2px)', paddingVertical: 10, pointerEvents: 'none' } as any, // eslint-disable-line
            ]}
          >
            <ContactsPicker />
          </View>
          <Text text="Waiting for patient..." weight="semibold" />
        </View>
      );
    }

    if (CONTACT_EDITORS.includes(currentEditor!)) {
      return (
        <>
          <SystemBars style="dark" />
          <ContactsPicker
            onChooseContacts={(contacts) => {
              onChooseContacts(contacts).catch(Sentry.captureException);
            }}
          />
        </>
      );
    } else if (currentEditor === 'PATIENT__SOCIAL_PLACE_PICKER') {
      return (
        <>
          <SystemBars style="dark" />
          <LocationPicker label="Places to go that provide distraction" onChoose={onChoosePlaces} />
        </>
      );
    }
  }

  switch (data?.APP_STATE.currentStep) {
    case 'OVERVIEW':
      return <PatientMyStoryMyPlanOverview testID="ControlledMyStoryMyPlanOverview" />;
    case 'MYSTORY__INTRODUCTION':
      return <PatientMyStoryIntroduction />;
    case 'MYSTORY__TIMELINE':
      return <PatientMyStoryNarrative />;
    case 'MYSTORY__TIMELINE_REVIEW':
      return <PatientMyStoryTimeline review={true} />;
    case 'MYSTORY__TIMELINE_FINAL':
      return <PatientMyStoryTimeline review={false} />;
    case 'RISK_CURVE__INTRODUCTION':
      return <PatientRiskCurveIntroduction />;
    case 'RISK_CURVE__REVIEW':
      return <PatientRiskCurveReview />;
    case 'MY_PLAN__INTRODUCTION':
      return <PatientMyPlanIntroduction />;
    case 'MY_PLAN__REVIEW':
      return <PatientMyPlanReview />;
    case 'COMPLETE':
      return <PatientMyStoryMyPlanOverview isComplete />;
    default:
      return null;
  }
};

export const PatientControlledMyStoryMyPlan = () => {
  const [isActive, setIsActive] = useState(true);
  useAppState(
    useCallback((newState) => {
      setIsActive(newState === 'active');
    }, []),
  );
  return (
    <>
      <SystemBars style="light" />
      {isActive ? <ControlledMyStoryMyPlan /> : null}
    </>
  );
};

export const ClinicianControlledMyStoryMyPlanPreview = () => {
  const props = useIframeProps('ClinicianControlledMyStoryMyPlanPreviewEvent');
  if (!props) return null;
  return (
    <QuizSetProductVariantContext.Provider
      value={props.productVariant ?? ProductVariant.AVIVA_ADULT}
    >
      <MyStoryMyPlanCompositionDataContext.Provider value={props.subscriptionData ?? null}>
        <ControlledMyStoryMyPlan hasParentSubscriptionContext />
      </MyStoryMyPlanCompositionDataContext.Provider>
    </QuizSetProductVariantContext.Provider>
  );
};
