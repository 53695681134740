import { useMutation } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import equals from 'fast-deep-equal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { useDebounce } from 'use-debounce';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import {
  CardStack,
  Card as CardStackCard,
  CardStackRef,
} from '@oui/app-core/src/components/CardStack';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { DateTimeInput } from '@oui/app-core/src/components/DateTimeInput';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { Icon } from '@oui/app-core/src/components/Icon';
import { RadioInput } from '@oui/app-core/src/components/RadioInput';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Lead, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  SleepDiaryConfigInfluencerFrequency,
  useSleepDiaryConfig,
} from '@oui/app-core/src/hooks/practices';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';
import { recordKeys } from '@oui/lib/src/recordKeys';

import { getSleepDelayItems, SLEEP_INFLUENCER_ICONS } from './SleepDiaryEntry';
import { useSleepDiaryContext } from '../components';
import { StackScreenProps } from '../types/navigation';

const SLEEP_INFLUENCER_LABELS = {
  caffeine: 'Drink caffeine?',
  alcohol: 'Drink alcohol?',
  tobacco: 'Smoke tobacco?',
  napping: 'Nap during the day?',
  exercise: 'Exercise 20+ min a day?',
  medicine: 'Take sleep medication?',
  deviceInBed: 'Use a device (cell, laptop, TV) in bed?',
};

function SleepInfluencerCard(props: {
  label: string;
  type: keyof typeof SLEEP_INFLUENCER_ICONS;
  value?: number;
  onChangeValue: (newValue: number) => void;
}) {
  const { theme } = useTheme();
  return (
    <View spacing={30}>
      <RadioInput
        testID={`SetupSleepDiary_influencer_${props.type}`}
        value={props.value}
        onChangeValue={props.onChangeValue}
        aria-label={props.label}
        label={() => {
          return (
            <View spacing={12} style={{ alignItems: 'center', alignSelf: 'center' }}>
              <View style={{ padding: 10, backgroundColor: '#d2dfff', borderRadius: 40 }}>
                <Icon
                  size={25}
                  name={SLEEP_INFLUENCER_ICONS[props.type]}
                  color={theme.color.dark}
                />
              </View>
              <Lead text={props.label} style={{ marginBottom: 25 }} textAlign="center" />
            </View>
          );
        }}
        items={[
          { label: 'Regularly', value: SleepDiaryConfigInfluencerFrequency.Regularly },
          { label: 'Sometimes', value: SleepDiaryConfigInfluencerFrequency.Sometimes },
          { label: 'Rarely', value: SleepDiaryConfigInfluencerFrequency.Rarely },
          { label: 'Never', value: SleepDiaryConfigInfluencerFrequency.Never },
        ]}
      />
    </View>
  );
}

export const SaveSleepDiaryConfigurationMutation = graphql(`
  mutation SaveSLeepDiaryConfiguration($context: String!, $key: String!, $data: Any!) {
    kvRespond(context: $context, key: $key, data: $data) {
      __typename
      context
      key
      value
    }
  }
`);

export function SetupSleepDiary() {
  const { addListener, navigate, replace, setOptions } =
    useNavigation<StackScreenProps<'SetupSleepDiary'>['navigation']>();
  const route = useRoute<StackScreenProps<'SetupSleepDiary'>['route']>();
  const { theme } = useTheme();
  const [step, setStep] = useState(0);
  const { data: persistedData, loading, isComplete } = useSleepDiaryConfig();
  const [saveSleepDiaryConfiguration] = useMutation(SaveSleepDiaryConfigurationMutation);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const cardStackRef = useRef<CardStackRef>(null);
  const { data, bind } = useForm<NonNullable<typeof persistedData>>({
    startHHMM: undefined,
    endHHMM: undefined,
    sleepDelay: undefined,
    caffeine: undefined,
    alcohol: undefined,
    tobacco: undefined,
    napping: undefined,
    exercise: undefined,
    medicine: undefined,
    deviceInBed: undefined,
    ...persistedData,
  });

  const { $t } = useI18n();

  const sleepDiaryContext = useSleepDiaryContext();

  useEffect(() => {
    return addListener('beforeRemove', (e) => {
      if (!isComplete && !showConfirmationModal) {
        e.preventDefault();
        setShowConfirmationModal(true);
      }
    });
  }, [isComplete, addListener, showConfirmationModal]);

  const [debouncedValue] = useDebounce(data, 500, { maxWait: 5000, equalityFn: equals });
  useEffect(() => {
    if (!loading) {
      const hasDefinedValue = !!Object.values(debouncedValue).find((v) => typeof v !== 'undefined');
      if (hasDefinedValue) {
        saveSleepDiaryConfiguration({
          variables: {
            context: 'sleepDiary',
            key: 'config',
            data: debouncedValue,
          },
        });
      }
    }
  }, [loading, saveSleepDiaryConfiguration, debouncedValue]);

  const fromSleepDiary = route.params?.fromSleepDiary;
  const goToSleepDiary = useCallback(() => {
    if (fromSleepDiary === 'true') {
      navigate('SleepDiary', {});
    } else {
      replace('SleepDiary', {});
    }
  }, [fromSleepDiary, navigate, replace]);

  useEffect(() => {
    setOptions({
      headerLeft:
        step === 0
          ? undefined
          : ({ tintColor }) => (
              <HeaderButtons>
                <HeaderItem
                  color={tintColor}
                  title=""
                  aria-label={step === 0 ? 'Close' : 'Previous'}
                  iconName={step === 0 ? 'close' : 'caret-left'}
                  onPress={() => {
                    setStep((s) => s - 1);
                  }}
                />
              </HeaderButtons>
            ),
      headerRight: () => (
        <HeaderButtons>
          <Button
            text={step === 0 ? 'Next' : 'Done'}
            testID={step === 0 ? 'SetupSleepDiary_nextButton' : 'SetupSleepDiary_saveButton'}
            onPress={() => {
              if (step === 0) {
                setStep(step + 1);
              } else {
                goToSleepDiary();
              }
            }}
          />
        </HeaderButtons>
      ),
    });
  }, [step, setOptions, goToSleepDiary]);

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      contentContainerStyle={{
        paddingHorizontal: 24,
        paddingVertical: 25,
        paddingBottom: 100,
        flexGrow: 1,
      }}
      testID="SetupSleepDiary_scrollView"
    >
      {loading && !persistedData ? (
        <ActivityIndicator />
      ) : step === 0 ? (
        <View style={{}} spacing={60}>
          <View spacing={12} style={{ marginHorizontal: 12 }}>
            <Text text="What time do you usually..." size={21} weight="semibold" role="heading" />
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View aria-hidden row flex={2}>
                <Text weight="semibold" text="In bed at" style={{ flex: 1 }} />
              </View>
              <DateTimeInput
                aria-label="In bed at"
                mode="time"
                minuteInterval={Platform.select({ default: 15, android: 5 })}
                {...bind('startHHMM', { 'aria-label': 'Get into bed' })}
                style={{ flex: 3 }}
              />
            </View>
            <View row style={{ justifyContent: 'space-between' }} spacing={20}>
              <View aria-hidden row flex={2}>
                <Text weight="semibold" text="Out of bed at" style={{ flex: 1 }} />
              </View>
              <DateTimeInput
                aria-label="Out of bed at"
                mode="time"
                minuteInterval={Platform.select({ default: 15, android: 5 })}
                {...bind('endHHMM', { 'aria-label': 'Get out of bed' })}
                style={{ flex: 3 }}
              />
            </View>
          </View>
          <View>
            <RadioInput
              label=""
              {...bind('sleepDelay', {
                label: 'How long does it normally take you to fall asleep?',
              })}
              labelSize="large"
              items={getSleepDelayItems($t)}
            />
          </View>
          <View>
            <RadioInput
              label=""
              {...bind('wakeupCount', {
                label: 'How often do you typically wake up during the night?',
              })}
              labelSize="large"
              items={[
                { label: 'Never', value: 0 },
                { label: '1-3 times', value: 1 },
                { label: '4-6 times', value: 4 },
                { label: 'More than 7 times', value: 7 },
              ]}
            />
          </View>
        </View>
      ) : step >= 1 && step <= 7 ? (
        <>
          <Text
            text="How often do you..."
            size={21}
            weight="semibold"
            color={theme.color.dark}
            textAlign="center"
            style={{ marginBottom: 30 }}
            role="heading"
          />
          <CardStack hideNextButtonIndexes={[0, 1, 2, 3, 4, 5, 6]} ref={cardStackRef}>
            {recordKeys(SLEEP_INFLUENCER_ICONS).map((influencer) => {
              const { onChangeValue, ...rest } = bind(influencer, {
                label: SLEEP_INFLUENCER_LABELS[influencer],
              });
              return (
                <CardStackCard key={influencer}>
                  <SleepInfluencerCard
                    {...rest}
                    onChangeValue={(newValue) => {
                      onChangeValue(newValue);
                      setTimeout(() => {
                        cardStackRef.current?.next();
                      }, 200);
                    }}
                    label={SLEEP_INFLUENCER_LABELS[influencer]}
                    type={influencer}
                  />
                </CardStackCard>
              );
            })}
          </CardStack>
        </>
      ) : null}
      <ConfirmationModal
        onCancel={() => {
          setShowConfirmationModal(false);
          goToSleepDiary();
        }}
        onConfirm={() => {
          setShowConfirmationModal(false);
        }}
        visible={showConfirmationModal}
        confirmText="Yes, finish"
        cancelText="Do it later"
        title="Finish setup?"
        description={$t(
          {
            id: 'SetupSleepDiary_confirmationModalTitle',
            defaultMessage: "You're almost done setting up your {item}. Would you like to finish?",
          },
          {
            item: sleepDiaryContext.name.toLowerCase(),
          },
        )}
      />
    </ScrollView>
  );
}
