import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';

import { MyPlanReview } from './screens/MyPlanReview/MyPlanReview';
import { MyPlanRounded } from './screens/MyPlanRounded';
import { SoloMyPlan } from './screens/SoloMyPlan';
import { SoloRiskCurve } from './screens/SoloRiskCurve';
import { SoloSuicideMode } from './screens/SoloSuicideMode';

registerChatArtifactPreviewComponent('MyPlan', () => MyPlanRounded);
registerChatArtifactPreviewComponent('MyPlanReview', () => MyPlanReview);
registerChatArtifactPreviewComponent('RiskCurve', () => SoloRiskCurve);
registerChatArtifactPreviewComponent('SoloMyPlan', () => SoloMyPlan);
registerChatArtifactPreviewComponent('SoloSuicideMode', () => SoloSuicideMode);
registerChatArtifactPreviewComponent('SuicideMode', () => SoloSuicideMode);

export * from './types/navigation';
export * from './register';
export { ClinicianControlledMyStoryMyPlanPreview } from './screens/ControlledMyStoryMyPlan';
