{
  "ID": "v2-session-03",
  "type": "Seq",
  "children": [
    {
      "ID": "setSessionName",
      "type": "Arg",
      "param": {
        "key": "sessionName",
        "func": "ArgAlways",
        "value": "ACTIVITY_PLANNING"
      }
    },
    {
      "ID": "setup",
      "type": "Exchange",
      "param": {
        "target": "setup"
      }
    },
    {
      "ID": "intro-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Welcome back!",
          "I'm really glad to see you again.",
          "Let's get started by looking at your practice."
        ]
      }
    },
    {
      "ID": "intro-practice-review-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-practice-review-mux-pred",
          "type": "Pred",
          "param": {
            "func": "PredValueStringEqual",
            "funcValue": "0",
            "body": {
              "ID": "intro-practice-review-mux-body",
              "type": "ActionsLength",
              "param": {
                "actionTypes": ["HOPE_KIT_ADD", "HOPE_KIT_REVIEW"],
                "previousSession": "HOPE_KIT"
              }
            }
          }
        },
        "map": {
          "true": {
            "ID": "intro-practice-review-none-mux",
            "type": "Mux",
            "param": {
              "body": {
                "ID": "into-practice-review-none-progress-pred",
                "type": "Pred",
                "param": {
                  "func": "PredDateTimeOccurredRecently",
                  "numHours": 12,
                  "body": {
                    "ID": "intro-practice-review-none-progress-lookup",
                    "type": "ProgressCompletedAt",
                    "param": {
                      "session": "HOPE_KIT"
                    }
                  }
                }
              },
              "map": {
                "true": {
                  "ID": "intro-practice-review-recent-session-true",
                  "type": "Mux",
                  "param": {
                    "body": {
                      "ID": "intro-practice-review-recent-session-true-random",
                      "type": "RandomNumber",
                      "param": {
                        "maxValueExclusive": "3"
                      }
                    },
                    "map": {
                      "0": {
                        "ID": "intro-practice-review-recent-session-true-0",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great you’re doing another session! Practices are important in making sure you make use of the skills you’ve learned. Next time, try doing the practice before moving onto the next session."
                          ]
                        }
                      },
                      "1": {
                        "ID": "intro-practice-review-recent-session-true-1",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "Great job starting another session today. If you can, try to make sure to do the suggested practices. You'll get the most out of this app by doing the practices."
                          ]
                        }
                      },
                      "2": {
                        "ID": "intro-practice-review-recent-session-true-2",
                        "type": "ChatText",
                        "props": {
                          "text": [
                            "It's great to see you start another session so quickly. One suggestion - try to do at least one practice between sessions. This helps reinforce what you've learned."
                          ]
                        }
                      }
                    }
                  }
                },
                "false": {
                  "ID": "intro-practice-review-none",
                  "type": "Seq",
                  "children": [
                    {
                      "ID": "intro-practice-review-none-text",
                      "type": "ChatText",
                      "props": {
                        "text": [
                          "It seems like you didn't get a chance to look at your Hope Kit since your last session?",
                          "Not to worry. The Hope Kit is very helpful so I'll make sure to put it in your practice to do list.",
                          "Practices usually only take a few minutes and you can do them anywhere, any time."
                        ]
                      }
                    }
                  ]
                }
              }
            }
          },
          "false": {
            "ID": "intro-practice-review-some",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-practice-review-some-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "I saw you did some practice with the Hope Kit. Great job.",
                    "How did you feel doing the practice?"
                  ]
                }
              },
              {
                "ID": "intro-practice-comfort-store",
                "type": "Store",
                "param": {
                  "key": "practiceComfort",
                  "context": "{{sessionName}}",
                  "target": {
                    "ID": "intro-practice-comfort",
                    "type": "InputSlider",
                    "props": {
                      "minValue": 1,
                      "maxValue": 5,
                      "step": 1,
                      "value": 3,
                      "labels": {
                        "1": "I didn't get it",
                        "2": "Bit unsure",
                        "3": "OK",
                        "4": "I got it",
                        "5": "I fully got it"
                      }
                    }
                  }
                }
              },
              {
                "ID": "intro-practice-review-some-text-2",
                "type": "ChatText",
                "props": {
                  "text": [
                    "Thanks for sharing that.",
                    "Practice makes perfect.",
                    "I'll make sure there's more practice with the Hope Kit in the coming weeks."
                  ]
                }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-pause-1",
      "type": "InputButton",
      "props": {
        "label": "Got it"
      }
    },
    {
      "ID": "intro-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Let's now talk about today's session.",
          "We're going to talk about 'getting going'.",
          "We will talk about what it is and why it is helpful."
        ]
      }
    },
    {
      "ID": "intro-what-is-activityplanning-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-what-is-activityplanning-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "What does 'getting going' involve?",
              "1": "This sounds a bit simple",
              "2": "Continue"
            }
          }
        },
        "map": {
          "1": {
            "ID": "intro-what-is-activityplanning-mux-1",
            "type": "Seq",
            "children": [
              {
                "ID": "intro-what-is-activityplanning-mux-1-text",
                "type": "ChatText",
                "props": {
                  "text": [
                    "It can be simple, but it is very powerful.",
                    "Research has shown getting active is one of the fastest ways to improve how you feel."
                  ]
                }
              },
              {
                "ID": "intro-what-is-activityplanning-mux-1-pause",
                "type": "InputButton",
                "props": { "label": "Ok. What's 'getting going' about?" }
              }
            ]
          }
        }
      }
    },
    {
      "ID": "intro-explain-activityplanning",
      "type": "ChatText",
      "props": {
        "text": [
          "When people think about therapy, often they think it's only about changing thoughts.",
          "But therapy can be more than that.",
          "This session shows how changing your actions can change how you feel."
        ]
      }
    },
    {
      "ID": "intro-learn-more-mux",
      "type": "Mux",
      "param": {
        "body": {
          "ID": "intro-learn-more-mux-choice",
          "type": "InputSingleChoice",
          "props": {
            "variant": "button",
            "label": {
              "0": "Can I learn more about this?",
              "1": "Continue"
            }
          }
        },
        "map": {
          "0": {
            "ID": "intro-learn-more-mux-0-text",
            "type": "ChatText",
            "props": {
              "text": ["Yes, of course!"]
            }
          }
        }
      }
    },
    {
      "ID": "intro-4",
      "type": "ChatText",
      "props": {
        "text": ["Let's start with the Learn Videos.", "When you're ready, click continue."]
      }
    },
    {
      "ID": "prompt-start-learn-voiceover",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "GETTING_GOING_VOICEOVER"
        },
        "buttonText": "Continue"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-voiceover-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-voiceover-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-voiceover-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "GETTING_GOING_VOICEOVER"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "quiz-question-3-followup",
      "type": "ChatText",
      "props": {
        "text": [
          "Doing active things, \"getting going\", can help you feel better.",
          "Let's now watch someone talking about their experience with Getting going."
        ]
      }
    },
    {
      "ID": "prompt-start-learn-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "GETTING_GOING_LEARN_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "learn-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "learn-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "learn-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "GETTING_GOING_LEARN_VIGNETTE"
        },
        "linkText": "Watch Learn Video"
      }
    },
    {
      "ID": "activityplanning-vignette-apply",
      "type": "ChatText",
      "props": {
        "text": [
          "Hopefully you can see how getting going can reduce your suicide risk.",
          "Let's now watch someone using the app to get going."
        ]
      }
    },
    {
      "ID": "prompt-start-apply-vignette",
      "type": "InputArtifact",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "GETTING_GOING_APPLY_VIGNETTE"
        },
        "buttonText": "Watch"
      },
      "param": {
        "retryText": ["Looks like you didn't complete the video."],
        "retryButton": "Retry",
        "skipButton": "Skip",
        "skipNode": {
          "ID": "apply-vignette-skip",
          "type": "Seq",
          "children": [
            {
              "ID": "apply-vignette-skip-text",
              "type": "ChatText",
              "props": {
                "text": ["No problem. You can always come back and watch later."]
              }
            }
          ]
        }
      }
    },
    {
      "ID": "apply-vignette-preview",
      "type": "ChatActivityPreview",
      "props": {
        "artifactName": "QuizSet",
        "params": {
          "slug": "GETTING_GOING_APPLY_VIGNETTE"
        },
        "linkText": "Watch Apply Video"
      }
    },
    {
      "ID": "transition-to-activityplanning-widget",
      "type": "ChatText",
      "props": {
        "text": [
          "You've now seen how to use the Activity Diary, and how it can be helpful.",
          "With the diary, you can schedule future events or enter past events.",
          "Let's start using yours."
        ]
      }
    },
    {
      "ID": "prompt-start-activityplanning",
      "type": "InputArtifact",
      "props": {
        "artifactName": "ActivityDiary",
        "buttonText": "Use Activity Diary"
      },
      "param": {
        "retryText": [
          "Looks like no activities were added.",
          "I know it can be hard to think of something to get going with at first. Try to keep it simple.",
          "Try adding an activity again."
        ],
        "retryButton": "Add activity",
        "skipButton": "I'll do it later",
        "skipNode": {
          "ID": "activityplanning-skip",
          "type": "ChatText",
          "props": {
            "text": ["Got it. Let's wrap up for today."]
          }
        },
        "completeNode": {
          "ID": "activityplanning-complete",
          "type": "Seq",
          "children": [
            {
              "ID": "activityplanning-preview",
              "type": "ChatActivityPreview",
              "props": {
                "artifactName": "ActivityDiary",
                "linkText": "View Activity Diary"
              }
            },
            {
              "ID": "activityplanning-complete-text",
              "type": "ChatText",
              "props": {
                "text": [
                  "Good job. You've now made a plan to get going.",
                  "I know it can be really hard to get going when you feel helpless or when it can feel like there is no point.",
                  "But hopefully you can see the benefits of getting going."
                ]
              }
            },
            {
              "ID": "activityplanning-complete-pause",
              "type": "InputButton",
              "props": {
                "label": "Got it"
              }
            }
          ]
        }
      }
    },
    {
      "ID": "intro-lessons-learned",
      "type": "ChatText",
      "props": {
        "text": [
          "As you know at the end of each session, there are two final tasks.",
          "First, please write the lessons you learned from this session.",
          "Lessons learned are the one or two key things you took away from today's session."
        ]
      }
    },
    {
      "ID": "lessonlearned",
      "type": "Exchange",
      "param": {
        "target": "lessonLearned"
      }
    },
    {
      "ID": "practice-text-1",
      "type": "ChatText",
      "props": {
        "text": [
          "Great! The other thing to do today is talk about practicing what we have gone over today.",
          "As you know, using what you have learned is the best way to benefit from this app.",
          "To help, set how many times this week you think you'll try to get going by planning and doing activities?"
        ]
      }
    },
    {
      "ID": "practice-frequency-store",
      "type": "Store",
      "param": {
        "key": "practiceFrequency",
        "context": "{{sessionName}}",
        "target": {
          "ID": "practice-frequency-input",
          "type": "InputSlider",
          "props": {
            "minValue": 1,
            "maxValue": 7,
            "step": 1,
            "value": 3,
            "labels": {}
          }
        }
      }
    },
    {
      "ID": "practice-frequence-arg",
      "type": "Arg",
      "param": { "func": "ArgCopyFromNodeResult", "key": "practiceFrequency" }
    },
    {
      "ID": "practice-text-2",
      "type": "ChatText",
      "props": {
        "text": [
          "Thanks for that.",
          "I'll make sure that getting going is on your to do list at least {{plural practiceFrequency \"# time\" \"# times\"}} this week.",
          "I'll also be sending you reminders to come back and do the items on your to do list."
        ]
      }
    },
    {
      "ID": "practice-pause-1",
      "type": "InputButton",
      "props": {
        "label": "I understand."
      }
    },
    {
      "ID": "outro-4",
      "type": "ChatText",
      "props": {
        "text": [
          "This is the end of today's session.",
          "Well done on finishing.",
          "I hope you're able to get going this week!",
          "Take care and see you soon."
        ]
      }
    },
    {
      "ID": "exit",
      "type": "Exchange",
      "param": {
        "target": "exit"
      }
    }
  ]
}
