import { ConvoNode } from '../../../stateMachine';

const promptBingesID = 'num-binges-confirm-no';

export const changeBiteProgressExchange: Extract<ConvoNode, { type: 'Seq' }> = {
  ID: 'changeBiteProgress',
  type: 'Seq',
  children: [
    {
      type: 'Arg',
      ID: 'loadPracticeStatus',
      param: { func: 'ArgInvoke', service: 'getChangeBiteProgressStatus' },
    },
    {
      type: 'Arg',
      ID: 'loadPracticeStatus-copy',
      param: { func: 'ArgCopyFromNodeResult', key: 'progressStatus' },
    },
    {
      type: 'Arg',
      ID: 'loadPracticeStatusEntry',
      param: { func: 'ArgAlways', key: 'progressStatusEntry', value: {} },
    },
    {
      ID: 'binges-mux',
      type: 'Mux',
      param: {
        body: {
          ID: 'prompt-binges-condition',
          type: 'Pred',
          param: {
            func: 'PredValueStringEqual',
            funcValue: 'true',
            body: {
              ID: 'argLookup-binges',
              type: 'Arg',
              param: { func: 'ArgTake', lookup: 'progressStatus.binges' },
            },
          },
        },
        map: {
          true: {
            ID: 'binges-true',
            type: 'Seq',
            children: [
              {
                ID: 'first-session-mux',
                type: 'Mux',
                param: {
                  body: {
                    ID: 'first-session-condition',
                    type: 'Pred',
                    param: {
                      func: 'PredValueStringEqual',
                      funcValue: '"OVEREATING_CYCLE"',
                      body: {
                        ID: 'argLookup-sessionName',
                        type: 'Arg',
                        param: { func: 'ArgTake', lookup: 'sessionName' },
                      },
                    },
                  },
                  map: {
                    true: {
                      ID: 'first-session-true',
                      type: 'GoTo',
                      param: { nextNodeID: promptBingesID },
                    },
                    false: {
                      ID: 'first-session-false',
                      type: 'Seq',
                      children: [],
                    },
                  },
                },
              },
              // 1. check if we have previous # of binges to ask about
              {
                ID: 'num-binges-mux',
                type: 'Mux',
                param: {
                  body: {
                    ID: 'num-binges-condition',
                    type: 'Pred',
                    param: {
                      func: 'PredValueStringEqual',
                      funcValue: 'null',
                      body: {
                        ID: 'argLookup-numBinges',
                        type: 'Arg',
                        param: { func: 'ArgTake', lookup: 'progressStatus.numBinges' },
                      },
                    },
                  },
                  map: {
                    true: {
                      ID: 'num-binges-null',
                      type: 'Seq',
                      children: [],
                    },
                    false: {
                      ID: 'num-binges-present',
                      type: 'Seq',
                      children: [
                        {
                          type: 'ChatText',
                          ID: 'num-binges-chat',
                          props: {
                            text: [
                              'In the Eating log, you said you binged {{progressStatus.numBinges}} times last week ({{progressStatus.lastWeekDateRange}}).',
                              'As best as you can remember, does this sound right?',
                            ],
                          },
                        },
                        {
                          ID: 'num-binges-confirm-mux',
                          type: 'Mux',
                          param: {
                            body: {
                              type: 'InputSingleChoice',
                              ID: 'num-binges-confirm',
                              props: { variant: 'button', label: { '0': 'Yes', '1': 'No' } },
                            },
                            map: {
                              0: {
                                ID: 'num-binges-confirm-yes',
                                type: 'Seq',
                                children: [
                                  {
                                    ID: 'num-binges-confirm-yes-arg',
                                    type: 'Arg',
                                    param: {
                                      func: 'ArgCopy',
                                      lookup: 'progressStatus.numBinges',
                                      destination: 'progressStatusEntry.binges',
                                    },
                                  },
                                  {
                                    ID: 'num-binges-confirm-yes-text',
                                    type: 'ChatText',
                                    props: { text: ['Thank you for sharing.'] },
                                  },
                                ],
                              },
                              1: {
                                ID: promptBingesID,
                                type: 'Seq',
                                children: [
                                  {
                                    ID: 'num-binges-confirm-no-text',
                                    type: 'ChatText',
                                    props: { text: ['How many times did you binge last week?'] },
                                  },
                                  {
                                    ID: 'num-binges-confirm-input',
                                    type: 'InputText',
                                    props: {
                                      keyboardType: 'numeric',
                                      placeholder: 'Enter number of times',
                                    },
                                  },
                                  {
                                    ID: 'num-binges-arg',
                                    type: 'Arg',
                                    param: {
                                      func: 'ArgCopyFromNodeResult',
                                      key: 'progressStatusEntry.binges',
                                    },
                                  },
                                  {
                                    ID: 'num-binges-confirm-no-text-2',
                                    type: 'ChatText',
                                    props: { text: ['Thank you for sharing.'] },
                                  },
                                ],
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
          false: { ID: 'binges-false', type: 'Seq', children: [] },
        },
      },
    },
    {
      ID: 'fadFixes-mux',
      type: 'Mux',
      param: {
        body: {
          ID: 'prompt-fadFixes-condition',
          type: 'Pred',
          param: {
            func: 'PredValueStringEqual',
            funcValue: 'true',
            body: {
              ID: 'argLookup-fadFixes',
              type: 'Arg',
              param: { func: 'ArgTake', lookup: 'progressStatus.fadFixes' },
            },
          },
        },
        map: {
          true: {
            ID: 'fadFixes-true',
            type: 'Seq',
            children: [
              {
                ID: 'num-fadFixes-prompt',
                type: 'ChatText',
                props: {
                  text: [
                    'In the last week, have you engaged in vomiting, misusing laxatives, diet pills, overexercising or fasting?',
                  ],
                },
              },
              {
                ID: 'num-fadFixes-confirm-mux',
                type: 'Mux',
                param: {
                  body: {
                    type: 'InputSingleChoice',
                    ID: 'num-fadFixes-confirm',
                    props: { variant: 'button', label: { '0': 'Yes', '1': 'No' } },
                  },
                  map: {
                    0: {
                      ID: 'num-fadFixes-confirm-yes',
                      type: 'Seq',
                      children: [
                        {
                          ID: 'num-fadFixes-confirm-yes-text',
                          type: 'ChatText',
                          props: { text: ['How many times?'] },
                        },
                        {
                          ID: 'num-fadFixes-confirm-input',
                          type: 'InputText',
                          props: {
                            keyboardType: 'numeric',
                            placeholder: 'Enter number of times',
                          },
                        },
                        {
                          ID: 'num-fadFixes-arg',
                          type: 'Arg',
                          param: {
                            func: 'ArgCopyFromNodeResult',
                            key: 'progressStatusEntry.fadFixes',
                          },
                        },
                        {
                          ID: 'num-fadFixes-confirm-yes-text-2',
                          type: 'ChatText',
                          props: { text: ['I appreciate you sharing that with me.'] },
                        },
                      ],
                    },
                    1: {
                      ID: 'num-fadFixes-confirm-no',
                      type: 'Seq',
                      children: [
                        {
                          ID: 'num-fadFixes-confirm-arg',
                          type: 'Arg',
                          param: {
                            func: 'ArgAlways',
                            key: 'progressStatusEntry.fadFixes',
                            value: 0,
                          },
                        },
                        {
                          ID: 'num-fadFixes-confirm-text',
                          type: 'ChatText',
                          props: { text: ['Appreciate you for sharing this.'] },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
          false: { ID: 'fadFixes-false', type: 'Seq', children: [] },
        },
      },
    },
    {
      ID: 'weight-mux',
      type: 'Mux',
      param: {
        body: {
          ID: 'prompt-weight-condition',
          type: 'Pred',
          param: {
            func: 'PredValueStringEqual',
            funcValue: 'true',
            body: {
              ID: 'argLookup-weight',
              type: 'Arg',
              param: { func: 'ArgTake', lookup: 'progressStatus.weight' },
            },
          },
        },
        map: {
          true: {
            ID: 'weight-true',
            type: 'Seq',
            children: [
              {
                ID: 'weight-prompt',
                type: 'ChatText',
                props: {
                  text: [
                    'And last question.',
                    'What is your current weight in pounds (approximately)?',
                  ],
                },
              },
              {
                ID: 'weight-confirm-input',
                type: 'InputText',
                props: { keyboardType: 'numeric' },
              },
              {
                ID: 'weight-arg',
                type: 'Arg',
                param: {
                  func: 'ArgCopyFromNodeResult',
                  key: 'progressStatusEntry.weight',
                },
              },
              {
                ID: 'weight-confirm-yes-text-2',
                type: 'ChatText',
                props: {
                  text: [
                    "I'm grateful that you shared that.",
                    '{{#if (eq sessionName "RELAPSE_PREVENTION")}}This is your last session so we won\'t ask you about this again but you can continue recording your weight from the profile screen.{{else}}We’ll ask you this every 4 weeks.{{/if}}',
                  ],
                },
              },
            ],
          },
          false: { ID: 'weight-false', type: 'Seq', children: [] },
        },
      },
    },
    {
      ID: 'response-mux',
      type: 'Mux',
      param: {
        body: {
          ID: 'response-mux-condition',
          type: 'ArgShape',
          param: {
            shape:
              '{{#or progressStatus.binges progressStatus.fadFixes progressStatus.weight}}true{{else}}false{{/or}}',
          },
        },
        map: {
          true: {
            ID: 'response-prompted',
            type: 'Seq',
            children: [
              {
                type: 'Arg',
                ID: 'saveProgressEntry',
                param: {
                  func: 'ArgInvoke',
                  service: 'saveChangeBiteProgressEntry',
                  args: [
                    {
                      // use {{jsonparse to get numbers instead of strings
                      binges: '{{jsonparse progressStatusEntry.binges}}',
                      fadFixes: '{{jsonparse progressStatusEntry.fadFixes}}',
                      weight: '{{jsonparse progressStatusEntry.weight}}',
                    },
                  ],
                },
              },
              {
                ID: 'outro',
                type: 'ChatText',
                props: { text: ['We’ve now entered this data in your progress tracker.'] },
              },
            ],
          },
          false: {
            ID: 'response-not-prompted',
            type: 'Seq',
            children: [
              {
                ID: 'not-prompted-outro',
                type: 'ChatText',
                props: {
                  text: [
                    'Your progress is up to date. I have nothing to check in with you about for now.',
                    "Let's continue",
                  ],
                },
              },
            ],
          },
        },
      },
    },
  ],
};
