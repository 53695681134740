import { ParamListBase, StackNavigationState, TypedNavigator } from '@react-navigation/core';
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack';

import { Relax } from './screens/index';
import { RelaxDiaryStackParamList } from './types/navigation';

export function registerRelaxDiaryScreens<T extends ParamListBase>(
  stack: TypedNavigator<
    T,
    StackNavigationState<RelaxDiaryStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >,
) {
  const Stack = stack as TypedNavigator<
    RelaxDiaryStackParamList,
    StackNavigationState<RelaxDiaryStackParamList>,
    StackNavigationOptions,
    StackNavigationEventMap,
    any // eslint-disable-line
  >;
  return (
    <>
      <Stack.Screen name="Relaxation" component={Relax} />
    </>
  );
}
