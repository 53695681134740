import { Platform } from 'react-native';

import { AspectRatio } from '@oui/app-core/src/components/AspectRatio';
import RNVideo from '@oui/app-core/src/components/RNVideo';
import { Text } from '@oui/app-core/src/components/Text';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import {
  ExplanationVideos,
  PatientMyStoryMyPlanContainer,
  PatientMyStoryMyPlanContainerSection,
  PatientMyStoryMyPlanQuestionAnswer,
} from '../components/PatientMyStoryMyPlanContainer';

export function PatientRiskCurveIntroduction() {
  const { $t } = useI18n();
  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientRiskCurveIntroduction_heading', defaultMessage: 'Risk curve' })}
      testID="PatientRiskCurveIntroduction"
    >
      <PatientMyStoryMyPlanContainerSection>
        <PatientMyStoryMyPlanQuestionAnswer
          question={$t({
            id: 'PatientRiskCurveIntroduction_question1',
            defaultMessage: 'What is it?',
          })}
          answer={$t({
            id: 'PatientRiskCurveIntroduction_answer1',
            defaultMessage: "Below is a diagram that introduces the 'suicide risk curve'.",
          })}
        />
      </PatientMyStoryMyPlanContainerSection>
      <PatientMyStoryMyPlanContainerSection alternate>
        <PatientMyStoryMyPlanQuestionAnswer
          question={$t({
            id: 'PatientRiskCurveIntroduction_videoLabel',
            defaultMessage: 'Diagram',
          })}
          answer={
            <AspectRatio aspectRatio={1037 / 720}>
              {Platform.OS === 'web' ? (
                <video
                  src={require('../assets/mystorymyplan/risk-curve.mp4')}
                  autoPlay
                  muted
                  playsInline
                  loop
                  width="100%"
                  height="100%"
                />
              ) : (
                <RNVideo
                  source={require('../assets/mystorymyplan/risk-curve.mp4')}
                  style={{ height: '100%', width: '100%' }}
                  resizeMode="contain"
                  repeat
                  muted
                />
              )}
            </AspectRatio>
          }
        />
      </PatientMyStoryMyPlanContainerSection>
      <PatientMyStoryMyPlanContainerSection>
        <Text
          text={$t({
            id: 'PatientRiskCurveIntroduction_videoDescription',
            defaultMessage:
              'The curve shows how over time, risk escalates to the point you want to take your life. Then the risk falls as time passes.',
          })}
          style={{ marginBottom: 24 }}
        />
        <PatientMyStoryMyPlanQuestionAnswer
          question={$t({
            id: 'PatientRiskCurveIntroduction_question2',
            defaultMessage: 'Why is this important?',
          })}
          answer={$t({
            id: 'PatientRiskCurveIntroduction_answer2',
            defaultMessage:
              'We want to help you to spot your warning signs when your risk starts to go up. That way, we can try stop the risk rising to keep you safe.',
          })}
        />
      </PatientMyStoryMyPlanContainerSection>
      <ExplanationVideos
        videos={[
          {
            caption: $t({
              id: 'PatientRiskCurveIntroduction_voiceoverCaption',
              defaultMessage:
                'Learn more about what the suicide risk curve is and how it applies to you.',
            }),
            slug: 'RISK_CURVE_VOICEOVER',
          },
          {
            caption: $t({
              id: 'PatientRiskCurveIntroduction_vignetteCaption',
              defaultMessage:
                'Watch how others organize what happened into their suicide risk curve. It may help you think about yours.',
            }),
            slug: 'RISK_CURVE_LEARN_VIGNETTE_COLLAB',
          },
        ]}
      />
    </PatientMyStoryMyPlanContainer>
  );
}
